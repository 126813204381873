import { inject, isDevMode } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { map } from 'rxjs';
import { AuthService } from "../../services/auth/auth.service";

export const PerunCapabilityGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const auth: AuthService = inject(AuthService);
  const router: Router = inject(Router);

  return auth.isAuthenticated$.pipe(map(authenticated => {
    let grantAccess: boolean = false;

    // Check the required resource capability of the user
    // TODO: Rewrite with ACL defined by state.url instead of route.data settings
    let resources: string | string[] = route.data['resource'] || [];
    if (typeof resources === 'string') {
      resources = [ resources ]
    }
    grantAccess = true
    for (let resource of resources) {
      grantAccess = grantAccess && auth.resources.includes(resource);
    }

    // In Development mode log authorization details
    if (isDevMode()) {
      console.group("Authorization by Perun Capability Guard")
      console.debug("Accessed route: " + route.url);
      console.debug("Required resources: ", resources);
      console.debug("User resources: ", auth.resources);
      if (grantAccess)
        console.debug("Access Granted");
      else
        console.debug("Access Denied");
      console.groupEnd();
    }

    // Show error if access denied
    if (!grantAccess)
      return router.createUrlTree(['/error/403'], {
        queryParams: {
          url: state.url
        }
      });

    return grantAccess;
  }));
}
