import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { tap } from "rxjs";
import { AuthService } from "../../services/auth/auth.service";
import { Login, Logout, UserProfileLoadFailed } from "./user.actions";

@Injectable()
export class UserEffects {
  constructor(
    private readonly actions$: Actions,
    private authService: AuthService,
    private router: Router
  ) {}

  login$ = createEffect( () => this.actions$.pipe(
      ofType(Login.type),
      tap(() => this.authService.login())
    ),
    { dispatch: false }
  );

  logout$ = createEffect( () => this.actions$.pipe(
      ofType(Logout.type),
      tap(() => this.authService.logout())
    ),
    { dispatch: false }
  );

  userProfileLoadFailed$ = createEffect( () => this.actions$.pipe(
      ofType(UserProfileLoadFailed.type),
      tap( () => this.router.navigate(['/error/500'], { skipLocationChange: true }) )
    ),
    { dispatch: false }
  )
}
