import { inject, isDevMode } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { map } from 'rxjs';
import { AuthService } from "../../services/auth/auth.service";

export const PerunCapabilityGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const auth:AuthService = inject(AuthService);

  return auth.isAuthenticated$.pipe(map(authenticated => {
    let grantAccess: boolean = false;

    // Redirect unauthenticated users to the login page
    // TODO: Set the return URL so that they are redirected back after authentication
    if (!authenticated)
      return auth.getLoginUrlTree();

    // Check the required resource capability of the user
    // TODO: Rewrite with ACL defined by state.url instead of route.data settings
    let resource: string = route.data['resource'] || '';
    grantAccess = auth.resources.includes(resource);

    // In Development mode log authorization details
    if (isDevMode()) {
      console.group("Authorization by Perun Capability Guard")
      console.debug("Target Resource: ", resource);
      console.debug("User Resources: ", auth.resources);
      if (grantAccess)
        console.debug("Access Granted");
      else
        console.debug("Access Denied");
      console.groupEnd();
    }

    // Show error if access denied
    if (!grantAccess)
      // Ugly hack to show the forbidden URL by: https://github.com/angular/angular/issues/17004
      return inject(Router).createUrlTree(['/error/403'], {
        queryParams: {
          url: state.url
        }
      });

    return grantAccess;
  }));
}
