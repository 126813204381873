/**
 * Contains all the API of Occupation Service
 */

export type IOCApiResponse = string;

/**
 * Information about public computer studyroom
 */
export interface IOCLocalityInfo {
  id: number,  // Locality Id
  name_cs: string,  // Czech locality name
  name_en: string,  // English locality name
  address: string,  // Locality address
  link: string,  // Web address
  available_computers: number,  // Current count of available computers
  total_computers: number,  // Total count of computers
  turnstile_info: IOCTurnstile,  // Turnstile
  schedules: IOCIntervalWithSchedule[],  // Schedules
  changes: IOCDateInterval[],  // Holiday schedules
  closures: IOCDateInterval[],  // Range when the computer room is closed
  additional_services: EOCAdditionalServices[],
  location_codes: {  // List of locality location codes
    computer_study_rooms: string[],
    entry_rooms: string[],
  },
}

export interface IOCInputSchedule {
  LocalityId: number,
  ScheduleType: EOCScheduleType,
  IntervalWithSchedule: IOCIntervalWithSchedule,
  EndOtherDaysInSchedule?: number,
}

export enum EOCScheduleType {
  SCHEDULE = 0,
  CLOSURE = 1
}

/**
 * Turnstile
 */
export interface IOCTurnstile {
  count: number,  // Current number of people in room
  limit: number,  // Maximal limit of studyroom
}

/**
 * Interval with date and schedule
 */
export interface IOCIntervalWithSchedule {
  id?: number,
  valid_from: string,  // From date
  valid_to?: string,  // To date
  schedule?: {
    sunday?: IOCTimeInterval[],
    monday?: IOCTimeInterval[],
    tuesday?: IOCTimeInterval[],
    wednesday?: IOCTimeInterval[],
    thursday?: IOCTimeInterval[],
    friday?: IOCTimeInterval[],
    saturday?: IOCTimeInterval[]
  }
}

/**
 * Time Interval
 */
export interface IOCTimeInterval {
  from: string,  // From time
  to: string  // To time
}

/**
 * Date interval
 */
export interface IOCDateInterval {
  id: number,
  from: string,  // From date
  to?: string  // To date
}

/**
 * Additional services enum
 */
export type EOCAdditionalServices = 'Printing' | 'DocumentBinding' | 'SellingOfficeSupplies' | 'Laminating';
