import { Component, OnInit } from '@angular/core';
import {select, Store} from "@ngrx/store";

import * as Reducers from 'src/app/core/store/reducers';
import {RouterOutlet} from "@angular/router";
import {fadein, slideFadeinRight, slideFadeinUp, zoomFadein} from "../router-animations";

@Component({
  selector: 'smart-layouts-modal',
  templateUrl: './layout-modal.component.html',
  styleUrls: ['./layout-modal.component.scss'],
  animations: [slideFadeinUp, slideFadeinRight, zoomFadein, fadein],
})
export class LayoutModalComponent implements OnInit {
  logo!: String
  isGrayTopbar!: Boolean
  isCardShadow!: Boolean
  isSquaredBorders!: Boolean
  isBorderless!: Boolean
  authPagesColor!: String
  routerAnimation!: String
  primaryColor!: string

  currentYear = new Date().getFullYear();

  constructor(private store: Store<any>) {
    this.store.pipe(select(Reducers.getSettings)).subscribe(state => {
      this.logo = state.settings.logo
      this.isGrayTopbar = state.settings.isGrayTopbar
      this.isCardShadow = state.settings.isCardShadow
      this.isSquaredBorders = state.settings.isSquaredBorders
      this.isBorderless = state.settings.isBorderless
      this.authPagesColor = state.settings.authPagesColor
      this.routerAnimation = state.settings.routerAnimation
      this.routerAnimation = state.settings.routerAnimation
      this.primaryColor = state.settings.primaryColor
    })
  }

  ngOnInit(): void {
  }

  routeAnimation(outlet: RouterOutlet, animation: string) {
    if (animation === this.routerAnimation) {
      return outlet.isActivated && outlet.activatedRoute.routeConfig!.path
    } else {
      return null
    }
  }

}
