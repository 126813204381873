import { createFeatureSelector, createSelector } from "@ngrx/store";
import { TasksState } from "./tasks.state";
import { ETaskStatus } from "../../../shared/api";
import { ETaskLocalState } from "../../util/task";

export const tasksFeatureName = "tasks";

export const selectTasksState = createFeatureSelector<TasksState>(tasksFeatureName);
export const selectTaskStateByUUID = (uuid: string) => createSelector(
  selectTasksState,
  (tasksState) => tasksState.tasks[uuid]
);
export const selectCompletedTasks = createSelector(
  selectTasksState,
  (tasksState) => Object.values(tasksState.tasks).filter(
    (task) => task.status == ETaskStatus.COMPLETED
  )
);
export const selectFailedTasks = createSelector(
  selectTasksState,
  (tasksState) => Object.values(tasksState.tasks).filter(
    (task) => task.status == ETaskStatus.FAILED || task.status == ETaskLocalState.SERV_UNAVAILABLE
  )
);
