import { menuContents } from "../menu.interface";

export const MainMenu: menuContents = [
/**
  {
    title: 'home',
    icon: 'fe fe-home',
    target: '/',
  },
*/
  {
    title: 'adminTools.title',
    items: [
      {
        title: 'externalSystems.security.title',
        icon: 'fe fe-shield',
        items: [
          {
            title: 'CSIRT Dashboard',
            target: 'https://dashboard.csirt.muni.cz/'
          }
        ]
      },
      {
        title: 'externalSystems.identity.title',
        icon: 'fa-regular fa-address-card',
        items: [
          {
            title: 'Perun',
            target: 'https://perun.aai.muni.cz/home'
          }
        ]
      },
      {
        title: 'externalSystems.infrastructure.title',
        icon: 'fe fe-server',
        items: [
          {
            title: 'Icinga monitoring',
            target: 'https://monitor.ics.muni.cz/'
          },
          {
            title: 'OpenStack',
            target: 'https://dashboard.cloud.muni.cz/'
          }
        ]
      },
      {
        title: 'adminTools.libraries.title',
        icon: 'fe fe-book-open',
        restrictTo: 'res:smart:libraries:sponsoring',
        items: [
          {
            title: 'adminTools.libraries.sponsoring.title',
            target: '/admin-tools/libraries/sponsoring',
            restrictTo: 'res:smart:libraries:sponsoring'
          }
        ]
      },
      {
        title: 'adminTools.m365.title',
        icon: 'fa-regular fa-address-book',
        restrictTo: 'res:smart:m365',
        items: [
          {
            title: 'adminTools.m365.securityGroups.title',
            target: '/admin-tools/m365/security-groups',
            restrictTo: 'res:smart:m365:security_groups:view',
          },
          {
            title: 'adminTools.m365.sharepoint.title',
            target: '/admin-tools/m365/sharepoint',
            restrictTo: 'res:smart:m365:storages:view',
          }
        ],
      },
      {
        title: 'adminTools.persons.title',
        // icon: 'fe fe-shield',
        icon: 'fe fe-user',
        restrictTo: 'res:smart:persons',
        items: [
          {
            title: 'adminTools.persons.banning.title',
            target: '/admin-tools/persons/banning',
            restrictTo: 'res:smart:persons:bans:view',
          },
          {
            title: 'adminTools.persons.fslogix.title',
            target: '/admin-tools/persons/fslogix',
            restrictTo: ['res:smart:pcsm:fslogix-profiles', 'res:smart:ucoadm'],
          }
        ]
      },
      {
        title: 'adminTools.computers.title',
        // icon: 'fe fe-shield',
        icon: 'fe fe-monitor',
        restrictTo: 'res:SMART_users',
        items: [
          {
            title: 'adminTools.computers.power.title',
            target: '/admin-tools/computers/power',
            restrictTo: ['res:SMART_users'],
          }
        ]
      },
      {
        title: 'adminTools.study-rooms.title',
        icon: 'fa-solid fa-landmark',
        restrictTo: 'res:smart:studyrooms',
        items: [
          {
            title: 'adminTools.study-rooms.schedule.title',
            target: '/admin-tools/study-rooms/schedule',
            restrictTo: 'res:smart:studyrooms:schedule:view',
          },
          {
            title: 'adminTools.study-rooms.reservation.title',
            target: '/admin-tools/study-rooms/reservation',
            restrictTo: 'res:smart:studyrooms:reservation:view',
          },
          {
            title: 'adminTools.study-rooms.occupation.shortTitle',
            target: '/admin-tools/study-rooms/occupation',
            restrictTo: 'res:smart:studyrooms:occupation:view',
          }
        ],
      },
      {
        title: 'externalSystems.web.title',
        icon: 'fe fe-compass',
        items: [
          {
            title: 'Umbraco',
            target: 'https://webcentrum.muni.cz/'
          }
        ]
      },
    ],
  },
/**
  {
    title: 'audit.title',
    items: [
      {
        title: 'audit.report',
        icon: 'fe fe-activity',
        items: [
          {
            title: 'audit.operations',
            target: '/reports/operations'
          }
        ],
      },
    ],
  },
*/
  {
    title: 'automation',
    items: [
      /**
       {
       title: 'processes.title',
       icon: 'fe fe-git-pull-request',
       items: [
       {
       title: 'processes.overview',
       target: '/processes/overview'
       },
       {
       title: 'processes.add',
       target: '/processes/add'
       },
       ],
       },
       */
      {
        title: 'tasks.title',
        icon: 'fe fe-play',
        items: [
          {
            title: 'tasks.overview',
            target: '/tasks/overview'
          },
          {
            title: 'tasks.add.title',
            target: '/tasks/add'
          },
        ],
      },
      /**
       {
       title: 'services.title',
       icon: 'fe fe-link',
       items: [
       {
       title: 'services.overview',
       target: '/services/overview'
       },
       {
       title: 'services.add',
       target: '/services/add'
       },
       ],
       },
       */
      {
        title: 'assets.title',
        icon: 'fe fe-package',
        restrictTo: 'res:SMART_users',
        items: [
          {
            title: 'assets.credentials',
            target: '/assets/credentials'
          },
          {
            title: 'assets.endpoints',
            target: '/assets/endpoints'
          }
        ],
      },
    ],
  },
]
