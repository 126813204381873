import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { environment } from "../../../../../environments/environment";

@Component({
  selector: 'smart-layouts-plain',
  templateUrl: './layout-plain.component.html',
  styleUrls: ['./layout-plain.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LayoutPlainComponent implements OnInit {

  appName: string = environment.appName;

  leftSiderCollapsed: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

}
