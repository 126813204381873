import { Component, OnInit } from '@angular/core';
import {select, Store} from "@ngrx/store";

import * as SettingsActions from 'src/app/core/store/settings/actions'
import * as Reducers from 'src/app/core/store/reducers'

@Component({
  selector: 'smart-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  isSidebarOpen: boolean | undefined;
  isMenuCollapsed: boolean | undefined
  isMenuShadow: boolean | undefined
  isMenuUnfixed: boolean | undefined
  menuLayoutType: string | undefined
  menuColor: string | undefined
  flyoutMenuColor: string | undefined
  systemLayoutColor: string | undefined
  isTopbarFixed: boolean | undefined
  isFooterDark: boolean | undefined
  isContentMaxWidth: boolean | undefined
  isAppMaxWidth: boolean | undefined
  isGrayBackground: boolean | undefined
  isGrayTopbar: boolean | undefined
  isCardShadow: boolean | undefined
  isSquaredBorders: boolean | undefined
  isBorderless: boolean | undefined
  routerAnimation: string | undefined
  locale: string | undefined
  leftMenuWidth: Number | undefined
  logo: string | undefined
  authPagesColor: string | undefined
  theme: string | undefined
  primaryColor: string | undefined
  buttonsHidden: boolean | undefined

  defaultColor = '#0000dc'
  window: any = window as any

  constructor(private store: Store<any>) {
    this.buttonsHidden = true
    this.store.pipe(select(Reducers.getSettings)).subscribe(state => {
      this.isSidebarOpen = state.settings.isSidebarOpen
      this.isMenuCollapsed = state.settings.isMenuCollapsed
      this.isMenuShadow = state.settings.isMenuShadow
      this.isMenuUnfixed = state.settings.isMenuUnfixed
      this.menuLayoutType = state.settings.menuLayoutType
      this.menuColor = state.settings.menuColor
      this.flyoutMenuColor = state.settings.flyoutMenuColor
      this.systemLayoutColor = state.settings.systemLayoutColor
      this.isTopbarFixed = state.settings.isTopbarFixed
      this.isFooterDark = state.settings.isFooterDark
      this.isContentMaxWidth = state.settings.isContentMaxWidth
      this.isAppMaxWidth = state.settings.isAppMaxWidth
      this.isGrayBackground = state.settings.isGrayBackground
      this.isGrayTopbar = state.settings.isGrayTopbar
      this.isCardShadow = state.settings.isCardShadow
      this.isSquaredBorders = state.settings.isSquaredBorders
      this.isBorderless = state.settings.isBorderless
      this.routerAnimation = state.settings.routerAnimation
      this.locale = state.settings.locale
      this.leftMenuWidth = state.settings.leftMenuWidth
      this.logo = state.settings.logo
      this.authPagesColor = state.settings.authPagesColor
      this.theme = state.settings.theme
      this.primaryColor = state.settings.primaryColor
    })
  }

  toggle() {
    this.store.dispatch(
      SettingsActions.SetSidebarState({isSidebarOpen: !this.isSidebarOpen})
    )
  }

  settingChange(value: boolean, setting: string) {
    this.store.dispatch(
      SettingsActions.ChangeSettings({settingItem: {[setting]: value}})
    )
  }

  setRouterAnimation(animation: any) {
    this.store.dispatch(
      SettingsActions.SetRouterAnimation({routerAnimation: animation})
    )
  }

  setWidth(value: number) {
    this.store.dispatch(
      SettingsActions.ChangeSettings({settingItem: {leftMenuWidth: value,}}),
    )
  }

  setTheme(nextTheme: any) {
    this.store.dispatch(
      SettingsActions.SetTheme({theme: nextTheme}),
    )
  }

  setNarrowView(isNarrow: boolean) {
    this.store.dispatch(
      SettingsActions.SetNarrowView({isAppMaxWidth: isNarrow})
    )
  }

  setNarrowContent(isNarrow: boolean) {
    this.store.dispatch(
      SettingsActions.SetNarrowContent({isContentMaxWidth: isNarrow})
    )
  }

  setPrimaryColor(e: any) {
    const color = e.target ? e.target.value : e
    const addStyles = () => {
      const styleElement = document.querySelector('#primaryColor')
      if (styleElement) {
        styleElement.remove()
      }
      const body = document.querySelector('body')
      const styleEl = document.createElement('style')
      const css = document.createTextNode(`:root { --kit-color-primary: ${color};}`)
      styleEl.setAttribute('id', 'primaryColor')
      styleEl.appendChild(css)
      // @ts-ignore
      body.appendChild(styleEl)
    }
    addStyles()
    this.store.dispatch(
      SettingsActions.SetThemeColor({primaryColor: color}),
    )
  }

  resetColor() {
    this.setPrimaryColor({target: {value: this.defaultColor}})
  }

  ngOnInit(): void {
  }

}
