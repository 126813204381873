import {Component, Input} from "@angular/core";
import {select, Store} from "@ngrx/store";
import * as Reducers from 'src/app/core/store/reducers'

@Component({
  selector: 'smart-system-acl',
  template: `
    <ng-content *ngIf="authorized"></ng-content>
  `,
})
export class ACLComponent {
  @Input() roles: any[] = []
  authorized: Boolean = false

  constructor(private store: Store<any>) {
    //this,store.pipe(select(Reducers))
  }
}
