import {
  Component,
  createComponent,
  EnvironmentInjector,
  NgModule,
  TemplateRef,
  ViewChild
} from '@angular/core'

import { IconDefinition } from '@ant-design/icons-angular'
import * as AllIcons from '@ant-design/icons-angular/icons'
import { NZ_ICONS } from "ng-zorro-antd/icon";

import { NzButtonModule } from 'ng-zorro-antd/button'
import { NzIconModule } from 'ng-zorro-antd/icon'
import { NzGridModule } from 'ng-zorro-antd/grid'
import { NzLayoutModule } from 'ng-zorro-antd/layout'
import { NzSpaceModule } from 'ng-zorro-antd/space'
import { NzAffixModule } from 'ng-zorro-antd/affix'
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb'
import { NzDropDownModule } from 'ng-zorro-antd/dropdown'
import { NzMenuModule } from 'ng-zorro-antd/menu'
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header'
import { NzPaginationModule } from 'ng-zorro-antd/pagination'
import { NzStepsModule } from 'ng-zorro-antd/steps'
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete'
import { NzCascaderModule } from 'ng-zorro-antd/cascader'
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox'
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker'
import { NzFormModule } from 'ng-zorro-antd/form'
import { NzInputModule } from 'ng-zorro-antd/input'
import { NzInputNumberModule } from 'ng-zorro-antd/input-number'
import { NzMentionModule } from 'ng-zorro-antd/mention'
import { NzRadioModule } from 'ng-zorro-antd/radio'
import { NzRateModule } from 'ng-zorro-antd/rate'
import { NzSelectModule } from 'ng-zorro-antd/select'
import { NzSliderModule } from 'ng-zorro-antd/slider'
import { NzSwitchModule } from 'ng-zorro-antd/switch'
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker'
import { NzTransferModule } from 'ng-zorro-antd/transfer'
import { NzTreeSelectModule } from 'ng-zorro-antd/tree-select'
import { NzUploadModule } from 'ng-zorro-antd/upload'
import { NzAvatarModule } from 'ng-zorro-antd/avatar'
import { NzBadgeModule } from 'ng-zorro-antd/badge'
import { NzCalendarModule } from 'ng-zorro-antd/calendar'
import { NzCardModule } from 'ng-zorro-antd/card'
import { NzCarouselModule } from 'ng-zorro-antd/carousel'
import { NzCollapseModule } from 'ng-zorro-antd/collapse'
import { NzCommentModule } from 'ng-zorro-antd/comment'
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions'
import { NzEmptyModule } from 'ng-zorro-antd/empty'
import { NzListModule } from 'ng-zorro-antd/list'
import { NzPopoverModule } from 'ng-zorro-antd/popover'
import { NzStatisticModule } from 'ng-zorro-antd/statistic'
import { NzTableModule } from 'ng-zorro-antd/table'
import { NzTabsModule } from 'ng-zorro-antd/tabs'
import { NzTagModule } from 'ng-zorro-antd/tag'
import { NzTimelineModule } from 'ng-zorro-antd/timeline'
import { NzToolTipModule } from 'ng-zorro-antd/tooltip'
import { NzTreeModule } from 'ng-zorro-antd/tree'
import { NzTypographyModule } from "ng-zorro-antd/typography";
import { NzAlertModule } from 'ng-zorro-antd/alert'
import { NzDrawerModule } from 'ng-zorro-antd/drawer'
import { NzMessageModule } from 'ng-zorro-antd/message'
import { NzModalModule } from 'ng-zorro-antd/modal'
import { NzNotificationModule } from 'ng-zorro-antd/notification'
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm'
import { NzProgressModule } from 'ng-zorro-antd/progress'
import { NzResultModule } from 'ng-zorro-antd/result'
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton'
import { NzSpinModule } from 'ng-zorro-antd/spin'
import { NzAnchorModule } from 'ng-zorro-antd/anchor'
import { NzBackTopModule } from 'ng-zorro-antd/back-top'
import { NzDividerModule } from 'ng-zorro-antd/divider'
import { NZ_CONFIG, NzConfig } from "ng-zorro-antd/core/config";

import { NZ_I18N, NZ_DATE_LOCALE, cs_CZ, en_US, NzI18nService } from "ng-zorro-antd/i18n";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { cs, enUS } from 'date-fns/locale';

/**
 * AntDesign Components
 */

const MODULES = [
  NzButtonModule,
  NzIconModule,
  NzGridModule,
  NzLayoutModule,
  NzSpaceModule,
  NzAffixModule,
  NzBreadCrumbModule,
  NzDropDownModule,
  NzMenuModule,
  NzPageHeaderModule,
  NzPaginationModule,
  NzStepsModule,
  NzAutocompleteModule,
  NzCascaderModule,
  NzCheckboxModule,
  NzDatePickerModule,
  NzFormModule,
  NzInputModule,
  NzInputNumberModule,
  NzMentionModule,
  NzRadioModule,
  NzRateModule,
  NzSelectModule,
  NzSliderModule,
  NzSwitchModule,
  NzTimePickerModule,
  NzTransferModule,
  NzTreeSelectModule,
  NzUploadModule,
  NzAvatarModule,
  NzBadgeModule,
  NzCalendarModule,
  NzCardModule,
  NzCarouselModule,
  NzCollapseModule,
  NzCommentModule,
  NzDescriptionsModule,
  NzEmptyModule,
  NzListModule,
  NzPopoverModule,
  NzStatisticModule,
  NzTableModule,
  NzTabsModule,
  NzTagModule,
  NzTimelineModule,
  NzToolTipModule,
  NzTreeModule,
  NzTypographyModule,
  NzAlertModule,
  NzDrawerModule,
  NzMessageModule,
  NzModalModule,
  NzNotificationModule,
  NzPopconfirmModule,
  NzProgressModule,
  NzResultModule,
  NzSkeletonModule,
  NzSpinModule,
  NzAnchorModule,
  NzBackTopModule,
  NzDividerModule,
]

/**
 * AntDesign Icons
 */
const antDesignIcons = AllIcons as {
  [key: string]: IconDefinition
}
const icons: IconDefinition[] = Object.keys(antDesignIcons).map(key => antDesignIcons[key])

/**
 * The module-level Component which contains template references.
 * Exporting is required for AOT compatibility
 */
@Component({
  template: `
    <ng-template #nzSpinnerTpl>
      <svg xmlns="http://www.w3.org/2000/svg"
        height="41px" width="41px" viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
        fill="none"
        class="smart-spinner">

        <circle r="35" cx="50" cy="50" stroke-width="10" stroke-opacity="0.75" fill="none" stroke-dasharray="164.93361431346415 56.97787143782138" transform="rotate(84 50 50)">
          <animateTransform attributeName="transform" type="rotate" calcMode="linear" values="0 50 50;360 50 50" keyTimes="0;1" dur="1s" begin="0s" repeatCount="indefinite"></animateTransform>
        </circle>
      </svg>
    </ng-template>
  `,
  styles: ['svg {position: absolute; top: calc(50% - 20.5px); left: calc(50% - 20.5px)}']
})
export class AntGlobalTemplatesComponent {
  @ViewChild('nzSpinnerTpl', { static: true })
  nzIndicator!: TemplateRef<void>;
}
// Factory function
const nzConfigFactory = (
  environmentInjector: EnvironmentInjector,
): NzConfig => {
  const antGlobalTemplatesComponent = createComponent(AntGlobalTemplatesComponent, { environmentInjector: environmentInjector });
  const nzIndicator = antGlobalTemplatesComponent.instance.nzIndicator;
  const nzLoadingIndicator = nzIndicator;
  return {
    spin: {
      nzIndicator
    },
    table: {
      nzLoadingIndicator
    }
  };
};

@NgModule({
  imports: [...MODULES],
  declarations: [
    AntGlobalTemplatesComponent
  ],
  providers: [
    { provide: NZ_CONFIG, useFactory: nzConfigFactory, deps: [EnvironmentInjector] },
    { provide: NZ_ICONS, useValue: icons },
    { provide: NZ_I18N, useFactory: (translate: TranslateService) => {
      switch (translate.currentLang?.substring(0,2)) {
        case 'cs':
          return cs_CZ;
        default:
          return en_US;
      }},
      deps: [TranslateService]
    },
    { provide: NZ_DATE_LOCALE, useFactory: (translate: TranslateService) => {
      switch (translate.currentLang?.substring(0,2)) {
        case 'cs':
          return cs;
        default:
          return enUS;
      }},
      deps: [TranslateService]
    },
  ],
  exports: [...MODULES],
})
export class AntdModule {
  constructor(
    private i18n: NzI18nService,
    private translateService: TranslateService) {

    this.translateService.onLangChange.subscribe((evt: LangChangeEvent) => {
      switch (evt.lang.substring(0,2)) {
        case 'cs':
          this.i18n.setLocale(cs_CZ);
          this.i18n.setDateLocale(cs);
          break;
        default:
          this.i18n.setLocale(en_US);
          this.i18n.setDateLocale(enUS);
      }
    });
  }
}
