import { Injectable } from '@angular/core';
import { RouterStateSnapshot, TitleStrategy } from "@angular/router";
import { Title } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class SMARTTitleStrategy extends TitleStrategy {

  private appTitle: string = 'MUNI SMART';

  constructor(private translate: TranslateService,
              private readonly title: Title) {
    super();
  }

  override updateTitle(snapshot: RouterStateSnapshot) {
    const title = this.buildTitle(snapshot);
    if (title === undefined) {
      this.setTitle(title);
    } else {
      this.translate.get(title).subscribe(translation => this.setTitle(translation) );
    }
  }

  setTitle(title: string | undefined) {
    if (title === undefined) {
      this.title.setTitle(this.appTitle);
    } else {
      this.title.setTitle(title.charAt(0).toUpperCase() + title.slice(1) + ' | ' + this.appTitle);
    }
  }
}
