<nz-avatar nzIcon="user" [nzShape]="'square'" [nzSize]="'large'" [ngClass]="userMenuAvatarClass"
           nz-dropdown nzTrigger="click" [nzDropdownMenu]="userMenu">
</nz-avatar>

<nz-dropdown-menu #userMenu="nzDropdownMenu">
  <ul nz-menu id="user-menu">
    <li class="user-name">
      {{ (user$ | async)?.full_name || 'Anonymous'}}
    </li>
    <li nz-menu-divider></li>
    <li nz-menu-item routerLink="my-tasks">
      <i class="fe fe-play me-2"></i>
      {{'user.tasks.title' | translate | firstLetterCapital}}
    </li>
    <li nz-menu-item (click)="showSettings()">
      <i class="fe fe-settings me-2"></i>
      {{'settings.title' | translate | firstLetterCapital}}
    </li>
    <a href="https://servicedesk-muni.atlassian.net/l/cp/WU4uRXXu" target="_blank">
      <li nz-menu-item>
        <i class="fe fe-help-circle me-2"></i>
        {{'user.menu.help' | translate | firstLetterCapital}}
      </li>
    </a>
    <li nz-menu-divider></li>
    <li nz-menu-item (click)="logout()">
      <i class="fe fe-log-out me-2"></i>
      {{'profile.logout' | translate | firstLetterCapital}}
    </li>
  </ul>
</nz-dropdown-menu>

<nz-drawer
  [(nzVisible)]="settingsVisible"
  nzTitle="{{'settings.title' | translate | firstLetterCapital}}"
  [nzClosable]="true"
  (nzOnClose)="hideSettings()">

  <user-settings *nzDrawerContent></user-settings>

</nz-drawer>
