import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from "@ngrx/store";
import { map } from "rxjs";
import { NotificationsSeen, selectNotificationsState } from "../../../store/notifications";

@Component({
  selector: 'active-tasks-menu',
  templateUrl: './active-tasks-menu.component.html',
  styleUrls: ['./active-tasks-menu.component.scss']
})
export class ActiveTasksMenuComponent implements OnInit, OnDestroy {

  notificationsBadge = 0;
  private notificationsBadgeSub;
  readonly notifications$ = this.store.select(selectNotificationsState).pipe(
    map(notifications => Object.values(notifications).sort(
      (a, b) => {
        const aMs: number = Date.parse(a.lastUpdate);
        const bMs: number = Date.parse(b.lastUpdate);
        return bMs - aMs; // Descending order
      }
    ))
  );

  constructor(private store: Store) {

    // Separate subscription for notification counter, so we can set it to 0 when clicked, but keep the notifications
    // in TasksState, so we can highlight them until the dropdown is displayed
    this.notificationsBadgeSub = this.store.select(selectNotificationsState).subscribe((notifications) => {
      this.notificationsBadge = Object.keys(notifications).length;
    });
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.notificationsBadgeSub.unsubscribe();
  }

  visibilityChanged(dropdownVisible: boolean) {
    if (dropdownVisible) {  // Hide the badge on dropdown display
      this.notificationsBadge = 0;
    } else if (this.notificationsBadge == 0) {  // Dispatch notifications seen on dropdown hide
      this.store.dispatch(NotificationsSeen());
    }
  }

}
