import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from "@ngrx/store";
import { Observer, Subscription } from "rxjs";
import { selectTaskStateByUUID, TaskCancelled } from "../../../store/tasks";
import { ITask } from "../../../util/task";
import { SmartApiService } from "../../../../shared/services/smart-api.service";
import { TranslateService } from "@ngx-translate/core";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { HttpErrorResponse } from "@angular/common/http";
import { isSMARTError } from "../../../../shared/api";

@Component({
  selector: 'task-detail',
  templateUrl: './task-detail.component.html',
  styleUrls: ['./task-detail.component.scss']
})
export class TaskDetailComponent implements OnInit, OnDestroy {

  @Input() taskUUID: string;
  localTask: ITask | undefined;
  onlineTask: ITask | undefined;
  displayLocalTask: boolean = false;

  private localTaskSub?: Subscription;

  constructor(private store: Store,
              private api: SmartApiService,
              private translate: TranslateService,
              private notification: NzNotificationService) {

    this.taskUUID = "";
  }

  ngOnInit(): void {
    let localTaskObs: Observer<ITask> = {
      next: task => {
        this.localTask = task;
        this.displayLocalTask = true;
      },
      error: () => this.localTask = undefined,
      complete: () => {}
    }
    this.localTaskSub = this.store.select(selectTaskStateByUUID(this.taskUUID)).subscribe(localTaskObs);

    let onlineTaskObs: Observer<ITask> = {
      next: task => this.onlineTask = task,
      error: err => {
        this.errorHandler(err);
        this.onlineTask = undefined;
      },
      complete: () => this.displayLocalTask = false
    }
    this.api.getTask(this.taskUUID).subscribe(onlineTaskObs);
  }

  ngOnDestroy(): void {
    if (this.localTaskSub !== undefined) {
      this.localTaskSub.unsubscribe();
    }
  }

  toggleLocalOnline(): void {
    this.displayLocalTask = !this.displayLocalTask;
  }

  cancelTask(task: ITask): void {
    this.store.dispatch(TaskCancelled({uuid: task.uuid}));
  }

  retryTask(task: ITask): void { }

  // Getters
  public taskName(): string {
    if (this.taskUUID == '' || (this.onlineTask === undefined && this.localTask === undefined)) return '';
    if (!this.displayLocalTask && this.onlineTask !== undefined) return this.onlineTask.name;
    if (this.displayLocalTask && this.localTask !== undefined) return this.localTask.name;
    // @ts-ignore - this.localTask cannot be undefined here
    return this.onlineTask !== undefined ? this.onlineTask.name : this.localTask.name;
  }

  // Privates
  private errorHandler(errorMsg: HttpErrorResponse) {
    let message: string;
    if (isSMARTError(errorMsg.error)) {
      message = errorMsg.error.message;
    } else {
      message = 'error.' + errorMsg.status.toString() + '.title';
    }
    this.notification.error(
      this.translate.instant('error.title'),
      this.translate.instant(message)
    );
  }
}
