<section id="error">

    <nz-result nzStatus="warning" 
               nzTitle="{{'error.mfa-required.title' | translate}}">
        <div nz-result-content>
        {{'error.mfa-required.explanation' | translate}}
        </div>
        <div nz-result-extra>
            <a href="https://it.muni.cz/sluzby/jednotne-prihlaseni-na-muni/vicefazove-overeni" hreflang="cs" target="_blank" rel="external">
                <button nz-button nzType="primary">{{'error.mfa-required.link' | translate}}</button>
            </a>
        </div>
    </nz-result>

</section>