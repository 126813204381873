<section *ngIf="(localTasks$ | async)?.tasks.length > 0">
  <header>
    <h5>{{'user.tasks.localTasksTitle' | translate | firstLetterCapital}}</h5>
  </header>
  <nz-collapse>
    <ng-container *ngFor="let task of (localTasks$ | async)?.tasks">

      <ng-template #taskOverview>
        <div class="task-overview">
          <div>{{task.name}}</div>
          <div>
            <div>{{task.timeStatusChanged | localisedDate: 'medium'}}</div>
            <nz-badge
              nzStatus="{{ task.status === 'completed' ? 'success' :
              task.status === 'processing' || task.status === 'starting' || task.status === 'running' ? 'processing' :
              task.status === 'cancel_requested' || task.status === 'terminating' || task.status === 'cancelled' ? 'warning' :
              task.status === 'server_unavailable' || task.status === 'failed' ? 'error' :
              'default' }}"
              nzText="{{('user.tasks.status.' + task.status) | translate}}">
            </nz-badge>
          </div>
        </div>
      </ng-template>

      <nz-collapse-panel [nzHeader]="taskOverview">
        <ng-container *ngTemplateOutlet="taskDetail; context: { $implicit: task }"></ng-container>
      </nz-collapse-panel>

    </ng-container>
  </nz-collapse>
</section>
<section>
  <header *ngIf="(localTasks$ | async)?.tasks.length > 0">
    <h5>{{'user.tasks.onlineTasksTitle' | translate | firstLetterCapital}}</h5>
  </header>
  <ng-container *ngIf="onlineTasksLoading; else onlineTasksLoaded">
    <nz-spin nzSimple [nzSize]="'large'"></nz-spin>
  </ng-container>
  <ng-template #onlineTasksLoaded>
    <ng-container *ngIf="onlineTasks?.length > 0; else onlineTasksEmpty">
      <nz-collapse>
        <ng-container *ngFor="let task of onlineTasks">

          <ng-template #taskHeader>
            <div class="task-overview">
              <div>{{task.name}}</div>
              <div>
                <div>{{task.timeStatusChanged | localisedDate: 'medium'}}</div>
                <nz-badge
                  nzStatus="{{ task.status === 'completed' ? 'success' :
              task.status === 'processing' || task.status === 'starting' || task.status === 'running' ? 'processing' :
              task.status === 'cancel_requested' || task.status === 'terminating' || task.status === 'cancelled' ? 'warning' :
              task.status === 'server_unavailable' || task.status === 'failed' ? 'error' :
              'default' }}"
                  nzText="{{('user.tasks.status.' + task.status) | translate}}">
                </nz-badge>
              </div>
            </div>
          </ng-template>

          <nz-collapse-panel [nzHeader]="taskHeader">
            <ng-container *ngTemplateOutlet="taskDetail; context: { $implicit: task }"></ng-container>
          </nz-collapse-panel>

        </ng-container>
      </nz-collapse>
    </ng-container>
    <ng-template #onlineTasksEmpty>
      <nz-empty></nz-empty>
    </ng-template>
  </ng-template>
</section>


<!-- TEMPLATES -->
<ng-template #taskDetail let-task>
  <div class="description">
    <div class="desc-label">{{'user.tasks.status.title' | translate | firstLetterCapital}}</div>
    <div class="desc-item">{{('user.tasks.status.' + task.status) | translate}}</div>

    <div class="desc-label">{{'user.tasks.created' | translate | firstLetterCapital}}</div>
    <div class="desc-item">{{task.timeCreated | localisedDate: 'medium'}}</div>

    <div class="desc-label">{{'user.tasks.lastUpdate' | translate | firstLetterCapital}}</div>
    <div class="desc-item">{{task.timeStatusChanged | localisedDate: 'medium'}}</div>

    <div class="desc-label">{{'user.tasks.priority.title' | translate | firstLetterCapital}}</div>
    <div class="desc-item">{{('user.tasks.priority.' + task.priority) | translate}}</div>

    <div class="desc-label">{{'user.tasks.endpoint' | translate | firstLetterCapital}}</div>
    <div class="desc-item">
      <nz-collapse nzBordered="false">
        <nz-collapse-panel nzHeader="{{task.payload.endpoint}}">
          <div class="description">
            <div class="desc-label">{{'user.tasks.credential' | translate | firstLetterCapital}}</div>
            <div class="desc-item">{{task.payload.credential}}</div>
          </div>
        </nz-collapse-panel>
      </nz-collapse>
    </div>

    <div class="desc-label">{{'user.tasks.command.title' | translate | firstLetterCapital}}</div>
    <div class="desc-item">
      <nz-collapse nzBordered="false">
        <nz-collapse-panel nzHeader="{{task.payload.command}}">
          <div class="description">
            <div class="desc-label">{{'user.tasks.command.parameters' | translate | firstLetterCapital}}</div>
            <div class="desc-item">{{task.payload.arguments.join(', ')}}</div>
          </div>
        </nz-collapse-panel>
      </nz-collapse>
    </div>

    <div class="desc-label">{{'user.tasks.owner' | translate | firstLetterCapital}}</div>
    <div class="desc-item">{{task.owner}}</div>

    <div class="desc-label">{{'user.tasks.group' | translate | firstLetterCapital}}</div>
    <div class="desc-item">{{task.group}}</div>

    <div></div>
    <div class="task-actions">
      <button type="button" class="btn btn-outline-danger mx-1" (click)="cancelTask(task)">
        <i class="fe fe-x-circle"></i>
        {{'user.tasks.cancel' | translate | firstLetterCapital}}
      </button>
      <button type="button" class="btn btn-outline-warning mx-1" (click)="retryTask(task)">
        <i class="fe fe-repeat"></i>
        {{'user.tasks.retry' | translate | firstLetterCapital}}
      </button>
    </div>
  </div>
</ng-template>
<!-- End of TEMPLATES -->
