import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'smart-new-entity-wizard-outputs',
  templateUrl: './new-entity-wizard-outputs.component.html',
  styleUrls: ['./new-entity-wizard-outputs.component.scss']
})
export class NewEntityWizardOutputsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
