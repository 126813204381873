import { createAction, props } from '@ngrx/store'
import { IdentityClaims } from "../../services/auth/IdentityClaims";

export enum UserActionNames {
  LOGIN = '[Auth] Login',
  USER_PROFILE_LOADED = '[Auth] User Profile Loaded',
  USER_PROFILE_LOAD_FAILED = '[Auth] Cannot Load User Profile',
  LOGOUT = '[Auth] Logout',
}

export const Login = createAction(UserActionNames.LOGIN);
export const UserProfileLoaded = createAction(UserActionNames.USER_PROFILE_LOADED, props<IdentityClaims>())
export const UserProfileLoadFailed = createAction(UserActionNames.USER_PROFILE_LOAD_FAILED)
export const Logout = createAction(UserActionNames.LOGOUT);

