<div class="d-inline-block mr-4">
  <nz-input-group class="extInput" [nzPrefix]="prefixIconSearch">
    <input nz-input (focus)="setVisible()" placeholder="{{'generic.search' | translate | firstLetterCapital}}" style="width: 200px" />
  </nz-input-group>
  <ng-template #prefixIconSearch>
    <i nz-icon nzType="search" class="extInputIcon" style="color: rgba(0,0,0,.25)"></i>
  </ng-template>
  <div class="livesearch" [ngClass]="{'livesearchVisible': showSearch}">
    <button class="close" type="button" (click)="setHidden()">
      <i class="icmn-cross"></i>
    </button>
    <div class="container-fluid">
      <div class="wrapper">
        <input #liveSearchInput [(ngModel)]="searchText" class="searchInput"
               placeholder="{{'generic.search' | translate | firstLetterCapital}}…" />
        <ul class="options">
          <li class="option optionCheckbox">
            <label nz-checkbox>Search within page</label>
          </li>
          <li class="option">Press enter to search</li>
        </ul>
        <div *ngIf="!searchText" class="results">
          <div class="resultsTitle">
            <span>No Results Found</span>
          </div>
        </div>
        <div *ngIf="searchText" class="results">
          <div class="resultsTitle">
            <span>Pages Search Results</span>
          </div>
          <div class="row">
            <div class="col-lg-4">
              <smart-search-result-content></smart-search-result-content>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
