export interface UserState {
  id: number;
  login: string | undefined;
  full_name: string | undefined;
  given_name: string | undefined;
  family_name: string | undefined;
  email: string | undefined;
}

export const initialUserState: UserState = {
  id: 0,
  login: undefined,
  full_name: undefined,
  given_name: undefined,
  family_name: undefined,
  email: undefined,
}
