import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'firstLetterCapital'
})
export class FirstLetterCapitalPipe implements PipeTransform {

  transform(text: string | undefined): string | undefined {
    if (!text)
      return text;

    return text[0].toUpperCase() + text.slice(1);
  }

}
