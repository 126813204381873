/**
 * Contains all the API of REST Zappa
 */

export interface ZappaLocality {
  id: number,
  name: string,
  computers_count: number,
  statuses: ZappaLocalityStatusCount[]
}

export interface ZappaLocalityDetail {
  id: number,
  name: string,
  studyrooms: ZappaStudyroom[],
}

export interface ZappaLocalityStatusCount {
  status: ZappaComputerStatus,
  count: number
}

/**
 * Represents a study room with computers
 */
export interface ZappaStudyroom {
  name: string,
  computers: ZappaComputer[],
}

/**
 * Represents a single computer
 */
export interface ZappaComputer {
  id: number,
  distinguished_name: string,
  fqdn: string,
  name: string,
  uin?: number, // UCO of the logged-in user
  status: ZappaComputerStatus,
}

/**
 * Enum of computer states
 */
export type ZappaComputerStatus = 'PowerOff' | 'PowerOn' | 'LoggedIn' | 'UserLeft' | 'MultiLog' | 'External' | 'Maintenance' | 'Unknown';
