/**
 * Contains all the API interfaces of SMART assets
 */

export enum EAssetType {
  SERVER = 'server',
  ENDPOINT = 'endpoint',
  CREDENTIAL = 'credential',
  KEY = 'key',
}

export interface IAssetData {
  key: string,
  value: string,
}

export interface IAssetName {
  uuid: string,
  Name: string,
}

export interface IAssetRequest {
  Name: string,
  AssetType: EAssetType,
  Asset: IAssetData,
}

export interface IAsset {
  uuid: string,
  Name: string,
  AssetType: EAssetType,
  Asset: IAssetData,
}
