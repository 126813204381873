import { Component } from '@angular/core';

@Component({
  selector: 'smart-mfa-required',
  templateUrl: './mfa-required.component.html',
  styleUrl: './mfa-required.component.scss'
})
export class MFARequiredComponent {

}
