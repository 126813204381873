import { Component, OnInit } from '@angular/core';
import { select, Store } from "@ngrx/store";
import * as Reducers from 'src/app/core/store/reducers';
import { Login } from "../../store/user";
import { environment } from "../../../../environments/environment";

@Component({
  selector: 'smart-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  appName: string = '';
  locale: string = '';

  constructor(private store: Store<any>) {
    this.appName = environment.appName;
    this.store.pipe(select(Reducers.getSettings)).subscribe(state => {
      this.locale = state.settings.locale;
    })
  }

  public login() {
    this.store.dispatch(Login());
  }

  ngOnInit(): void {
  }

  isCzech(): boolean {
    return !!this.locale.match('cs-CZ');
  }

}
