<div class="user-welcome">
  <user-welcome></user-welcome>
</div>

<div class="dashboard">

  <user-statistics></user-statistics>

  <smart-status></smart-status>

</div>
