<nz-card nzTitle="{{'smart.statusTitle' | translate}}">
  <div class="labelled-list">
    <div class="label">SMART Server</div>
    <div class="item">
      <ng-container *ngIf="serverUp !== undefined; else serverHealthUnknown">
        <nz-badge *ngIf="serverUp; else serverOffline" nzStatus="success" nzText="online"></nz-badge>
        <ng-template #serverOffline>
          <nz-badge nzStatus="error" nzText="offline"></nz-badge>
        </ng-template>
      </ng-container>
      <ng-template #serverHealthUnknown>
        <nz-badge nzStatus="default" nzText="{{'generic.loading' | translate}}"></nz-badge>
      </ng-template>
    </div>

    <div class="label">WebUI Version</div>
    <div class="item">{{webuiVersion}}</div>

    <div class="label">{{'smart.connectors' | translate | firstLetterCapital}}</div>
    <div class="item {{connectorsOK.length == connectors.length ? 'text-success' : 'text-danger'}}">
      <span [className]="connectorsOK.length == connectors.length ? 'text-success' : 'text-danger'"
          nz-popover [nzPopoverContent]="connectorList" nzPopoverPlacement="rightTop">
        {{connectorsOK.length}}/{{connectors.length}}
      </span>
    </div>

    <div class="label">{{'smart.tasks.running' | translate | firstLetterCapital}}</div>
    <div class="item">0</div>
  </div>
</nz-card>


<ng-template #connectorList>
  <div class="labelled-list">
    <ng-container *ngFor="let connector of connectors">
      <div class="label">{{connector.name}}</div>
      <div class="item">
        <nz-badge *ngIf="connector.up; else connectorOffline" nzText="online" nzStatus="success"></nz-badge>
        <ng-template #connectorOffline>
          <nz-badge nzText="offline" nzStatus="error"></nz-badge>
        </ng-template>
      </div>
    </ng-container>
  </div>
</ng-template>
