import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { DatePipe } from "@angular/common";

@Pipe({
  name: 'localisedDate',
  pure: false
})
export class LocalisedDatePipe implements PipeTransform {

  private value: string = '';
  private lastLang: string = ''; // required to update the value when currentLang is changed
  private lastDate: string = '';
  private lastPattern: string = 'mediumDate';

  constructor(private translate: TranslateService) { }

  isMicrosoftJsonDate(dateStr: string): boolean {
    return /^\/Date\(\d+\)\/$/.test(dateStr);
  }

  convertToISOFormat(dateStr: string): string {
    if (!this.isMicrosoftJsonDate(dateStr)) {
      return dateStr;
    }
    const timestampMatch = dateStr.match(/\d+/); // Extract timestamp
    if (!timestampMatch) {
      throw new Error("Invalid Microsoft JSON date format");
    }
    const timestamp = parseInt(timestampMatch[0], 10); // Convert to number
    const date = new Date(timestamp); // Convert to Date object
    return date.toISOString(); // Convert to ISO 8601 format
  }

  transform(date: string, pattern: string = 'mediumDate'): string {

    date = this.convertToISOFormat(date);

    const currLang = this.translate.currentLang || 'en-US';

    // if we ask another time for the same locale & date, return the last value
    if ((currLang === this.lastLang) && (date === this.lastDate) && (pattern === this.lastPattern)) {
      return this.value;
    }

    this.value = new DatePipe(currLang).transform(date, pattern) || date;

    this.lastLang = currLang;
    this.lastDate = date;
    this.lastPattern = pattern;

    return this.value;
  }

}
