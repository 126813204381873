import * as store from 'store'
import {initialSettings, SettingsState, SystemSettings} from "./model";
import {Action, createReducer, on} from "@ngrx/store";
import * as SettingActions from "./actions";
import * as _ from "lodash/fp";

const settingsReducer = createReducer(
  initialSettings,
  on(SettingActions.Init, (state) => ({...state, loaded: false, error: null})),
  on(SettingActions.LoadSettings, (state) => ({...state, loaded: false, error: null})),
  on(SettingActions.LoadSettingsSuccess, (state) => ({...state, loaded: true, error: null})),
  on(SettingActions.LoadSettingsFailure, (state, {error}) => ({...state, error})),
  on(SettingActions.ChangeSettings, (state) => ({...state, loaded: false, error: null})),
  on(SettingActions.ChangeSettingsSuccess, (state) => {
    return {
      ...state,
      loaded: true,
      error: null,
    };
  }),
  on(SettingActions.ChangeSettingsFailure, (state, {error}) => ({...state, error})),
  on(SettingActions.SwitchLocale, (state, { locale }) => {let newState = _.cloneDeep(state); newState.settings.locale = locale; return newState}),
  on(SettingActions.SetSidebarState, (state, {isSidebarOpen}) => {let ns = _.cloneDeep(state); ns.settings.isSidebarOpen = isSidebarOpen; return ns;}),
  on(SettingActions.SetThemeColor, (state, {primaryColor}) => {let ns = _.cloneDeep(state); ns.settings.primaryColor = primaryColor; return ns;}),
  on(SettingActions.SetMenuCollapsed, (state, {isMenuCollapsed}) => {let ns = _.cloneDeep(state); ns.settings.isMenuCollapsed = isMenuCollapsed; return ns;}),
  on(SettingActions.SetTheme, (state, {theme}) => {let ns = _.cloneDeep(state); ns.settings.theme = theme; return ns;}),
  on(SettingActions.SetRouterAnimation, (state, {routerAnimation}) => {let ns = _.cloneDeep(state); ns.settings.routerAnimation = routerAnimation; return ns;}),
  on(SettingActions.SetMenuColor, (state, {menuColor}) => {let ns = _.cloneDeep(state); ns.settings.menuColor = menuColor; return ns;}),
  on(SettingActions.SetNarrowView, (state, {isAppMaxWidth}) => {let ns = _.cloneDeep(state); ns.settings.isAppMaxWidth = isAppMaxWidth; return ns;}),
  on(SettingActions.SetNarrowContent, (state, {isContentMaxWidth}) => {let ns = _.cloneDeep(state); ns.settings.isContentMaxWidth = isContentMaxWidth; return ns;}),
);

export function reducer(state: SettingsState | undefined, action: Action) {
  return settingsReducer(state, action);
}

export const getSettings = (state: any) => state
