/**
 * Contains all the API definitions of Person Manager 2
 */

export const PSWSService = {
  "type": "rest",
  "commands": {
    "muniAdGroup:get": {
      "httpMethod": "get",
      "endpoint": "/groups",
      "queryParameters": ["searchString"]
    },
    "muniAdGroup:detail:get": {
      "httpMethod": "get",
      "endpoint": "/groups/{id}"
    },
    "muniAdUser:get": {
      "httpMethod": "get",
      "endpoint": "/users/{searchText}"
    },
    "spoSites:get": {
      "httpMethod": "get",
      "endpoint": "/spoSites",
      "queryParameters": ["filter", "groupConnectedSites"]
    },
    "spoSites:url:get": {
      "httpMethod": "get",
      "endpoint": "/spoSites/{url}"
    },
    "groupsMailModeration:put": {
      "httpMethod": "put",
      "endpoint": "/groups/mailModeration",
      "body": `{
          "body": "{body}"
        }`
    },
    "spoSites:put": {
      "httpMethod": "put",
      "endpoint": "/spoSites",
      "body": `{
          "body": "{body}"
        }`
    },
    "profile:get": {
      "httpMethod": "get",
      "endpoint": "/profile/{uin}"
    },
    "profile:reset": {
      "httpMethod": "put",
      "endpoint": "/profile/{uin}"
    },
    "history:get": {
      "httpMethod": "get",
      "endpoint": "/history/{historyNext}"
    },
  }
}

