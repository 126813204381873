/**
 * Contains all the API of ReservationService
 */

export namespace ReservationService {

  export const endpoint = "c4c565e8-d7db-49ce-87bd-066123b6a2cb"

  /**
   * Only used for documentation purposes
   */
  export const commands = [
    'GET_localities',
    'GET_reservations',
    'POST_reservation',
    'PUT_reservation',
    'DELETE_reservation',
  ]

  export interface Locality {
    id: number;
    name?: string;
    studyrooms?: Studyroom[];
  }

  export interface Studyroom {
    id: number;
    name?: string;
    localityId?: number;
  }

  export interface ReservationRequest {
    mapLocalityId: number;
    mapStudyroomId?: number;
    reservedFrom: string;
    reservedTo: string;
    note?: string;
  }

  export interface Reservation {
    id: number;
    mapLocalityId: number;
    mapStudyroomId?: number;
    reservedFrom: string;
    reservedTo: string;
    cancelled: boolean;
    note?: string;
  }

  export interface UpdateReservationRequest {
    id: number;
    reservedFrom: string;
    reservedTo: string;
    note?: string;
  }

}

