/**
 * Barrel export for the neater API interfaces imports
 */

export * from "./assets.interfaces";
export * from "./generic.interfaces";
export * from "./tasks.interfaces";

// Admin tools
export * from "./malleus.interfaces";
export * from "./m365.interfaces";
export * from "./occupationService.interfaces";
export * from "./perun.interfaces";
export * from "./psws.interfaces";
export * from "./zappa.interfaces";
export * from "./reservationService.interfaces";
