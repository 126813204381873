/**
 * Contains all the generic API interfaces of SMART
 */

/* Health Check */

export enum EHealthStatus {
  UNKNOWN = 'Unknown',
  HEALTHY = 'Healthy',
  UNHEALTHY = 'Unhealthy',
}

export interface IHealthStatus {
  status: EHealthStatus,
  totalDuration: string,  // HH:mm:ss.fffffff containing the processing time
  entries: IHealthStatusEntries
}

export interface IHealthStatusEntries {
  [index: string]: IHealthStatusEntry;
}

export interface IHealthStatusEntry {
  status: EHealthStatus,
  duration: string,  // HH:mm:ss.fffffff containing the processing time
  data: any,
  tags: string[],
  description?: string,
  exception?: string,
}

export const HealthStatusUnavailable: IHealthStatus = {
  status: EHealthStatus.UNKNOWN,
  totalDuration: "00:00:00.0000000",
  entries: {}
}

/* Errors */

export interface IError { // SMART API Problem object
  instance: string,  // FIXME: Some URI???
  type: string,  // URI of the error type
  parameters: object,  // FIXME: WTF???
  title: string,
  detail: string,  // FIXME: ???
  status: {
    statusCode: number,  // Status code (corresponding with HTTP status codes?)
    reasonPhrase: string,  // FIXME: Human readable error message?
  }
  path: string,  // Path (URI of the SMART API endpoint) which caused the error
  message: string  // Identifier of the human-readable description
}

export function isSMARTError(obj: any): obj is IError {
  return (
    obj &&
    typeof obj === 'object' &&
    typeof obj['type'] === 'string' &&
    typeof obj['title'] === 'string' &&
    typeof obj['status'] === 'number' &&
    typeof obj['path'] === 'string' &&
    typeof obj['message'] === 'string'
  );
}
