import { Action, createReducer, on } from "@ngrx/store";
import { initialTasksState, TasksState } from "./tasks.state";
import {
  TaskCancelled,
  TaskConfirmed,
  TaskCreated,
  TaskFinished,
  TaskNotificationsSeen, TaskRequestError, TaskServUnavailable,
  TaskStatusUpdated
} from "./tasks.actions";
import { ETaskLocalState } from "../../util/task";

const tasksReducer = createReducer(
  initialTasksState,

  on(TaskCreated, (state, task) => ({
    notifications: state.notifications,
    tasks: {...state.tasks, [task.uuid]: task},
  })),

  on(TaskServUnavailable, (state, message) => {
    let notifications = [...state.notifications];
    if (!notifications.includes(message.uuid))
      notifications.push(message.uuid)

    let tasks = {...state.tasks};
    tasks[message.uuid] = {
      ...tasks[message.uuid],
      status: ETaskLocalState.SERV_UNAVAILABLE,
      timeStatusChanged: (new Date).toISOString()
    }

    return {
      notifications: notifications,
      tasks: tasks
    }
  }),

  on(TaskRequestError, (state, message) => {
    let notifications = [...state.notifications];
    if (!notifications.includes(message.uuid))
      notifications.push(message.uuid)

    let tasks = {...state.tasks};
    tasks[message.uuid] = {
      ...tasks[message.uuid],
      status: ETaskLocalState.REQUEST_ERROR,
      timeStatusChanged: (new Date).toISOString()
    }

    return {
      notifications: notifications,
      tasks: tasks
    }
  }),

  on(TaskConfirmed, (state, message) => {
    let tasks = {...state.tasks};
    let origTask = state.tasks[message.timestamp];
    delete tasks[message.timestamp];
    // tasks[message.confirmation.uuid] = {
    //   uuid: message.confirmation.uuid,
    //   priority: message.confirmation.priority,
    //   flavor: message.confirmation.flavor,
    //   payload: message.confirmation.payload,
    //   timeCreated: state.tasks[message.timestamp].timeCreated,
    //   status: message.confirmation.status.status,
    //   timeStatusChanged: message.confirmation.timeStatusChanged,
    //   owner: message.confirmation.ownerUser,
    //   group: message.confirmation.ownerGroup,
    //   log: [...state.tasks[message.timestamp].log, message.confirmation]
    //}
    // FIXME: Temporary fix for direct taskdispatcher access
    tasks[message.confirmation.uuid] = {
      uuid: message.confirmation.uuid,
      name: message.confirmation.name,
      priority: origTask.priority,
      flavor: origTask.flavor,
      payload: origTask.payload,
      timeCreated: origTask.timeCreated,
      status: message.confirmation.status.status,
      timeStatusChanged: message.timestamp,
      owner: origTask.owner,
      group: origTask.group,
      log: [...origTask.log, message.confirmation]
    }
    return {
      notifications: state.notifications,
      tasks: tasks
    }
  }),

  on(TaskStatusUpdated, (state) => ({
    ...state,
  })),
  on(TaskNotificationsSeen, state => ({
    notifications: [],
    tasks: {...state.tasks},
  })),
  on(TaskFinished, TaskCancelled, (state, task) => {
    const notifications = state.notifications.filter((uuid: string) => uuid != task.uuid);
    const tasks = {...state.tasks};
    delete tasks[task.uuid];
    return {
      notifications: notifications,
      tasks: tasks,
    }
  }),
)

export function reducer(state: TasksState | undefined, action: Action) {
  return tasksReducer(state, action);
}
