import { Injectable, isDevMode } from "@angular/core";
import { HttpClient, HttpErrorResponse, HttpParams } from "@angular/common/http";
import {
  catchError,
  defer,
  interval,
  map,
  Observable,
  Observer,
  of,
  ReplaySubject,
  startWith,
  Subject,
  switchMap
} from "rxjs";
import { Store } from "@ngrx/store";

import {
  EAssetType,
  ETaskCredentials,
  ETaskFlavor,
  ETaskPriority,
  ETaskStatus, HealthStatusUnavailable,
  IAsset,
  IAssetName,
  IAssetRequest,
  IHealthStatus,
  isSMARTError,
  ITaskRequest,
  ITaskResponse,
} from "../api";

import { environment } from "../../../environments/environment";
import { TaskCreated, TaskRequestError, TaskServUnavailable } from "../../core/store/tasks";
import { ETaskLocalState, ITask, taskFromITaskResponse } from "../../core/util/task";
import { NotificationNew } from "../../core/store/notifications";
import { AuthService } from "../../core/services/auth/auth.service";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { TranslateService } from "@ngx-translate/core";
import { ConnectedServiceError } from "../exceptions";

@Injectable({
  providedIn: 'root'
})
export class SmartApiService {

  readonly apiURL: URL = new URL(environment.apiURL);
  readonly notifications$: Subject<any> = new Subject<any>();

  private health: ReplaySubject<IHealthStatus> = new ReplaySubject<IHealthStatus>(1);
  readonly health$: Observable<IHealthStatus> = defer(() => this.health.asObservable());

  // TODO: Rewrite as HTTP Interceptor
  private headers: {[index: string]: string} = {
  'Authorization': `Bearer ${this.auth.accessToken}`,
  'Content-Type': 'application/json',
  }

  /**
   * Creates a new SMART API service and connects it to the backend.
   *
   * @param store Injected NgRx Store to update internal state based on the API.
   * @param http Injected HTTP Client to make REST calls.
   * @param auth Injected AuthService containing identity and authorization data (OIDC wrapper)
   * @param translate Injected TranslateService used for localised error notification messages
   * @param notification  Injected NzNotificationService used for error notification messages
   *
   * @throws TypeError API URL from environment.apiURL cannot be parsed.
   */
  constructor(
    private store: Store,
    private http: HttpClient,
    private auth: AuthService,
    private translate: TranslateService,
    private notification: NzNotificationService
  ) {

    // Listen to the access token change and update the headers
    this.auth.accessToken$.subscribe(
      (token) => this.headers['Authorization'] = `Bearer ${token}`
    );

    // API Health stream
    interval(10000) // Update every 10 seconds
      .pipe(
        // Early HTTP call to speed up the initialization
        startWith(
          this.http.get<IHealthStatus>(this.apiURL + 'management/health').pipe(
            catchError(() => of(HealthStatusUnavailable))
          )
        ),
        // SwitchMap to cancel the HTTP call if it takes longer than interval and tries again
        switchMap(
          () => this.http.get<IHealthStatus>(this.apiURL + 'management/health').pipe(
            catchError(() => of(HealthStatusUnavailable))
          )
        )
      )
      .subscribe(this.health);

/*  Websocket testing
    // Websocket Notifications
    let wsURL = new URL(this.apiURL);
    wsURL.protocol = 'ws';
    // TODO: Use wsURL or real WS URL
    let ws = new WebSocket("wss://demo.piesocket.com/v3/channel_123?api_key=VCXCEuvhGcBDP7XhiJJUDvR1e1D3eiVjgZ9VRiaV");
    let ws$ = new Observable((obs: Observer<MessageEvent>) => {
      ws.onmessage = obs.next.bind(obs);
      ws.onerror = obs.error.bind(obs);
      ws.onclose = obs.complete.bind(obs);
      return ws.close.bind(ws);
    });
    ws$.pipe(
      // TODO: Convert to standardized interface
      map(
        (msg: MessageEvent) => {
          return msg.data
        }
      )
    ).subscribe(this.notifications$);

    // TODO: Remove debug function
    this.debugWS();
*/
  }

  /** General functionality **/

  /* Section: Tasks */

  /**
   * Handles the HTTP request to the SMART Server and notifies about errors
   *
   * @param name  Human-readable identifier of the task
   * @param endpoint  UUID of the task endpoint
   * @param flavor  Type of the request
   * @param command   Command that shall be performed on the endpoint
   * @param parameters  List of the command parameters, default is empty list
   * @param priority  Task priority, default is NORMAL
   * @param credential  Credential that shall be used for the authorization to the endpoint (default is JWT passthrough)
   * @param notificationLvl Allows for overriding how the notifications are displayed. By default, all notifications are displayed.
   * @private
   */
  private createSMARTTask(
    name: string,
    endpoint: string,
    flavor: ETaskFlavor,
    command: string,
    parameters: string[] = [],
    priority: ETaskPriority = ETaskPriority.NORMAL,
    credential: string = ETaskCredentials.TOKEN_PASSTHROUGH,
    notificationLvl: SmartApiService.NotificationLevel = SmartApiService.NotificationLevel.All
  ): Observable<ITaskResponse> {

    let taskReq: ITaskRequest = {
      name: name,
      flavor: flavor,
      priority: priority,
      payload: {
        endpoint: endpoint,
        credential: credential,
        command: command,
        arguments: parameters
      },
    };

    return this.http
      .post<ITaskResponse>(this.apiURL.toString() + 'task-dispatcher/task', taskReq, {headers: this.headers})
      .pipe(
        catchError((error, caught) => {
          if (notificationLvl <= SmartApiService.NotificationLevel.HttpOnly) {
            this.notification.error(
              this.translate.instant('notifications.httpError', {'statusCode': error.status.toString()}),
              this.translate.instant(`error.${error.status}.title`)
            );
          }
          if (isDevMode()) {
            console.group('HTTP Error Response from SMART API');
            console.error(error);
            console.groupEnd();
          }
          throw error;  // Http
          return caught;  // Never happens, but the lint is happier
        }),
        map(response => {
          switch (response.status.status) {
            case ETaskStatus.FAILED:
            case ETaskStatus.NOTFOUND:
            case ETaskStatus.UNKNOWN:
              if (notificationLvl <= SmartApiService.NotificationLevel.All) {
                this.notification.error(
                  this.translate.instant('notifications.serviceError'),
                  response.status.message
                );
              }
              if (isDevMode()) {
                console.group('Connected service error');
                console.error(response);
                console.groupEnd();
              }
              throw ConnectedServiceError.fromTaskResponse(response);
            default:
              return response
          }
        })
      );

  }

  public createTask(
    name: string,
    endpoint: string,
    flavor: ETaskFlavor,
    command: string,
    parameters: string[] = [],
    priority: ETaskPriority = ETaskPriority.NORMAL,
    credential: string = ETaskCredentials.TOKEN_PASSTHROUGH,
    notificationLvl: SmartApiService.NotificationLevel = SmartApiService.NotificationLevel.All
  ): Observable<any> {

    return this.createSMARTTask(
      name,
      endpoint,
      flavor,
      command,
      parameters,
      priority,
      credential,
      notificationLvl
    ).pipe(
      map(response => {
        if (response.result['resultJson'] == '')
          return null;

        try {
          return JSON.parse(response.result['resultJson']);
        } catch (e) {  // If it didn't parse as JSON, it probably is just a plain string, so return that
          return response.result['resultJson'];
        }
      })
    )

  }

  public createDeferredTask(
    name: string,
    endpoint: string,
    flavor: ETaskFlavor,
    command: string,
    parameters: string[] = [],
    priority: ETaskPriority = ETaskPriority.NORMAL,
    credential: string = ETaskCredentials.TOKEN_PASSTHROUGH
  ) {

    let timestamp = new Date();

    let task:ITask = {
      uuid: timestamp.getTime().toString(),
      name: name,
      priority: priority,
      flavor: flavor,
      payload: {
        endpoint: endpoint,
        credential: credential,
        command: command,
        arguments: parameters
      },
      timeCreated: timestamp.toISOString(),
      status: ETaskLocalState.CREATED,
      timeStatusChanged: timestamp.toISOString(),
      log: []
    };
    this.store.dispatch(TaskCreated(task));

    let apiResponseObserver: Observer<ITaskResponse> = {
      next: response => this.store.dispatch(NotificationNew({
        uuid: response.uuid,
        name: response.name,
        lastUpdate: response.timeProcessed,
        status: response.status.status
      })),
      error: (response: HttpErrorResponse) => {
        if (isSMARTError(response.error)) {
          this.store.dispatch(TaskRequestError({
            uuid: task.uuid,
            error: response.error.message
          }))
        } else {
          this.store.dispatch(TaskServUnavailable({
            uuid: task.uuid,
            error: 'error.' + response.status.toString() + '.title'
          }))
        }
      },
      complete: () => {}
    }

    this.createSMARTTask(
      task.name,
      task.payload.endpoint,
      task.flavor,
      task.payload.command,
      task.payload.arguments,
      task.priority,
      task.payload.credential,
      SmartApiService.NotificationLevel.None  // Error notifications handled in RxJs store
    ).subscribe(apiResponseObserver)

  }

  public createUiTask(
    name: string,
    endpoint: string,
    flavor: ETaskFlavor,
    command: string,
    parameters: string[] = [],
    credential: string = ETaskCredentials.TOKEN_PASSTHROUGH,
    notificationLvl: SmartApiService.NotificationLevel = SmartApiService.NotificationLevel.All
  ) {

    // Same as realtime task for now, shall be distinct in the future
    return this.createTask(
      name,
      endpoint,
      flavor,
      command,
      parameters,
      ETaskPriority.REALTIME,
      credential,
      notificationLvl
    );

  }

  public createRealtimeTask(
    name: string,
    endpoint: string,
    flavor: ETaskFlavor,
    command: string,
    parameters: string[] = [],
    credential: string = ETaskCredentials.TOKEN_PASSTHROUGH,
    notificationLvl: SmartApiService.NotificationLevel = SmartApiService.NotificationLevel.All
  ) {

    return this.createTask(
      name,
      endpoint,
      flavor,
      command,
      parameters,
      ETaskPriority.REALTIME,
      credential,
      notificationLvl
    );

  }

  /**
   * Retrieves a list of tasks.
   */
  public getTasks(): Observable<ITask[]> {

    return this.http
      .get<ITaskResponse[]>(this.apiURL.toString() + 'task-dispatcher/tasks', {headers: this.headers})
      .pipe(
        // Remap the ITaskResponse[] API object to ITask[] internal object
        map((responses: ITaskResponse[]) => responses.map(taskFromITaskResponse))
      )

  }

  /**
   * Retrieves detail information about the task specified by UUID.
   *
   * @param uuid of the task
   */
  public getTask(uuid: string): Observable<ITask> {

    let params = new HttpParams().set('taskId', uuid);

    return this.http
      .get<ITaskResponse>(
        this.apiURL.toString() + 'task-dispatcher/task',
        {headers: this.headers, params: params}
      ).pipe(
        // Remap the ITaskResponse API object to ITask internal object
        map((response: ITaskResponse) => taskFromITaskResponse(response))
      )

  }

  /* Section: Assets */

  /**
   * Retrieves a list of assets. If filter is specified, return only assets with wanted UUIDs.
   *
   * @param filter Array of UUIDs which shall be retrieved.
   */
  public getAssets(filter?: string[]): Observable<IAsset[]> {

    if (typeof filter === 'undefined') {
      return this.http.get<IAsset[]>(this.apiURL.toString() + 'asset-service/assets', {headers: this.headers});
    } else {
      let params = new HttpParams({
        fromObject: {'assetIds': filter}
      });

      return this.http.get<IAsset[]>(
        this.apiURL.toString() + 'asset-service/assetsById',
        {headers: this.headers, params: params}
      );
    }
  }

  /**
   * Deletes an asset identified by its UUID.
   *
   * @param uuid of the asset
   */
  public deleteAsset(uuid: string): Observable<IAssetName> {

    let params = new HttpParams().set('assetId', uuid);

    return this.http.delete<IAssetName>(
      this.apiURL.toString() + 'asset-service/asset',
      {headers: this.headers, params: params}
    );
  }


  /**
   * Creates an asset.
   *
   * @param request data of the asset which shall be created
   */
  public createAsset(request: IAssetRequest): Observable<IAssetName> {
    return this.http.post<IAssetName>(
      this.apiURL.toString() + 'asset-service/asset',
      request,
      {headers: this.headers}
    );
  }

  /**
   * Retrieves a list of credentials.
   */
  public getCredentials(): Observable<IAsset[]> {
    return this.getAssets().pipe(
      map(assets => assets.filter(asset => asset.AssetType === EAssetType.CREDENTIAL))
    );
  }

  /**
   * Retrieves a list of endpoints.
   */
  public getEndpoints(): Observable<IAsset[]> {
    return this.getAssets().pipe(
      map(assets => assets.filter(asset => asset.AssetType === EAssetType.ENDPOINT))
    );
  }

  /** Utilities **/

  private debugWS() {
    this.notifications$.subscribe({
      next: (data) => { console.debug("WS: " + data) },
      error: (data) => { console.error("WS: " + data); },
      complete: () => { console.log("WS: Connection closed") },
    });
  }

}
// Declaration merging to add class enum (https://www.typescriptlang.org/docs/handbook/declaration-merging.html#merging-namespaces-with-classes)
export namespace SmartApiService {

  export enum NotificationLevel {
    All,  // Display error notifications for both HTTP errors and Connected Service errors
    HttpOnly, // Display error notifications only for the HTTP errors (errors between Smart Server and WebUI)
    None, // Do not display any error notifications
  }

}
