<nz-badge [nzCount]="notificationsBadge">
  <button nz-button nzSize="large" nzType="default" [disabled]="(notifications$ | async)?.length <= 0"
          nz-tooltip nzTooltipPlacement="bottom" nzTooltipTitle="{{'user.tasks.tooltip-no-active' | translate}}" nzTooltipTrigger="{{(notifications$ | async)?.length > 0 ? null : 'hover'}}"
          nz-dropdown nzTrigger="click" [nzDropdownMenu]="activeTasksMenu" (nzVisibleChange)="visibilityChanged($event)">

    <div class="active-tasks-counter {{(notifications$ | async)?.length <= 0 ? 'hide-counter' : ''}}" >
      {{(notifications$ | async)?.length}}
    </div>

    <div nz-icon nzType="file-sync" nzTheme="outline"></div>

  </button>
</nz-badge>

<nz-dropdown-menu #activeTasksMenu="nzDropdownMenu">
  <ul nz-menu class="active-tasks-list">
      <li class="task"
          *ngFor="let task of (notifications$ | async)"
          [class.task-notification]="(notifications$ | async)?.hasOwnProperty(task.uuid)"
          [routerLink]="['/my-tasks']" [queryParams]="{task: task.uuid}">

        <ng-container *ngTemplateOutlet="taskInfo; context: { $implicit: task }"></ng-container>
      </li>
  </ul>
</nz-dropdown-menu>


<!-- TEMPLATES -->
<ng-template #taskInfo let-task>
  <div nz-typography nzEllipsis class="task-name">{{task.name}}</div>
  <div class="task-details">
    <div>{{task.lastUpdate | localisedDate:'short'}}</div>
    <nz-badge
      nzStatus="{{ task.status == 'COMPLETED' ? 'success' :
        task.status == 'PROCESSING' || task.status == 'STARTING' || task.status == 'RUNNING' ? 'processing' :
        task.status == 'CANCEL_REQUESTED' || task.status == 'TERMINATING' || task.status == 'CANCELLED' ? 'warning' :
        task.status == 'SMART_UNAVAILABLE' || task.status == 'FAILED' ? 'error' :
        'default' }}"
      nzText="{{('user.tasks.status.' + task.status) | translate}}">
    </nz-badge>
  </div>
</ng-template>
<!-- End of TEMPLATES -->
