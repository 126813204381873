import { Component, OnInit } from '@angular/core';
import { Store } from "@ngrx/store";
import { selectCompletedTasks, selectFailedTasks, selectTasksState } from "../../../store/tasks";
import { map } from "rxjs";

@Component({
  selector: 'user-statistics',
  templateUrl: './user-statistics.component.html',
  styleUrls: ['./user-statistics.component.scss']
})
export class UserStatisticsComponent implements OnInit {

  readonly activeTasksCnt$ = this.store.select(selectTasksState).pipe(
    map(tasksStore => Object.values(tasksStore.tasks).length)
  );
  readonly completedTasksCnt$ = this.store.select(selectCompletedTasks).pipe(
    map(completedTasks => completedTasks.length)
  );
  readonly failedTasksCnt$ = this.store.select(selectFailedTasks).pipe(
    map(failedTasks => failedTasks.length)
  );

  constructor(private store: Store) { }

  ngOnInit(): void {
  }

}
