<form nz-form>
  <nz-form-item>
    <nz-form-label nzFor="locale" class="pb-1">{{'settings.language' | translate | firstLetterCapital}}</nz-form-label>
    <nz-select name="locale" [ngModel]="locale" (ngModelChange)="changeLocale($event)">
      <nz-option nzValue="cs-CZ" nzLabel="Čeština"></nz-option>
      <nz-option nzValue="en-US" nzLabel="English"></nz-option>
    </nz-select>
  </nz-form-item>

  <fieldset>
    <legend>{{'settings.appearance.title' | translate | firstLetterCapital}}</legend>

    <nz-form-item>
      <nz-form-label nzFor="theme" class="pb-1">{{'settings.appearance.theme' | translate | firstLetterCapital}}</nz-form-label>
      <nz-select name="theme" [ngModel]="theme" (ngModelChange)="changeTheme($event)">
        <!-- TODO: Use prefers-color-scheme: <nz-option nzValue="system" nzLabel="System"></nz-option> -->
        <nz-option nzValue="default" nzLabel="{{'settings.appearance.light' | translate | firstLetterCapital}}"></nz-option>
        <nz-option nzValue="dark" nzLabel="Dark">{{'settings.appearance.dark' | translate | firstLetterCapital}}</nz-option>
      </nz-select>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label nzFor="primaryColor">{{'settings.appearance.accentColor' | translate | firstLetterCapital}}</nz-form-label>
      <input name="primaryColor" type="color" [ngModel]="primaryColor" (ngModelChange)="changePrimaryColor($event)">
      <div class="color-reset" (click)="resetPrimaryColor()">
        <i class="fe fe-delete"></i>
      </div>
    </nz-form-item>
  </fieldset>
</form>
