<nz-layout>
  <nz-header>
    <div class="logo-container">
      <img class="logo-muni-ics" src="../../../../../assets/images/logo_MUNI-ICS.png" alt="Logo MUNI ICS"/>
      <img class="logo-smart" src="../../../../../assets/images/logo_SMART.png" alt="Logo SMART"/>
    </div>
  </nz-header>
  <nz-content>
    <router-outlet #outlet="outlet"></router-outlet>
  </nz-content>
  <nz-footer>
    <!-- TODO: <smart-sidebar></smart-sidebar> -->
    <smart-footer></smart-footer>
  </nz-footer>
</nz-layout>
