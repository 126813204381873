import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'smart-search-result-content',
  templateUrl: './result-content.component.html',
  styleUrls: ['./result-content.component.scss']
})
export class ResultContentComponent implements OnInit {
  thumbImage!: string
  resultText!: string
  resultSource!: string

  constructor() { }

  ngOnInit(): void {
  }

}
