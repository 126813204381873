import { Component, OnInit } from '@angular/core';
import { map } from "rxjs";
import { Store } from "@ngrx/store";
import { selectUserDetails } from "../../../store/user";

@Component({
  selector: 'user-welcome',
  templateUrl: './user-welcome.component.html',
  styleUrls: ['./user-welcome.component.scss']
})
export class UserWelcomeComponent implements OnInit {

  user$ = this.store.select(selectUserDetails);

  userInitials$ = this.user$.pipe(
    map((user) => {
      let initials = '';
      if (typeof user.given_name === 'string' && user.given_name.length > 0)
        initials += user.given_name[0].toUpperCase();
      if (typeof user.family_name === 'string' && user.family_name.length > 0)
        initials += user.family_name[0].toUpperCase();
      return initials;
    })
  );

  avatarClass$ = this.user$.pipe(
    map((user) => {
      let className = '';
      if (typeof user.family_name === 'string' && user.family_name.length > 0)
        className = user.family_name[0];
      return className
        .normalize("NFD")
        .replace(/\p{Diacritic}/gu, "")
        .toLowerCase();
    })
  );

  constructor(private store: Store) { }

  ngOnInit(): void {
  }

}
