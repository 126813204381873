import {Component, OnInit} from '@angular/core';
import * as store from 'store';
import {
  Router,
  ActivatedRoute,
  NavigationStart
} from '@angular/router'
import { Meta, Title } from "@angular/platform-browser";
import {select, Store} from "@ngrx/store";
import * as SettingsActions from 'src/app/core/store/settings/actions'
import * as Reducers from 'src/app/core/store/reducers'

import { TranslateService } from "@ngx-translate/core";
import english from './locales/en-US'
import czech from './locales/cs-CZ'
const locales: { [index: string]: any } = {
  'en-US': english,
  'cs-CZ': czech,
}

import { filter } from "rxjs";
import * as qs from 'qs';

import { BreadcrumbComponent, BreadcrumbItemDirective } from 'xng-breadcrumb';

import packageInfo from '../../package.json';

@Component({
  selector: 'app-root',
  template: `
    <ng-progress></ng-progress>
    <router-outlet></router-outlet>
  `,
})
export class AppComponent implements OnInit {
  title = 'SmartWebUI';

  _locale: string = 'en-US'
  _theme: string = 'default'

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private store: Store<any>,
    private translate: TranslateService,
    private meta: Meta,
  ) {

    /* Display application version */
    this.meta.addTag({
      name: 'version',
      content: packageInfo.version
    });

    /* Set up localisation */
    for (let localeID in locales) {
      translate.setTranslation(localeID, locales[localeID]);  // Set up translation file
    }
    translate.setDefaultLang('en-US');  // Fallback language

    // Check browser language and set up accordingly
    browser_lang_check:
    for (let lang of navigator.languages) {
      for (let localeID in locales) {
        if (localeID.startsWith(lang))  {   // shortcode (en) matches fullcode (en_US)
          translate.use(localeID);
          break browser_lang_check;
        }
      }
    }

    // Set up pipe to keep localisation in sync with user settings
    this.store.pipe(select(Reducers.getSettings)).subscribe(state => {
      if (this._locale !== state.settings.locale) {
        translate.use(state.settings.locale)
      }
      if (this._theme !== state.settings.theme) {
        this.setTheme(state.settings.theme)
      }
      this._locale = state.settings.locale
      this._theme = state.settings.theme
    })
  }

  ngOnInit(): void {
    // listen url query params and set them to ngrx store
    this.router.events
      .pipe(filter((event): event is NavigationStart => event instanceof NavigationStart))
      .subscribe((event: NavigationStart) => {
        const queryString = event.url.match(/\?(.*)/);
        if (queryString) {
          const queryParams = qs.parse(queryString[1]);
          const keys = Object.keys(queryParams);
          if (keys.length) {
            keys.forEach(key => {
              let value
              switch (queryParams[key]) {
                case 'false':
                  value = false
                  break
                case 'true':
                  value = true
                  break
                default:
                  value = queryParams[key]
                  break
              }
              this.store.dispatch(
                SettingsActions.ChangeSettings({key: value}),
              )
            })
          }
        }
      })

    const setViewPort = (isMobileView: any = false, isTabletView: any = false) => {
      this.store.dispatch(SettingsActions.ChangeSettings({'isMobileView': isMobileView}))
      this.store.dispatch(SettingsActions.ChangeSettings({'isTabletView': isTabletView}))
    }

    const detectViewPort = (load = false) => {
      const _isMobileView = window.innerWidth < 768
      const _isTabletView = window.innerWidth < 992
      const _isDesktopView = !_isMobileView && !_isTabletView
      const isMobileView = store.get('app.settings.isMobileView')
      const isTabletView = store.get('app.settings.isTabletView')
      const isDesktopView = !isMobileView && !isTabletView

      if (_isDesktopView && (_isDesktopView !== isDesktopView || load)) {
        setViewPort(false, false)
      }

      if (_isTabletView && !_isMobileView && (_isTabletView !== isTabletView || load)) {
        setViewPort(false, true)
        this.store.dispatch(SettingsActions.ChangeSettings({isMenuCollapsed: true}))
      }

      if (_isMobileView && (_isMobileView !== isMobileView || load)) {
        setViewPort(true, false)
      }
    }

    detectViewPort(true)
    window.addEventListener('resize', () => {
      detectViewPort()
    })

    const primaryColor = () => {
      const color = store.get('app.settings.primaryColor')
      if (color) {
        const addStyles = () => {
          const styleElement = document.querySelector('#primaryColor')
          if (styleElement) {
            styleElement.remove()
          }
          const body = document.querySelector('body')
          const styleEl = document.createElement('style')
          const css = document.createTextNode(`:root { --kit-color-primary: ${color};}`)
          styleEl.setAttribute('id', 'primaryColor')
          styleEl.appendChild(css)
          body!.appendChild(styleEl)
        }
        addStyles()
        this.store.dispatch(SettingsActions.ChangeSettings({primaryColor: color}))
      }
    }

    primaryColor()


  }


  setTheme = (theme: any) => {
    document.querySelector('html')!.setAttribute('data-kit-theme', theme)
    if (theme === 'default') {
      this.store.dispatch(
        SettingsActions.SetMenuColor({menuColor: 'light'}), //ChangeSettings({settingItem: {menuColor: 'light',}}),
      )
    }
    if (theme === 'dark') {
      this.store.dispatch(
        SettingsActions.SetMenuColor({menuColor: 'dark',}),
      )
    }
  }
}

