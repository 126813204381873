import {LOCALE_ID, NgModule} from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {NgProgressModule} from "ngx-progressbar";
import {NgProgressRouterModule} from "ngx-progressbar/router";
import {NgProgressHttpModule} from "ngx-progressbar/http";
import {StoreRouterConnectingModule} from '@ngrx/router-store';
import {EffectsModule} from '@ngrx/effects';
import { RouterModule, TitleStrategy } from "@angular/router";
import {TranslateCompiler, TranslateModule} from "@ngx-translate/core";
import {TranslateMessageFormatCompiler} from 'ngx-translate-messageformat-compiler';

import { StoreModule } from "@ngrx/store";
import { reducers, metaReducers } from "./core/store";

import { provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import {FormsModule} from "@angular/forms";
import {registerLocaleData} from "@angular/common";
import { default as localeEn } from '@angular/common/locales/en';
import cs from '@angular/common/locales/cs';
registerLocaleData(cs);
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CoreModule } from "./core/core.module";
import { UserEffects } from "./core/store/user";
import { SMARTTitleStrategy } from "./core/services/smart-title-strategy/smarttitle-strategy.service";
import { SharedModule } from "./shared/shared.module";
import { TasksEffects } from "./core/store/tasks/tasks.effects";
import { provideMatomo, withRouter } from 'ngx-matomo-client';
import { environment } from "../environments/environment";
const LOCALE_PROVIDERS = [
  { provide: LOCALE_ID, useValue: 'en' },
]

registerLocaleData(localeEn, 'en')

@NgModule({ declarations: [
        AppComponent,
    ],
    bootstrap: [AppComponent], imports: [CoreModule.forRoot(),
        BrowserAnimationsModule,
        BrowserModule,
        FormsModule,
        AppRoutingModule,
        RouterModule,
        TranslateModule.forRoot({
            compiler: {
                provide: TranslateCompiler,
                useClass: TranslateMessageFormatCompiler
            }
        }),
        NgProgressModule.withConfig({
            thick: true,
            spinner: false,
            color: '#0190fe'
        }),
        NgProgressRouterModule,
        NgProgressHttpModule,
        SharedModule,
        StoreModule.forRoot(reducers, {
            runtimeChecks: {
                //          strictStateSerializability: true, // Switched off, because localStorageSync deserializes Date strings
                strictActionSerializability: true,
            },
            metaReducers: metaReducers,
        }),
        EffectsModule.forRoot([UserEffects, TasksEffects]),
        StoreRouterConnectingModule.forRoot()], providers: [...LOCALE_PROVIDERS,
        Title,
        { provide: TitleStrategy, useClass: SMARTTitleStrategy },
        provideMatomo(environment.matomo, withRouter()), provideHttpClient(withInterceptorsFromDi())] })
export class AppModule {
}
