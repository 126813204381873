<nz-table #nestedTable [nzData]="listOfUsers" [nzPageSize]="10">

</nz-table>

<!--
<ul class="list-unstyled">
  <li class="item">
    <div class="itemPic">
      <img src="assets/images/avatars/1.jpg" alt="User 01"/>
    </div>
    <div class="flex-fill">
      <div class="font-weight-bold text-dark">User 01</div>
      <div class="text-muted">Some Role</div>
    </div>
    <div class="flex-fill row">
      <div class="col-3">
        <a href="javascript: void(0);" class="btn btn-outline-primary align-self-end">Read</a>
      </div>
      <div class="col-3">
        <a href="javascript: void(0);" class="btn btn-outline-danger align-self-end">Execute Actions</a>
      </div>
      <div class="col-3">
        <nz-date-picker [nzPlaceHolder]="'Expiration date'" [(ngModel)]="date"
                        (ngModelChange)="onChange($event)"></nz-date-picker>
      </div>
    </div>
  </li>

  <li class="item">
    <div class="itemPic">
      <img src="assets/images/avatars/2.jpg" alt="Pavel Omacka"/>
    </div>
    <div class="flex-fill">
      <div class="font-weight-bold text-dark">Pavel Omacka</div>
      <div class="text-muted">Support Agent</div>
    </div>
    <div class="flex-fill row">
      <div class="col-3">
        <a href="javascript: void(0);" class="btn btn-outline-primary align-self-end">Read</a>
      </div>
      <div class="col-3">
        <a href="javascript: void(0);" class="btn btn-outline-danger align-self-end">Execute Actions</a>
      </div>
      <div class="col-3">
        <nz-date-picker [nzPlaceHolder]="'Expiration date'" [(ngModel)]="date"
                        (ngModelChange)="onChange($event)"></nz-date-picker>
      </div>
    </div>
  </li>
  <li class="item">
    <div class="itemPic">
      <img src="assets/images/avatars/3.jpg" alt="Jitrnica"/>
    </div>
    <div class="flex-fill">
      <div class="font-weight-bold text-dark">Jitrnica</div>
      <div class="text-muted">Administrator</div>
    </div>
    <div class="flex-fill row">
      <div class="col-3">
        <a href="javascript: void(0);" class="btn btn-outline-primary align-self-end">Read</a>
      </div>
      <div class="col-3">
        <a href="javascript: void(0);" class="btn btn-outline-danger align-self-end">Execute Actions</a>
      </div>
      <div class="col-3">
        <nz-date-picker [nzPlaceHolder]="'Expiration date'" [(ngModel)]="date"
                        (ngModelChange)="onChange($event)"></nz-date-picker>
      </div>
    </div>
  </li>
  <li class="item">
    <div class="itemPic">
      <img src="assets/images/avatars/4.jpg" alt="Someone"/>
    </div>
    <div class="flex-fill">
      <div class="font-weight-bold text-dark">Someone</div>
      <div class="text-muted">Viewer</div>
    </div>
    <div class="flex-fill row">
      <div class="col-3">
        <a href="javascript: void(0);" class="btn btn-outline-primary align-self-end">Read</a>
      </div>
      <div class="col-3">
        <a href="javascript: void(0);" class="btn btn-outline-danger align-self-end">Execute Actions</a>
      </div>
      <div class="col-3">
        <nz-date-picker [nzPlaceHolder]="'Expiration date'" [(ngModel)]="date"
                        (ngModelChange)="onChange($event)"></nz-date-picker>
      </div>
    </div>
  </li>
  <li class="item">
    <div class="itemPic">
      <img src="assets/images/avatars/5.jpg" alt="Květoslava Nováková"/>
    </div>
    <div class="mb-5">
      <div class="font-weight-bold text-dark">Květoslava Nováková</div>
      <div class="text-muted">Administrator</div>
    </div>
    <div class="mb-5">
      <div class="col-3">
        <a href="javascript: void(0);" class="btn btn-outline-primary align-self-end">Read</a>
      </div>
      <div class="col-3">
        <a href="javascript: void(0);" class="btn btn-outline-danger align-self-end">Execute Actions</a>
      </div>
      <div class="col-3">
        <nz-date-picker [nzPlaceHolder]="'Expiration date'" [(ngModel)]="date"
                        (ngModelChange)="onChange($event)"></nz-date-picker>
      </div>
    </div>
  </li>

</ul>
  -->
