import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { menuContents } from "./menu.interface";
import { MainMenu } from './config'

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  constructor() {}

  getMainMenu(): Observable<menuContents> {
    return of(MainMenu);
  }
}
