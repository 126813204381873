import { Injectable, isDevMode } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from "rxjs";
import { filter } from 'rxjs/operators';
import { OAuthService } from "angular-oauth2-oidc";
import { environment } from "../../../../environments/environment";
import { IdentityClaims } from "./IdentityClaims";
import { Store } from "@ngrx/store";
import { UserProfileLoaded, UserProfileLoadFailed } from "../../store/user";

@Injectable({
  providedIn: 'root'
  // Should be singleton according to: https://medium.com/weekly-webtips/a-singleton-service-in-angular-a6ed577413d6
})
export class AuthService {

  public isAuthenticated$ = new BehaviorSubject<boolean>(false);

  // The list contains capabilities as set up in Perun > Resource > Attributes > Resource capabilities.
  public resources$ = new BehaviorSubject<string[]>([]);

  // Contains the user access token or empty string (if no valid token is present)
  readonly accessToken$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor(
    private oAuthService: OAuthService,
    private router: Router,
    private store: Store
  ) {
    // Log creation of the AuthService in development mode
    if (isDevMode()) {
      console.warn("A new AuthService has been created.")
    }
    this.oAuthService.configure(environment.auth);

    // Update 'isAuthenticated' observable when access_token changes
    // Theoretically can cause race condition when run in multiple tabs, but in reality never observed
    // (https://github.com/jeroenheijmans/sample-angular-oauth2-oidc-with-auth-guards/issues/2)
    // TODO: Rewrite listener to specific storage event if it gets propagated into the oAuth library
    window.addEventListener('storage', (event) => {
      // The 'key' is 'null' if the event was caused by .clear()
      if (event.key !== 'access_token' && event.key !== null) {
        return;
      }

      if (isDevMode()) {
        console.warn('Noticed changes to access_token (most likely from another tab), updating isAuthenticated');
      }

      this.accessToken$.next(this.oAuthService.getAccessToken());
      this.isAuthenticated$.next(this.oAuthService.hasValidAccessToken());

      if (!this.oAuthService.hasValidAccessToken()) {
        this.navigateToLoginPage();
      }
    });

    // For every oAuth event update the 'accessToken', 'isAuthenticated' and 'resources'
    this.oAuthService.events.subscribe(_ => {
      this.accessToken$.next(this.oAuthService.getAccessToken());
      this.isAuthenticated$.next(this.oAuthService.hasValidAccessToken());

      if (!this.isAuthenticated$.value || !this.oAuthService.getIdentityClaims().hasOwnProperty('eduperson_entitlement'))
        this.resources$.next([]);
      else {
        this.resources$.next(
          (this.oAuthService.getIdentityClaims() as IdentityClaims).eduperson_entitlement
            .map((claim) => {
              // Remove #idm.ics.muni.cz from the end of the resource capability string
              claim = claim.substring(0, claim.lastIndexOf('#'));

              // Remove the leading "urn:geant:muni.cz:"
              claim = claim.split(':').slice(3).join(':');

              // Decode claim string, so it is in the same form as entered in Perun
              return decodeURIComponent(claim);
            })
        );
      }

    });

    this.oAuthService.events
      .pipe(filter(e => ['token_received'].includes(e.type)))
      .subscribe(() => this.oAuthService.loadUserProfile());

    this.oAuthService.events
      .pipe(filter(e => ['user_profile_loaded'].includes(e.type)))
      .subscribe(() => {
        const claims:IdentityClaims | null = <IdentityClaims | null>this.oAuthService.getIdentityClaims();
        if (!claims) {
          this.store.dispatch(UserProfileLoadFailed());
        } else {
          this.store.dispatch(UserProfileLoaded(claims));
        }
      })

    // Detect logout
    this.oAuthService.events
      .pipe(filter(e => ['session_terminated', 'session_error'].includes(e.type)))
      .subscribe(() => this.navigateToLoginPage());

    // Setup automatic refresh of Access Token with Refresh Token
    this.oAuthService.setupAutomaticSilentRefresh({}, "access_token");
  }

  public runInitialLogin(): Promise<boolean> {
    if (this.oAuthService.hasValidAccessToken()) {
      const claims:IdentityClaims | null = <IdentityClaims | null>this.oAuthService.getIdentityClaims();
      if (!claims) {
        this.store.dispatch(UserProfileLoadFailed());
      } else {
        this.store.dispatch(UserProfileLoaded(claims));
      }
    }

    return this.oAuthService.loadDiscoveryDocumentAndTryLogin();
  }

  public getLoginUrlTree() {
    return this.router.createUrlTree(['/login']);
  }

  public navigateToLoginPage() {
    // sessionStorage.setItem('app.login.origin', this.router.url);
    this.router.navigateByUrl( this.getLoginUrlTree() );
  }

  public login(targetUrl?: string) {
    this.oAuthService.initLoginFlow(targetUrl || this.router.url);
  }

  public logout() {
    this.oAuthService.logOut();
  }

  public refresh() { this.oAuthService.silentRefresh(); }

  /**
   * Load the list of resource capabilities of the user.
   * The list contains capabilities as set up in Perun > Resource > Attributes > Resource capabilities.
   */
  public get resources(): string[] {
    return this.resources$.value;
  }

  /**
   * Authorizes request to access given resource.
   */
  public authorizeResource(resource: string): boolean {
    return this.resources$.value.includes(resource);
  }

  // FIXME: REMOVE BEFORE PRODUCTION!!! TESTING ONLY.
  public get accessToken() { return this.oAuthService.getAccessToken(); }
  public get refreshToken() { return this.oAuthService.getRefreshToken(); }
  public get identityClaims(): IdentityClaims { return this.oAuthService.getIdentityClaims() as IdentityClaims; }
  public get idToken() { return this.oAuthService.getIdToken(); }
  public get logoutUrl() { return this.oAuthService.logoutUrl; }
}
