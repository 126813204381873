import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AppPreloader } from "./app-routing-loader";

// Modules
import { CoreModule } from "./core/core.module";
import { SharedModule } from "./shared/shared.module";

// Guards
import { AuthGuard, AuthGuardChild } from "./core/guards/auth-guard/auth.guard";
import { PerunCapabilityGuard } from "./core/guards/perun-capability/perun-capability.guard";

// Layouts
import {LayoutModalComponent} from "./core/components/layouts/layout-modal/layout-modal.component";
import {
  LayoutPlainComponent
} from "./core/components/layouts/layout-plain/layout-plain.component";
import {
  LayoutBreadcrumbsComponent
} from "./core/components/layouts/layout-breadcrumbs/layout-breadcrumbs.component";

// Components
import { LoginComponent } from "./core/components/login/login.component";
import { DashboardComponent } from "./core/components/dashboard/dashboard.component";
import { UserTasksComponent } from "./core/components/tasks/user-tasks/user-tasks.component";
import { Error403Component } from "./shared/components/error/403/403.component";
import { Error404Component } from "./shared/components/error/404/404.component";
import { Error500Component } from "./shared/components/error/500/500.component";
import { MFARequiredComponent } from './shared/components/error/mfa-required/mfa-required.component';


const routes: Routes = [
  {
    path: 'login',
    component: LayoutModalComponent,
    children: [
      { path: '', title: 'login.title', component: LoginComponent },
    ],
  },
  {
    path: 'error',
    component: LayoutModalComponent,
    children: [
      { path: '403', title: 'error.403.title', component: Error403Component },
      { path: '404', title: 'error.404.title', component: Error404Component },
      { path: '500', title: 'error.500.title', component: Error500Component },
    ]
  },
  {
    path: '',
    component: LayoutPlainComponent,
    data: { breadcrumb: { label: 'home' } },
    children: [
      {
        path: '',
        pathMatch: 'full',
        canActivate: [AuthGuard],
        component: DashboardComponent,
      },
      {
        path: '',
        canActivateChild: [AuthGuard],
        component: LayoutBreadcrumbsComponent,
        children: [
          {
            path: 'admin-tools',
            data: {
              breadcrumb: { label: 'adminTools.title', disable: true },
            },
            loadChildren: () =>
              import('src/app/smart-admin-tools/admin-tools.module').then(m => m.AdminToolsModule),
          },
          {
            path: 'assets',
            canActivate: [PerunCapabilityGuard],
            data: {
              breadcrumb: { label: 'assets.title', disable: true },
              resource: "res:SMART_users"
            },
            loadChildren: () =>
              import('src/app/smart-assets/smart-assets.module').then(m => m.SmartAssetsModule),
          },
          {
            path: 'processes',
            data: { breadcrumb: { label: 'processes.title', disable: true } },
            loadChildren: () =>
              import('src/app/smart-processes/processes.module').then(m => m.ProcessesModule),
          },
          {
            path: 'reports',
            data: { breadcrumb: { label: 'audit.report', disable: true } },
            loadChildren: () =>
              import('src/app/smart-reporting/reporting.module').then(m => m.ReportingModule),
          },
          {
            path: 'services',
            data: { breadcrumb: { label: 'services.title', disable: true } },
            loadChildren: () =>
              import('src/app/smart-services/services.module').then(m => m.ServicesModule),
          },
          {
            path: 'tasks',
            data: { breadcrumb: { label: 'tasks.title', disable: true } },
            loadChildren: () =>
              import('src/app/smart-tasks/tasks.module').then(m => m.TasksModule),
          },
          {
            path: 'my-tasks',
            title: 'user.tasks.title',
            data: { breadcrumb: { label: 'user.tasks.title' } },
            component: UserTasksComponent,
          },
          {
            path: 'error',
            data: { breadcrumb: { label: 'notifications.error', disable: true } },
            children: [
              { 
                path: 'mfa-required',
                title: 'error.mfa-required.title',
                data: { breadcrumb: { label: 'error.mfa-required.title' } },
                component: MFARequiredComponent
              }
            ]
          }
        ],
      }
    ]
  },
  {
    path: '**',
    component: LayoutModalComponent,
    children: [
      { path: '', component: Error404Component, title: 'error.404.title' }
    ]
  },
];

@NgModule({
  imports: [
    CoreModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(routes, {
      preloadingStrategy: AppPreloader,
  //    enableTracing: true
    }),
  ],
  providers: [AppPreloader],
  exports: [RouterModule]
})
export class AppRoutingModule { }
