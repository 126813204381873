import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'smart-system-404',
  templateUrl: './404.component.html',
})
export class Error404Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  goBack() {
    history.back();
  }
}
