<h4>{{'user.tasks.title' | translate}}</h4>

<nz-card>
  <nz-tabset [(nzSelectedIndex)]="selectedTab">
    <nz-tab *ngIf="showTaskDetails" nzTitle="{{'user.tasks.detail' | translate | firstLetterCapital}}">
      <task-detail [taskUUID]="showTaskDetails"></task-detail>
    </nz-tab>
    <nz-tab nzTitle="{{'user.tasks.active' | translate | firstLetterCapital }}" [nzDisabled]="!(activeTasksExist$ | async)">
      <active-tasks-list></active-tasks-list>
    </nz-tab>
    <nz-tab nzTitle="{{'user.tasks.all' | translate | firstLetterCapital }}">
      <user-tasks-list></user-tasks-list>
    </nz-tab>
  </nz-tabset>
</nz-card>
