import { Action, createReducer, on } from "@ngrx/store";
import { BookmarksState, initialBookmarksState } from "./bookmarks.state";
import { BookmarkAdded, BookmarkRemoved } from "./bookmarks.actions";

const bookmarksReducer = createReducer(
  initialBookmarksState,
  on(BookmarkAdded, (state, bookmark) => [
    ...state,
    { name: bookmark.name, link: bookmark.link }
  ]),
  on(BookmarkRemoved, (state, exBookmark) => state.filter(bookmark => bookmark.link !== exBookmark.link)),
);

export function reducer(state: BookmarksState | undefined, action: Action) {
  return bookmarksReducer(state, action);
}
