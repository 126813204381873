import { createAction, props } from "@ngrx/store";
import { Bookmark } from "./bookmarks.state";

export enum BookmarksActionNames {
  BOOKMARK_ADDED = '[Bookmarks] Bookmark added',
  BOOKMARK_REMOVED = '[Bookmarks] Bookmark removed',
}

export const BookmarkAdded = createAction(BookmarksActionNames.BOOKMARK_ADDED, props<Bookmark>());
export const BookmarkRemoved = createAction(BookmarksActionNames.BOOKMARK_REMOVED, props<Bookmark>());
