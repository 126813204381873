import { createAction, props } from "@ngrx/store";
import { ITaskResponse } from "../../../shared/api";
import { ITask } from "../../util/task";

export enum TasksActionNames {
  CREATED = '[Task] Created',
  CONFIRMED = '[Task] Confirmed by backend',
  STATUS_UPDATED = '[Task] Status updated',
  FINISHED = '[Task] Finished',
  NOTIFICATIONS_SEEN = '[Task] Notifications seen',
  SERV_UNAVAILABLE = '[Task] SMART server unavailable',
  REQUEST_ERROR = '[Task] Request error',
  CANCELLED = '[Task] Cancelled',
}

export const TaskCreated = createAction(TasksActionNames.CREATED, props<ITask>());
export const TaskServUnavailable = createAction(
  TasksActionNames.SERV_UNAVAILABLE,
  props<{uuid: string, error: string}>()
);
export const TaskRequestError = createAction(
  TasksActionNames.REQUEST_ERROR,
  props<{uuid: string, error: string}>()
);
export const TaskConfirmed = createAction(TasksActionNames.CONFIRMED, props<{timestamp: string, confirmation: ITaskResponse}>());
export const TaskStatusUpdated = createAction(TasksActionNames.STATUS_UPDATED, props<ITaskResponse>());
export const TaskFinished = createAction(TasksActionNames.FINISHED, props<{uuid: string}>());
export const TaskNotificationsSeen = createAction(TasksActionNames.NOTIFICATIONS_SEEN);
export const TaskCancelled = createAction(TasksActionNames.CANCELLED, props<{uuid: string}>());
