import { ActionReducer, MetaReducer } from "@ngrx/store";
import { localStorageSync } from "ngrx-store-localstorage";
import { environment } from "../../../environments/environment";
import { SETTINGS_FEATURE_KEY } from "./settings/model";
import { notificationsFeatureName } from "./notifications";
import { bookmarksFeatureName } from "./bookmarks";

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({
    keys: [ bookmarksFeatureName, SETTINGS_FEATURE_KEY, notificationsFeatureName ],
    rehydrate: true,
    storage: localStorage,
    storageKeySerializer: (key) => `smart_${key}`,
  })(reducer);
}

export function logger(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state: any, action: any): any => {
    const result = reducer(state, action)
    console.groupCollapsed(action.type)
    console.log('prev state', state)
    console.log('action', action)
    console.log('next state', result)
    console.groupEnd()
    return result
  }
}

export const metaReducers: MetaReducer<any>[] = !environment.production ?
    [logger, localStorageSyncReducer]
  : [localStorageSyncReducer]
