/**
 * Contains all the API objects of PCSM
 */

import { Observable, of } from "rxjs";

export namespace pcsm {

    export const UUID = "";

    export interface IADNode {
        "DistinguishedName": string,
        "Type": string,
        "Name": string,
        "Description": string | null,
    }

    export interface IOrganizationalUnit extends IADNode {
        "Type": "OrganizationalUnit",
        "OrganizationalUnits": IOrganizationalUnit[],
        "Computers": IComputer[],
    }

    export interface IComputer extends IADNode {
        "Type": "Computer"
    }

    export function isOrganizationalUnit(object: IADNode): object is IOrganizationalUnit {
        return object.Type == "OrganizationalUnit";
    }
    export function isComputer(object: IADNode): object is IComputer {
        return object.Type == "Computer";
    }

    export function getMockData(): Observable<IOrganizationalUnit[]> {
        return of(mock_AD_tree);
    }

    const mock_AD_tree: IOrganizationalUnit[] = [
        {
          "DistinguishedName": "OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
          "Name": "Desktops",
          "Description": null,
          "OrganizationalUnits": [
            {
              "DistinguishedName": "OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
              "Name": "UKB",
              "Description": "cit@ukb.muni.cz",
              "OrganizationalUnits": [
                {
                  "DistinguishedName": "OU=RECEPTION,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "RECEPTION",
                  "Description": null,
                  "OrganizationalUnits": [
                    {
                      "DistinguishedName": "OU=UKB-D-382914OU,OU=RECEPTION,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-D-382914OU",
                      "Description": "B22/recepce",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-382914,OU=UKB-D-382914OU,OU=RECEPTION,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-382914",
                          "Description": "B22/recepce",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=UKB-D-382915OU,OU=RECEPTION,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-D-382915OU",
                      "Description": "E35/vratnice",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-382915,OU=UKB-D-382915OU,OU=RECEPTION,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-382915",
                          "Description": "E35/vratnice",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=UKB-D-382916OU,OU=RECEPTION,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-D-382916OU",
                      "Description": "E34/vratnice",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-382916,OU=UKB-D-382916OU,OU=RECEPTION,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-382916",
                          "Description": "E34/vratnice",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=UKB-D-382919OU,OU=RECEPTION,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-D-382919OU",
                      "Description": "F01-1/vratnice",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-382919,OU=UKB-D-382919OU,OU=RECEPTION,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-382919",
                          "Description": "F01-1/vratnice",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=UKB-D-382912OU,OU=RECEPTION,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-D-382912OU",
                      "Description": "PCO/vratnice",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-382912,OU=UKB-D-382912OU,OU=RECEPTION,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-382912",
                          "Description": "PCO/vratnice",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=UKB-D-429205OU,OU=RECEPTION,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-D-429205OU",
                      "Description": "F37/vratnice",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-429205,OU=UKB-D-429205OU,OU=RECEPTION,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-429205",
                          "Description": "F37/vratnice",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    }
                  ],
                  "Computers": [],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=FSPS,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "FSPS",
                  "Description": null,
                  "OrganizationalUnits": [
                    {
                      "DistinguishedName": "OU=Kampus,OU=FSPS,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Kampus",
                      "Description": null,
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=Oddeleni Centra jazykoveho vzdelavani,OU=Kampus,OU=FSPS,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "Oddeleni Centra jazykoveho vzdelavani",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=UKB-D-456926OU,OU=Oddeleni Centra jazykoveho vzdelavani,OU=Kampus,OU=FSPS,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-456926OU",
                              "Description": "C15/111 - Prucklova",
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=UKB-D-456926,OU=UKB-D-456926OU,OU=Oddeleni Centra jazykoveho vzdelavani,OU=Kampus,OU=FSPS,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-456926",
                                  "Description": "C15/111 - Prucklova",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=Dekanat,OU=Kampus,OU=FSPS,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "Dekanat",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=Dekan,OU=Dekanat,OU=Kampus,OU=FSPS,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Dekan",
                              "Description": "nemazat OU - Adobe",
                              "OrganizationalUnits": [
                                {
                                  "DistinguishedName": "OU=UKB-D-426684OU,OU=Dekan,OU=Dekanat,OU=Kampus,OU=FSPS,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-426684OU",
                                  "Description": "D33/228 \u2013 Cacek",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-426684,OU=UKB-D-426684OU,OU=Dekan,OU=Dekanat,OU=Kampus,OU=FSPS,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-426684",
                                      "Description": "D33/228 \u2013 Cacek",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                }
                              ],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=Ekonomicke oddeleni,OU=Dekanat,OU=Kampus,OU=FSPS,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Ekonomicke oddeleni",
                              "Description": null,
                              "OrganizationalUnits": [
                                {
                                  "DistinguishedName": "OU=UKB-D-421645OU,OU=Ekonomicke oddeleni,OU=Dekanat,OU=Kampus,OU=FSPS,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-421645OU",
                                  "Description": "D33/215 - Martanova / nemazat OU - Adobe",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-421645,OU=UKB-D-421645OU,OU=Ekonomicke oddeleni,OU=Dekanat,OU=Kampus,OU=FSPS,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-421645",
                                      "Description": "D33/215 - Martanova / nemazat OU - Adobe",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-401753OU,OU=Ekonomicke oddeleni,OU=Dekanat,OU=Kampus,OU=FSPS,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-401753OU",
                                  "Description": "D33/210 - ?",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-401753,OU=UKB-D-401753OU,OU=Ekonomicke oddeleni,OU=Dekanat,OU=Kampus,OU=FSPS,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-401753",
                                      "Description": "D33/210 - ?",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                }
                              ],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=Oddeleni pro strategii a rozvoj,OU=Dekanat,OU=Kampus,OU=FSPS,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Oddeleni pro strategii a rozvoj",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=Oddeleni vedy\\, vyzkumu a projektove podpory,OU=Dekanat,OU=Kampus,OU=FSPS,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Oddeleni vedy, vyzkumu a projektove podpory",
                              "Description": null,
                              "OrganizationalUnits": [
                                {
                                  "DistinguishedName": "OU=UKB-D-333228OU,OU=Oddeleni vedy\\, vyzkumu a projektove podpory,OU=Dekanat,OU=Kampus,OU=FSPS,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-333228OU",
                                  "Description": "D33/224 - Peterkova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-333228,OU=UKB-D-333228OU,OU=Oddeleni vedy\\, vyzkumu a projektove podpory,OU=Dekanat,OU=Kampus,OU=FSPS,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-333228",
                                      "Description": "D33/224 - Peterkova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                }
                              ],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=Oddeleni vnejsich vztahu,OU=Dekanat,OU=Kampus,OU=FSPS,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Oddeleni vnejsich vztahu",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=Personalni oddeleni,OU=Dekanat,OU=Kampus,OU=FSPS,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Personalni oddeleni",
                              "Description": null,
                              "OrganizationalUnits": [
                                {
                                  "DistinguishedName": "OU=UKB-D-401747OU,OU=Personalni oddeleni,OU=Dekanat,OU=Kampus,OU=FSPS,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-401747OU",
                                  "Description": "D33/205 - Cenkova - Adobe 2020",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-401747,OU=UKB-D-401747OU,OU=Personalni oddeleni,OU=Dekanat,OU=Kampus,OU=FSPS,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-401747",
                                      "Description": "D33/205 - Cenkova - Adobe 2020",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                }
                              ],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=Sekretariat,OU=Dekanat,OU=Kampus,OU=FSPS,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Sekretariat",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=Studijni oddeleni,OU=Dekanat,OU=Kampus,OU=FSPS,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Studijni oddeleni",
                              "Description": null,
                              "OrganizationalUnits": [
                                {
                                  "DistinguishedName": "OU=UKB-D-401751OU,OU=Studijni oddeleni,OU=Dekanat,OU=Kampus,OU=FSPS,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-401751OU",
                                  "Description": "D33/204 - Malkova - Adobe Pro 2020",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-401751,OU=UKB-D-401751OU,OU=Studijni oddeleni,OU=Dekanat,OU=Kampus,OU=FSPS,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-401751",
                                      "Description": "D33/204 - Malkova - Adobe Pro 2020",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                }
                              ],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=Jina pracoviste,OU=Kampus,OU=FSPS,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "Jina pracoviste",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=Inkubator kinantropologickeho vyzkumu,OU=Jina pracoviste,OU=Kampus,OU=FSPS,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Inkubator kinantropologickeho vyzkumu",
                              "Description": null,
                              "OrganizationalUnits": [
                                {
                                  "DistinguishedName": "OU=UKB-D-439061OU,OU=Inkubator kinantropologickeho vyzkumu,OU=Jina pracoviste,OU=Kampus,OU=FSPS,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-439061OU",
                                  "Description": "D33/115 - DSP",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-439061,OU=UKB-D-439061OU,OU=Inkubator kinantropologickeho vyzkumu,OU=Jina pracoviste,OU=Kampus,OU=FSPS,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-439061",
                                      "Description": "D33/115 - DSP",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                }
                              ],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=Laboratore FSpS,OU=Jina pracoviste,OU=Kampus,OU=FSPS,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Laboratore FSpS",
                              "Description": null,
                              "OrganizationalUnits": [
                                {
                                  "DistinguishedName": "OU=UKB-D-456243OU,OU=Laboratore FSpS,OU=Jina pracoviste,OU=Kampus,OU=FSPS,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-456243OU",
                                  "Description": "E34/233 - Grun",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-456243,OU=UKB-D-456243OU,OU=Laboratore FSpS,OU=Jina pracoviste,OU=Kampus,OU=FSPS,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-456243",
                                      "Description": "E34/233 - Grun",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-421367OU,OU=Laboratore FSpS,OU=Jina pracoviste,OU=Kampus,OU=FSPS,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-421367OU",
                                  "Description": "E34/128 - Pospisil LAB",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-421367,OU=UKB-D-421367OU,OU=Laboratore FSpS,OU=Jina pracoviste,OU=Kampus,OU=FSPS,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-421367",
                                      "Description": null,
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-47058OU,OU=Laboratore FSpS,OU=Jina pracoviste,OU=Kampus,OU=FSPS,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-47058OU",
                                  "Description": "E34/128 - Pospisil",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-47058,OU=UKB-D-47058OU,OU=Laboratore FSpS,OU=Jina pracoviste,OU=Kampus,OU=FSPS,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-47058",
                                      "Description": "E34/128 - Pospisil",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                }
                              ],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=Katedry,OU=Kampus,OU=FSPS,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "Katedry",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=Katedra pohybovych aktivit a zdravi,OU=Katedry,OU=Kampus,OU=FSPS,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Katedra pohybovych aktivit a zdravi",
                              "Description": null,
                              "OrganizationalUnits": [
                                {
                                  "DistinguishedName": "OU=UKB-D-441564OU,OU=Katedra pohybovych aktivit a zdravi,OU=Katedry,OU=Kampus,OU=FSPS,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-441564OU",
                                  "Description": "E34/224 - Kapounkova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-441564,OU=UKB-D-441564OU,OU=Katedra pohybovych aktivit a zdravi,OU=Katedry,OU=Kampus,OU=FSPS,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-441564",
                                      "Description": "E34/224 - Kapounkova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-441565OU,OU=Katedra pohybovych aktivit a zdravi,OU=Katedry,OU=Kampus,OU=FSPS,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-441565OU",
                                  "Description": "E34/228 - Struhar",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-441565,OU=UKB-D-441565OU,OU=Katedra pohybovych aktivit a zdravi,OU=Katedry,OU=Kampus,OU=FSPS,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-441565",
                                      "Description": "E34/228 - Struhar",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-439825OU,OU=Katedra pohybovych aktivit a zdravi,OU=Katedry,OU=Kampus,OU=FSPS,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-439825OU",
                                  "Description": "E34/217 - Vysoky",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-439825,OU=UKB-D-439825OU,OU=Katedra pohybovych aktivit a zdravi,OU=Katedry,OU=Kampus,OU=FSPS,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-439825",
                                      "Description": "E34/217 - Vysoky",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                }
                              ],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=Katedra sportovniho vykonu a diagnostiky,OU=Katedry,OU=Kampus,OU=FSPS,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Katedra sportovniho vykonu a diagnostiky",
                              "Description": null,
                              "OrganizationalUnits": [
                                {
                                  "DistinguishedName": "OU=UKB-D-327948OU,OU=Katedra sportovniho vykonu a diagnostiky,OU=Katedry,OU=Kampus,OU=FSPS,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-327948OU",
                                  "Description": "W10/E34/318 - Partykova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-327948,OU=UKB-D-327948OU,OU=Katedra sportovniho vykonu a diagnostiky,OU=Katedry,OU=Kampus,OU=FSPS,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-327948",
                                      "Description": "W10/E34/318 - Partykova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-456238OU,OU=Katedra sportovniho vykonu a diagnostiky,OU=Katedry,OU=Kampus,OU=FSPS,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-456238OU",
                                  "Description": "E34/307 - Hlinsky",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-456238,OU=UKB-D-456238OU,OU=Katedra sportovniho vykonu a diagnostiky,OU=Katedry,OU=Kampus,OU=FSPS,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-456238",
                                      "Description": "E34/307 - Hlinsky",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-401741OU,OU=Katedra sportovniho vykonu a diagnostiky,OU=Katedry,OU=Kampus,OU=FSPS,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-401741OU",
                                  "Description": "E34/305 - Kralova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-401741,OU=UKB-D-401741OU,OU=Katedra sportovniho vykonu a diagnostiky,OU=Katedry,OU=Kampus,OU=FSPS,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-401741",
                                      "Description": "E34/305 - Kralova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                }
                              ],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=Katedra telesne vychovy a spolecenskych ved,OU=Katedry,OU=Kampus,OU=FSPS,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Katedra telesne vychovy a spolecenskych ved",
                              "Description": null,
                              "OrganizationalUnits": [
                                {
                                  "DistinguishedName": "OU=UKB-D-418195OU,OU=Katedra telesne vychovy a spolecenskych ved,OU=Katedry,OU=Kampus,OU=FSPS,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-418195OU",
                                  "Description": "D33/315 - Cihounkova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-418195,OU=UKB-D-418195OU,OU=Katedra telesne vychovy a spolecenskych ved,OU=Katedry,OU=Kampus,OU=FSPS,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-418195",
                                      "Description": "D33/315 - Cihounkova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-401736OU,OU=Katedra telesne vychovy a spolecenskych ved,OU=Katedry,OU=Kampus,OU=FSPS,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-401736OU",
                                  "Description": "W10 - D33/310 - spolecne",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-401736,OU=UKB-D-401736OU,OU=Katedra telesne vychovy a spolecenskych ved,OU=Katedry,OU=Kampus,OU=FSPS,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-401736",
                                      "Description": "W10 - D33/310 - spolecne",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-411473OU,OU=Katedra telesne vychovy a spolecenskych ved,OU=Katedry,OU=Kampus,OU=FSPS,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-411473OU",
                                  "Description": "D33/308 - Hurych",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-411473,OU=UKB-D-411473OU,OU=Katedra telesne vychovy a spolecenskych ved,OU=Katedry,OU=Kampus,OU=FSPS,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-411473",
                                      "Description": "D33/308 - Hurych",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-348524OU,OU=Katedra telesne vychovy a spolecenskych ved,OU=Katedry,OU=Kampus,OU=FSPS,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-348524OU",
                                  "Description": "D33/338 - Juva 1058",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-348524,OU=UKB-D-348524OU,OU=Katedra telesne vychovy a spolecenskych ved,OU=Katedry,OU=Kampus,OU=FSPS,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-348524",
                                      "Description": "D33/338 - Juva 1058",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-401752OU,OU=Katedra telesne vychovy a spolecenskych ved,OU=Katedry,OU=Kampus,OU=FSPS,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-401752OU",
                                  "Description": "W10 - D33/309 - Gillavry",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-401752,OU=UKB-D-401752OU,OU=Katedra telesne vychovy a spolecenskych ved,OU=Katedry,OU=Kampus,OU=FSPS,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-401752",
                                      "Description": "W10 - D33/309 - Gillavry",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=Centrum univerzitniho sportu,OU=Katedra telesne vychovy a spolecenskych ved,OU=Katedry,OU=Kampus,OU=FSPS,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "Centrum univerzitniho sportu",
                                  "Description": null,
                                  "OrganizationalUnits": [],
                                  "Computers": [],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-401738OU,OU=Katedra telesne vychovy a spolecenskych ved,OU=Katedry,OU=Kampus,OU=FSPS,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-401738OU",
                                  "Description": "D33/335 - Novak",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-401738,OU=UKB-D-401738OU,OU=Katedra telesne vychovy a spolecenskych ved,OU=Katedry,OU=Kampus,OU=FSPS,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-401738",
                                      "Description": "D33/335 - Novak",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-401739OU,OU=Katedra telesne vychovy a spolecenskych ved,OU=Katedry,OU=Kampus,OU=FSPS,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-401739OU",
                                  "Description": "E34/319 - DSP",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-401739,OU=UKB-D-401739OU,OU=Katedra telesne vychovy a spolecenskych ved,OU=Katedry,OU=Kampus,OU=FSPS,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-401739",
                                      "Description": "E34/319 - DSP",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-421031OU,OU=Katedra telesne vychovy a spolecenskych ved,OU=Katedry,OU=Kampus,OU=FSPS,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-421031OU",
                                  "Description": "E34/319 - DSP",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-421031,OU=UKB-D-421031OU,OU=Katedra telesne vychovy a spolecenskych ved,OU=Katedry,OU=Kampus,OU=FSPS,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-421031",
                                      "Description": "E34/319 - DSP",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-421032OU,OU=Katedra telesne vychovy a spolecenskych ved,OU=Katedry,OU=Kampus,OU=FSPS,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-421032OU",
                                  "Description": "E34/319 - DSP",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-421032,OU=UKB-D-421032OU,OU=Katedra telesne vychovy a spolecenskych ved,OU=Katedry,OU=Kampus,OU=FSPS,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-421032",
                                      "Description": "E34/319 - DSP",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                }
                              ],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=Ucelova zarizeni,OU=Kampus,OU=FSPS,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "Ucelova zarizeni",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=Centrum celozivotniho vzdelavani,OU=Ucelova zarizeni,OU=Kampus,OU=FSPS,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Centrum celozivotniho vzdelavani",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=Technicko-provozni oddeleni,OU=Ucelova zarizeni,OU=Kampus,OU=FSPS,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Technicko-provozni oddeleni",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=Udolni,OU=FSPS,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Udolni",
                      "Description": null,
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=Ucelova zarizeni,OU=Udolni,OU=FSPS,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "Ucelova zarizeni",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=Technicko-provozni oddeleni,OU=Ucelova zarizeni,OU=Udolni,OU=FSPS,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Technicko-provozni oddeleni",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=Katedry,OU=Udolni,OU=FSPS,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "Katedry",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=Katedra telesne vychovy a spolecenskych ved,OU=Katedry,OU=Udolni,OU=FSPS,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Katedra telesne vychovy a spolecenskych ved",
                              "Description": null,
                              "OrganizationalUnits": [
                                {
                                  "DistinguishedName": "OU=Centrum univerzitniho sportu,OU=Katedra telesne vychovy a spolecenskych ved,OU=Katedry,OU=Udolni,OU=FSPS,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "Centrum univerzitniho sportu",
                                  "Description": null,
                                  "OrganizationalUnits": [],
                                  "Computers": [],
                                  "Type": "OrganizationalUnit"
                                }
                              ],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    }
                  ],
                  "Computers": [],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "LF",
                  "Description": null,
                  "OrganizationalUnits": [
                    {
                      "DistinguishedName": "OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Kampus",
                      "Description": null,
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=Jina pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "Jina pracoviste",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=Centrum jazykoveho vzdelavani,OU=Jina pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Centrum jazykoveho vzdelavani",
                              "Description": null,
                              "OrganizationalUnits": [
                                {
                                  "DistinguishedName": "OU=UKB-D-419941OU,OU=Centrum jazykoveho vzdelavani,OU=Jina pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-419941OU",
                                  "Description": "C15/108 - Svanda",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-419941,OU=UKB-D-419941OU,OU=Centrum jazykoveho vzdelavani,OU=Jina pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-419941",
                                      "Description": "C15/108 - Svanda",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-413878OU,OU=Centrum jazykoveho vzdelavani,OU=Jina pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-413878OU",
                                  "Description": "C15/118 - Porizkova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-413878,OU=UKB-D-413878OU,OU=Centrum jazykoveho vzdelavani,OU=Jina pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-413878",
                                      "Description": "C15/118 - Porizkova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-393087OU,OU=Centrum jazykoveho vzdelavani,OU=Jina pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-393087OU",
                                  "Description": "C15/116 - Puncochar",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-393087,OU=UKB-D-393087OU,OU=Centrum jazykoveho vzdelavani,OU=Jina pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-393087",
                                      "Description": "C15/116 - Puncochar",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-425791OU,OU=Centrum jazykoveho vzdelavani,OU=Jina pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-425791OU",
                                  "Description": "C15/118 - Sevcikova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-425791,OU=UKB-D-425791OU,OU=Centrum jazykoveho vzdelavani,OU=Jina pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-425791",
                                      "Description": "C15/118 - Sevcikova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-419952OU,OU=Centrum jazykoveho vzdelavani,OU=Jina pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-419952OU",
                                  "Description": "C15/117 - Klapilova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-419952,OU=UKB-D-419952OU,OU=Centrum jazykoveho vzdelavani,OU=Jina pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-419952",
                                      "Description": "C15/117 - Klapilova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-414752OU,OU=Centrum jazykoveho vzdelavani,OU=Jina pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-414752OU",
                                  "Description": "C15/108 - Gachallova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-414752,OU=UKB-D-414752OU,OU=Centrum jazykoveho vzdelavani,OU=Jina pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-414752",
                                      "Description": "C15/108 - Gachallova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-428645OU,OU=Centrum jazykoveho vzdelavani,OU=Jina pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-428645OU",
                                  "Description": "C15/115 - Dvorackova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-428645,OU=UKB-D-428645OU,OU=Centrum jazykoveho vzdelavani,OU=Jina pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-428645",
                                      "Description": "C15/115 - Dvorackova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-419938OU,OU=Centrum jazykoveho vzdelavani,OU=Jina pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-419938OU",
                                  "Description": "C15/109 - Lexova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-419938,OU=UKB-D-419938OU,OU=Centrum jazykoveho vzdelavani,OU=Jina pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-419938",
                                      "Description": "C15/109 - Lexova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-414753OU,OU=Centrum jazykoveho vzdelavani,OU=Jina pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-414753OU",
                                  "Description": "C15/117 - Aydin",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-414753,OU=UKB-D-414753OU,OU=Centrum jazykoveho vzdelavani,OU=Jina pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-414753",
                                      "Description": "C15/117 - Aydin - EN",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-411663OU,OU=Centrum jazykoveho vzdelavani,OU=Jina pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-411663OU",
                                  "Description": "C15/117 - Lahodova Valisova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-411663,OU=UKB-D-411663OU,OU=Centrum jazykoveho vzdelavani,OU=Jina pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-411663",
                                      "Description": "C15/117 - Lahodova Valisova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-400404OU,OU=Centrum jazykoveho vzdelavani,OU=Jina pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-400404OU",
                                  "Description": "C15/109 - Kibalnikova EN",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-400404,OU=UKB-D-400404OU,OU=Centrum jazykoveho vzdelavani,OU=Jina pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-400404",
                                      "Description": "C15/109 - Kibalnikova EN",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-422018OU,OU=Centrum jazykoveho vzdelavani,OU=Jina pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-422018OU",
                                  "Description": "C15/112 - Vyoralkova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-422018,OU=UKB-D-422018OU,OU=Centrum jazykoveho vzdelavani,OU=Jina pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-422018",
                                      "Description": "C15/112 - Vyoralkova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-405526OU,OU=Centrum jazykoveho vzdelavani,OU=Jina pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-405526OU",
                                  "Description": "C15/108 - Jenis",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-405526,OU=UKB-D-405526OU,OU=Centrum jazykoveho vzdelavani,OU=Jina pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-405526",
                                      "Description": "C15/108 - Jenis",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-469988OU,OU=Centrum jazykoveho vzdelavani,OU=Jina pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-469988OU",
                                  "Description": "C15/108 - Modlikova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-469988,OU=UKB-D-469988OU,OU=Centrum jazykoveho vzdelavani,OU=Jina pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-469988",
                                      "Description": "C15/108 - Modlikova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-432949OU,OU=Centrum jazykoveho vzdelavani,OU=Jina pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-432949OU",
                                  "Description": "A20/329 - Reskova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-432949,OU=UKB-D-432949OU,OU=Centrum jazykoveho vzdelavani,OU=Jina pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-432949",
                                      "Description": "A20/329 - Reskova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                }
                              ],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=Pracoviste nelekarskych oboru,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "Pracoviste nelekarskych oboru",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=Ustav zdravotnich ved,OU=Pracoviste nelekarskych oboru,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Ustav zdravotnich ved",
                              "Description": "F01-1/523 - DSP",
                              "OrganizationalUnits": [
                                {
                                  "DistinguishedName": "OU=UKB-D-457481OU,OU=Ustav zdravotnich ved,OU=Pracoviste nelekarskych oboru,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-457481OU",
                                  "Description": "F01-1/318 - Rozinkova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-457481,OU=UKB-D-457481OU,OU=Ustav zdravotnich ved,OU=Pracoviste nelekarskych oboru,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-457481",
                                      "Description": "F01-1/318 - Rozinkova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-451301OU,OU=Ustav zdravotnich ved,OU=Pracoviste nelekarskych oboru,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-451301OU",
                                  "Description": "F01-1/323 - Vecerova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-451301,OU=UKB-D-451301OU,OU=Ustav zdravotnich ved,OU=Pracoviste nelekarskych oboru,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-451301",
                                      "Description": "F01-1/323 - Vecerova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-461772OU,OU=Ustav zdravotnich ved,OU=Pracoviste nelekarskych oboru,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-461772OU",
                                  "Description": "F01-1/523 - DSP",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-461772,OU=UKB-D-461772OU,OU=Ustav zdravotnich ved,OU=Pracoviste nelekarskych oboru,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-461772",
                                      "Description": "F01-1/523 - DSP",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-461773OU,OU=Ustav zdravotnich ved,OU=Pracoviste nelekarskych oboru,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-461773OU",
                                  "Description": "F01-1/517 - DSP",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-461773,OU=UKB-D-461773OU,OU=Ustav zdravotnich ved,OU=Pracoviste nelekarskych oboru,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-461773",
                                      "Description": "F01-1/517 - DSP",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-451303OU,OU=Ustav zdravotnich ved,OU=Pracoviste nelekarskych oboru,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-451303OU",
                                  "Description": "F01-1/326 - Pesakova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-451303,OU=UKB-D-451303OU,OU=Ustav zdravotnich ved,OU=Pracoviste nelekarskych oboru,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-451303",
                                      "Description": "F01-1/326 - Pesakova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-461774OU,OU=Ustav zdravotnich ved,OU=Pracoviste nelekarskych oboru,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-461774OU",
                                  "Description": "F01-1/523 - Brimova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-461774,OU=UKB-D-461774OU,OU=Ustav zdravotnich ved,OU=Pracoviste nelekarskych oboru,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-461774",
                                      "Description": "F01-1/523 - Brimova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-460424OU,OU=Ustav zdravotnich ved,OU=Pracoviste nelekarskych oboru,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-460424OU",
                                  "Description": "F01-1/515 - Wilhelmova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-460424,OU=UKB-D-460424OU,OU=Ustav zdravotnich ved,OU=Pracoviste nelekarskych oboru,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-460424",
                                      "Description": "F01-1/515 - Wilhelmova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-460207OU,OU=Ustav zdravotnich ved,OU=Pracoviste nelekarskych oboru,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-460207OU",
                                  "Description": "F01-1/510 - Pospisil",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-460207,OU=UKB-D-460207OU,OU=Ustav zdravotnich ved,OU=Pracoviste nelekarskych oboru,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-460207",
                                      "Description": "F01-1/510 - Pospisil",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-460425OU,OU=Ustav zdravotnich ved,OU=Pracoviste nelekarskych oboru,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-460425OU",
                                  "Description": "F01-1/516 \u2013 Vesela",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-460425,OU=UKB-D-460425OU,OU=Ustav zdravotnich ved,OU=Pracoviste nelekarskych oboru,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-460425",
                                      "Description": "F01-1/516 \u2013 Vesela",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-451302OU,OU=Ustav zdravotnich ved,OU=Pracoviste nelekarskych oboru,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-451302OU",
                                  "Description": "F01-1/325 - Snajdrova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-451302,OU=UKB-D-451302OU,OU=Ustav zdravotnich ved,OU=Pracoviste nelekarskych oboru,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-451302",
                                      "Description": "F01-1/325 - Snajdrova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-457478OU,OU=Ustav zdravotnich ved,OU=Pracoviste nelekarskych oboru,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-457478OU",
                                  "Description": "F01-1/519 - Korabova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-457478,OU=UKB-D-457478OU,OU=Ustav zdravotnich ved,OU=Pracoviste nelekarskych oboru,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-457478",
                                      "Description": "F01-1/519 - Korabova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-443527OU,OU=Ustav zdravotnich ved,OU=Pracoviste nelekarskych oboru,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-443527OU",
                                  "Description": "F01-1/322 - Beharkova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-443527,OU=UKB-D-443527OU,OU=Ustav zdravotnich ved,OU=Pracoviste nelekarskych oboru,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-443527",
                                      "Description": "F01-1/322 - Beharkova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-473243OU,OU=Ustav zdravotnich ved,OU=Pracoviste nelekarskych oboru,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-473243OU",
                                  "Description": "F01-1/3.p - vice uzivatelu lf-uzv",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-473243,OU=UKB-D-473243OU,OU=Ustav zdravotnich ved,OU=Pracoviste nelekarskych oboru,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-473243",
                                      "Description": "F01-1/3.p - vice uzivatelu lf-uzv",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-473320OU,OU=Ustav zdravotnich ved,OU=Pracoviste nelekarskych oboru,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-473320OU",
                                  "Description": "F01-1/3.p - DSP",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-473320,OU=UKB-D-473320OU,OU=Ustav zdravotnich ved,OU=Pracoviste nelekarskych oboru,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-473320",
                                      "Description": "F01-1/3.p - DSP",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-461570OU,OU=Ustav zdravotnich ved,OU=Pracoviste nelekarskych oboru,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-461570OU",
                                  "Description": "F01-1/325 - DSP",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-461570,OU=UKB-D-461570OU,OU=Ustav zdravotnich ved,OU=Pracoviste nelekarskych oboru,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-461570",
                                      "Description": "F01-1/325 - DSP",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-473321OU,OU=Ustav zdravotnich ved,OU=Pracoviste nelekarskych oboru,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-473321OU",
                                  "Description": "F01-1/3.p - vice uzivatelu lf-uzv",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-473321,OU=UKB-D-473321OU,OU=Ustav zdravotnich ved,OU=Pracoviste nelekarskych oboru,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-473321",
                                      "Description": "F01-1/3.p - vice uzivatelu lf-uzv",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-473322OU,OU=Ustav zdravotnich ved,OU=Pracoviste nelekarskych oboru,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-473322OU",
                                  "Description": "F01-1/3.p - vice uzivatelu lf-uzv",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-473322,OU=UKB-D-473322OU,OU=Ustav zdravotnich ved,OU=Pracoviste nelekarskych oboru,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-473322",
                                      "Description": "F01-1/3.p - vice uzivatelu lf-uzv",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-443528OU,OU=Ustav zdravotnich ved,OU=Pracoviste nelekarskych oboru,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-443528OU",
                                  "Description": "F01-1/526 - Hartmanova, Mica",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-443528,OU=UKB-D-443528OU,OU=Ustav zdravotnich ved,OU=Pracoviste nelekarskych oboru,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-443528",
                                      "Description": "F01-1/526 - Hartmanova, Mica",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-443529OU,OU=Ustav zdravotnich ved,OU=Pracoviste nelekarskych oboru,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-443529OU",
                                  "Description": "F01-1/526 - Mica",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-443529,OU=UKB-D-443529OU,OU=Ustav zdravotnich ved,OU=Pracoviste nelekarskych oboru,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-443529",
                                      "Description": "F01-1/526 - Mica",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-395423OU,OU=Ustav zdravotnich ved,OU=Pracoviste nelekarskych oboru,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-395423OU",
                                  "Description": "W10/F01-1/519 - Pospisil",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-395423,OU=UKB-D-395423OU,OU=Ustav zdravotnich ved,OU=Pracoviste nelekarskych oboru,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-395423",
                                      "Description": "W10/F01-1/519 - Pospisil",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-470580OU,OU=Ustav zdravotnich ved,OU=Pracoviste nelekarskych oboru,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-470580OU",
                                  "Description": "F01-1/317 - Chaloupkova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-470580,OU=UKB-D-470580OU,OU=Ustav zdravotnich ved,OU=Pracoviste nelekarskych oboru,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-470580",
                                      "Description": "F01-1/317 - Chaloupkova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-451300OU,OU=Ustav zdravotnich ved,OU=Pracoviste nelekarskych oboru,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-451300OU",
                                  "Description": "F01-1/324 - Mackova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-451300,OU=UKB-D-451300OU,OU=Ustav zdravotnich ved,OU=Pracoviste nelekarskych oboru,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-451300",
                                      "Description": "F01-1/324 - Mackova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-459399OU,OU=Ustav zdravotnich ved,OU=Pracoviste nelekarskych oboru,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-459399OU",
                                  "Description": "F01-1/524 - Friessova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-459399,OU=UKB-D-459399OU,OU=Ustav zdravotnich ved,OU=Pracoviste nelekarskych oboru,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-459399",
                                      "Description": "F01-1/524 - Friessova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-470581OU,OU=Ustav zdravotnich ved,OU=Pracoviste nelekarskych oboru,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-470581OU",
                                  "Description": "F01-1/321 - Saibertova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-470581,OU=UKB-D-470581OU,OU=Ustav zdravotnich ved,OU=Pracoviste nelekarskych oboru,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-470581",
                                      "Description": "F01-1/321 - Saibertova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-470582OU,OU=Ustav zdravotnich ved,OU=Pracoviste nelekarskych oboru,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-470582OU",
                                  "Description": "F01-1/324 - Kuril",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-470582,OU=UKB-D-470582OU,OU=Ustav zdravotnich ved,OU=Pracoviste nelekarskych oboru,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-470582",
                                      "Description": "F01-1/324 - Kuril",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-459401OU,OU=Ustav zdravotnich ved,OU=Pracoviste nelekarskych oboru,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-459401OU",
                                  "Description": "F01-1/524 - Langaufova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-459401,OU=UKB-D-459401OU,OU=Ustav zdravotnich ved,OU=Pracoviste nelekarskych oboru,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-459401",
                                      "Description": "F01-1/524 - Langaufova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-470583OU,OU=Ustav zdravotnich ved,OU=Pracoviste nelekarskych oboru,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-470583OU",
                                  "Description": "F01-1/523 - Kralova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-470583,OU=UKB-D-470583OU,OU=Ustav zdravotnich ved,OU=Pracoviste nelekarskych oboru,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-470583",
                                      "Description": "F01-1/523 - Kralova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-470584OU,OU=Ustav zdravotnich ved,OU=Pracoviste nelekarskych oboru,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-470584OU",
                                  "Description": "F01-1/525 - Burilova, Dolanova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-470584,OU=UKB-D-470584OU,OU=Ustav zdravotnich ved,OU=Pracoviste nelekarskych oboru,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-470584",
                                      "Description": "F01-1/525 - Burilova, Dolanova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-470585OU,OU=Ustav zdravotnich ved,OU=Pracoviste nelekarskych oboru,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-470585OU",
                                  "Description": "F01-1/527 - Soldanova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-470585,OU=UKB-D-470585OU,OU=Ustav zdravotnich ved,OU=Pracoviste nelekarskych oboru,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-470585",
                                      "Description": "F01-1/527 - Soldanova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                }
                              ],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=Spolecna pracoviste s FNBrno Jihlavska,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "Spolecna pracoviste s FNBrno Jihlavska",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=Interni hematologicka a onkologicka klinika,OU=Spolecna pracoviste s FNBrno Jihlavska,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Interni hematologicka a onkologicka klinika",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=Klinika interni geriatrie a praktickeho lekarstvi,OU=Spolecna pracoviste s FNBrno Jihlavska,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Klinika interni geriatrie a praktickeho lekarstvi",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=Spolecna pracoviste s FNUSA,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "Spolecna pracoviste s FNUSA",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=Stomatologicka klinika (SIMU),OU=Spolecna pracoviste s FNUSA,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Stomatologicka klinika (SIMU)",
                              "Description": null,
                              "OrganizationalUnits": [
                                {
                                  "DistinguishedName": "OU=2.NP,OU=Stomatologicka klinika (SIMU),OU=Spolecna pracoviste s FNUSA,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "2.NP",
                                  "Description": null,
                                  "OrganizationalUnits": [
                                    {
                                      "DistinguishedName": "OU=Mistnost 209,OU=2.NP,OU=Stomatologicka klinika (SIMU),OU=Spolecna pracoviste s FNUSA,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "Mistnost 209",
                                      "Description": null,
                                      "OrganizationalUnits": [
                                        {
                                          "DistinguishedName": "OU=UKB-D-429321OU,OU=Mistnost 209,OU=2.NP,OU=Stomatologicka klinika (SIMU),OU=Spolecna pracoviste s FNUSA,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-429321OU",
                                          "Description": "F37/209 - Vlkovska",
                                          "OrganizationalUnits": [],
                                          "Computers": [
                                            {
                                              "DistinguishedName": "CN=UKB-D-429321,OU=UKB-D-429321OU,OU=Mistnost 209,OU=2.NP,OU=Stomatologicka klinika (SIMU),OU=Spolecna pracoviste s FNUSA,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                              "Name": "UKB-D-429321",
                                              "Description": "F37/209 - Vlkovska",
                                              "Type": "Computer"
                                            }
                                          ],
                                          "Type": "OrganizationalUnit"
                                        },
                                        {
                                          "DistinguishedName": "OU=UKB-D-429317OU,OU=Mistnost 209,OU=2.NP,OU=Stomatologicka klinika (SIMU),OU=Spolecna pracoviste s FNUSA,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-429317OU",
                                          "Description": "F37/209 - Machova",
                                          "OrganizationalUnits": [],
                                          "Computers": [
                                            {
                                              "DistinguishedName": "CN=UKB-D-429317,OU=UKB-D-429317OU,OU=Mistnost 209,OU=2.NP,OU=Stomatologicka klinika (SIMU),OU=Spolecna pracoviste s FNUSA,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                              "Name": "UKB-D-429317",
                                              "Description": "F37/209 - Machova",
                                              "Type": "Computer"
                                            }
                                          ],
                                          "Type": "OrganizationalUnit"
                                        },
                                        {
                                          "DistinguishedName": "OU=UKB-D-429319OU,OU=Mistnost 209,OU=2.NP,OU=Stomatologicka klinika (SIMU),OU=Spolecna pracoviste s FNUSA,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-429319OU",
                                          "Description": "F37/209 - Gruzova",
                                          "OrganizationalUnits": [],
                                          "Computers": [
                                            {
                                              "DistinguishedName": "CN=UKB-D-429319,OU=UKB-D-429319OU,OU=Mistnost 209,OU=2.NP,OU=Stomatologicka klinika (SIMU),OU=Spolecna pracoviste s FNUSA,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                              "Name": "UKB-D-429319",
                                              "Description": "F37/209 - Gruzova",
                                              "Type": "Computer"
                                            }
                                          ],
                                          "Type": "OrganizationalUnit"
                                        },
                                        {
                                          "DistinguishedName": "OU=UKB-D-429320OU,OU=Mistnost 209,OU=2.NP,OU=Stomatologicka klinika (SIMU),OU=Spolecna pracoviste s FNUSA,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-429320OU",
                                          "Description": "F37/209 - Pospisil",
                                          "OrganizationalUnits": [],
                                          "Computers": [
                                            {
                                              "DistinguishedName": "CN=UKB-D-429320,OU=UKB-D-429320OU,OU=Mistnost 209,OU=2.NP,OU=Stomatologicka klinika (SIMU),OU=Spolecna pracoviste s FNUSA,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                              "Name": "UKB-D-429320",
                                              "Description": "F37/209 - Pospisil",
                                              "Type": "Computer"
                                            }
                                          ],
                                          "Type": "OrganizationalUnit"
                                        },
                                        {
                                          "DistinguishedName": "OU=UKB-D-429316OU,OU=Mistnost 209,OU=2.NP,OU=Stomatologicka klinika (SIMU),OU=Spolecna pracoviste s FNUSA,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-429316OU",
                                          "Description": "F37/209 - Chladkova",
                                          "OrganizationalUnits": [],
                                          "Computers": [
                                            {
                                              "DistinguishedName": "CN=UKB-D-429316,OU=UKB-D-429316OU,OU=Mistnost 209,OU=2.NP,OU=Stomatologicka klinika (SIMU),OU=Spolecna pracoviste s FNUSA,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                              "Name": "UKB-D-429316",
                                              "Description": "F37/209 - Chladkova",
                                              "Type": "Computer"
                                            }
                                          ],
                                          "Type": "OrganizationalUnit"
                                        },
                                        {
                                          "DistinguishedName": "OU=UKB-D-429318OU,OU=Mistnost 209,OU=2.NP,OU=Stomatologicka klinika (SIMU),OU=Spolecna pracoviste s FNUSA,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-429318OU",
                                          "Description": "F37/209 - Wrchlabsky",
                                          "OrganizationalUnits": [],
                                          "Computers": [
                                            {
                                              "DistinguishedName": "CN=UKB-D-429318,OU=UKB-D-429318OU,OU=Mistnost 209,OU=2.NP,OU=Stomatologicka klinika (SIMU),OU=Spolecna pracoviste s FNUSA,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                              "Name": "UKB-D-429318",
                                              "Description": "F37/209 - Wrchlabsky",
                                              "Type": "Computer"
                                            }
                                          ],
                                          "Type": "OrganizationalUnit"
                                        }
                                      ],
                                      "Computers": [],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=Mistnost 221,OU=2.NP,OU=Stomatologicka klinika (SIMU),OU=Spolecna pracoviste s FNUSA,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "Mistnost 221",
                                      "Description": null,
                                      "OrganizationalUnits": [
                                        {
                                          "DistinguishedName": "OU=UKB-D-429322OU,OU=Mistnost 221,OU=2.NP,OU=Stomatologicka klinika (SIMU),OU=Spolecna pracoviste s FNUSA,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-429322OU",
                                          "Description": "F37/221 - doktori",
                                          "OrganizationalUnits": [],
                                          "Computers": [
                                            {
                                              "DistinguishedName": "CN=UKB-D-429322,OU=UKB-D-429322OU,OU=Mistnost 221,OU=2.NP,OU=Stomatologicka klinika (SIMU),OU=Spolecna pracoviste s FNUSA,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                              "Name": "UKB-D-429322",
                                              "Description": "F37/221 - doktori",
                                              "Type": "Computer"
                                            }
                                          ],
                                          "Type": "OrganizationalUnit"
                                        },
                                        {
                                          "DistinguishedName": "OU=UKB-D-429323OU,OU=Mistnost 221,OU=2.NP,OU=Stomatologicka klinika (SIMU),OU=Spolecna pracoviste s FNUSA,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-429323OU",
                                          "Description": "F37/221 - doktori",
                                          "OrganizationalUnits": [],
                                          "Computers": [
                                            {
                                              "DistinguishedName": "CN=UKB-D-429323,OU=UKB-D-429323OU,OU=Mistnost 221,OU=2.NP,OU=Stomatologicka klinika (SIMU),OU=Spolecna pracoviste s FNUSA,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                              "Name": "UKB-D-429323",
                                              "Description": "F37/221 - doktori",
                                              "Type": "Computer"
                                            }
                                          ],
                                          "Type": "OrganizationalUnit"
                                        },
                                        {
                                          "DistinguishedName": "OU=UKB-D-429324OU,OU=Mistnost 221,OU=2.NP,OU=Stomatologicka klinika (SIMU),OU=Spolecna pracoviste s FNUSA,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-429324OU",
                                          "Description": "F37/221 - doktori",
                                          "OrganizationalUnits": [],
                                          "Computers": [
                                            {
                                              "DistinguishedName": "CN=UKB-D-429324,OU=UKB-D-429324OU,OU=Mistnost 221,OU=2.NP,OU=Stomatologicka klinika (SIMU),OU=Spolecna pracoviste s FNUSA,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                              "Name": "UKB-D-429324",
                                              "Description": "F37/221 - doktori",
                                              "Type": "Computer"
                                            }
                                          ],
                                          "Type": "OrganizationalUnit"
                                        },
                                        {
                                          "DistinguishedName": "OU=UKB-D-429325OU,OU=Mistnost 221,OU=2.NP,OU=Stomatologicka klinika (SIMU),OU=Spolecna pracoviste s FNUSA,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-429325OU",
                                          "Description": "F37/221 - doktori",
                                          "OrganizationalUnits": [],
                                          "Computers": [
                                            {
                                              "DistinguishedName": "CN=UKB-D-429325,OU=UKB-D-429325OU,OU=Mistnost 221,OU=2.NP,OU=Stomatologicka klinika (SIMU),OU=Spolecna pracoviste s FNUSA,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                              "Name": "UKB-D-429325",
                                              "Description": "F37/221 - doktori",
                                              "Type": "Computer"
                                            }
                                          ],
                                          "Type": "OrganizationalUnit"
                                        },
                                        {
                                          "DistinguishedName": "OU=UKB-D-429326OU,OU=Mistnost 221,OU=2.NP,OU=Stomatologicka klinika (SIMU),OU=Spolecna pracoviste s FNUSA,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-429326OU",
                                          "Description": "F37/221 - doktori",
                                          "OrganizationalUnits": [],
                                          "Computers": [
                                            {
                                              "DistinguishedName": "CN=UKB-D-429326,OU=UKB-D-429326OU,OU=Mistnost 221,OU=2.NP,OU=Stomatologicka klinika (SIMU),OU=Spolecna pracoviste s FNUSA,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                              "Name": "UKB-D-429326",
                                              "Description": "F37/221 - doktori",
                                              "Type": "Computer"
                                            }
                                          ],
                                          "Type": "OrganizationalUnit"
                                        }
                                      ],
                                      "Computers": [],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=Mistnost 229,OU=2.NP,OU=Stomatologicka klinika (SIMU),OU=Spolecna pracoviste s FNUSA,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "Mistnost 229",
                                      "Description": null,
                                      "OrganizationalUnits": [
                                        {
                                          "DistinguishedName": "OU=UKB-D-429327OU,OU=Mistnost 229,OU=2.NP,OU=Stomatologicka klinika (SIMU),OU=Spolecna pracoviste s FNUSA,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-429327OU",
                                          "Description": "F37/229 - Kucera",
                                          "OrganizationalUnits": [],
                                          "Computers": [
                                            {
                                              "DistinguishedName": "CN=UKB-D-429327,OU=UKB-D-429327OU,OU=Mistnost 229,OU=2.NP,OU=Stomatologicka klinika (SIMU),OU=Spolecna pracoviste s FNUSA,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                              "Name": "UKB-D-429327",
                                              "Description": "F37/229 - Kucera",
                                              "Type": "Computer"
                                            }
                                          ],
                                          "Type": "OrganizationalUnit"
                                        },
                                        {
                                          "DistinguishedName": "OU=UKB-D-429328OU,OU=Mistnost 229,OU=2.NP,OU=Stomatologicka klinika (SIMU),OU=Spolecna pracoviste s FNUSA,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-429328OU",
                                          "Description": "F37/229 - Sikula",
                                          "OrganizationalUnits": [],
                                          "Computers": [
                                            {
                                              "DistinguishedName": "CN=UKB-D-429328,OU=UKB-D-429328OU,OU=Mistnost 229,OU=2.NP,OU=Stomatologicka klinika (SIMU),OU=Spolecna pracoviste s FNUSA,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                              "Name": "UKB-D-429328",
                                              "Description": "F37/229 - Sikula",
                                              "Type": "Computer"
                                            }
                                          ],
                                          "Type": "OrganizationalUnit"
                                        },
                                        {
                                          "DistinguishedName": "OU=UKB-D-429329OU,OU=Mistnost 229,OU=2.NP,OU=Stomatologicka klinika (SIMU),OU=Spolecna pracoviste s FNUSA,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-429329OU",
                                          "Description": "F37/229 - Pernica/Cecava",
                                          "OrganizationalUnits": [],
                                          "Computers": [
                                            {
                                              "DistinguishedName": "CN=UKB-D-429329,OU=UKB-D-429329OU,OU=Mistnost 229,OU=2.NP,OU=Stomatologicka klinika (SIMU),OU=Spolecna pracoviste s FNUSA,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                              "Name": "UKB-D-429329",
                                              "Description": "F37/229 - Pernica/Cecava",
                                              "Type": "Computer"
                                            }
                                          ],
                                          "Type": "OrganizationalUnit"
                                        },
                                        {
                                          "DistinguishedName": "OU=UKB-D-429330OU,OU=Mistnost 229,OU=2.NP,OU=Stomatologicka klinika (SIMU),OU=Spolecna pracoviste s FNUSA,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-429330OU",
                                          "Description": "F37/229 - Hromcik",
                                          "OrganizationalUnits": [],
                                          "Computers": [
                                            {
                                              "DistinguishedName": "CN=UKB-D-429330,OU=UKB-D-429330OU,OU=Mistnost 229,OU=2.NP,OU=Stomatologicka klinika (SIMU),OU=Spolecna pracoviste s FNUSA,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                              "Name": "UKB-D-429330",
                                              "Description": "F37/229 - Hromcik",
                                              "Type": "Computer"
                                            }
                                          ],
                                          "Type": "OrganizationalUnit"
                                        },
                                        {
                                          "DistinguishedName": "OU=UKB-D-429331OU,OU=Mistnost 229,OU=2.NP,OU=Stomatologicka klinika (SIMU),OU=Spolecna pracoviste s FNUSA,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-429331OU",
                                          "Description": "F37/229 - Berta",
                                          "OrganizationalUnits": [],
                                          "Computers": [
                                            {
                                              "DistinguishedName": "CN=UKB-D-429331,OU=UKB-D-429331OU,OU=Mistnost 229,OU=2.NP,OU=Stomatologicka klinika (SIMU),OU=Spolecna pracoviste s FNUSA,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                              "Name": "UKB-D-429331",
                                              "Description": "F37/229 - Berta",
                                              "Type": "Computer"
                                            }
                                          ],
                                          "Type": "OrganizationalUnit"
                                        }
                                      ],
                                      "Computers": [],
                                      "Type": "OrganizationalUnit"
                                    }
                                  ],
                                  "Computers": [],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=5.NP,OU=Stomatologicka klinika (SIMU),OU=Spolecna pracoviste s FNUSA,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "5.NP",
                                  "Description": null,
                                  "OrganizationalUnits": [
                                    {
                                      "DistinguishedName": "OU=UKB-D-429359OU,OU=5.NP,OU=Stomatologicka klinika (SIMU),OU=Spolecna pracoviste s FNUSA,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-429359OU",
                                      "Description": "F37/507 - Kukletova",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-429359,OU=UKB-D-429359OU,OU=5.NP,OU=Stomatologicka klinika (SIMU),OU=Spolecna pracoviste s FNUSA,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-429359",
                                          "Description": "F37/507 - Kukletova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-429210OU,OU=5.NP,OU=Stomatologicka klinika (SIMU),OU=Spolecna pracoviste s FNUSA,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-429210OU",
                                      "Description": "F37/507 - Bartakova",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-429210,OU=UKB-D-429210OU,OU=5.NP,OU=Stomatologicka klinika (SIMU),OU=Spolecna pracoviste s FNUSA,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-429210",
                                          "Description": "F37/507 - Bartakova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-429363OU,OU=5.NP,OU=Stomatologicka klinika (SIMU),OU=Spolecna pracoviste s FNUSA,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-429363OU",
                                      "Description": "F37/522 - Holla",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-429363,OU=UKB-D-429363OU,OU=5.NP,OU=Stomatologicka klinika (SIMU),OU=Spolecna pracoviste s FNUSA,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-429363",
                                          "Description": "F37/522 - Holla",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    }
                                  ],
                                  "Computers": [],
                                  "Type": "OrganizationalUnit"
                                }
                              ],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=Ustav klinicke imunologie a alergologie,OU=Spolecna pracoviste s FNUSA,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Ustav klinicke imunologie a alergologie",
                              "Description": null,
                              "OrganizationalUnits": [
                                {
                                  "DistinguishedName": "OU=UKB-D-454680OU,OU=Ustav klinicke imunologie a alergologie,OU=Spolecna pracoviste s FNUSA,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-454680OU",
                                  "Description": "C03/309 - Kuliskova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-454680,OU=UKB-D-454680OU,OU=Ustav klinicke imunologie a alergologie,OU=Spolecna pracoviste s FNUSA,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-454680",
                                      "Description": "C03/309 - Kuliskova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                }
                              ],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "Spravni pracoviste",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=Technicko-provozni oddeleni,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Technicko-provozni oddeleni",
                              "Description": null,
                              "OrganizationalUnits": [
                                {
                                  "DistinguishedName": "OU=UKB-D-456038OU,OU=Technicko-provozni oddeleni,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-456038OU",
                                  "Description": "F01-1/2S10 - Strakaty",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-456038,OU=UKB-D-456038OU,OU=Technicko-provozni oddeleni,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-456038",
                                      "Description": "F01-1/2S10 - Strakaty",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-456037OU,OU=Technicko-provozni oddeleni,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-456037OU",
                                  "Description": "B17/229 - Spacil",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-456037,OU=UKB-D-456037OU,OU=Technicko-provozni oddeleni,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-456037",
                                      "Description": "B17/229 - Spacil",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-417123OU,OU=Technicko-provozni oddeleni,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-417123OU",
                                  "Description": "F01-1/129 - Bim",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-417123,OU=UKB-D-417123OU,OU=Technicko-provozni oddeleni,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-417123",
                                      "Description": "F01-1/129 - Bim",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-456040OU,OU=Technicko-provozni oddeleni,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-456040OU",
                                  "Description": "B22/115B - Skrivankova (podatelna)",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-456040,OU=UKB-D-456040OU,OU=Technicko-provozni oddeleni,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-456040",
                                      "Description": "B22/115B Skrivankova (podatelna)",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-456039OU,OU=Technicko-provozni oddeleni,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-456039OU",
                                  "Description": "F01-1/127 - Prochazkova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-456039,OU=UKB-D-456039OU,OU=Technicko-provozni oddeleni,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-456039",
                                      "Description": "F01-1/127 - Prochazkova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-417124OU,OU=Technicko-provozni oddeleni,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-417124OU",
                                  "Description": "F01-1/128 - Julinek",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-417124,OU=UKB-D-417124OU,OU=Technicko-provozni oddeleni,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-417124",
                                      "Description": "F01-1/128 - Julinek",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-456032OU,OU=Technicko-provozni oddeleni,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-456032OU",
                                  "Description": "B22/115b - Brazdova (podatelna)",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-456032,OU=UKB-D-456032OU,OU=Technicko-provozni oddeleni,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-456032",
                                      "Description": "B22/115B - Brazdova (podatelna)",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                }
                              ],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Dekanat",
                              "Description": null,
                              "OrganizationalUnits": [
                                {
                                  "DistinguishedName": "OU=Kancelar tajemnika,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "Kancelar tajemnika",
                                  "Description": null,
                                  "OrganizationalUnits": [
                                    {
                                      "DistinguishedName": "OU=UKB-D-456393OU,OU=Kancelar tajemnika,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-456393OU",
                                      "Description": "C15/315 - Tesarova",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-456393,OU=UKB-D-456393OU,OU=Kancelar tajemnika,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-456393",
                                          "Description": "C15/315 - Tesarova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-414532OU,OU=Kancelar tajemnika,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-414532OU",
                                      "Description": "F01-1/411 \u2013 Uherkova",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-414532,OU=UKB-D-414532OU,OU=Kancelar tajemnika,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-414532",
                                          "Description": "F01-1/411 \u2013 Uherkova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    }
                                  ],
                                  "Computers": [],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=Dekan,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "Dekan",
                                  "Description": null,
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-406538,OU=Dekan,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-406538",
                                      "Description": "B17/333 - Repko",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=Publikacni oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "Publikacni oddeleni",
                                  "Description": null,
                                  "OrganizationalUnits": [
                                    {
                                      "DistinguishedName": "OU=UKB-D-455639OU,OU=Publikacni oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-455639OU",
                                      "Description": "C15/322 - Korcova - Nemazat OU (Adobe 2017)",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-455639,OU=UKB-D-455639OU,OU=Publikacni oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-455639",
                                          "Description": "C15/322 - Korcova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    }
                                  ],
                                  "Computers": [],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=Ekonomicke oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "Ekonomicke oddeleni",
                                  "Description": null,
                                  "OrganizationalUnits": [
                                    {
                                      "DistinguishedName": "OU=UKB-D-320736OU,OU=Ekonomicke oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-320736OU",
                                      "Description": "W10/B17/307 - inventarizace",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-320736,OU=UKB-D-320736OU,OU=Ekonomicke oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-320736",
                                          "Description": "W10/B17/317 - inventarizace",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-395500OU,OU=Ekonomicke oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-395500OU",
                                      "Description": "B17/314 - Vrabelova - Nemazat OU (Adobe 2015)",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-395500,OU=UKB-D-395500OU,OU=Ekonomicke oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-395500",
                                          "Description": "B17/314 - Vrabelova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-456397OU,OU=Ekonomicke oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-456397OU",
                                      "Description": "B17/306 - Fialova",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-456397,OU=UKB-D-456397OU,OU=Ekonomicke oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-456397",
                                          "Description": "B17/306 - Fialova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-456400OU,OU=Ekonomicke oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-456400OU",
                                      "Description": "B17/311 - Vitouchova",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-456400,OU=UKB-D-456400OU,OU=Ekonomicke oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-456400",
                                          "Description": "B17/311 - Vitouchova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-456401OU,OU=Ekonomicke oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-456401OU",
                                      "Description": "B17/313 - Edlerova",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-456401,OU=UKB-D-456401OU,OU=Ekonomicke oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-456401",
                                          "Description": "B17/313 - Edlerova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-456394OU,OU=Ekonomicke oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-456394OU",
                                      "Description": "B17/306 - Stoskova",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-456394,OU=UKB-D-456394OU,OU=Ekonomicke oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-456394",
                                          "Description": "B17/306 - Stoskova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-456404OU,OU=Ekonomicke oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-456404OU",
                                      "Description": "B17/307 - Valaskova",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-456404,OU=UKB-D-456404OU,OU=Ekonomicke oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-456404",
                                          "Description": "B17/307 - Valaskova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-456396OU,OU=Ekonomicke oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-456396OU",
                                      "Description": "B17/311 - Jagerova",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-456396,OU=UKB-D-456396OU,OU=Ekonomicke oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-456396",
                                          "Description": "B17/311 - Jagerova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-456405OU,OU=Ekonomicke oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-456405OU",
                                      "Description": "B17/307 - Brychtova",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-456405,OU=UKB-D-456405OU,OU=Ekonomicke oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-456405",
                                          "Description": "B17/307 - Brychtova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-456399OU,OU=Ekonomicke oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-456399OU",
                                      "Description": "B17/305 - Bohmova",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-456399,OU=UKB-D-456399OU,OU=Ekonomicke oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-456399",
                                          "Description": "B17/305 - Bohmova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-456398OU,OU=Ekonomicke oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-456398OU",
                                      "Description": "B17/315 - Pastorkova",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-456398,OU=UKB-D-456398OU,OU=Ekonomicke oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-456398",
                                          "Description": "B17/315 - Pastorkova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-456402OU,OU=Ekonomicke oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-456402OU",
                                      "Description": "B17/312 - Andrysikova",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-456402,OU=UKB-D-456402OU,OU=Ekonomicke oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-456402",
                                          "Description": "B17/312 - Andrysikova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-456395OU,OU=Ekonomicke oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-456395OU",
                                      "Description": "B17/309 - Mertensova",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-456395,OU=UKB-D-456395OU,OU=Ekonomicke oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-456395",
                                          "Description": "B17/309 - Mertensova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-456403OU,OU=Ekonomicke oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-456403OU",
                                      "Description": "B17/309 - Nekvapilova",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-456403,OU=UKB-D-456403OU,OU=Ekonomicke oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-456403",
                                          "Description": "B17/309 - Nekvapilova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-446534OU,OU=Ekonomicke oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-446534OU",
                                      "Description": "B17/208 - Siruckova",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-446534,OU=UKB-D-446534OU,OU=Ekonomicke oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-446534",
                                          "Description": "B17/208 - Siruckova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-347431OU,OU=Ekonomicke oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-347431OU",
                                      "Description": "B17/305 - Palenikova",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-347431,OU=UKB-D-347431OU,OU=Ekonomicke oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-347431",
                                          "Description": "B17/305 - Palenikova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-465304OU,OU=Ekonomicke oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-465304OU",
                                      "Description": "B17/313 - Myslinova",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-465304,OU=UKB-D-465304OU,OU=Ekonomicke oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-465304",
                                          "Description": "B17/313 - Myslinova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-395501OU,OU=Ekonomicke oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-395501OU",
                                      "Description": "B17/314 - Linhartova - Nemazat OU ( Adobe 2015)",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-395501,OU=UKB-D-395501OU,OU=Ekonomicke oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-395501",
                                          "Description": "B17/314 - Linhartova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-461790OU,OU=Ekonomicke oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-461790OU",
                                      "Description": "B17/308 - Lerchova",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-461790,OU=UKB-D-461790OU,OU=Ekonomicke oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-461790",
                                          "Description": "B17/308 - Lerchova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-465305OU,OU=Ekonomicke oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-465305OU",
                                      "Description": "B17/312 - Pacholova",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-465305,OU=UKB-D-465305OU,OU=Ekonomicke oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-465305",
                                          "Description": "B17/312 - Pacholova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    }
                                  ],
                                  "Computers": [],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=Oddeleni projektove podpory,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "Oddeleni projektove podpory",
                                  "Description": null,
                                  "OrganizationalUnits": [
                                    {
                                      "DistinguishedName": "OU=UKB-D-395499OU,OU=Oddeleni projektove podpory,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-395499OU",
                                      "Description": "F37/526 - Gromesova",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-395499,OU=UKB-D-395499OU,OU=Oddeleni projektove podpory,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-395499",
                                          "Description": "F37/526 - Gromesova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-456351OU,OU=Oddeleni projektove podpory,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-456351OU",
                                      "Description": "F37/518 - Bernhardtova - Nemazat OU (Adobe 2017)",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-456351,OU=UKB-D-456351OU,OU=Oddeleni projektove podpory,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-456351",
                                          "Description": "F37/518 - Bernhardtova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-456350OU,OU=Oddeleni projektove podpory,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-456350OU",
                                      "Description": "F37/526 - Weissova  - Nemazat OU (Adobe 2015)",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-456350,OU=UKB-D-456350OU,OU=Oddeleni projektove podpory,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-456350",
                                          "Description": "F37/526 - Weissova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-395497OU,OU=Oddeleni projektove podpory,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-395497OU",
                                      "Description": "F37/525 - Krejcirikova- Nemazat OU ( Adobe 2015)",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-395497,OU=UKB-D-395497OU,OU=Oddeleni projektove podpory,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-395497",
                                          "Description": "F37/525 - Krejcirikova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-395498OU,OU=Oddeleni projektove podpory,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-395498OU",
                                      "Description": "F37/525 - Splouchalova - Nemazat OU (Adobe 2015)",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-395498,OU=UKB-D-395498OU,OU=Oddeleni projektove podpory,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-395498",
                                          "Description": "F37/525 - Splouchalova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-456349OU,OU=Oddeleni projektove podpory,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-456349OU",
                                      "Description": "F37/518 - Cubova - Nemazat OU (Adobe 2017)",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-456349,OU=UKB-D-456349OU,OU=Oddeleni projektove podpory,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-456349",
                                          "Description": "F37/518 - Cubova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    }
                                  ],
                                  "Computers": [],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=Oddeleni pro komunikaci a vnejsi vztahy,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "Oddeleni pro komunikaci a vnejsi vztahy",
                                  "Description": null,
                                  "OrganizationalUnits": [
                                    {
                                      "DistinguishedName": "OU=UKB-D-441347OU,OU=Oddeleni pro komunikaci a vnejsi vztahy,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-441347OU",
                                      "Description": "A17/329 - Dvorakova",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-441347,OU=UKB-D-441347OU,OU=Oddeleni pro komunikaci a vnejsi vztahy,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-441347",
                                          "Description": "A17/329 - Dvorakova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    }
                                  ],
                                  "Computers": [],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=Oddeleni pro kvalifikacni rozvoj,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "Oddeleni pro kvalifikacni rozvoj",
                                  "Description": null,
                                  "OrganizationalUnits": [
                                    {
                                      "DistinguishedName": "OU=UKB-D-472792OU,OU=Oddeleni pro kvalifikacni rozvoj,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-472792OU",
                                      "Description": "B17/225 - Tesarikova",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-472792,OU=UKB-D-472792OU,OU=Oddeleni pro kvalifikacni rozvoj,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-472792",
                                          "Description": "B17/225 - Tesarikova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-456061OU,OU=Oddeleni pro kvalifikacni rozvoj,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-456061OU",
                                      "Description": "B17/225 - Wolfova - Nemazat OU (Adobe 2017)",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-456061,OU=UKB-D-456061OU,OU=Oddeleni pro kvalifikacni rozvoj,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-456061",
                                          "Description": "B17/225 - Wolfova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-456062OU,OU=Oddeleni pro kvalifikacni rozvoj,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-456062OU",
                                      "Description": "B17/225 - Kovarova - Nemazat OU (Adobe 2015)",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-456062,OU=UKB-D-456062OU,OU=Oddeleni pro kvalifikacni rozvoj,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-456062",
                                          "Description": "B17/225 - Kovarova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-456063OU,OU=Oddeleni pro kvalifikacni rozvoj,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-456063OU",
                                      "Description": "B17/225 - Navratil - Nemazat OU (Adobe 2015)",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-456063,OU=UKB-D-456063OU,OU=Oddeleni pro kvalifikacni rozvoj,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-456063",
                                          "Description": "B17/225 - Navratil",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    }
                                  ],
                                  "Computers": [],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=Oddeleni pro specializacni vzdelavani lekaru,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "Oddeleni pro specializacni vzdelavani lekaru",
                                  "Description": null,
                                  "OrganizationalUnits": [
                                    {
                                      "DistinguishedName": "OU=UKB-D-454496OU,OU=Oddeleni pro specializacni vzdelavani lekaru,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-454496OU",
                                      "Description": "C15/317 - Hudakova",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-454496,OU=UKB-D-454496OU,OU=Oddeleni pro specializacni vzdelavani lekaru,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-454496",
                                          "Description": "C15/317 - Hudakova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-426713OU,OU=Oddeleni pro specializacni vzdelavani lekaru,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-426713OU",
                                      "Description": "C15/323 - Simkova",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-426713,OU=UKB-D-426713OU,OU=Oddeleni pro specializacni vzdelavani lekaru,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-426713",
                                          "Description": "C15/323 - Simkova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-464019OU,OU=Oddeleni pro specializacni vzdelavani lekaru,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-464019OU",
                                      "Description": "C15/317 - Kralikova",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-464019,OU=UKB-D-464019OU,OU=Oddeleni pro specializacni vzdelavani lekaru,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-464019",
                                          "Description": "C15/317 - Kralikova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-446509OU,OU=Oddeleni pro specializacni vzdelavani lekaru,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-446509OU",
                                      "Description": "C15/317 - Mikeskova",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-446509,OU=UKB-D-446509OU,OU=Oddeleni pro specializacni vzdelavani lekaru,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-446509",
                                          "Description": "C15/317 - Mikeskova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    }
                                  ],
                                  "Computers": [],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=Oddeleni pro vedu a kvalitu,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "Oddeleni pro vedu a kvalitu",
                                  "Description": null,
                                  "OrganizationalUnits": [],
                                  "Computers": [],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=Oddeleni verejnych zakazek,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "Oddeleni verejnych zakazek",
                                  "Description": null,
                                  "OrganizationalUnits": [
                                    {
                                      "DistinguishedName": "OU=UKB-D-444460OU,OU=Oddeleni verejnych zakazek,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-444460OU",
                                      "Description": "B17/223 - Buriska",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-444460,OU=UKB-D-444460OU,OU=Oddeleni verejnych zakazek,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-444460",
                                          "Description": "B17/223 - Buriska",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    }
                                  ],
                                  "Computers": [],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=Personalni oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "Personalni oddeleni",
                                  "Description": null,
                                  "OrganizationalUnits": [
                                    {
                                      "DistinguishedName": "OU=UKB-D-456363OU,OU=Personalni oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-456363OU",
                                      "Description": "C15/313 - Maleckova",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-456363,OU=UKB-D-456363OU,OU=Personalni oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-456363",
                                          "Description": "C15/313 - Maleckova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-397635OU,OU=Personalni oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-397635OU",
                                      "Description": "C15/325 - Filaskova",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-397635,OU=UKB-D-397635OU,OU=Personalni oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-397635",
                                          "Description": "C15/325 - Filaskova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-456364OU,OU=Personalni oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-456364OU",
                                      "Description": "C15/327 - Janackova",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-456364,OU=UKB-D-456364OU,OU=Personalni oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-456364",
                                          "Description": "C15/327 - Janackova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-458316OU,OU=Personalni oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-458316OU",
                                      "Description": "C15/329 - Ambrozova",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-458316,OU=UKB-D-458316OU,OU=Personalni oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-458316",
                                          "Description": "C15/329 - Ambrozova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-456366OU,OU=Personalni oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-456366OU",
                                      "Description": "C15/326 - Cmielova",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-456366,OU=UKB-D-456366OU,OU=Personalni oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-456366",
                                          "Description": "C15/326 - Cmielova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-397636OU,OU=Personalni oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-397636OU",
                                      "Description": "C15/325 - Klapkova",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-397636,OU=UKB-D-397636OU,OU=Personalni oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-397636",
                                          "Description": "C15/325 - Klapkova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-456362OU,OU=Personalni oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-456362OU",
                                      "Description": "C15/324 - Candrlova",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-456362,OU=UKB-D-456362OU,OU=Personalni oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-456362",
                                          "Description": "C15/324 - Candrlova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-456365OU,OU=Personalni oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-456365OU",
                                      "Description": "C15/324 - Pagova",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-456365,OU=UKB-D-456365OU,OU=Personalni oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-456365",
                                          "Description": "C15/324 - Pagova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-458317OU,OU=Personalni oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-458317OU",
                                      "Description": "C15/329 - Kneblova",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-458317,OU=UKB-D-458317OU,OU=Personalni oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-458317",
                                          "Description": "C15/329 - Kneblova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-397637OU,OU=Personalni oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-397637OU",
                                      "Description": "C15/313 - Koziskova",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-397637,OU=UKB-D-397637OU,OU=Personalni oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-397637",
                                          "Description": "C15/313 - Koziskova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-456368OU,OU=Personalni oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-456368OU",
                                      "Description": "C15/326 - Bobusova",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-456368,OU=UKB-D-456368OU,OU=Personalni oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-456368",
                                          "Description": "C15/326 - Bobusova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-456367OU,OU=Personalni oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-456367OU",
                                      "Description": "C15/327 - Palenikova",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-456367,OU=UKB-D-456367OU,OU=Personalni oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-456367",
                                          "Description": "C15/327 - Palenikova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-397634OU,OU=Personalni oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-397634OU",
                                      "Description": "C15/329 - Holcmannova",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-397634,OU=UKB-D-397634OU,OU=Personalni oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-397634",
                                          "Description": "C15/329 - Holcmannova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    }
                                  ],
                                  "Computers": [],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=Pravni oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "Pravni oddeleni",
                                  "Description": null,
                                  "OrganizationalUnits": [
                                    {
                                      "DistinguishedName": "OU=UKB-D-425592OU,OU=Pravni oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-425592OU",
                                      "Description": "B17/209 - Holbova",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-425592,OU=UKB-D-425592OU,OU=Pravni oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-425592",
                                          "Description": "B17/209 - Holbova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-422561OU,OU=Pravni oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-422561OU",
                                      "Description": "B17/209 - Vlkova",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-422561,OU=UKB-D-422561OU,OU=Pravni oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-422561",
                                          "Description": "B17/209 - Vlkova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    }
                                  ],
                                  "Computers": [],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=Sekretariat,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "Sekretariat",
                                  "Description": null,
                                  "OrganizationalUnits": [],
                                  "Computers": [],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=Studijni oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "Studijni oddeleni",
                                  "Description": null,
                                  "OrganizationalUnits": [
                                    {
                                      "DistinguishedName": "OU=UKB-D-456341OU,OU=Studijni oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-456341OU",
                                      "Description": "B17/212 - Terzngelova",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-456341,OU=UKB-D-456341OU,OU=Studijni oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-456341",
                                          "Description": "B17/212 - Terzngelova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-456343OU,OU=Studijni oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-456343OU",
                                      "Description": "B17/212 - Hromadova",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-456343,OU=UKB-D-456343OU,OU=Studijni oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-456343",
                                          "Description": "B17/212 - Hromadova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-456345OU,OU=Studijni oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-456345OU",
                                      "Description": "B17/224 - Pankova",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-456345,OU=UKB-D-456345OU,OU=Studijni oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-456345",
                                          "Description": "B17/224 - Pankova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-425587OU,OU=Studijni oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-425587OU",
                                      "Description": "B17/224 - Chalasova",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-425587,OU=UKB-D-425587OU,OU=Studijni oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-425587",
                                          "Description": "B17/224 - Chalasova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-468398OU,OU=Studijni oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-468398OU",
                                      "Description": "B17/207 - Horak Puchala 366521",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-468398,OU=UKB-D-468398OU,OU=Studijni oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-468398",
                                          "Description": "B17/207 - Horak Puchala 366521",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-456344OU,OU=Studijni oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-456344OU",
                                      "Description": "B17/227 - Horakova",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-456344,OU=UKB-D-456344OU,OU=Studijni oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-456344",
                                          "Description": "B17/227 - Horakova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-456342OU,OU=Studijni oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-456342OU",
                                      "Description": "B17/209 - Bednarikova",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-456342,OU=UKB-D-456342OU,OU=Studijni oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-456342",
                                          "Description": "B17/209 - Bednarikova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-456340OU,OU=Studijni oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-456340OU",
                                      "Description": "B17/227 - Sehnalova - Adobe Pro 2017",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-456340,OU=UKB-D-456340OU,OU=Studijni oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-456340",
                                          "Description": "B17/227 - Sehnalova - Adobe Pro 2017",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-455636OU,OU=Studijni oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-455636OU",
                                      "Description": "B17/227 - Chlupova",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-455636,OU=UKB-D-455636OU,OU=Studijni oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-455636",
                                          "Description": "B17/227 - Chlupova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-436231OU,OU=Studijni oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-436231OU",
                                      "Description": "B17/209 - Micankova",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-436231,OU=UKB-D-436231OU,OU=Studijni oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-436231",
                                          "Description": "B17/209 - Micankova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-456339OU,OU=Studijni oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-456339OU",
                                      "Description": "B17/207 - Pavlackova",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-456339,OU=UKB-D-456339OU,OU=Studijni oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-456339",
                                          "Description": "B17/207 - Pavlackova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-456346OU,OU=Studijni oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-456346OU",
                                      "Description": "B17/207 - Grycova",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-456346,OU=UKB-D-456346OU,OU=Studijni oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-456346",
                                          "Description": "B17/207 - Grycova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    }
                                  ],
                                  "Computers": [],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=Tajemnik,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "Tajemnik",
                                  "Description": null,
                                  "OrganizationalUnits": [
                                    {
                                      "DistinguishedName": "OU=UKB-D-451456OU,OU=Tajemnik,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-451456OU",
                                      "Description": "B17/335 - Sellner",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-451456,OU=UKB-D-451456OU,OU=Tajemnik,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-451456",
                                          "Description": "B17/335 - Sellner",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    }
                                  ],
                                  "Computers": [],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=Zahranicni oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "Zahranicni oddeleni",
                                  "Description": null,
                                  "OrganizationalUnits": [
                                    {
                                      "DistinguishedName": "OU=UKB-D-455508OU,OU=Zahranicni oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-455508OU",
                                      "Description": "B17/211 - Sankova",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-455508,OU=UKB-D-455508OU,OU=Zahranicni oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-455508",
                                          "Description": "B17/211 - Sankova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-455506OU,OU=Zahranicni oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-455506OU",
                                      "Description": "B17/211 - Melicharova",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-455506,OU=UKB-D-455506OU,OU=Zahranicni oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-455506",
                                          "Description": "B17/211 - Melicharova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-449773OU,OU=Zahranicni oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-449773OU",
                                      "Description": "B17/211 - Palackova",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-449773,OU=UKB-D-449773OU,OU=Zahranicni oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-449773",
                                          "Description": "B17/211 - Palackova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-449150OU,OU=Zahranicni oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-449150OU",
                                      "Description": "B17/211 \u2013 Pilatova",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-449150,OU=UKB-D-449150OU,OU=Zahranicni oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-449150",
                                          "Description": "B17/211 \u2013 Pilatova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-400318OU,OU=Zahranicni oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-400318OU",
                                      "Description": "B17/211 - Neckarova",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-400318,OU=UKB-D-400318OU,OU=Zahranicni oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-400318",
                                          "Description": "B17/211 - Neckarova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    }
                                  ],
                                  "Computers": [],
                                  "Type": "OrganizationalUnit"
                                }
                              ],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "Teoreticka pracoviste",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=Anatomicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Anatomicky ustav",
                              "Description": null,
                              "OrganizationalUnits": [
                                {
                                  "DistinguishedName": "OU=UKB-D-441228OU,OU=Anatomicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-441228OU",
                                  "Description": "F01-2/2S41 - Sandor",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-441228,OU=UKB-D-441228OU,OU=Anatomicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-441228",
                                      "Description": "F01-2/2S41 - Sandor",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-454743OU,OU=Anatomicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-454743OU",
                                  "Description": "F01-2/107 - Joukal",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-454743,OU=UKB-D-454743OU,OU=Anatomicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-454743",
                                      "Description": "F01-2/107 - Joukal",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-471767OU,OU=Anatomicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-471767OU",
                                  "Description": "F1-2/164 - Trenciansky",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-471767,OU=UKB-D-471767OU,OU=Anatomicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-471767",
                                      "Description": "F1-2/164 - Trenciansky",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-425225OU,OU=Anatomicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-425225OU",
                                  "Description": "F01-2/167 - Hasanova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-425225,OU=UKB-D-425225OU,OU=Anatomicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-425225",
                                      "Description": "F01-2/167 - Hasanova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-454745OU,OU=Anatomicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-454745OU",
                                  "Description": "F01-2/141 - Rabova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-454745,OU=UKB-D-454745OU,OU=Anatomicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-454745",
                                      "Description": "F01-2/141 - Rabova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-428707OU,OU=Anatomicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-428707OU",
                                  "Description": "F01-1/617 - Skladana",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-428707,OU=UKB-D-428707OU,OU=Anatomicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-428707",
                                      "Description": "F01-1/617 - Skladana",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-425227OU,OU=Anatomicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-425227OU",
                                  "Description": "F01-2/166 - Musilova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-425227,OU=UKB-D-425227OU,OU=Anatomicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-425227",
                                      "Description": "F01-2/166 - Musilova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-467876OU,OU=Anatomicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-467876OU",
                                  "Description": "EN - F01-2/168 - Zamani",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-467876,OU=UKB-D-467876OU,OU=Anatomicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-467876",
                                      "Description": "EN - F01-2/168 - Zamani",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                }
                              ],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=Biochemicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Biochemicky ustav",
                              "Description": null,
                              "OrganizationalUnits": [
                                {
                                  "DistinguishedName": "OU=UKB-D-444914OU,OU=Biochemicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-444914OU",
                                  "Description": "A16/309 - Kos",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-444914,OU=UKB-D-444914OU,OU=Biochemicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-444914",
                                      "Description": "A16/309 - Kos",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-413171OU,OU=Biochemicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-413171OU",
                                  "Description": "W10/A16/111 - Sebrlova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-413171,OU=UKB-D-413171OU,OU=Biochemicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-413171",
                                      "Description": "W10/A16/111 - Sebrlova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-444913OU,OU=Biochemicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-444913OU",
                                  "Description": "A16/324 - Smutna",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-444913,OU=UKB-D-444913OU,OU=Biochemicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-444913",
                                      "Description": "A16/324 - Smutna",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-453738OU,OU=Biochemicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-453738OU",
                                  "Description": "A16/113 - Kralikova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-453738,OU=UKB-D-453738OU,OU=Biochemicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-453738",
                                      "Description": "A16/113 - Kralikova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-382703OU,OU=Biochemicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-382703OU",
                                  "Description": "A16/218 - Audy",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-382703,OU=UKB-D-382703OU,OU=Biochemicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-382703",
                                      "Description": "A16/218 - Audy",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-444915OU,OU=Biochemicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-444915OU",
                                  "Description": "A16/114 - Dostal",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-444915,OU=UKB-D-444915OU,OU=Biochemicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-444915",
                                      "Description": "A16/114 - Dostal",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-440420OU,OU=Biochemicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-440420OU",
                                  "Description": "A16/311 \u2013 Paulova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-440420,OU=UKB-D-440420OU,OU=Biochemicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-440420",
                                      "Description": "A16/311 \u2013 Paulova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-440419OU,OU=Biochemicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-440419OU",
                                  "Description": "A16/322 - Tomandlova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-440419,OU=UKB-D-440419OU,OU=Biochemicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-440419",
                                      "Description": "A16/322 - Tomandlova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-444912OU,OU=Biochemicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-444912OU",
                                  "Description": "A16/323 - Slanina",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-444912,OU=UKB-D-444912OU,OU=Biochemicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-444912",
                                      "Description": "A16/323 - Slanina",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-473020OU,OU=Biochemicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-473020OU",
                                  "Description": "A16/218 - Audy 239574",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-473020,OU=UKB-D-473020OU,OU=Biochemicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-473020",
                                      "Description": "A16/218 - Audy 239574",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                }
                              ],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=Biofyzikalni ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Biofyzikalni ustav",
                              "Description": null,
                              "OrganizationalUnits": [
                                {
                                  "DistinguishedName": "OU=UKB-D-462444OU,OU=Biofyzikalni ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-462444OU",
                                  "Description": "F01-1/420 - Forytkova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-462444,OU=UKB-D-462444OU,OU=Biofyzikalni ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-462444",
                                      "Description": "F01-1/420 - Forytkova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-422371OU,OU=Biofyzikalni ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-422371OU",
                                  "Description": "F01-1/418 - Mornstein",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-422371,OU=UKB-D-422371OU,OU=Biofyzikalni ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-422371",
                                      "Description": "F01-1/418 - Mornstein",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-440850OU,OU=Biofyzikalni ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-440850OU",
                                  "Description": "F01-1/422 - Vlk",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-440850,OU=UKB-D-440850OU,OU=Biofyzikalni ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-440850",
                                      "Description": "F01-1/422 - Vlk",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                }
                              ],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=Biologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Biologicky ustav",
                              "Description": null,
                              "OrganizationalUnits": [
                                {
                                  "DistinguishedName": "OU=VS Vladimira Rotrekla,OU=Biologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "VS Vladimira Rotrekla",
                                  "Description": null,
                                  "OrganizationalUnits": [
                                    {
                                      "DistinguishedName": "OU=UKB-D-387820OU,OU=VS Vladimira Rotrekla,OU=Biologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-387820OU",
                                      "Description": "A19/323b - Geryk Michele",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-387820,OU=UKB-D-387820OU,OU=VS Vladimira Rotrekla,OU=Biologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-387820",
                                          "Description": "A19/323b - Geryk Michele",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-387812OU,OU=VS Vladimira Rotrekla,OU=Biologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-387812OU",
                                      "Description": "A19/323a - Pozo - EN",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-387812,OU=UKB-D-387812OU,OU=VS Vladimira Rotrekla,OU=Biologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-387812",
                                          "Description": "A19/323a - Pozo - EN",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    }
                                  ],
                                  "Computers": [],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-468354OU,OU=Biologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-468354OU",
                                  "Description": "FNB Ustav patologie I1, 2. np., lab 41 - Slaby",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-468354,OU=UKB-D-468354OU,OU=Biologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-468354",
                                      "Description": "FNB Ustav patologie I1, 2. np., lab 41 - Slaby",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=VS Pavla Krejciho,OU=Biologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "VS Pavla Krejciho",
                                  "Description": null,
                                  "OrganizationalUnits": [
                                    {
                                      "DistinguishedName": "OU=UKB-D-429047OU,OU=VS Pavla Krejciho,OU=Biologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-429047OU",
                                      "Description": "A19/231 - Czyrek Aleksandra",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-429047,OU=UKB-D-429047OU,OU=VS Pavla Krejciho,OU=Biologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-429047",
                                          "Description": "A19/231 - Czyrek Aleksandra",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-383468OU,OU=VS Pavla Krejciho,OU=Biologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-383468OU",
                                      "Description": "A19/231 - Kocerova 245648",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-383468,OU=UKB-D-383468OU,OU=VS Pavla Krejciho,OU=Biologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-383468",
                                          "Description": "A19/231 - Kocerova 245648",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    }
                                  ],
                                  "Computers": [],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=VS Nicoly Silvy,OU=Biologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "VS Nicoly Silvy",
                                  "Description": null,
                                  "OrganizationalUnits": [
                                    {
                                      "DistinguishedName": "OU=UKB-D-436097OU,OU=VS Nicoly Silvy,OU=Biologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-436097OU",
                                      "Description": "B06/104 - DSP EN",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-436097,OU=UKB-D-436097OU,OU=VS Nicoly Silvy,OU=Biologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-436097",
                                          "Description": "B06/104 - DSP EN",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-436095OU,OU=VS Nicoly Silvy,OU=Biologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-436095OU",
                                      "Description": "B06/104 - DSP EN",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-436095,OU=UKB-D-436095OU,OU=VS Nicoly Silvy,OU=Biologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-436095",
                                          "Description": "B06/104 - DSP EN",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    }
                                  ],
                                  "Computers": [],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=VS Lumira Krejciho,OU=Biologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "VS Lumira Krejciho",
                                  "Description": null,
                                  "OrganizationalUnits": [
                                    {
                                      "DistinguishedName": "OU=UKB-D-410632OU,OU=VS Lumira Krejciho,OU=Biologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-410632OU",
                                      "Description": "B06/315 - Pavlikova",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-410632,OU=UKB-D-410632OU,OU=VS Lumira Krejciho,OU=Biologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-410632",
                                          "Description": "B06/315 - Pavlikova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-462442OU,OU=VS Lumira Krejciho,OU=Biologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-462442OU",
                                      "Description": "B07/307 - Nikulenkov",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-462442,OU=UKB-D-462442OU,OU=VS Lumira Krejciho,OU=Biologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-462442",
                                          "Description": "B07/307 - Nikulenkov",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-462441OU,OU=VS Lumira Krejciho,OU=Biologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-462441OU",
                                      "Description": "B07/307- Zacpalova",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-462441,OU=UKB-D-462441OU,OU=VS Lumira Krejciho,OU=Biologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-462441",
                                          "Description": "B07/307- Zacpalova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-38478OU,OU=VS Lumira Krejciho,OU=Biologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-38478OU",
                                      "Description": "B07/303 - Liang",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-38478,OU=UKB-D-38478OU,OU=VS Lumira Krejciho,OU=Biologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-38478",
                                          "Description": "B07/303 - Liang",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    }
                                  ],
                                  "Computers": [],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=VS Lenky Besse,OU=Biologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "VS Lenky Besse",
                                  "Description": null,
                                  "OrganizationalUnits": [
                                    {
                                      "DistinguishedName": "OU=UKB-D-467751OU,OU=VS Lenky Besse,OU=Biologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-467751OU",
                                      "Description": "EN - A19/222 - LAB Besse",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-467751,OU=UKB-D-467751OU,OU=VS Lenky Besse,OU=Biologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-467751",
                                          "Description": "EN - A19/222 - LAB Besse",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    }
                                  ],
                                  "Computers": [],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-338899OU,OU=Biologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-338899OU",
                                  "Description": "A19/312 - Abraham EN",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-338899,OU=UKB-D-338899OU,OU=Biologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-338899",
                                      "Description": "A19/312 - Abraham EN",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=VS Stjepana Uldrijana,OU=Biologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "VS Stjepana Uldrijana",
                                  "Description": null,
                                  "OrganizationalUnits": [
                                    {
                                      "DistinguishedName": "OU=UKB-D-387813OU,OU=VS Stjepana Uldrijana,OU=Biologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-387813OU",
                                      "Description": "B06/313 - Dorotikova",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-387813,OU=UKB-D-387813OU,OU=VS Stjepana Uldrijana,OU=Biologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-387813",
                                          "Description": "B06/313 - Dorotikova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-453745OU,OU=VS Stjepana Uldrijana,OU=Biologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-453745OU",
                                      "Description": "B06/313 - Vadovicova",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-453745,OU=UKB-D-453745OU,OU=VS Stjepana Uldrijana,OU=Biologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-453745",
                                          "Description": "B06/313 - Vadovicova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-453744OU,OU=VS Stjepana Uldrijana,OU=Biologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-453744OU",
                                      "Description": "B06/313 - Kozdonova",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-453744,OU=UKB-D-453744OU,OU=VS Stjepana Uldrijana,OU=Biologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-453744",
                                          "Description": "B06/313 - Kozdonova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    }
                                  ],
                                  "Computers": [],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-454337OU,OU=Biologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-454337OU",
                                  "Description": "A19/312 - Elrefaay EN",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-454337,OU=UKB-D-454337OU,OU=Biologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-454337",
                                      "Description": "A19/312 - Elrefaay EN",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=VS Zdenka Andrysika,OU=Biologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "VS Zdenka Andrysika",
                                  "Description": null,
                                  "OrganizationalUnits": [],
                                  "Computers": [],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=VS Davida Smajse,OU=Biologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "VS Davida Smajse",
                                  "Description": null,
                                  "OrganizationalUnits": [
                                    {
                                      "DistinguishedName": "OU=UKB-D-472661OU,OU=VS Davida Smajse,OU=Biologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-472661OU",
                                      "Description": "B06/205 - Hrala 408747",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-472661,OU=UKB-D-472661OU,OU=VS Davida Smajse,OU=Biologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-472661",
                                          "Description": "B06/205 - Hrala 408747",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-472662OU,OU=VS Davida Smajse,OU=Biologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-472662OU",
                                      "Description": "B06/318 - Vrbova 423794",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-472662,OU=UKB-D-472662OU,OU=VS Davida Smajse,OU=Biologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-472662",
                                          "Description": "B06/318 - Vrbova 423794",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-465969OU,OU=VS Davida Smajse,OU=Biologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-465969OU",
                                      "Description": "B17/215 - Ruzickova",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-465969,OU=UKB-D-465969OU,OU=VS Davida Smajse,OU=Biologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-465969",
                                          "Description": "B17/215 - Ruzickova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-468215OU,OU=VS Davida Smajse,OU=Biologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-468215OU",
                                      "Description": "B06/204 - Bosak 106498",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-468215,OU=UKB-D-468215OU,OU=VS Davida Smajse,OU=Biologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-468215",
                                          "Description": "B06/204 - Bosak 106498",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    }
                                  ],
                                  "Computers": [],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-400002OU,OU=Biologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-400002OU",
                                  "Description": "C04/336 - DSP",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-400002,OU=UKB-D-400002OU,OU=Biologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-400002",
                                      "Description": "C04/336 - DSP",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-474754OU,OU=Biologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-474754OU",
                                  "Description": "B06/102 - Cetkovska 106847",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-474754,OU=UKB-D-474754OU,OU=Biologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-474754",
                                      "Description": "B06/102 - Cetkovska 106847",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                }
                              ],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=Farmakologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Farmakologicky ustav",
                              "Description": null,
                              "OrganizationalUnits": [
                                {
                                  "DistinguishedName": "OU=A19,OU=Farmakologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "A19",
                                  "Description": null,
                                  "OrganizationalUnits": [
                                    {
                                      "DistinguishedName": "OU=UKB-D-421133OU,OU=A19,OU=Farmakologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-421133OU",
                                      "Description": "A19/116 - Blablova",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-421133,OU=UKB-D-421133OU,OU=A19,OU=Farmakologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-421133",
                                          "Description": "A19/116 - Blablova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-410629OU,OU=A19,OU=Farmakologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-410629OU",
                                      "Description": "W10/A19/107 - Pistovcakova",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-410629,OU=UKB-D-410629OU,OU=A19,OU=Farmakologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-410629",
                                          "Description": "W10/A19/107 - Pistovcakova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-407034OU,OU=A19,OU=Farmakologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-407034OU",
                                      "Description": "A19/109 - Fockova",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-407034,OU=UKB-D-407034OU,OU=A19,OU=Farmakologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-407034",
                                          "Description": "A19/109 - Fockova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-418376OU,OU=A19,OU=Farmakologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-418376OU",
                                      "Description": "A19/108 - Landa",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-418376,OU=UKB-D-418376OU,OU=A19,OU=Farmakologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-418376",
                                          "Description": "A19/108 - Landa",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-443510OU,OU=A19,OU=Farmakologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-443510OU",
                                      "Description": "A19/109 - Machalova",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-443510,OU=UKB-D-443510OU,OU=A19,OU=Farmakologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-443510",
                                          "Description": "A19/109 - Machalova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-457416OU,OU=A19,OU=Farmakologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-457416OU",
                                      "Description": "A19-111 - Rihova",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-457416,OU=UKB-D-457416OU,OU=A19,OU=Farmakologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-457416",
                                          "Description": "A19-111 - Rihova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-440849OU,OU=A19,OU=Farmakologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-440849OU",
                                      "Description": "A19/107 \u2013 Bartosova",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-440849,OU=UKB-D-440849OU,OU=A19,OU=Farmakologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-440849",
                                          "Description": "A19/107 \u2013 Bartosova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    }
                                  ],
                                  "Computers": [],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=C03,OU=Farmakologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "C03",
                                  "Description": null,
                                  "OrganizationalUnits": [],
                                  "Computers": [],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=C15,OU=Farmakologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "C15",
                                  "Description": null,
                                  "OrganizationalUnits": [],
                                  "Computers": [],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=CREATIC,OU=Farmakologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "CREATIC",
                                  "Description": null,
                                  "OrganizationalUnits": [
                                    {
                                      "DistinguishedName": "OU=UKB-D-387830OU,OU=CREATIC,OU=Farmakologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-387830OU",
                                      "Description": "C03/319 - Flajsarova",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-387830,OU=UKB-D-387830OU,OU=CREATIC,OU=Farmakologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-387830",
                                          "Description": "C03/319 - Flajsarova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-453794OU,OU=CREATIC,OU=Farmakologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-453794OU",
                                      "Description": "CREATIC - Demlova",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-453794,OU=UKB-D-453794OU,OU=CREATIC,OU=Farmakologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-453794",
                                          "Description": "CREATIC - Demlova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-453793OU,OU=CREATIC,OU=Farmakologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-453793OU",
                                      "Description": "CREATIC - Bartova",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-453793,OU=UKB-D-453793OU,OU=CREATIC,OU=Farmakologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-453793",
                                          "Description": "CREATIC - Bartova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    }
                                  ],
                                  "Computers": [],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=CZECRIN,OU=Farmakologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "CZECRIN",
                                  "Description": null,
                                  "OrganizationalUnits": [],
                                  "Computers": [],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=A16,OU=Farmakologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "A16",
                                  "Description": null,
                                  "OrganizationalUnits": [
                                    {
                                      "DistinguishedName": "OU=UKB-D-425359OU,OU=A16,OU=Farmakologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-425359OU",
                                      "Description": "A16/126 - Hammer",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-425359,OU=UKB-D-425359OU,OU=A16,OU=Farmakologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-425359",
                                          "Description": "A16/126 - Hammer",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-453792OU,OU=A16,OU=Farmakologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-453792OU",
                                      "Description": "A16/??? - Zemanova",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-453792,OU=UKB-D-453792OU,OU=A16,OU=Farmakologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-453792",
                                          "Description": "A16/??? - Zemanova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-464263OU,OU=A16,OU=Farmakologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-464263OU",
                                      "Description": "A16/128 - Amchova",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-464263,OU=UKB-D-464263OU,OU=A16,OU=Farmakologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-464263",
                                          "Description": "A16/128 - Amchova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-407037OU,OU=A16,OU=Farmakologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-407037OU",
                                      "Description": "A16/109 - Vrankova",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-407037,OU=UKB-D-407037OU,OU=A16,OU=Farmakologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-407037",
                                          "Description": "A16/109 - Vrankova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-407041OU,OU=A16,OU=Farmakologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-407041OU",
                                      "Description": "A16/123 - Demirci",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-407041,OU=UKB-D-407041OU,OU=A16,OU=Farmakologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-407041",
                                          "Description": "A16/123 - Demirci",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-407032OU,OU=A16,OU=Farmakologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-407032OU",
                                      "Description": "A16/112 - Klaskova",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-407032,OU=UKB-D-407032OU,OU=A16,OU=Farmakologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-407032",
                                          "Description": "A16/112 - Klaskova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    }
                                  ],
                                  "Computers": [],
                                  "Type": "OrganizationalUnit"
                                }
                              ],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=Fyziologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Fyziologicky ustav",
                              "Description": null,
                              "OrganizationalUnits": [
                                {
                                  "DistinguishedName": "OU=UKB-D-472698OU,OU=Fyziologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-472698OU",
                                  "Description": "A20/223 - Babula",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-472698,OU=UKB-D-472698OU,OU=Fyziologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-472698",
                                      "Description": "A20/223 - Babula",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-472699OU,OU=Fyziologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-472699OU",
                                  "Description": "A20/218 - Stepka",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-472699,OU=UKB-D-472699OU,OU=Fyziologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-472699",
                                      "Description": "A20/218 - Stepka",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-383140OU,OU=Fyziologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-383140OU",
                                  "Description": "W10/A20/317 - studenti",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-383140,OU=UKB-D-383140OU,OU=Fyziologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-383140",
                                      "Description": "W10/A20/317 - studenti",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-451485OU,OU=Fyziologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-451485OU",
                                  "Description": "A20/1S12 - Nadenicek",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-451485,OU=UKB-D-451485OU,OU=Fyziologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-451485",
                                      "Description": "A20/1S12 - Nadenicek",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-446734OU,OU=Fyziologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-446734OU",
                                  "Description": "A20/224 - Reznickova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-446734,OU=UKB-D-446734OU,OU=Fyziologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-446734",
                                      "Description": "A20/224 - Reznickova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-446733OU,OU=Fyziologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-446733OU",
                                  "Description": "A20/218 - Fojtu/ Novakova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-446733,OU=UKB-D-446733OU,OU=Fyziologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-446733",
                                      "Description": "A20/218 - Fojtu/ Novakova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=VS Markety Bebarove,OU=Fyziologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "VS Markety Bebarove",
                                  "Description": null,
                                  "OrganizationalUnits": [
                                    {
                                      "DistinguishedName": "OU=UKB-D-472700OU,OU=VS Markety Bebarove,OU=Fyziologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-472700OU",
                                      "Description": "A20/312 - Kral",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-472700,OU=UKB-D-472700OU,OU=VS Markety Bebarove,OU=Fyziologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-472700",
                                          "Description": "A20/312 - Kral",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-346476OU,OU=VS Markety Bebarove,OU=Fyziologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-346476OU",
                                      "Description": "W10/A20/321 - DSP",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-346476,OU=UKB-D-346476OU,OU=VS Markety Bebarove,OU=Fyziologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-346476",
                                          "Description": "W10/A20/321 - DSP",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-450824OU,OU=VS Markety Bebarove,OU=Fyziologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-450824OU",
                                      "Description": "A20/312 - Kral STARY",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-450824,OU=UKB-D-450824OU,OU=VS Markety Bebarove,OU=Fyziologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-450824",
                                          "Description": "A20/312 - Kral STARY",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-472638OU,OU=VS Markety Bebarove,OU=Fyziologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-472638OU",
                                      "Description": "A20/318 - LAB ?",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-472638,OU=UKB-D-472638OU,OU=VS Markety Bebarove,OU=Fyziologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-472638",
                                          "Description": "A20/318 - LAB ?",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-451486OU,OU=VS Markety Bebarove,OU=Fyziologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-451486OU",
                                      "Description": "A20/227 - Angelovski - EN",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-451486,OU=UKB-D-451486OU,OU=VS Markety Bebarove,OU=Fyziologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-451486",
                                          "Description": "A20/227 - Angelovski - EN",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-457427OU,OU=VS Markety Bebarove,OU=Fyziologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-457427OU",
                                      "Description": "A20/313 - Svecova",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-457427,OU=UKB-D-457427OU,OU=VS Markety Bebarove,OU=Fyziologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-457427",
                                          "Description": "A20/313 - Svecova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    }
                                  ],
                                  "Computers": [],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-446732OU,OU=Fyziologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-446732OU",
                                  "Description": "A20/319 - Stracina",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-446732,OU=UKB-D-446732OU,OU=Fyziologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-446732",
                                      "Description": "A20/319 - Stracina",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-425668OU,OU=Fyziologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-425668OU",
                                  "Description": "A20/226 - Nyvltova Fisakova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-425668,OU=UKB-D-425668OU,OU=Fyziologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-425668",
                                      "Description": "A20/226 - Nyvltova Fisakova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-425216OU,OU=Fyziologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-425216OU",
                                  "Description": "A20/313 - Budinska",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-425216,OU=UKB-D-425216OU,OU=Fyziologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-425216",
                                      "Description": "A20/313 - Budinska",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-446731OU,OU=Fyziologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-446731OU",
                                  "Description": "A20/315 - Novakova M.",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-446731,OU=UKB-D-446731OU,OU=Fyziologicky ustav,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-446731",
                                      "Description": "A20/315 - Novakova M.",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                }
                              ],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=Ustav histologie a embryologie,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Ustav histologie a embryologie",
                              "Description": null,
                              "OrganizationalUnits": [
                                {
                                  "DistinguishedName": "OU=UKB-D-447573OU,OU=Ustav histologie a embryologie,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-447573OU",
                                  "Description": "F01-1/213a - Pospisilova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-447573,OU=UKB-D-447573OU,OU=Ustav histologie a embryologie,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-447573",
                                      "Description": "F01-1/213a - Pospisilova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-347526OU,OU=Ustav histologie a embryologie,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-347526OU",
                                  "Description": "W10/F01-1/1S21 - Jongen",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-347526,OU=UKB-D-347526OU,OU=Ustav histologie a embryologie,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-347526",
                                      "Description": "W10/F01-1/1S21 - Jongen - EN",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-321264OU,OU=Ustav histologie a embryologie,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-321264OU",
                                  "Description": "W10/F01-1/214 - Kolackova - VPN - FNUSA",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-321264,OU=UKB-D-321264OU,OU=Ustav histologie a embryologie,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-321264",
                                      "Description": "W10/F01-1/214 - Kolackova - VPN - FNUSA",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-348562OU,OU=Ustav histologie a embryologie,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-348562OU",
                                  "Description": "W10/F01-1/1S21 - Grafova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-348562,OU=UKB-D-348562OU,OU=Ustav histologie a embryologie,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-348562",
                                      "Description": "W10/F01-1/1S21 - Grafova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-457993OU,OU=Ustav histologie a embryologie,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-457993OU",
                                  "Description": "F01-1/218 - Baltasova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-457993,OU=UKB-D-457993OU,OU=Ustav histologie a embryologie,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-457993",
                                      "Description": "F01-1/218 - Baltasova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-457994OU,OU=Ustav histologie a embryologie,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-457994OU",
                                  "Description": "F01-1/218 - Mecova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-457994,OU=UKB-D-457994OU,OU=Ustav histologie a embryologie,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-457994",
                                      "Description": "F01-1/218 - Mecova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-457995OU,OU=Ustav histologie a embryologie,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-457995OU",
                                  "Description": "F01-1/623 - Holubcova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-457995,OU=UKB-D-457995OU,OU=Ustav histologie a embryologie,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-457995",
                                      "Description": "F01-1/623 - Holubcova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-373864OU,OU=Ustav histologie a embryologie,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-373864OU",
                                  "Description": "W10/F01-1/217 - Tatickova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-373864,OU=UKB-D-373864OU,OU=Ustav histologie a embryologie,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-373864",
                                      "Description": "W10/F01-1/217 - Tatickova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-467797OU,OU=Ustav histologie a embryologie,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-467797OU",
                                  "Description": "F01-1/211 - Koutna",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-467797,OU=UKB-D-467797OU,OU=Ustav histologie a embryologie,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-467797",
                                      "Description": "F01-1/211 - Koutna",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-446403OU,OU=Ustav histologie a embryologie,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-446403OU",
                                  "Description": "F01-1/222 - Slamenikova 541003",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-446403,OU=UKB-D-446403OU,OU=Ustav histologie a embryologie,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-446403",
                                      "Description": "F01-1/222 - Slamenikova 541003",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-395524OU,OU=Ustav histologie a embryologie,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-395524OU",
                                  "Description": "F01-1/225 - Slovackova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-395524,OU=UKB-D-395524OU,OU=Ustav histologie a embryologie,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-395524",
                                      "Description": "F01-1/225 - Slovackova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-446405OU,OU=Ustav histologie a embryologie,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-446405OU",
                                  "Description": "F01-1/214 - Jezova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-446405,OU=UKB-D-446405OU,OU=Ustav histologie a embryologie,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-446405",
                                      "Description": "F01-1/214 - Jezova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-459505OU,OU=Ustav histologie a embryologie,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-459505OU",
                                  "Description": "F01-1/1S22 - Souralova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-459505,OU=UKB-D-459505OU,OU=Ustav histologie a embryologie,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-459505",
                                      "Description": "F01-1/1S22 - Souralova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-467190OU,OU=Ustav histologie a embryologie,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-467190OU",
                                  "Description": "F01-1/1S22 - Smolnicka",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-467190,OU=UKB-D-467190OU,OU=Ustav histologie a embryologie,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-467190",
                                      "Description": "F01-1/1S22 - Smolnicka",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-320061OU,OU=Ustav histologie a embryologie,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-320061OU",
                                  "Description": "F01-1/634 - laborator",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-320061,OU=UKB-D-320061OU,OU=Ustav histologie a embryologie,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-320061",
                                      "Description": "F01-1/634 - laborator",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-467191OU,OU=Ustav histologie a embryologie,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-467191OU",
                                  "Description": "F01-1/1S22 - Dlugosova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-467191,OU=UKB-D-467191OU,OU=Ustav histologie a embryologie,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-467191",
                                      "Description": "F01-1/1S22  - Dlugosova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=VS Lukase Cajanka,OU=Ustav histologie a embryologie,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "VS Lukase Cajanka",
                                  "Description": null,
                                  "OrganizationalUnits": [
                                    {
                                      "DistinguishedName": "OU=UKB-D-458560OU,OU=VS Lukase Cajanka,OU=Ustav histologie a embryologie,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-458560OU",
                                      "Description": "F01-1/228 - Cajanek",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-458560,OU=UKB-D-458560OU,OU=VS Lukase Cajanka,OU=Ustav histologie a embryologie,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-458560",
                                          "Description": "F01-1/228 - Cajanek",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    }
                                  ],
                                  "Computers": [],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-338765OU,OU=Ustav histologie a embryologie,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-338765OU",
                                  "Description": "F01-1/228 - Jacobsen",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-338765,OU=UKB-D-338765OU,OU=Ustav histologie a embryologie,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-338765",
                                      "Description": "F01-1/228 - Jacobsen",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-446404OU,OU=Ustav histologie a embryologie,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-446404OU",
                                  "Description": "F01-1/226 \u2013 Danylevska",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-446404,OU=UKB-D-446404OU,OU=Ustav histologie a embryologie,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-446404",
                                      "Description": "F01-1/226 \u2013 Danylevska",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-458561OU,OU=Ustav histologie a embryologie,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-458561OU",
                                  "Description": "F01-1/623 - Tauwinklova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-458561,OU=UKB-D-458561OU,OU=Ustav histologie a embryologie,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-458561",
                                      "Description": "F01-1/623 - Tauwinklova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-373863OU,OU=Ustav histologie a embryologie,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-373863OU",
                                  "Description": "W10/F01-1/1S22 - DSP",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-373863,OU=UKB-D-373863OU,OU=Ustav histologie a embryologie,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-373863",
                                      "Description": "W10/F01-1/1S22 - DSP",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-457413OU,OU=Ustav histologie a embryologie,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-457413OU",
                                  "Description": "F01-1/222 - Cigosova EN",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-457413,OU=UKB-D-457413OU,OU=Ustav histologie a embryologie,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-457413",
                                      "Description": "F01-1/222 Cigosova - EN",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-331405OU,OU=Ustav histologie a embryologie,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-331405OU",
                                  "Description": "W10/F01-1/1S22 - DSP",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-331405,OU=UKB-D-331405OU,OU=Ustav histologie a embryologie,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-331405",
                                      "Description": "W10/F01-1/1S22 - DSP",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-359421OU,OU=Ustav histologie a embryologie,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-359421OU",
                                  "Description": "W10/C03/115 - Hulinova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-359421,OU=UKB-D-359421OU,OU=Ustav histologie a embryologie,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-359421",
                                      "Description": "W10/C03/115 - Hulinova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-447574OU,OU=Ustav histologie a embryologie,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-447574OU",
                                  "Description": "F01-1/227 - Hruba",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-447574,OU=UKB-D-447574OU,OU=Ustav histologie a embryologie,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-447574",
                                      "Description": "F01-1/227 - Hruba",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                }
                              ],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=Ustav lekarske etiky,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Ustav lekarske etiky",
                              "Description": null,
                              "OrganizationalUnits": [
                                {
                                  "DistinguishedName": "OU=UKB-D-418371OU,OU=Ustav lekarske etiky,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-418371OU",
                                  "Description": "C15/329 \u2013 Sucha",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-418371,OU=UKB-D-418371OU,OU=Ustav lekarske etiky,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-418371",
                                      "Description": "C15/329 \u2013 Sucha",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                }
                              ],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=Ustav lekarske genetiky,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Ustav lekarske genetiky",
                              "Description": null,
                              "OrganizationalUnits": [
                                {
                                  "DistinguishedName": "OU=UKB-D-430411OU,OU=Ustav lekarske genetiky,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-430411OU",
                                  "Description": "F01-1/621 - nahrada",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-430411,OU=UKB-D-430411OU,OU=Ustav lekarske genetiky,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-430411",
                                      "Description": "F01-1/621 - nahrada",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-461559OU,OU=Ustav lekarske genetiky,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-461559OU",
                                  "Description": "F01-1/622 - sekretariat",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-461559,OU=UKB-D-461559OU,OU=Ustav lekarske genetiky,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-461559",
                                      "Description": "F01-1/622 - sekretariat",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-430413OU,OU=Ustav lekarske genetiky,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-430413OU",
                                  "Description": "F01-1/622 - Stehlikova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-430413,OU=UKB-D-430413OU,OU=Ustav lekarske genetiky,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-430413",
                                      "Description": "F01-1/622 - Stehlikova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                }
                              ],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=Ustav patologicke fyziologie,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Ustav patologicke fyziologie",
                              "Description": null,
                              "OrganizationalUnits": [
                                {
                                  "DistinguishedName": "OU=UKB-D-407049OU,OU=Ustav patologicke fyziologie,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-407049OU",
                                  "Description": "A18/113 - Lakoma",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-407049,OU=UKB-D-407049OU,OU=Ustav patologicke fyziologie,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-407049",
                                      "Description": "A18/113 - Lakoma",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-454476OU,OU=Ustav patologicke fyziologie,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-454476OU",
                                  "Description": "A18/231 - Hladikova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-454476,OU=UKB-D-454476OU,OU=Ustav patologicke fyziologie,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-454476",
                                      "Description": "A18/231 - Hladikova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=VS Sabiny Sevcikove,OU=Ustav patologicke fyziologie,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "VS Sabiny Sevcikove",
                                  "Description": null,
                                  "OrganizationalUnits": [
                                    {
                                      "DistinguishedName": "OU=UKB-D-450038OU,OU=VS Sabiny Sevcikove,OU=Ustav patologicke fyziologie,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-450038OU",
                                      "Description": "C03/111 - Polakova",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-450038,OU=UKB-D-450038OU,OU=VS Sabiny Sevcikove,OU=Ustav patologicke fyziologie,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-450038",
                                          "Description": "C03/111 - Polakova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-461443OU,OU=VS Sabiny Sevcikove,OU=Ustav patologicke fyziologie,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-461443OU",
                                      "Description": "BiologyPark/A.009 - Ruzickova",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-461443,OU=UKB-D-461443OU,OU=VS Sabiny Sevcikove,OU=Ustav patologicke fyziologie,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-461443",
                                          "Description": "BiologyPark/A.009 - Ruzickova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-461444OU,OU=VS Sabiny Sevcikove,OU=Ustav patologicke fyziologie,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-461444OU",
                                      "Description": "BiologyPark/A.009 - DSP",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-461444,OU=UKB-D-461444OU,OU=VS Sabiny Sevcikove,OU=Ustav patologicke fyziologie,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-461444",
                                          "Description": "BiologyPark/A.009 - DSP",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-446321OU,OU=VS Sabiny Sevcikove,OU=Ustav patologicke fyziologie,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-446321OU",
                                      "Description": "BiologyPark/2.patro - Sevcikova",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-446321,OU=UKB-D-446321OU,OU=VS Sabiny Sevcikove,OU=Ustav patologicke fyziologie,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-446321",
                                          "Description": "BiologyPark/2.patro - Sevcikova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-450037OU,OU=VS Sabiny Sevcikove,OU=Ustav patologicke fyziologie,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-450037OU",
                                      "Description": "BiologyPark/4.patro - Vlachova",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-450037,OU=UKB-D-450037OU,OU=VS Sabiny Sevcikove,OU=Ustav patologicke fyziologie,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-450037",
                                          "Description": "BiologyPark/4.patro - Vlachova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    }
                                  ],
                                  "Computers": [],
                                  "Type": "OrganizationalUnit"
                                }
                              ],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=Ustav psychologie a psychosomatiky,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Ustav psychologie a psychosomatiky",
                              "Description": null,
                              "OrganizationalUnits": [
                                {
                                  "DistinguishedName": "OU=UKB-D-474732OU,OU=Ustav psychologie a psychosomatiky,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-474732OU",
                                  "Description": "A19/128 - Baresova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-474732,OU=UKB-D-474732OU,OU=Ustav psychologie a psychosomatiky,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-474732",
                                      "Description": "A19/128 - Baresova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-433151OU,OU=Ustav psychologie a psychosomatiky,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-433151OU",
                                  "Description": "A19/134 - Malatincova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-433151,OU=UKB-D-433151OU,OU=Ustav psychologie a psychosomatiky,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-433151",
                                      "Description": "A19/134 - Malatincova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-460967OU,OU=Ustav psychologie a psychosomatiky,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-460967OU",
                                  "Description": "A19/131 - Sucha",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-460967,OU=UKB-D-460967OU,OU=Ustav psychologie a psychosomatiky,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-460967",
                                      "Description": "A19/131 - Sucha",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-429833OU,OU=Ustav psychologie a psychosomatiky,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-429833OU",
                                  "Description": "A19/132 - Slezackova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-429833,OU=UKB-D-429833OU,OU=Ustav psychologie a psychosomatiky,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-429833",
                                      "Description": "A19/132 - Slezackova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                }
                              ],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=Ustav verejneho zdravi,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Ustav verejneho zdravi",
                              "Description": null,
                              "OrganizationalUnits": [
                                {
                                  "DistinguishedName": "OU=UKB-D-405527OU,OU=Ustav verejneho zdravi,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-405527OU",
                                  "Description": "A21/318 - Matejova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-405527,OU=UKB-D-405527OU,OU=Ustav verejneho zdravi,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-405527",
                                      "Description": "A21/318 - Matejova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-406991OU,OU=Ustav verejneho zdravi,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-406991OU",
                                  "Description": "A21/329 - ambulance",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-406991,OU=UKB-D-406991OU,OU=Ustav verejneho zdravi,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-406991",
                                      "Description": "A21/329 - ambulance",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-NOVOHRADOU,OU=Ustav verejneho zdravi,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-NOVOHRADOU",
                                  "Description": "A21/327 - Novohradska 248070",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-NOVOHRAD,OU=UKB-D-NOVOHRADOU,OU=Ustav verejneho zdravi,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-NOVOHRAD",
                                      "Description": "A21/327 - Novohradska 248070",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-406990OU,OU=Ustav verejneho zdravi,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-406990OU",
                                  "Description": "A21/319 - Fialova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-406990,OU=UKB-D-406990OU,OU=Ustav verejneho zdravi,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-406990",
                                      "Description": "A21/319 - Fialova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-396943OU,OU=Ustav verejneho zdravi,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-396943OU",
                                  "Description": "A21/327 - Novohradska",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-396943,OU=UKB-D-396943OU,OU=Ustav verejneho zdravi,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-396943",
                                      "Description": "A21/327 - Novohradska",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-438952OU,OU=Ustav verejneho zdravi,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-438952OU",
                                  "Description": "A21/308 - DSP",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-438952,OU=UKB-D-438952OU,OU=Ustav verejneho zdravi,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-438952",
                                      "Description": "A21/308 - DSP",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-438955OU,OU=Ustav verejneho zdravi,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-438955OU",
                                  "Description": "A21/308 - DSP - EN",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-438955,OU=UKB-D-438955OU,OU=Ustav verejneho zdravi,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-438955",
                                      "Description": "A21/308 - DSP - EN",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-406992OU,OU=Ustav verejneho zdravi,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-406992OU",
                                  "Description": "A21/318 \u2013 Jancekova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-406992,OU=UKB-D-406992OU,OU=Ustav verejneho zdravi,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-406992",
                                      "Description": "A21/318 \u2013 Jancekova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-418329OU,OU=Ustav verejneho zdravi,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-418329OU",
                                  "Description": "A21/316 - Perina",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-418329,OU=UKB-D-418329OU,OU=Ustav verejneho zdravi,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-418329",
                                      "Description": "A21/316 - Perina",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-406993OU,OU=Ustav verejneho zdravi,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-406993OU",
                                  "Description": "A21/315 - sklad",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-406993,OU=UKB-D-406993OU,OU=Ustav verejneho zdravi,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-406993",
                                      "Description": "A21/315 - sklad",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-396245OU,OU=Ustav verejneho zdravi,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-396245OU",
                                  "Description": "A21/324 - Krobot",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-396245,OU=UKB-D-396245OU,OU=Ustav verejneho zdravi,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-396245",
                                      "Description": "A21/324 - Krobot",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-429886OU,OU=Ustav verejneho zdravi,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-429886OU",
                                  "Description": "A21/326 - Koscik",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-429886,OU=UKB-D-429886OU,OU=Ustav verejneho zdravi,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-429886",
                                      "Description": "A21/326 - Koscik",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-396246OU,OU=Ustav verejneho zdravi,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-396246OU",
                                  "Description": "A21/324 - Drobov - EN",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-396246,OU=UKB-D-396246OU,OU=Ustav verejneho zdravi,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-396246",
                                      "Description": "A21/324 - Drobov - EN",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-396243OU,OU=Ustav verejneho zdravi,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-396243OU",
                                  "Description": "A21/313 - Deflerova Brazdova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-396243,OU=UKB-D-396243OU,OU=Ustav verejneho zdravi,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-396243",
                                      "Description": "A21/313 - Deflerova Brazdova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-396244OU,OU=Ustav verejneho zdravi,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-396244OU",
                                  "Description": "A21/319 - Kapounova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-396244,OU=UKB-D-396244OU,OU=Ustav verejneho zdravi,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-396244",
                                      "Description": "A21/319 - Kapounova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-406996OU,OU=Ustav verejneho zdravi,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-406996OU",
                                  "Description": "A21/315 - Chuchmova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-406996,OU=UKB-D-406996OU,OU=Ustav verejneho zdravi,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-406996",
                                      "Description": "A21/315 - Chuchmova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-406995OU,OU=Ustav verejneho zdravi,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-406995OU",
                                  "Description": "A21/322 - Karpiskova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-406995,OU=UKB-D-406995OU,OU=Ustav verejneho zdravi,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-406995",
                                      "Description": "A21/322 - Karpiskova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-438954OU,OU=Ustav verejneho zdravi,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-438954OU",
                                  "Description": "A21/308 - DSP",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-438954,OU=UKB-D-438954OU,OU=Ustav verejneho zdravi,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-438954",
                                      "Description": "A21/308 - DSP",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-438953OU,OU=Ustav verejneho zdravi,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-438953OU",
                                  "Description": "A21/308 - DSP",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-438953,OU=UKB-D-438953OU,OU=Ustav verejneho zdravi,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-438953",
                                      "Description": "A21/308 - DSP",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-415967OU,OU=Ustav verejneho zdravi,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-415967OU",
                                  "Description": "A21/316 - Forejt",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-415967,OU=UKB-D-415967OU,OU=Ustav verejneho zdravi,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-415967",
                                      "Description": "A21/316 - Forejt",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-454335OU,OU=Ustav verejneho zdravi,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-454335OU",
                                  "Description": "A21/315 - Riad - EN",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-454335,OU=UKB-D-454335OU,OU=Ustav verejneho zdravi,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-454335",
                                      "Description": "A21/315 - Riad - EN",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-406994OU,OU=Ustav verejneho zdravi,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-406994OU",
                                  "Description": "A21/215 \u2013 Kolackova - Laborator",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-406994,OU=UKB-D-406994OU,OU=Ustav verejneho zdravi,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-406994",
                                      "Description": "A21/215 \u2013 Kolackova - Laborator",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-406998OU,OU=Ustav verejneho zdravi,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-406998OU",
                                  "Description": "A21/322 - Kolackova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-406998,OU=UKB-D-406998OU,OU=Ustav verejneho zdravi,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-406998",
                                      "Description": "A21/322 - Kolackova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-466432OU,OU=Ustav verejneho zdravi,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-466432OU",
                                  "Description": "A21/321 - Zverina",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-466432,OU=UKB-D-466432OU,OU=Ustav verejneho zdravi,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-466432",
                                      "Description": "A21/321 - Zverina",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-466433OU,OU=Ustav verejneho zdravi,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-466433OU",
                                  "Description": "A21/311 - Kanova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-466433,OU=UKB-D-466433OU,OU=Ustav verejneho zdravi,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-466433",
                                      "Description": "A21/311 - Kanova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-461428OU,OU=Ustav verejneho zdravi,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-461428OU",
                                  "Description": "A21/318 - Suchodolova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-461428,OU=UKB-D-461428OU,OU=Ustav verejneho zdravi,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-461428",
                                      "Description": "A21/318 - Suchodolova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-461429OU,OU=Ustav verejneho zdravi,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-461429OU",
                                  "Description": "A21/319 - Zelenkova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-461429,OU=UKB-D-461429OU,OU=Ustav verejneho zdravi,OU=Teoreticka pracoviste,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-461429",
                                      "Description": "A21/319 - Zelenkova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                }
                              ],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=Ucelova zarizeni,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "Ucelova zarizeni",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=Simulacni centrum,OU=Ucelova zarizeni,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Simulacni centrum",
                              "Description": null,
                              "OrganizationalUnits": [
                                {
                                  "DistinguishedName": "OU=3.NP,OU=Simulacni centrum,OU=Ucelova zarizeni,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "3.NP",
                                  "Description": null,
                                  "OrganizationalUnits": [
                                    {
                                      "DistinguishedName": "OU=UKB-D-429360OU,OU=3.NP,OU=Simulacni centrum,OU=Ucelova zarizeni,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-429360OU",
                                      "Description": "F37/326 - Justrova",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-429360,OU=UKB-D-429360OU,OU=3.NP,OU=Simulacni centrum,OU=Ucelova zarizeni,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-429360",
                                          "Description": "F37/326 - Justrova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-429351OU,OU=3.NP,OU=Simulacni centrum,OU=Ucelova zarizeni,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-429351OU",
                                      "Description": "F37/325 \u2013 Bublavy",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-429351,OU=UKB-D-429351OU,OU=3.NP,OU=Simulacni centrum,OU=Ucelova zarizeni,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-429351",
                                          "Description": "F37/325 \u2013 Bublavy",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-429358OU,OU=3.NP,OU=Simulacni centrum,OU=Ucelova zarizeni,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-429358OU",
                                      "Description": "F37/325 - Gebauerova",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-429358,OU=UKB-D-429358OU,OU=3.NP,OU=Simulacni centrum,OU=Ucelova zarizeni,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-429358",
                                          "Description": "F37/325 - Gebauerova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-429353OU,OU=3.NP,OU=Simulacni centrum,OU=Ucelova zarizeni,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-429353OU",
                                      "Description": "F37/326 - Ovcik",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-429353,OU=UKB-D-429353OU,OU=3.NP,OU=Simulacni centrum,OU=Ucelova zarizeni,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-429353",
                                          "Description": "F37/326 - Ovcik",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-429361OU,OU=3.NP,OU=Simulacni centrum,OU=Ucelova zarizeni,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-429361OU",
                                      "Description": "F37/326 - Hajkova",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-429361,OU=UKB-D-429361OU,OU=3.NP,OU=Simulacni centrum,OU=Ucelova zarizeni,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-429361",
                                          "Description": "F37/326 - Hajkova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-429345OU,OU=3.NP,OU=Simulacni centrum,OU=Ucelova zarizeni,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-429345OU",
                                      "Description": "F37/323 - zasedacka",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-429345,OU=UKB-D-429345OU,OU=3.NP,OU=Simulacni centrum,OU=Ucelova zarizeni,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-429345",
                                          "Description": "F37/323 - zasedacka",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-429335OU,OU=3.NP,OU=Simulacni centrum,OU=Ucelova zarizeni,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-429335OU",
                                      "Description": "F37/313 - Externiste",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-429335,OU=UKB-D-429335OU,OU=3.NP,OU=Simulacni centrum,OU=Ucelova zarizeni,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-429335",
                                          "Description": "F37/313 - Externiste",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-429334OU,OU=3.NP,OU=Simulacni centrum,OU=Ucelova zarizeni,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-429334OU",
                                      "Description": "F37/313 - Externiste",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-429334,OU=UKB-D-429334OU,OU=3.NP,OU=Simulacni centrum,OU=Ucelova zarizeni,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-429334",
                                          "Description": "F37/313 - Externiste",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-429336OU,OU=3.NP,OU=Simulacni centrum,OU=Ucelova zarizeni,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-429336OU",
                                      "Description": "F37/315 - Externiste",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-429336,OU=UKB-D-429336OU,OU=3.NP,OU=Simulacni centrum,OU=Ucelova zarizeni,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-429336",
                                          "Description": "F37/315 - Externiste",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-429337OU,OU=3.NP,OU=Simulacni centrum,OU=Ucelova zarizeni,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-429337OU",
                                      "Description": "F37/315 - Externiste",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-429337,OU=UKB-D-429337OU,OU=3.NP,OU=Simulacni centrum,OU=Ucelova zarizeni,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-429337",
                                          "Description": "F37/315 - Externiste",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-429338OU,OU=3.NP,OU=Simulacni centrum,OU=Ucelova zarizeni,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-429338OU",
                                      "Description": "F37/315 - Externiste",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-429338,OU=UKB-D-429338OU,OU=3.NP,OU=Simulacni centrum,OU=Ucelova zarizeni,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-429338",
                                          "Description": "F37/315 - Externiste",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-429340OU,OU=3.NP,OU=Simulacni centrum,OU=Ucelova zarizeni,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-429340OU",
                                      "Description": "F37/321 - Externiste",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-429340,OU=UKB-D-429340OU,OU=3.NP,OU=Simulacni centrum,OU=Ucelova zarizeni,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-429340",
                                          "Description": "F37/321 - Externiste",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-429341OU,OU=3.NP,OU=Simulacni centrum,OU=Ucelova zarizeni,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-429341OU",
                                      "Description": "F37/321 - Externiste",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-429341,OU=UKB-D-429341OU,OU=3.NP,OU=Simulacni centrum,OU=Ucelova zarizeni,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-429341",
                                          "Description": "F37/321 - Externiste",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-429342OU,OU=3.NP,OU=Simulacni centrum,OU=Ucelova zarizeni,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-429342OU",
                                      "Description": "F37/321 - Externiste",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-429342,OU=UKB-D-429342OU,OU=3.NP,OU=Simulacni centrum,OU=Ucelova zarizeni,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-429342",
                                          "Description": "F37/321 - Externiste",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-429343OU,OU=3.NP,OU=Simulacni centrum,OU=Ucelova zarizeni,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-429343OU",
                                      "Description": "F37/321 - Externiste",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-429343,OU=UKB-D-429343OU,OU=3.NP,OU=Simulacni centrum,OU=Ucelova zarizeni,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-429343",
                                          "Description": "F37/321 - Externiste",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-429370OU,OU=3.NP,OU=Simulacni centrum,OU=Ucelova zarizeni,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-429370OU",
                                      "Description": "F37/322 - Externiste",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-429370,OU=UKB-D-429370OU,OU=3.NP,OU=Simulacni centrum,OU=Ucelova zarizeni,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-429370",
                                          "Description": "F37/322 - Externiste",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-429354OU,OU=3.NP,OU=Simulacni centrum,OU=Ucelova zarizeni,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-429354OU",
                                      "Description": "F37/326 - Jakubu",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-429354,OU=UKB-D-429354OU,OU=3.NP,OU=Simulacni centrum,OU=Ucelova zarizeni,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-429354",
                                          "Description": "F37/326 - Jakubu",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-429350OU,OU=3.NP,OU=Simulacni centrum,OU=Ucelova zarizeni,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-429350OU",
                                      "Description": "F37/324 - Sperkova",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-429350,OU=UKB-D-429350OU,OU=3.NP,OU=Simulacni centrum,OU=Ucelova zarizeni,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-429350",
                                          "Description": "F37/324 - Sperkova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-429349OU,OU=3.NP,OU=Simulacni centrum,OU=Ucelova zarizeni,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-429349OU",
                                      "Description": "F37/324 - Kovarikova",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-429349,OU=UKB-D-429349OU,OU=3.NP,OU=Simulacni centrum,OU=Ucelova zarizeni,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-429349",
                                          "Description": "F37/324 - Kovarikova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-429348OU,OU=3.NP,OU=Simulacni centrum,OU=Ucelova zarizeni,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-429348OU",
                                      "Description": "F37/324 \u2013 Bosakova",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-429348,OU=UKB-D-429348OU,OU=3.NP,OU=Simulacni centrum,OU=Ucelova zarizeni,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-429348",
                                          "Description": "F37/324 \u2013 Bosakova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-429366OU,OU=3.NP,OU=Simulacni centrum,OU=Ucelova zarizeni,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-429366OU",
                                      "Description": "F37/325 - Sulak",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-429366,OU=UKB-D-429366OU,OU=3.NP,OU=Simulacni centrum,OU=Ucelova zarizeni,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-429366",
                                          "Description": "F37/325 - Sulak",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-429368OU,OU=3.NP,OU=Simulacni centrum,OU=Ucelova zarizeni,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-429368OU",
                                      "Description": "F37/322 - Externiste",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-429368,OU=UKB-D-429368OU,OU=3.NP,OU=Simulacni centrum,OU=Ucelova zarizeni,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-429368",
                                          "Description": "F37/322 - Externiste",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-429369OU,OU=3.NP,OU=Simulacni centrum,OU=Ucelova zarizeni,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-429369OU",
                                      "Description": "F37/322 - Externiste",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-429369,OU=UKB-D-429369OU,OU=3.NP,OU=Simulacni centrum,OU=Ucelova zarizeni,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-429369",
                                          "Description": "F37/322 - Externiste",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-429333OU,OU=3.NP,OU=Simulacni centrum,OU=Ucelova zarizeni,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-429333OU",
                                      "Description": "F37/322 - Externiste",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-429333,OU=UKB-D-429333OU,OU=3.NP,OU=Simulacni centrum,OU=Ucelova zarizeni,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-429333",
                                          "Description": "F37/322 - Externiste",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-429339OU,OU=3.NP,OU=Simulacni centrum,OU=Ucelova zarizeni,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-429339OU",
                                      "Description": "F37/315 - Externiste",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-429339,OU=UKB-D-429339OU,OU=3.NP,OU=Simulacni centrum,OU=Ucelova zarizeni,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-429339",
                                          "Description": "F37/315 - Externiste",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-429352OU,OU=3.NP,OU=Simulacni centrum,OU=Ucelova zarizeni,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-429352OU",
                                      "Description": "F37/325 - Polman",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-429352,OU=UKB-D-429352OU,OU=3.NP,OU=Simulacni centrum,OU=Ucelova zarizeni,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-429352",
                                          "Description": "F37/325 - Polman",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    }
                                  ],
                                  "Computers": [],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=4.NP,OU=Simulacni centrum,OU=Ucelova zarizeni,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "4.NP",
                                  "Description": null,
                                  "OrganizationalUnits": [
                                    {
                                      "DistinguishedName": "OU=UKB-D-429213OU,OU=4.NP,OU=Simulacni centrum,OU=Ucelova zarizeni,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-429213OU",
                                      "Description": "F37/419 - predsali",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-429213,OU=UKB-D-429213OU,OU=4.NP,OU=Simulacni centrum,OU=Ucelova zarizeni,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-429213",
                                          "Description": "F37/419 - predsali",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-429212OU,OU=4.NP,OU=Simulacni centrum,OU=Ucelova zarizeni,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-429212OU",
                                      "Description": "F37/409 \u2013 predsali",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-429212,OU=UKB-D-429212OU,OU=4.NP,OU=Simulacni centrum,OU=Ucelova zarizeni,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-429212",
                                          "Description": "F37/409 \u2013 predsali",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    }
                                  ],
                                  "Computers": [],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=5.NP,OU=Simulacni centrum,OU=Ucelova zarizeni,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "5.NP",
                                  "Description": null,
                                  "OrganizationalUnits": [
                                    {
                                      "DistinguishedName": "OU=UKB-D-429356OU,OU=5.NP,OU=Simulacni centrum,OU=Ucelova zarizeni,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-429356OU",
                                      "Description": "F37/516 - zasedacka",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-429356,OU=UKB-D-429356OU,OU=5.NP,OU=Simulacni centrum,OU=Ucelova zarizeni,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-429356",
                                          "Description": "F37/516 - zasedacka",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-429357OU,OU=5.NP,OU=Simulacni centrum,OU=Ucelova zarizeni,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-429357OU",
                                      "Description": "F37/524 - zasedacka",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-429357,OU=UKB-D-429357OU,OU=5.NP,OU=Simulacni centrum,OU=Ucelova zarizeni,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-429357",
                                          "Description": "F37/524 - zasedacka",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-429390OU,OU=5.NP,OU=Simulacni centrum,OU=Ucelova zarizeni,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-429390OU",
                                      "Description": "F37/sklad",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-429390,OU=UKB-D-429390OU,OU=5.NP,OU=Simulacni centrum,OU=Ucelova zarizeni,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-429390",
                                          "Description": "F37/sklad",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-429383OU,OU=5.NP,OU=Simulacni centrum,OU=Ucelova zarizeni,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-429383OU",
                                      "Description": "F37/517 - Kosinova",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-429383,OU=UKB-D-429383OU,OU=5.NP,OU=Simulacni centrum,OU=Ucelova zarizeni,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-429383",
                                          "Description": "F37/517 - Kosinova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-429385OU,OU=5.NP,OU=Simulacni centrum,OU=Ucelova zarizeni,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-429385OU",
                                      "Description": "F37/508 - Putna",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-429385,OU=UKB-D-429385OU,OU=5.NP,OU=Simulacni centrum,OU=Ucelova zarizeni,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-429385",
                                          "Description": "F37/508 - Putna",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-429344OU,OU=5.NP,OU=Simulacni centrum,OU=Ucelova zarizeni,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-429344OU",
                                      "Description": "F37/525 - Kassova",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-429344,OU=UKB-D-429344OU,OU=5.NP,OU=Simulacni centrum,OU=Ucelova zarizeni,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-429344",
                                          "Description": "F37/525 - Kassova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-429362OU,OU=5.NP,OU=Simulacni centrum,OU=Ucelova zarizeni,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-429362OU",
                                      "Description": "F37/514 - Harazim",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-429362,OU=UKB-D-429362OU,OU=5.NP,OU=Simulacni centrum,OU=Ucelova zarizeni,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-429362",
                                          "Description": "F37/514 - Harazim",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-429347OU,OU=5.NP,OU=Simulacni centrum,OU=Ucelova zarizeni,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-429347OU",
                                      "Description": "F37/512 - Varga",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-429347,OU=UKB-D-429347OU,OU=5.NP,OU=Simulacni centrum,OU=Ucelova zarizeni,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-429347",
                                          "Description": "F37/512 - Varga",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-429365OU,OU=5.NP,OU=Simulacni centrum,OU=Ucelova zarizeni,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-429365OU",
                                      "Description": "F37/525 - Dundacek",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-429365,OU=UKB-D-429365OU,OU=5.NP,OU=Simulacni centrum,OU=Ucelova zarizeni,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-429365",
                                          "Description": "F37/525 - Dundacek",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-429332OU,OU=5.NP,OU=Simulacni centrum,OU=Ucelova zarizeni,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-429332OU",
                                      "Description": "F37/515 - Bruzlova",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-429332,OU=UKB-D-429332OU,OU=5.NP,OU=Simulacni centrum,OU=Ucelova zarizeni,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-429332",
                                          "Description": "F37/515 - Bruzlova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-429367OU,OU=5.NP,OU=Simulacni centrum,OU=Ucelova zarizeni,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-429367OU",
                                      "Description": "F37/513 - Vadurova",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-429367,OU=UKB-D-429367OU,OU=5.NP,OU=Simulacni centrum,OU=Ucelova zarizeni,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-429367",
                                          "Description": "F37/513 - Vadurova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-429346OU,OU=5.NP,OU=Simulacni centrum,OU=Ucelova zarizeni,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-429346OU",
                                      "Description": "F37/515 - Micankova",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-429346,OU=UKB-D-429346OU,OU=5.NP,OU=Simulacni centrum,OU=Ucelova zarizeni,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-429346",
                                          "Description": "F37/515 - Micankova",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-429389OU,OU=5.NP,OU=Simulacni centrum,OU=Ucelova zarizeni,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-429389OU",
                                      "Description": "F37/513 - Lhotska",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-429389,OU=UKB-D-429389OU,OU=5.NP,OU=Simulacni centrum,OU=Ucelova zarizeni,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-429389",
                                          "Description": "F37/513 - Lhotska",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=UKB-D-429364OU,OU=5.NP,OU=Simulacni centrum,OU=Ucelova zarizeni,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-429364OU",
                                      "Description": "F37/514 - Skrisovska",
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=UKB-D-429364,OU=UKB-D-429364OU,OU=5.NP,OU=Simulacni centrum,OU=Ucelova zarizeni,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "UKB-D-429364",
                                          "Description": "F37/514 - Skrisovska",
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    }
                                  ],
                                  "Computers": [],
                                  "Type": "OrganizationalUnit"
                                }
                              ],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=Chovne a uzivatelske zarizeni laboratornich zvirat,OU=Ucelova zarizeni,OU=Kampus,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Chovne a uzivatelske zarizeni laboratornich zvirat",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=Komenskeho,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Komenskeho",
                      "Description": null,
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=Jina pracoviste,OU=Komenskeho,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "Jina pracoviste",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=RMU - Centrum jazykoveho vzdelavani,OU=Jina pracoviste,OU=Komenskeho,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "RMU - Centrum jazykoveho vzdelavani",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=Pracoviste nelekarskych oboru,OU=Komenskeho,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "Pracoviste nelekarskych oboru",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=Katedra fyzioterapie a rehabilitace,OU=Pracoviste nelekarskych oboru,OU=Komenskeho,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Katedra fyzioterapie a rehabilitace",
                              "Description": null,
                              "OrganizationalUnits": [
                                {
                                  "DistinguishedName": "OU=UKB-D-460868OU,OU=Katedra fyzioterapie a rehabilitace,OU=Pracoviste nelekarskych oboru,OU=Komenskeho,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-460868OU",
                                  "Description": "KOM2/256 - Vavrouchova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-460868,OU=UKB-D-460868OU,OU=Katedra fyzioterapie a rehabilitace,OU=Pracoviste nelekarskych oboru,OU=Komenskeho,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-460868",
                                      "Description": "KOM2/256 - Vavrouchova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-460505OU,OU=Katedra fyzioterapie a rehabilitace,OU=Pracoviste nelekarskych oboru,OU=Komenskeho,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-460505OU",
                                  "Description": "KOM2/256 - Dunklerova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-460505,OU=UKB-D-460505OU,OU=Katedra fyzioterapie a rehabilitace,OU=Pracoviste nelekarskych oboru,OU=Komenskeho,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-460505",
                                      "Description": "KOM2/256 - Dunklerova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-472397OU,OU=Katedra fyzioterapie a rehabilitace,OU=Pracoviste nelekarskych oboru,OU=Komenskeho,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-472397OU",
                                  "Description": "KOM2/255 - Dosbaba 211806",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-472397,OU=UKB-D-472397OU,OU=Katedra fyzioterapie a rehabilitace,OU=Pracoviste nelekarskych oboru,OU=Komenskeho,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-472397",
                                      "Description": "KOM2/255 - Dosbaba 211806",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-472398OU,OU=Katedra fyzioterapie a rehabilitace,OU=Pracoviste nelekarskych oboru,OU=Komenskeho,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-472398OU",
                                  "Description": "KOM2/255A - Batalik114757",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-472398,OU=UKB-D-472398OU,OU=Katedra fyzioterapie a rehabilitace,OU=Pracoviste nelekarskych oboru,OU=Komenskeho,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-472398",
                                      "Description": "KOM2/255A - Batalik114757",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                }
                              ],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=Katedra laboratornich metod,OU=Pracoviste nelekarskych oboru,OU=Komenskeho,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Katedra laboratornich metod",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=Katedra optometrie a ortoptiky,OU=Pracoviste nelekarskych oboru,OU=Komenskeho,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Katedra optometrie a ortoptiky",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=Katedra osetrovatelstvi a porodni asistence,OU=Pracoviste nelekarskych oboru,OU=Komenskeho,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Katedra osetrovatelstvi a porodni asistence",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=Spolecna pracoviste s FNUSA,OU=Komenskeho,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "Spolecna pracoviste s FNUSA",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=Stomatologicka klinika,OU=Spolecna pracoviste s FNUSA,OU=Komenskeho,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Stomatologicka klinika",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=Spravni pracoviste,OU=Komenskeho,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "Spravni pracoviste",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=Dekanat,OU=Spravni pracoviste,OU=Komenskeho,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Dekanat",
                              "Description": null,
                              "OrganizationalUnits": [
                                {
                                  "DistinguishedName": "OU=Zahranicni oddeleni,OU=Dekanat,OU=Spravni pracoviste,OU=Komenskeho,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "Zahranicni oddeleni",
                                  "Description": null,
                                  "OrganizationalUnits": [],
                                  "Computers": [],
                                  "Type": "OrganizationalUnit"
                                }
                              ],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=FNBrno - Pracoviste mediciny dospeleho veku,OU=Komenskeho,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FNBrno - Pracoviste mediciny dospeleho veku",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=Interni hematologicka a onkologicka klinika,OU=FNBrno - Pracoviste mediciny dospeleho veku,OU=Komenskeho,OU=LF,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Interni hematologicka a onkologicka klinika",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    }
                  ],
                  "Computers": [],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "SCI",
                  "Description": null,
                  "OrganizationalUnits": [
                    {
                      "DistinguishedName": "OU=Dekanat,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Dekanat",
                      "Description": null,
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=Oddeleni pro doktorske studium,OU=Dekanat,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "Oddeleni pro doktorske studium",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=UKB-D-409289OU,OU=Oddeleni pro doktorske studium,OU=Dekanat,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-409289OU",
                              "Description": "B17/413 - Klimova - Adobe Pro 11",
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=UKB-D-409289,OU=UKB-D-409289OU,OU=Oddeleni pro doktorske studium,OU=Dekanat,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-409289",
                                  "Description": "B17/413 - Klimova",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=UKB-D-409290OU,OU=Oddeleni pro doktorske studium,OU=Dekanat,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-409290OU",
                              "Description": "B17/413 - Kainerova - Adobe Pro 11",
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=UKB-D-409290,OU=UKB-D-409290OU,OU=Oddeleni pro doktorske studium,OU=Dekanat,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-409290",
                                  "Description": "B17/413 - Kainerova",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=Personalni oddeleni,OU=Dekanat,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "Personalni oddeleni",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=UKB-D-409281OU,OU=Personalni oddeleni,OU=Dekanat,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-409281OU",
                              "Description": "B17/407 \u2013 Hrbkova",
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=UKB-D-409281,OU=UKB-D-409281OU,OU=Personalni oddeleni,OU=Dekanat,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-409281",
                                  "Description": "B17/407 \u2013 Hrbkova",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=UKB-D-409285OU,OU=Personalni oddeleni,OU=Dekanat,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-409285OU",
                              "Description": "B17/406 - Schneiderova",
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=UKB-D-409285,OU=UKB-D-409285OU,OU=Personalni oddeleni,OU=Dekanat,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-409285",
                                  "Description": "B17/406 - Schneiderova",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=UKB-D-407191OU,OU=Personalni oddeleni,OU=Dekanat,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-407191OU",
                              "Description": "B17/407 \u2013 Istvankova",
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=UKB-D-407191,OU=UKB-D-407191OU,OU=Personalni oddeleni,OU=Dekanat,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-407191",
                                  "Description": "B17/407 \u2013 Istvankova",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=Studijni oddeleni,OU=Dekanat,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "Studijni oddeleni",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=UKB-D-409287OU,OU=Studijni oddeleni,OU=Dekanat,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-409287OU",
                              "Description": "B17/409 - Doupovcova",
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=UKB-D-409287,OU=UKB-D-409287OU,OU=Studijni oddeleni,OU=Dekanat,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-409287",
                                  "Description": "B17/409 - Doupovcova",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=UKB-D-409288OU,OU=Studijni oddeleni,OU=Dekanat,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-409288OU",
                              "Description": "B17/411 - Navratilova",
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=UKB-D-409288,OU=UKB-D-409288OU,OU=Studijni oddeleni,OU=Dekanat,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-409288",
                                  "Description": "B17/411 - Navratilova",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=Kancelar tajemnika,OU=Dekanat,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "Kancelar tajemnika",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=Sekretariat,OU=Kancelar tajemnika,OU=Dekanat,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Sekretariat",
                              "Description": null,
                              "OrganizationalUnits": [
                                {
                                  "DistinguishedName": "OU=UKB-D-400025OU,OU=Sekretariat,OU=Kancelar tajemnika,OU=Dekanat,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-400025OU",
                                  "Description": "B17/429 - Ilcikova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-400025,OU=UKB-D-400025OU,OU=Sekretariat,OU=Kancelar tajemnika,OU=Dekanat,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-400025",
                                      "Description": "B17/429 - Ilcikova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                }
                              ],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=Vnejsi vztahy\\, komunikace a marketing,OU=Kancelar tajemnika,OU=Dekanat,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Vnejsi vztahy, komunikace a marketing",
                              "Description": null,
                              "OrganizationalUnits": [
                                {
                                  "DistinguishedName": "OU=UKB-D-409344OU,OU=Vnejsi vztahy\\, komunikace a marketing,OU=Kancelar tajemnika,OU=Dekanat,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-409344OU",
                                  "Description": "B17/412 \u2013 Kamenikova - Adobe Acrobat 2020",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-409344,OU=UKB-D-409344OU,OU=Vnejsi vztahy\\, komunikace a marketing,OU=Kancelar tajemnika,OU=Dekanat,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-409344",
                                      "Description": "B17/412 \u2013 Kamenikova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-409343OU,OU=Vnejsi vztahy\\, komunikace a marketing,OU=Kancelar tajemnika,OU=Dekanat,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-409343OU",
                                  "Description": "B17/412 \u2013 Dilhofova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-409343,OU=UKB-D-409343OU,OU=Vnejsi vztahy\\, komunikace a marketing,OU=Kancelar tajemnika,OU=Dekanat,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-409343",
                                      "Description": "B17/412 \u2013 Dilhofova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                }
                              ],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=Tajemnik,OU=Kancelar tajemnika,OU=Dekanat,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Tajemnik",
                              "Description": null,
                              "OrganizationalUnits": [
                                {
                                  "DistinguishedName": "OU=UKB-D-457947OU,OU=Tajemnik,OU=Kancelar tajemnika,OU=Dekanat,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-457947OU",
                                  "Description": "C05/207 - Kasparovska",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-457947,OU=UKB-D-457947OU,OU=Tajemnik,OU=Kancelar tajemnika,OU=Dekanat,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-457947",
                                      "Description": "C05/207 - Kasparovska",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                }
                              ],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=Ostatni pracoviste,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Ostatni pracoviste",
                      "Description": null,
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=Centrum jazykovaho vzdelavani,OU=Ostatni pracoviste,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "Centrum jazykovaho vzdelavani",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=UKB-D-350531OU,OU=Centrum jazykovaho vzdelavani,OU=Ostatni pracoviste,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-350531OU",
                              "Description": "C15/107 - Dudova",
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=UKB-D-350531,OU=UKB-D-350531OU,OU=Centrum jazykovaho vzdelavani,OU=Ostatni pracoviste,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-350531",
                                  "Description": "C15/107 - Dudova",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=UKB-D-342728OU,OU=Centrum jazykovaho vzdelavani,OU=Ostatni pracoviste,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-342728OU",
                              "Description": "C15/107 - Bizkova",
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=UKB-D-342728,OU=UKB-D-342728OU,OU=Centrum jazykovaho vzdelavani,OU=Ostatni pracoviste,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-342728",
                                  "Description": "C15/107 - Bizkova",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=UKB-D-347423OU,OU=Centrum jazykovaho vzdelavani,OU=Ostatni pracoviste,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-347423OU",
                              "Description": "C15/119 - Dlabolova",
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=UKB-D-347423,OU=UKB-D-347423OU,OU=Centrum jazykovaho vzdelavani,OU=Ostatni pracoviste,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-347423",
                                  "Description": "C15/119 - Dlabolova",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=UKB-D-375102OU,OU=Centrum jazykovaho vzdelavani,OU=Ostatni pracoviste,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-375102OU",
                              "Description": "C15/119 - Pavlikova",
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=UKB-D-375102,OU=UKB-D-375102OU,OU=Centrum jazykovaho vzdelavani,OU=Ostatni pracoviste,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-375102",
                                  "Description": "C15/119 - Pavlikova",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Ustavy",
                      "Description": null,
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=Narodni centrum pro vyzkum biomolekul,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "Narodni centrum pro vyzkum biomolekul",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=UKB-D-457362OU,OU=Narodni centrum pro vyzkum biomolekul,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-457362OU",
                              "Description": "C02/231 - Forstova pro studenty",
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=UKB-D-457362,OU=UKB-D-457362OU,OU=Narodni centrum pro vyzkum biomolekul,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-457362",
                                  "Description": "C02/231 - Forstova pro studenty",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=UKB-D-474977OU,OU=Narodni centrum pro vyzkum biomolekul,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-474977OU",
                              "Description": "C02/225 - Forstova 242506",
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=UKB-D-474977,OU=UKB-D-474977OU,OU=Narodni centrum pro vyzkum biomolekul,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-474977",
                                  "Description": "C02/225 - Forstova 242506",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=UKB-D-451903OU,OU=Narodni centrum pro vyzkum biomolekul,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-451903OU",
                              "Description": "B07/204 - Matulova a spol",
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=UKB-D-451903,OU=UKB-D-451903OU,OU=Narodni centrum pro vyzkum biomolekul,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-451903",
                                  "Description": "B07/204 - Matulova a spol",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=UKB-D-395984OU,OU=Narodni centrum pro vyzkum biomolekul,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-395984OU",
                              "Description": "B07/204 - Matulova 9032",
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=UKB-D-395984,OU=UKB-D-395984OU,OU=Narodni centrum pro vyzkum biomolekul,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-395984",
                                  "Description": "B07/204 - Matulova 9032",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=Ustav biochemie,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "Ustav biochemie",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=UKB-D-410470OU,OU=Ustav biochemie,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-410470OU",
                              "Description": "C05/307 - Ondrova 500177",
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=UKB-D-410470,OU=UKB-D-410470OU,OU=Ustav biochemie,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-410470",
                                  "Description": "C05/307 - Ondrova 500177",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=Chemicka sekce,OU=Ustav biochemie,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Chemicka sekce",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=UKB-D-347444OU,OU=Ustav biochemie,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-347444OU",
                              "Description": "C05/106 - Maly",
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=UKB-D-347444,OU=UKB-D-347444OU,OU=Ustav biochemie,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-347444",
                                  "Description": "C05/106 - Maly",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=UKB-D-347417OU,OU=Ustav biochemie,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-347417OU",
                              "Description": "W10 - C05/106 - DSP",
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=UKB-D-347417,OU=UKB-D-347417OU,OU=Ustav biochemie,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-347417",
                                  "Description": "W10 - C05/106 - DSP",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=Ustav experimentalni biologie,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "Ustav experimentalni biologie",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=Oddeleni experimentalni biologie rostlin,OU=Ustav experimentalni biologie,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Oddeleni experimentalni biologie rostlin",
                              "Description": "C03/116 - Bila",
                              "OrganizationalUnits": [
                                {
                                  "DistinguishedName": "OU=UKB-D-462076OU,OU=Oddeleni experimentalni biologie rostlin,OU=Ustav experimentalni biologie,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-462076OU",
                                  "Description": "C03/116 - Bila",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-462076,OU=UKB-D-462076OU,OU=Oddeleni experimentalni biologie rostlin,OU=Ustav experimentalni biologie,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-462076",
                                      "Description": "C03/116 - Bila",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-428725OU,OU=Oddeleni experimentalni biologie rostlin,OU=Ustav experimentalni biologie,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-428725OU",
                                  "Description": "C13/118 - Samalova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-428725,OU=UKB-D-428725OU,OU=Oddeleni experimentalni biologie rostlin,OU=Ustav experimentalni biologie,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-428725",
                                      "Description": "C13/118 - Samalova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                }
                              ],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=Ceska sbirka mikroorganismu,OU=Ustav experimentalni biologie,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Ceska sbirka mikroorganismu",
                              "Description": null,
                              "OrganizationalUnits": [
                                {
                                  "DistinguishedName": "OU=UKB-D-474856OU,OU=Ceska sbirka mikroorganismu,OU=Ustav experimentalni biologie,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-474856OU",
                                  "Description": "E25/1S08 - Novakova 13197",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-474856,OU=UKB-D-474856OU,OU=Ceska sbirka mikroorganismu,OU=Ustav experimentalni biologie,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-474856",
                                      "Description": "E25/1S08 - Novakova 13197",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-392846OU,OU=Ceska sbirka mikroorganismu,OU=Ustav experimentalni biologie,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-392846OU",
                                  "Description": "W10/E25/1S34 - Sudakova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-392846,OU=UKB-D-392846OU,OU=Ceska sbirka mikroorganismu,OU=Ustav experimentalni biologie,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-392846",
                                      "Description": "W10/E25/1S34 - Sudakova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-338923OU,OU=Ceska sbirka mikroorganismu,OU=Ustav experimentalni biologie,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-338923OU",
                                  "Description": "W10/E25/2S18 \u2013 LAB",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-338923,OU=UKB-D-338923OU,OU=Ceska sbirka mikroorganismu,OU=Ustav experimentalni biologie,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-338923",
                                      "Description": "W10/E25/2S18 \u2013 LAB",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-420880OU,OU=Ceska sbirka mikroorganismu,OU=Ustav experimentalni biologie,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-420880OU",
                                  "Description": "E25/1S08 - Teturova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-420880,OU=UKB-D-420880OU,OU=Ceska sbirka mikroorganismu,OU=Ustav experimentalni biologie,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-420880",
                                      "Description": "E25/1S08 - Teturova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-391766OU,OU=Ceska sbirka mikroorganismu,OU=Ustav experimentalni biologie,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-391766OU",
                                  "Description": "W10/E25/1S12 \u2013 Laichmanova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-391766,OU=UKB-D-391766OU,OU=Ceska sbirka mikroorganismu,OU=Ustav experimentalni biologie,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-391766",
                                      "Description": "W10/E25/1S12 \u2013 Laichmanova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-370519OU,OU=Ceska sbirka mikroorganismu,OU=Ustav experimentalni biologie,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-370519OU",
                                  "Description": "E25/1S11 - Vives",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-370519,OU=UKB-D-370519OU,OU=Ceska sbirka mikroorganismu,OU=Ustav experimentalni biologie,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-370519",
                                      "Description": "E25/1S11 - Vives",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                }
                              ],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=Oddeleni fyziologie a imunologie zivocichu,OU=Ustav experimentalni biologie,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Oddeleni fyziologie a imunologie zivocichu",
                              "Description": null,
                              "OrganizationalUnits": [
                                {
                                  "DistinguishedName": "OU=UKB-D-461915OU,OU=Oddeleni fyziologie a imunologie zivocichu,OU=Ustav experimentalni biologie,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-461915OU",
                                  "Description": "D36/329 - Valnohova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-461915,OU=UKB-D-461915OU,OU=Oddeleni fyziologie a imunologie zivocichu,OU=Ustav experimentalni biologie,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-461915",
                                      "Description": "D36/329 - Valnohova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-461916OU,OU=Oddeleni fyziologie a imunologie zivocichu,OU=Ustav experimentalni biologie,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-461916OU",
                                  "Description": "D36/329 - Dolezalova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-461916,OU=UKB-D-461916OU,OU=Oddeleni fyziologie a imunologie zivocichu,OU=Ustav experimentalni biologie,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-461916",
                                      "Description": "D36/329 - Dolezalova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-396212OU,OU=Oddeleni fyziologie a imunologie zivocichu,OU=Ustav experimentalni biologie,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-396212OU",
                                  "Description": "EN - D36/1.. - LAB",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-396212,OU=UKB-D-396212OU,OU=Oddeleni fyziologie a imunologie zivocichu,OU=Ustav experimentalni biologie,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-396212",
                                      "Description": "EN - D36/1.. - LAB",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-421459OU,OU=Oddeleni fyziologie a imunologie zivocichu,OU=Ustav experimentalni biologie,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-421459OU",
                                  "Description": "D36/1S16 - Harnos",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-421459,OU=UKB-D-421459OU,OU=Oddeleni fyziologie a imunologie zivocichu,OU=Ustav experimentalni biologie,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-421459",
                                      "Description": "D36/1S16 - Harnos",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-421463OU,OU=Oddeleni fyziologie a imunologie zivocichu,OU=Ustav experimentalni biologie,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-421463OU",
                                  "Description": "EN - D36/1S14 -DSP",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-421463,OU=UKB-D-421463OU,OU=Oddeleni fyziologie a imunologie zivocichu,OU=Ustav experimentalni biologie,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-421463",
                                      "Description": "EN - D36/1S14 -DSP",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-421457OU,OU=Oddeleni fyziologie a imunologie zivocichu,OU=Ustav experimentalni biologie,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-421457OU",
                                  "Description": "EN - D36/1S14 - DSP",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-421457,OU=UKB-D-421457OU,OU=Oddeleni fyziologie a imunologie zivocichu,OU=Ustav experimentalni biologie,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-421457",
                                      "Description": "EN - D36/1S14 - DSP",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-457500OU,OU=Oddeleni fyziologie a imunologie zivocichu,OU=Ustav experimentalni biologie,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-457500OU",
                                  "Description": "EN - D36/122 - LAB",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-457500,OU=UKB-D-457500OU,OU=Oddeleni fyziologie a imunologie zivocichu,OU=Ustav experimentalni biologie,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-457500",
                                      "Description": "EN - D36/122 - LAB",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-396720OU,OU=Oddeleni fyziologie a imunologie zivocichu,OU=Ustav experimentalni biologie,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-396720OU",
                                  "Description": "D36/124 - Slabakova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-396720,OU=UKB-D-396720OU,OU=Oddeleni fyziologie a imunologie zivocichu,OU=Ustav experimentalni biologie,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-396720",
                                      "Description": "D36/124 - Slabakova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-466165OU,OU=Oddeleni fyziologie a imunologie zivocichu,OU=Ustav experimentalni biologie,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-466165OU",
                                  "Description": "D36/124 - Prochazkova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-466165,OU=UKB-D-466165OU,OU=Oddeleni fyziologie a imunologie zivocichu,OU=Ustav experimentalni biologie,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-466165",
                                      "Description": "D36/124 - Prochazkova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-461866OU,OU=Oddeleni fyziologie a imunologie zivocichu,OU=Ustav experimentalni biologie,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-461866OU",
                                  "Description": "EN - D36/??? - Vacha",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-461866,OU=UKB-D-461866OU,OU=Oddeleni fyziologie a imunologie zivocichu,OU=Ustav experimentalni biologie,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-461866",
                                      "Description": "EN - D36/??? - Vacha",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-461865OU,OU=Oddeleni fyziologie a imunologie zivocichu,OU=Ustav experimentalni biologie,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-461865OU",
                                  "Description": "EN - D36/??? - Vacha",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-461865,OU=UKB-D-461865OU,OU=Oddeleni fyziologie a imunologie zivocichu,OU=Ustav experimentalni biologie,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-461865",
                                      "Description": "EN - D36/??? - Vacha",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                }
                              ],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=Oddeleni genetiky a molekularni biologie,OU=Ustav experimentalni biologie,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Oddeleni genetiky a molekularni biologie",
                              "Description": null,
                              "OrganizationalUnits": [
                                {
                                  "DistinguishedName": "OU=UKB-D-453617OU,OU=Oddeleni genetiky a molekularni biologie,OU=Ustav experimentalni biologie,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-453617OU",
                                  "Description": "E25/212 - Kuntova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-453617,OU=UKB-D-453617OU,OU=Oddeleni genetiky a molekularni biologie,OU=Ustav experimentalni biologie,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-453617",
                                      "Description": "E25/212 - Kuntova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-391770OU,OU=Oddeleni genetiky a molekularni biologie,OU=Ustav experimentalni biologie,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-391770OU",
                                  "Description": "W10 - E25/213 - Kadankova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-391770,OU=UKB-D-391770OU,OU=Oddeleni genetiky a molekularni biologie,OU=Ustav experimentalni biologie,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-391770",
                                      "Description": "W10 - E25/213 - Kadankova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-435472OU,OU=Oddeleni genetiky a molekularni biologie,OU=Ustav experimentalni biologie,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-435472OU",
                                  "Description": "E25/239 - Finstrlova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-435472,OU=UKB-D-435472OU,OU=Oddeleni genetiky a molekularni biologie,OU=Ustav experimentalni biologie,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-435472",
                                      "Description": "E25/239 - Finstrlova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-453616OU,OU=Oddeleni genetiky a molekularni biologie,OU=Ustav experimentalni biologie,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-453616OU",
                                  "Description": "E25/243 - Koudelkova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-453616,OU=UKB-D-453616OU,OU=Oddeleni genetiky a molekularni biologie,OU=Ustav experimentalni biologie,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-453616",
                                      "Description": "E25/243 - Koudelkova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-421776OU,OU=Oddeleni genetiky a molekularni biologie,OU=Ustav experimentalni biologie,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-421776OU",
                                  "Description": "D36/311 - Rousova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-421776,OU=UKB-D-421776OU,OU=Oddeleni genetiky a molekularni biologie,OU=Ustav experimentalni biologie,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-421776",
                                      "Description": "D36/311 - Rousova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-428535OU,OU=Oddeleni genetiky a molekularni biologie,OU=Ustav experimentalni biologie,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-428535OU",
                                  "Description": "E25/212 - Maslanova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-428535,OU=UKB-D-428535OU,OU=Oddeleni genetiky a molekularni biologie,OU=Ustav experimentalni biologie,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-428535",
                                      "Description": "E25/212 - Maslanova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-365554OU,OU=Oddeleni genetiky a molekularni biologie,OU=Ustav experimentalni biologie,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-365554OU",
                                  "Description": "W10/C13/323 - DSP",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-365554,OU=UKB-D-365554OU,OU=Oddeleni genetiky a molekularni biologie,OU=Ustav experimentalni biologie,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-365554",
                                      "Description": "W10/C13/323 - DSP",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-346062OU,OU=Oddeleni genetiky a molekularni biologie,OU=Ustav experimentalni biologie,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-346062OU",
                                  "Description": "W10/C13/323 - Lukjanova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-346062,OU=UKB-D-346062OU,OU=Oddeleni genetiky a molekularni biologie,OU=Ustav experimentalni biologie,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-346062",
                                      "Description": "W10/C13/323 - Lukjanova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-440083OU,OU=Oddeleni genetiky a molekularni biologie,OU=Ustav experimentalni biologie,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-440083OU",
                                  "Description": "C13/322 - Vallova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-440083,OU=UKB-D-440083OU,OU=Oddeleni genetiky a molekularni biologie,OU=Ustav experimentalni biologie,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-440083",
                                      "Description": "C13/322 - Vallova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-461263OU,OU=Oddeleni genetiky a molekularni biologie,OU=Ustav experimentalni biologie,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-461263OU",
                                  "Description": "E25/245 - Bunaticka",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-461263,OU=UKB-D-461263OU,OU=Oddeleni genetiky a molekularni biologie,OU=Ustav experimentalni biologie,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-461263",
                                      "Description": "E25/245 - Bunaticka",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-436348OU,OU=Oddeleni genetiky a molekularni biologie,OU=Ustav experimentalni biologie,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-436348OU",
                                  "Description": "C13/322 - Kuglik",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-436348,OU=UKB-D-436348OU,OU=Oddeleni genetiky a molekularni biologie,OU=Ustav experimentalni biologie,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-436348",
                                      "Description": "C13/322 - Kuglik",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-467066OU,OU=Oddeleni genetiky a molekularni biologie,OU=Ustav experimentalni biologie,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-467066OU",
                                  "Description": "D36/211 - Laborator",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-467066,OU=UKB-D-467066OU,OU=Oddeleni genetiky a molekularni biologie,OU=Ustav experimentalni biologie,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-467066",
                                      "Description": "D36/211 - Laborator",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-474425OU,OU=Oddeleni genetiky a molekularni biologie,OU=Ustav experimentalni biologie,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-474425OU",
                                  "Description": "C13/323 - Trbusek 133123",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-474425,OU=UKB-D-474425OU,OU=Oddeleni genetiky a molekularni biologie,OU=Ustav experimentalni biologie,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-474425",
                                      "Description": "C13/323 - Trbusek 133123",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-428236OU,OU=Oddeleni genetiky a molekularni biologie,OU=Ustav experimentalni biologie,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-428236OU",
                                  "Description": "E25/212 - Koseckova Micenkova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-428236,OU=UKB-D-428236OU,OU=Oddeleni genetiky a molekularni biologie,OU=Ustav experimentalni biologie,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-428236",
                                      "Description": "E25/212 - Koseckova Micenkova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-413309OU,OU=Oddeleni genetiky a molekularni biologie,OU=Ustav experimentalni biologie,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-413309OU",
                                  "Description": "W10/E25/238 - Doskar",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-413309,OU=UKB-D-413309OU,OU=Oddeleni genetiky a molekularni biologie,OU=Ustav experimentalni biologie,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-413309",
                                      "Description": "W10/E25/238 - Doskar",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-428534OU,OU=Oddeleni genetiky a molekularni biologie,OU=Ustav experimentalni biologie,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-428534OU",
                                  "Description": "C13/323 - Urbankova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-428534,OU=UKB-D-428534OU,OU=Oddeleni genetiky a molekularni biologie,OU=Ustav experimentalni biologie,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-428534",
                                      "Description": "C13/323 - Urbankova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-456157OU,OU=Oddeleni genetiky a molekularni biologie,OU=Ustav experimentalni biologie,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-456157OU",
                                  "Description": "C13/324 - Repkova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-456157,OU=UKB-D-456157OU,OU=Oddeleni genetiky a molekularni biologie,OU=Ustav experimentalni biologie,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-456157",
                                      "Description": "C13/324 - Repkova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-467701OU,OU=Oddeleni genetiky a molekularni biologie,OU=Ustav experimentalni biologie,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-467701OU",
                                  "Description": "D36/328 - Knopfova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-467701,OU=UKB-D-467701OU,OU=Oddeleni genetiky a molekularni biologie,OU=Ustav experimentalni biologie,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-467701",
                                      "Description": "D36/328 - Knopfova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-441475OU,OU=Oddeleni genetiky a molekularni biologie,OU=Ustav experimentalni biologie,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-441475OU",
                                  "Description": "D36/328 - Kohoutek",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-441475,OU=UKB-D-441475OU,OU=Oddeleni genetiky a molekularni biologie,OU=Ustav experimentalni biologie,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-441475",
                                      "Description": "D36/328 - Kohoutek",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                }
                              ],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=Oddeleni mikrobiologie,OU=Ustav experimentalni biologie,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Oddeleni mikrobiologie",
                              "Description": null,
                              "OrganizationalUnits": [
                                {
                                  "DistinguishedName": "OU=UKB-D-466513OU,OU=Oddeleni mikrobiologie,OU=Ustav experimentalni biologie,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-466513OU",
                                  "Description": "E25/311 - Cerna",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-466513,OU=UKB-D-466513OU,OU=Oddeleni mikrobiologie,OU=Ustav experimentalni biologie,OU=Ustavy,OU=SCI,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-466513",
                                      "Description": "E25/311 - Cerna",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                }
                              ],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    }
                  ],
                  "Computers": [],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=SUKB,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "SUKB",
                  "Description": null,
                  "OrganizationalUnits": [
                    {
                      "DistinguishedName": "OU=Centrum informacnich technologii,OU=SUKB,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Centrum informacnich technologii",
                      "Description": null,
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=UKB-D-397632OU,OU=Centrum informacnich technologii,OU=SUKB,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-397632OU",
                          "Description": "W10/B22/307 - Test PC TW Puchner",
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=UKB-D-397632,OU=UKB-D-397632OU,OU=Centrum informacnich technologii,OU=SUKB,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-397632",
                              "Description": "W10/B22/307 - Test PC TW Puchner",
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=UKB-D-465285OU,OU=Centrum informacnich technologii,OU=SUKB,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-465285OU",
                          "Description": "B22/307 - Puchner",
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=UKB-D-465285,OU=UKB-D-465285OU,OU=Centrum informacnich technologii,OU=SUKB,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-465285",
                              "Description": "B22/307 - Puchner",
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=UKB-D-456325OU,OU=Centrum informacnich technologii,OU=SUKB,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-456325OU",
                          "Description": "B22/304 - Ruprecht",
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=UKB-D-456325,OU=UKB-D-456325OU,OU=Centrum informacnich technologii,OU=SUKB,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-456325",
                              "Description": "B22/304 - Ruprecht",
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=UKB-D-456324OU,OU=Centrum informacnich technologii,OU=SUKB,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-456324OU",
                          "Description": "B22/304 - Fedrsel",
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=UKB-D-456324,OU=UKB-D-456324OU,OU=Centrum informacnich technologii,OU=SUKB,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-456324",
                              "Description": "B22/304 - Fedrsel",
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=UKB-D-399713OU,OU=Centrum informacnich technologii,OU=SUKB,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-399713OU",
                          "Description": "B22/308 - Milan",
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=UKB-D-399713,OU=UKB-D-399713OU,OU=Centrum informacnich technologii,OU=SUKB,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-399713",
                              "Description": "B22/308 - Milan",
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=Knihovna univerzitniho kampusu,OU=SUKB,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Knihovna univerzitniho kampusu",
                      "Description": null,
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=UKB-D-454303OU,OU=Knihovna univerzitniho kampusu,OU=SUKB,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-454303OU",
                          "Description": "B09/121 - Moravcova",
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=UKB-D-454303,OU=UKB-D-454303OU,OU=Knihovna univerzitniho kampusu,OU=SUKB,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-454303",
                              "Description": "B09/121 - Moravcova",
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=UKB-D-471772OU,OU=Knihovna univerzitniho kampusu,OU=SUKB,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-471772OU",
                          "Description": "B09/336 - Pliskova",
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=UKB-D-471772,OU=UKB-D-471772OU,OU=Knihovna univerzitniho kampusu,OU=SUKB,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-471772",
                              "Description": "B09/336 - Pliskova",
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=UKB-D-443582OU,OU=Knihovna univerzitniho kampusu,OU=SUKB,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-443582OU",
                          "Description": "B09/248 - Foltynova",
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=UKB-D-443582,OU=UKB-D-443582OU,OU=Knihovna univerzitniho kampusu,OU=SUKB,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-443582",
                              "Description": "B09/248 - Foltynova",
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=UKB-D-454304OU,OU=Knihovna univerzitniho kampusu,OU=SUKB,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-454304OU",
                          "Description": "B09/210 \u2013 Gregrova",
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=UKB-D-454304,OU=UKB-D-454304OU,OU=Knihovna univerzitniho kampusu,OU=SUKB,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-454304",
                              "Description": "B09/210 \u2013 Gregrova",
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=UKB-D-454308OU,OU=Knihovna univerzitniho kampusu,OU=SUKB,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-454308OU",
                          "Description": "B09/121 \u2013 Sebestova",
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=UKB-D-454308,OU=UKB-D-454308OU,OU=Knihovna univerzitniho kampusu,OU=SUKB,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-454308",
                              "Description": "B09/121 \u2013 Sebestova",
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=UKB-D-454309OU,OU=Knihovna univerzitniho kampusu,OU=SUKB,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-454309OU",
                          "Description": "B09/314 - Fialova",
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=UKB-D-454309,OU=UKB-D-454309OU,OU=Knihovna univerzitniho kampusu,OU=SUKB,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-454309",
                              "Description": "B09/314 - Fialova",
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=UKB-D-454310OU,OU=Knihovna univerzitniho kampusu,OU=SUKB,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-454310OU",
                          "Description": "B09/312 \u2013 Vasicek \u2013 Adobe Acrobat 2020",
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=UKB-D-454310,OU=UKB-D-454310OU,OU=Knihovna univerzitniho kampusu,OU=SUKB,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-454310",
                              "Description": "B09/312 \u2013 Vasicek \u2013 Adobe Acrobat 2020",
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=UKB-D-454305OU,OU=Knihovna univerzitniho kampusu,OU=SUKB,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-454305OU",
                          "Description": "B09/210 \u2013 Pelzova",
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=UKB-D-454305,OU=UKB-D-454305OU,OU=Knihovna univerzitniho kampusu,OU=SUKB,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-454305",
                              "Description": "B09/210 \u2013 Pelzova",
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=UKB-D-472593OU,OU=Knihovna univerzitniho kampusu,OU=SUKB,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-472593OU",
                          "Description": "B09/314 - Abrahamova",
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=UKB-D-472593,OU=UKB-D-472593OU,OU=Knihovna univerzitniho kampusu,OU=SUKB,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-472593",
                              "Description": "B09/314 - Abrahamova",
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=UKB-D-454311OU,OU=Knihovna univerzitniho kampusu,OU=SUKB,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-454311OU",
                          "Description": "B09/327 - Vartecka",
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=UKB-D-454311,OU=UKB-D-454311OU,OU=Knihovna univerzitniho kampusu,OU=SUKB,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-454311",
                              "Description": "B09/327 - Vartecka",
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=UKB-D-454306OU,OU=Knihovna univerzitniho kampusu,OU=SUKB,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-454306OU",
                          "Description": "B09/211 - Drimalova Adobe CS6",
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=UKB-D-454306,OU=UKB-D-454306OU,OU=Knihovna univerzitniho kampusu,OU=SUKB,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-454306",
                              "Description": "B09/211 - Drimalova Adobe CS6",
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=UKB-D-373434OU,OU=Knihovna univerzitniho kampusu,OU=SUKB,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-373434OU",
                          "Description": "W10 - B09/113 \u2013 Hnilicka",
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=UKB-D-373434,OU=UKB-D-373434OU,OU=Knihovna univerzitniho kampusu,OU=SUKB,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-373434",
                              "Description": "W10 - B09/113 \u2013 Hnilicka",
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=UKB-D-450849OU,OU=Knihovna univerzitniho kampusu,OU=SUKB,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-450849OU",
                          "Description": "B09/248 - Justova",
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=UKB-D-450849,OU=UKB-D-450849OU,OU=Knihovna univerzitniho kampusu,OU=SUKB,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-450849",
                              "Description": "B09/248 - Justova",
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=UKB-D-450848OU,OU=Knihovna univerzitniho kampusu,OU=SUKB,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-450848OU",
                          "Description": "B09/336 \u2013 Mensikova",
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=UKB-D-450848,OU=UKB-D-450848OU,OU=Knihovna univerzitniho kampusu,OU=SUKB,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-450848",
                              "Description": "B09/336 \u2013 Mensikova",
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=UKB-D-440040OU,OU=Knihovna univerzitniho kampusu,OU=SUKB,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-440040OU",
                          "Description": "B09/210 - Hrazdirova",
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=UKB-D-440040,OU=UKB-D-440040OU,OU=Knihovna univerzitniho kampusu,OU=SUKB,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-440040",
                              "Description": "B09/210 - Hrazdirova",
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=UKB-D-441980OU,OU=Knihovna univerzitniho kampusu,OU=SUKB,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-441980OU",
                          "Description": "B09/326 - Stodulkova",
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=UKB-D-441980,OU=UKB-D-441980OU,OU=Knihovna univerzitniho kampusu,OU=SUKB,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-441980",
                              "Description": "B09/326 - Stodulkova",
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=UKB-D-454307OU,OU=Knihovna univerzitniho kampusu,OU=SUKB,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-454307OU",
                          "Description": "B09/211 - volny",
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=UKB-D-454307,OU=UKB-D-454307OU,OU=Knihovna univerzitniho kampusu,OU=SUKB,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-454307",
                              "Description": "B09/211 - volny",
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=Sprava budov,OU=SUKB,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Sprava budov",
                      "Description": "DWG",
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=B06,OU=Sprava budov,OU=SUKB,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "B06",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=UKB-D-462714OU,OU=B06,OU=Sprava budov,OU=SUKB,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-462714OU",
                              "Description": "B06/1S06 - Hudec",
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=UKB-D-462714,OU=UKB-D-462714OU,OU=B06,OU=Sprava budov,OU=SUKB,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-462714",
                                  "Description": "B06/1S06 - Hudec",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=UKB-D-395214OU,OU=B06,OU=Sprava budov,OU=SUKB,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-395214OU",
                              "Description": "B06/1S06 - Sedlackova 254550",
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=UKB-D-395214,OU=UKB-D-395214OU,OU=B06,OU=Sprava budov,OU=SUKB,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-395214",
                                  "Description": "B06/1S06 - Sedlackova 254550",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=A20,OU=Sprava budov,OU=SUKB,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "A20",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=UKB-D-462715OU,OU=A20,OU=Sprava budov,OU=SUKB,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-462715OU",
                              "Description": "A20/1S08 - Svetlik",
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=UKB-D-462715,OU=UKB-D-462715OU,OU=A20,OU=Sprava budov,OU=SUKB,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-462715",
                                  "Description": "A20/1S08 - Svetlik",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=B17,OU=Sprava budov,OU=SUKB,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "B17",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=UKB-D-REMUNDOU,OU=B17,OU=Sprava budov,OU=SUKB,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-REMUNDOU",
                              "Description": "B17/118 - Remundova",
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=UKB-D-REMUND,OU=UKB-D-REMUNDOU,OU=B17,OU=Sprava budov,OU=SUKB,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-REMUND",
                                  "Description": "B17/118 - Remundova",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=UKB-D-395213OU,OU=B17,OU=Sprava budov,OU=SUKB,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-395213OU",
                              "Description": "B17/112 - Grancic",
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=UKB-D-395213,OU=UKB-D-395213OU,OU=B17,OU=Sprava budov,OU=SUKB,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-395213",
                                  "Description": "B17/112 - Grancic",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=Utvar bezpecnosti a ostrahy,OU=SUKB,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Utvar bezpecnosti a ostrahy",
                      "Description": "DWG",
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=UKB-D-462707OU,OU=Utvar bezpecnosti a ostrahy,OU=SUKB,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-462707OU",
                          "Description": "B09/213 - Kocenda",
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=UKB-D-462707,OU=UKB-D-462707OU,OU=Utvar bezpecnosti a ostrahy,OU=SUKB,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-462707",
                              "Description": "B09/213 - Kocenda",
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=Utvar reditele,OU=SUKB,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Utvar reditele",
                      "Description": null,
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=UKB-D-462709OU,OU=Utvar reditele,OU=SUKB,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-462709OU",
                          "Description": "B17/112 - Kachlikova - DWG",
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=UKB-D-462709,OU=UKB-D-462709OU,OU=Utvar reditele,OU=SUKB,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-462709",
                              "Description": "B17/112 - Kachlikova - DWG",
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=UKB-D-462710OU,OU=Utvar reditele,OU=SUKB,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-462710OU",
                          "Description": "B17/114 - Knurova - Adobe Pro 10",
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=UKB-D-462710,OU=UKB-D-462710OU,OU=Utvar reditele,OU=SUKB,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-462710",
                              "Description": "B17/114 - Knurova - Adobe Pro 10",
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=UKB-D-462711OU,OU=Utvar reditele,OU=SUKB,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-462711OU",
                          "Description": "B17/114 - Slavickova - Adobe Pro 10",
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=UKB-D-462711,OU=UKB-D-462711OU,OU=Utvar reditele,OU=SUKB,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-462711",
                              "Description": "B17/114 - Slavickova - Adobe Pro 10",
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=UKB-D-462712OU,OU=Utvar reditele,OU=SUKB,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-462712OU",
                          "Description": "B17/114 - Gebaureova - Adobe Pro 10",
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=UKB-D-462712,OU=UKB-D-462712OU,OU=Utvar reditele,OU=SUKB,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-462712",
                              "Description": "B17/114 - Gebaureova - Adobe Pro 10",
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=UKB-D-462713OU,OU=Utvar reditele,OU=SUKB,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-462713OU",
                          "Description": "B17/116 - Fnukalova",
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=UKB-D-462713,OU=UKB-D-462713OU,OU=Utvar reditele,OU=SUKB,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-462713",
                              "Description": "B17/116 - Fnukalova",
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    }
                  ],
                  "Computers": [],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=TEST PC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "TEST PC",
                  "Description": null,
                  "OrganizationalUnits": [
                    {
                      "DistinguishedName": "OU=UKB-D-335857OU,OU=TEST PC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-D-335857OU",
                      "Description": "testovaci PC Kuba",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-335857,OU=UKB-D-335857OU,OU=TEST PC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-335857",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=UKB-D-421325OU,OU=TEST PC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-D-421325OU",
                      "Description": "testovaci PC Tom F",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-421325,OU=UKB-D-421325OU,OU=TEST PC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-421325",
                          "Description": "TF TEST PC",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=UKB-D-335817OU,OU=TEST PC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-D-335817OU",
                      "Description": "testovaci PC Kuba",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-335817,OU=UKB-D-335817OU,OU=TEST PC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-335817",
                          "Description": "Kuba PC TEST",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    }
                  ],
                  "Computers": [],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "CEITEC",
                  "Description": null,
                  "OrganizationalUnits": [
                    {
                      "DistinguishedName": "OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Mendelovo centrum genomiky a proteomiky rostlin",
                      "Description": null,
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=Centralni laborator Proteomika,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "Centralni laborator Proteomika",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=Laborator rostlinneho vyzkumu,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "Laborator rostlinneho vyzkumu",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=UKB-D-426093OU,OU=Laborator rostlinneho vyzkumu,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-426093OU",
                              "Description": "C02/324 - Novotna",
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=UKB-D-426093,OU=UKB-D-426093OU,OU=Laborator rostlinneho vyzkumu,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-426093",
                                  "Description": "C02/324 - Novotna",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=UKB-D-416751OU,OU=Laborator rostlinneho vyzkumu,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-416751OU",
                              "Description": "C02/324 - Smeringai",
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=UKB-D-416751,OU=UKB-D-416751OU,OU=Laborator rostlinneho vyzkumu,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-416751",
                                  "Description": "C02/324 - Smeringai",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=VS Helene Robert Boisivon,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "VS Helene Robert Boisivon",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=UKB-D-467617OU,OU=VS Helene Robert Boisivon,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-467617OU",
                              "Description": "EN - E26/216 - Guennich",
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=UKB-D-467617,OU=UKB-D-467617OU,OU=VS Helene Robert Boisivon,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-467617",
                                  "Description": "EN - E26/216 - Guennich",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=UKB-D-467616OU,OU=VS Helene Robert Boisivon,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-467616OU",
                              "Description": "EN - E26/216 - Belaidi",
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=UKB-D-467616,OU=UKB-D-467616OU,OU=VS Helene Robert Boisivon,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-467616",
                                  "Description": "EN - E26/216 - Belaidi",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=UKB-D-467615OU,OU=VS Helene Robert Boisivon,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-467615OU",
                              "Description": "EN - E26/216 - Paraiso",
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=UKB-D-467615,OU=UKB-D-467615OU,OU=VS Helene Robert Boisivon,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-467615",
                                  "Description": "EN - E26/216 - Paraiso",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=UKB-D-374964OU,OU=VS Helene Robert Boisivon,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-374964OU",
                              "Description": "W10 - E26/216 - Sedlacek",
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=UKB-D-374964,OU=UKB-D-374964OU,OU=VS Helene Robert Boisivon,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-374964",
                                  "Description": "W10 - E26/216 - Sedlacek",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=UKB-D-473295OU,OU=VS Helene Robert Boisivon,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-473295OU",
                              "Description": "E26/216 - Sedlacek",
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=UKB-D-473295,OU=UKB-D-473295OU,OU=VS Helene Robert Boisivon,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-473295",
                                  "Description": "E26/216 - Sedlacek",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=UKB-D-473297OU,OU=VS Helene Robert Boisivon,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-473297OU",
                              "Description": "E26/216 - Svihlova",
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=UKB-D-473297,OU=UKB-D-473297OU,OU=VS Helene Robert Boisivon,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-473297",
                                  "Description": "E26/216 - Svihlova",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=UKB-D-473298OU,OU=VS Helene Robert Boisivon,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-473298OU",
                              "Description": "E26/216 - Stefkova",
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=UKB-D-473298,OU=UKB-D-473298OU,OU=VS Helene Robert Boisivon,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-473298",
                                  "Description": "E26/216 - Stefkova",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=UKB-D-473299OU,OU=VS Helene Robert Boisivon,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-473299OU",
                              "Description": "E26/216 - Macova",
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=UKB-D-473299,OU=UKB-D-473299OU,OU=VS Helene Robert Boisivon,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-473299",
                                  "Description": "E26/216 - Macova",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=UKB-D-473300OU,OU=VS Helene Robert Boisivon,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-473300OU",
                              "Description": "E26/216 - Pukysova",
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=UKB-D-473300,OU=UKB-D-473300OU,OU=VS Helene Robert Boisivon,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-473300",
                                  "Description": "E26/216 - Pukysova",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=UKB-D-418168OU,OU=VS Helene Robert Boisivon,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-418168OU",
                              "Description": "EN - E26/216 - Prabhullachandran",
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=UKB-D-418168,OU=UKB-D-418168OU,OU=VS Helene Robert Boisivon,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-418168",
                                  "Description": "EN - E26/216 - Prabhullachandran",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=VS Jana Hejatka,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "VS Jana Hejatka",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=UKB-D-449916OU,OU=VS Jana Hejatka,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-449916OU",
                              "Description": "C02/312 - Melnikava EN",
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=UKB-D-449916,OU=UKB-D-449916OU,OU=VS Jana Hejatka,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-449916",
                                  "Description": "C02/312 - Melnikava EN",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=UKB-D-454331OU,OU=VS Jana Hejatka,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-454331OU",
                              "Description": "C02/341 - Laborator RDP EN / Prism",
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=UKB-D-454331,OU=UKB-D-454331OU,OU=VS Jana Hejatka,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-454331",
                                  "Description": "C02/341 - Laborator RDP EN / Prism",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=UKB-D-454332OU,OU=VS Jana Hejatka,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-454332OU",
                              "Description": "C02/341 - Laborator RDP EN / Prism",
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=UKB-D-454332,OU=UKB-D-454332OU,OU=VS Jana Hejatka,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-454332",
                                  "Description": "C02/341 - Laborator RDP EN / Prism",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=UKB-D-449917OU,OU=VS Jana Hejatka,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-449917OU",
                              "Description": "C02/314 - Pekarova",
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=UKB-D-449917,OU=UKB-D-449917OU,OU=VS Jana Hejatka,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-449917",
                                  "Description": "C02/314 - Pekarova",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=UKB-D-301648OU,OU=VS Jana Hejatka,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-301648OU",
                              "Description": "C02/325 - Hejatkova EN OLD",
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=UKB-D-301648,OU=UKB-D-301648OU,OU=VS Jana Hejatka,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-301648",
                                  "Description": "C02/325 - Hejatkova EN OLD",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=UKB-D-210355OU,OU=VS Jana Hejatka,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-210355OU",
                              "Description": "W10/C02-325 - Urbankova",
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=UKB-D-210355,OU=UKB-D-210355OU,OU=VS Jana Hejatka,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-210355",
                                  "Description": "W10/C02/325 - Urbankova",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=UKB-D-449919OU,OU=VS Jana Hejatka,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-449919OU",
                              "Description": "C02/315 - Tomovicova EN",
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=UKB-D-449919,OU=UKB-D-449919OU,OU=VS Jana Hejatka,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-449919",
                                  "Description": "C02/315 - Tomovicova EN",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=UKB-D-449920OU,OU=VS Jana Hejatka,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-449920OU",
                              "Description": "C02/344 - Hejatkova EN",
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=UKB-D-449920,OU=UKB-D-449920OU,OU=VS Jana Hejatka,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-449920",
                                  "Description": "C02/344 - Hejatkova EN",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=UKB-D-449918OU,OU=VS Jana Hejatka,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-449918OU",
                              "Description": "C02/314 \u2013 Pushkarova EN",
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=UKB-D-449918,OU=UKB-D-449918OU,OU=VS Jana Hejatka,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-449918",
                                  "Description": "C02/314 \u2013 Pushkarova EN",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=UKB-D-454330OU,OU=VS Jana Hejatka,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-454330OU",
                              "Description": "C02/341 - laborator RDP EN",
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=UKB-D-454330,OU=UKB-D-454330OU,OU=VS Jana Hejatka,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-454330",
                                  "Description": "C02/341 - laborator RDP EN",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=VS Jiriho Fajkuse,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "VS Jiriho Fajkuse",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=UKB-D-397878OU,OU=VS Jiriho Fajkuse,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-397878OU",
                              "Description": "W10/C02/111 - DSP",
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=UKB-D-397878,OU=UKB-D-397878OU,OU=VS Jiriho Fajkuse,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-397878",
                                  "Description": "W10/C02/111 - DSP",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=UKB-D-397879OU,OU=VS Jiriho Fajkuse,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-397879OU",
                              "Description": "W10/C02/111 - DSP",
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=UKB-D-397879,OU=UKB-D-397879OU,OU=VS Jiriho Fajkuse,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-397879",
                                  "Description": "W10/C02/111 - DSP",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=UKB-D-426189OU,OU=VS Jiriho Fajkuse,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-426189OU",
                              "Description": "C02/227 - Prerovska",
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=UKB-D-426189,OU=UKB-D-426189OU,OU=VS Jiriho Fajkuse,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-426189",
                                  "Description": "C02/227 - Prerovska",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=UKB-D-401700OU,OU=VS Jiriho Fajkuse,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-401700OU",
                              "Description": "C02/227 - Dadejova",
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=UKB-D-401700,OU=UKB-D-401700OU,OU=VS Jiriho Fajkuse,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-401700",
                                  "Description": "C02/227 - Dadejova",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=UKB-D-454068OU,OU=VS Jiriho Fajkuse,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-454068OU",
                              "Description": "C02/212 - Prochazkova",
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=UKB-D-454068,OU=UKB-D-454068OU,OU=VS Jiriho Fajkuse,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-454068",
                                  "Description": "C02/212 - Prochazkova",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=VS Karla Rihy,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "VS Karla Rihy",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=UKB-D-450997OU,OU=VS Karla Rihy,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-450997OU",
                              "Description": "E26/209 - Riha",
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=UKB-D-450997,OU=UKB-D-450997OU,OU=VS Karla Rihy,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-450997",
                                  "Description": "E26/209 - Riha",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=UKB-D-449755OU,OU=VS Karla Rihy,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-449755OU",
                              "Description": "EN/E26/214 - Calzada",
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=UKB-D-449755,OU=UKB-D-449755OU,OU=VS Karla Rihy,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-449755",
                                  "Description": "EN/E26/214 - Calzada",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=UKB-D-449756OU,OU=VS Karla Rihy,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-449756OU",
                              "Description": "E26/214 - Fulnecek",
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=UKB-D-449756,OU=UKB-D-449756OU,OU=VS Karla Rihy,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-449756",
                                  "Description": "E26/214 - Fulnecek",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=UKB-D-392757OU,OU=VS Karla Rihy,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-392757OU",
                              "Description": "EN/E26/214 - Volkova",
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=UKB-D-392757,OU=UKB-D-392757OU,OU=VS Karla Rihy,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-392757",
                                  "Description": "EN/E26/214 - Volkova",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=UKB-D-461330OU,OU=VS Karla Rihy,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-461330OU",
                              "Description": "E26/214 - Mikulkova",
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=UKB-D-461330,OU=UKB-D-461330OU,OU=VS Karla Rihy,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-461330",
                                  "Description": "E26/214 - Mikulkova",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=UKB-D-451294OU,OU=VS Karla Rihy,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-451294OU",
                              "Description": "E26/214 - Faturova",
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=UKB-D-451294,OU=UKB-D-451294OU,OU=VS Karla Rihy,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-451294",
                                  "Description": "E26/214 - Faturova",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=UKB-D-461327OU,OU=VS Karla Rihy,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-461327OU",
                              "Description": "EN/E26/214 - Saddala",
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=UKB-D-461327,OU=UKB-D-461327OU,OU=VS Karla Rihy,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-461327",
                                  "Description": "EN/E26/214 - Saddala",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=UKB-D-461328OU,OU=VS Karla Rihy,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-461328OU",
                              "Description": "EN/E26/214 - DSP",
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=UKB-D-461328,OU=UKB-D-461328OU,OU=VS Karla Rihy,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-461328",
                                  "Description": "EN/E26/214 - DSP",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=UKB-D-461329OU,OU=VS Karla Rihy,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-461329OU",
                              "Description": "E26/214 - Brolik",
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=UKB-D-461329,OU=UKB-D-461329OU,OU=VS Karla Rihy,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-461329",
                                  "Description": "E26/214 - Brolik",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=UKB-D-421894OU,OU=VS Karla Rihy,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-421894OU",
                              "Description": "EN/E26/207 \u2013 Fulneckova",
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=UKB-D-421894,OU=UKB-D-421894OU,OU=VS Karla Rihy,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-421894",
                                  "Description": "EN/E26/207 \u2013 Fulneckova",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=UKB-D-374961OU,OU=VS Karla Rihy,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-374961OU",
                              "Description": "EN/E26/207 - DSP",
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=UKB-D-374961,OU=UKB-D-374961OU,OU=VS Karla Rihy,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-374961",
                                  "Description": "EN/E26/207 - DSP",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=UKB-D-374962OU,OU=VS Karla Rihy,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-374962OU",
                              "Description": "E26/214 - Fulnecek zalozni PC",
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=UKB-D-374962,OU=UKB-D-374962OU,OU=VS Karla Rihy,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-374962",
                                  "Description": "E26/214 - Fulnecek zalozni PC",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=VS Martina Lysaka,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "VS Martina Lysaka",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=UKB-D-374931OU,OU=VS Martina Lysaka,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-374931OU",
                              "Description": "E26/329 - DSP",
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=UKB-D-374931,OU=UKB-D-374931OU,OU=VS Martina Lysaka,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-374931",
                                  "Description": "E26/329 - DSP",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=UKB-D-383349OU,OU=VS Martina Lysaka,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-383349OU",
                              "Description": "E26/329 - DSP",
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=UKB-D-383349,OU=UKB-D-383349OU,OU=VS Martina Lysaka,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-383349",
                                  "Description": "E26/329 - DSP",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=UKB-D-347487OU,OU=VS Martina Lysaka,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-347487OU",
                              "Description": "W10/E26/321 - Coufalova",
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=UKB-D-347487,OU=UKB-D-347487OU,OU=VS Martina Lysaka,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-347487",
                                  "Description": "W10/E26/321 - Coufalova",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=UKB-D-425142OU,OU=VS Martina Lysaka,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-425142OU",
                              "Description": "E26/327 - Lysak",
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=UKB-D-425142,OU=UKB-D-425142OU,OU=VS Martina Lysaka,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-425142",
                                  "Description": "E26/327 - Lysak",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=UKB-D-393710OU,OU=VS Martina Lysaka,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-393710OU",
                              "Description": "W10/E26/329 - DSP",
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=UKB-D-393710,OU=UKB-D-393710OU,OU=VS Martina Lysaka,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-393710",
                                  "Description": "W10/E26/329 - DSP",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=UKB-D-445002OU,OU=VS Martina Lysaka,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-445002OU",
                              "Description": "E26/312 - Kubikova",
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=UKB-D-445002,OU=UKB-D-445002OU,OU=VS Martina Lysaka,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-445002",
                                  "Description": "E26/312 - Kubikova",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=VS Zbynka Zdrahala,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "VS Zbynka Zdrahala",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=UKB-D-384551OU,OU=VS Zbynka Zdrahala,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-384551OU",
                              "Description": "W10/E26/119 - Diplomanti",
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=UKB-D-384551,OU=UKB-D-384551OU,OU=VS Zbynka Zdrahala,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-384551",
                                  "Description": "W10/E26/119 - Diplomanti",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=Bunecne zobrazovani,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "Bunecne zobrazovani",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=UKB-D-460627OU,OU=Bunecne zobrazovani,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-460627OU",
                              "Description": "C02/118 - Brezak",
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=UKB-D-460627,OU=UKB-D-460627OU,OU=Bunecne zobrazovani,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-460627",
                                  "Description": "C02/118 - Brezak",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=UKB-D-469926OU,OU=Bunecne zobrazovani,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-469926OU",
                              "Description": "C02/118 - Legartova",
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=UKB-D-469926,OU=UKB-D-469926OU,OU=Bunecne zobrazovani,OU=Mendelovo centrum genomiky a proteomiky rostlin,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-469926",
                                  "Description": "C02/118 - Legartova",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=Sekce administrativy,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Sekce administrativy",
                      "Description": null,
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=Oddeleni grantove podpory,OU=Sekce administrativy,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "Oddeleni grantove podpory",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=UKB-D-418657OU,OU=Oddeleni grantove podpory,OU=Sekce administrativy,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-418657OU",
                              "Description": "E35/1S028 - DSP",
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=UKB-D-418657,OU=UKB-D-418657OU,OU=Oddeleni grantove podpory,OU=Sekce administrativy,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-418657",
                                  "Description": "E35/1S028 - DSP",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=Oddeleni podpory vedy a inovaci,OU=Sekce administrativy,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "Oddeleni podpory vedy a inovaci",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=Provozni oddeleni,OU=Sekce administrativy,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "Provozni oddeleni",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=UKB-D-425008OU,OU=Sekce administrativy,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-425008OU",
                          "Description": "B22/115 - Urbankova",
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=UKB-D-425008,OU=UKB-D-425008OU,OU=Sekce administrativy,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-425008",
                              "Description": "B22/115 - Urbankova",
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=Ekonomicke oddeleni,OU=Sekce administrativy,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "Ekonomicke oddeleni",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=C02,OU=Ekonomicke oddeleni,OU=Sekce administrativy,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "C02",
                              "Description": null,
                              "OrganizationalUnits": [
                                {
                                  "DistinguishedName": "OU=UKB-D-388052OU,OU=C02,OU=Ekonomicke oddeleni,OU=Sekce administrativy,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-388052OU",
                                  "Description": "C02/323 - Jalova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-388052,OU=UKB-D-388052OU,OU=C02,OU=Ekonomicke oddeleni,OU=Sekce administrativy,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-388052",
                                      "Description": "C02/323 - Jalova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                }
                              ],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=C04,OU=Ekonomicke oddeleni,OU=Sekce administrativy,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "C04",
                              "Description": null,
                              "OrganizationalUnits": [
                                {
                                  "DistinguishedName": "OU=UKB-D-427979OU,OU=C04,OU=Ekonomicke oddeleni,OU=Sekce administrativy,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-427979OU",
                                  "Description": "C04/115 - Holkovicova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-427979,OU=UKB-D-427979OU,OU=C04,OU=Ekonomicke oddeleni,OU=Sekce administrativy,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-427979",
                                      "Description": "C04/115 - Holkovicova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-425007OU,OU=C04,OU=Ekonomicke oddeleni,OU=Sekce administrativy,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-425007OU",
                                  "Description": "C04/115 - Leblochova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-425007,OU=UKB-D-425007OU,OU=C04,OU=Ekonomicke oddeleni,OU=Sekce administrativy,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-425007",
                                      "Description": "C04/115 - Leblochova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                }
                              ],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=E35-1NP,OU=Ekonomicke oddeleni,OU=Sekce administrativy,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "E35-1NP",
                              "Description": null,
                              "OrganizationalUnits": [
                                {
                                  "DistinguishedName": "OU=UKB-D-428026OU,OU=E35-1NP,OU=Ekonomicke oddeleni,OU=Sekce administrativy,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-428026OU",
                                  "Description": "E35/133 - Sadilkova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-428026,OU=UKB-D-428026OU,OU=E35-1NP,OU=Ekonomicke oddeleni,OU=Sekce administrativy,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-428026",
                                      "Description": "E35/133 - Sadilkova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-418653OU,OU=E35-1NP,OU=Ekonomicke oddeleni,OU=Sekce administrativy,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-418653OU",
                                  "Description": "E35/133 - Ambrozova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-418653,OU=UKB-D-418653OU,OU=E35-1NP,OU=Ekonomicke oddeleni,OU=Sekce administrativy,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-418653",
                                      "Description": "E35/133 - Ambrozova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-400306OU,OU=E35-1NP,OU=Ekonomicke oddeleni,OU=Sekce administrativy,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-400306OU",
                                  "Description": "E35/128 - Musilova 254239",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-400306,OU=UKB-D-400306OU,OU=E35-1NP,OU=Ekonomicke oddeleni,OU=Sekce administrativy,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-400306",
                                      "Description": "E35/128 - Musilova 254239",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                }
                              ],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=E35-1S,OU=Ekonomicke oddeleni,OU=Sekce administrativy,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "E35-1S",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=E35-2NP,OU=Ekonomicke oddeleni,OU=Sekce administrativy,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "E35-2NP",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=Personalni oddeleni,OU=Sekce administrativy,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "Personalni oddeleni",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=E35,OU=Personalni oddeleni,OU=Sekce administrativy,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "E35",
                              "Description": null,
                              "OrganizationalUnits": [
                                {
                                  "DistinguishedName": "OU=UKB-D-339117OU,OU=E35,OU=Personalni oddeleni,OU=Sekce administrativy,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-339117OU",
                                  "Description": "E35/1S015 - Bartova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-339117,OU=UKB-D-339117OU,OU=E35,OU=Personalni oddeleni,OU=Sekce administrativy,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-339117",
                                      "Description": "E35/1S015 - Bartova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=UKB-D-427980OU,OU=E35,OU=Personalni oddeleni,OU=Sekce administrativy,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-427980OU",
                                  "Description": "E35/1S015 - Linhartova",
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=UKB-D-427980,OU=UKB-D-427980OU,OU=E35,OU=Personalni oddeleni,OU=Sekce administrativy,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "UKB-D-427980",
                                      "Description": "E35/1S015 - Linhartova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                }
                              ],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=UKB-D-418652OU,OU=Sekce administrativy,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-418652OU",
                          "Description": "E35/1S047 - DSP",
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=UKB-D-418652,OU=UKB-D-418652OU,OU=Sekce administrativy,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-418652",
                              "Description": "E35/1S047 - DSP",
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=Oddeleni realizace akci,OU=Sekce administrativy,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "Oddeleni realizace akci",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=UKB-D-418649OU,OU=Oddeleni realizace akci,OU=Sekce administrativy,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-418649OU",
                              "Description": "E35/1S027 - Fajtlova",
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=UKB-D-418649,OU=UKB-D-418649OU,OU=Oddeleni realizace akci,OU=Sekce administrativy,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-418649",
                                  "Description": "E35/1S027 - Fajtlova",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=UKB-D-418651OU,OU=Oddeleni realizace akci,OU=Sekce administrativy,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-418651OU",
                              "Description": "E35/1S027 - Janickova",
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=UKB-D-418651,OU=UKB-D-418651OU,OU=Oddeleni realizace akci,OU=Sekce administrativy,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-418651",
                                  "Description": "E35/1S027 - Janickova",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=Kancelar reditele,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Kancelar reditele",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=Centrum molekularni mediciny,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Centrum molekularni mediciny",
                      "Description": null,
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=VS Ondreje Slabeho,OU=Centrum molekularni mediciny,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "VS Ondreje Slabeho",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=UKB-D-447571OU,OU=VS Ondreje Slabeho,OU=Centrum molekularni mediciny,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-447571OU",
                              "Description": "E35/225 - Vecera EN",
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=UKB-D-447571,OU=UKB-D-447571OU,OU=VS Ondreje Slabeho,OU=Centrum molekularni mediciny,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-447571",
                                  "Description": "E35/225 - Vecera EN",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=Centralni laborator Genomika,OU=Centrum molekularni mediciny,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "Centralni laborator Genomika",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=UKB-D-471805OU,OU=Centralni laborator Genomika,OU=Centrum molekularni mediciny,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-471805OU",
                              "Description": "E35/255 - Vrzalova",
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=UKB-D-471805,OU=UKB-D-471805OU,OU=Centralni laborator Genomika,OU=Centrum molekularni mediciny,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-471805",
                                  "Description": "E35/255 - Vrzalova",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=UKB-D-439763OU,OU=Centralni laborator Genomika,OU=Centrum molekularni mediciny,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-439763OU",
                              "Description": "E35/255 - Blahakova",
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=UKB-D-439763,OU=UKB-D-439763OU,OU=Centralni laborator Genomika,OU=Centrum molekularni mediciny,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-439763",
                                  "Description": "E35/255 - Blahakova",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=VS Mary OConnell,OU=Centrum molekularni mediciny,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "VS Mary OConnell",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=UKB-D-438738OU,OU=VS Mary OConnell,OU=Centrum molekularni mediciny,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-438738OU",
                              "Description": "EN - E35/143 - Keegan",
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=UKB-D-438738,OU=UKB-D-438738OU,OU=VS Mary OConnell,OU=Centrum molekularni mediciny,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-438738",
                                  "Description": "EN - E35/143 - Keegan",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=UKB-D-438737OU,OU=VS Mary OConnell,OU=Centrum molekularni mediciny,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-438737OU",
                              "Description": "EN - E35/144 - sklad",
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=UKB-D-438737,OU=UKB-D-438737OU,OU=VS Mary OConnell,OU=Centrum molekularni mediciny,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-438737",
                                  "Description": "EN - E35/144 - sklad",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=UKB-D-385748OU,OU=VS Mary OConnell,OU=Centrum molekularni mediciny,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-385748OU",
                              "Description": "W10/E35/263 - Melicherova",
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=UKB-D-385748,OU=UKB-D-385748OU,OU=VS Mary OConnell,OU=Centrum molekularni mediciny,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-385748",
                                  "Description": "W10/E35/263 - Melicherova",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=VS Sarky Pospisilove,OU=Centrum molekularni mediciny,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "VS Sarky Pospisilove",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=UKB-D-419354OU,OU=VS Sarky Pospisilove,OU=Centrum molekularni mediciny,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-419354OU",
                              "Description": "E35/257 - Pospisilova",
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=UKB-D-419354,OU=UKB-D-419354OU,OU=VS Sarky Pospisilove,OU=Centrum molekularni mediciny,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-419354",
                                  "Description": "E35/257 - Pospisilova",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=UKB-D-430436OU,OU=VS Sarky Pospisilove,OU=Centrum molekularni mediciny,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-430436OU",
                              "Description": "E35/258 - Malcikova",
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=UKB-D-430436,OU=UKB-D-430436OU,OU=VS Sarky Pospisilove,OU=Centrum molekularni mediciny,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-430436",
                                  "Description": "E35/258 - Malcikova",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=VS Michala Smidy,OU=Centrum molekularni mediciny,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "VS Michala Smidy",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=UKB-D-454486OU,OU=VS Michala Smidy,OU=Centrum molekularni mediciny,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-454486OU",
                              "Description": "E35/142 - Skrnova",
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=UKB-D-454486,OU=UKB-D-454486OU,OU=VS Michala Smidy,OU=Centrum molekularni mediciny,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-454486",
                                  "Description": "E35/142 - Skrnova",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=Centrum neuroved,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Centrum neuroved",
                      "Description": null,
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=VS Ivana Rektora,OU=Centrum neuroved,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "VS Ivana Rektora",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=UKB-D-424957OU,OU=VS Ivana Rektora,OU=Centrum neuroved,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-424957OU",
                              "Description": "E35/1S064 - Ulcak",
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=UKB-D-424957,OU=UKB-D-424957OU,OU=VS Ivana Rektora,OU=Centrum neuroved,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-424957",
                                  "Description": "E35/1S064 - Ulcak",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=VS Milana Brazdila,OU=Centrum neuroved,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "VS Milana Brazdila",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=UKB-D-429964OU,OU=VS Milana Brazdila,OU=Centrum neuroved,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-429964OU",
                              "Description": "E35/2S111 - Jurkovicova",
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=UKB-D-429964,OU=UKB-D-429964OU,OU=VS Milana Brazdila,OU=Centrum neuroved,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-429964",
                                  "Description": "E35/2S111 - Jurkovicova",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=UKB-D-468508OU,OU=VS Milana Brazdila,OU=Centrum neuroved,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-468508OU",
                              "Description": "E35/1S058 - Goliasova",
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=UKB-D-468508,OU=UKB-D-468508OU,OU=VS Milana Brazdila,OU=Centrum neuroved,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-468508",
                                  "Description": "E35/1S058 - Goliasova",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=UKB-D-411325OU,OU=VS Milana Brazdila,OU=Centrum neuroved,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-411325OU",
                              "Description": "E35/1S101 - Safarikova",
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=UKB-D-411325,OU=UKB-D-411325OU,OU=VS Milana Brazdila,OU=Centrum neuroved,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-411325",
                                  "Description": "E35/1S101 - Safarikova",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=Centrum strukturni biologie,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Centrum strukturni biologie",
                      "Description": null,
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=VS Petra Tesiny,OU=Centrum strukturni biologie,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "VS Petra Tesiny",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=UKB-D-475075OU,OU=VS Petra Tesiny,OU=Centrum strukturni biologie,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-475075OU",
                              "Description": "E35/1S154 - Zapletal 323808",
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=UKB-D-475075,OU=UKB-D-475075OU,OU=VS Petra Tesiny,OU=Centrum strukturni biologie,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-475075",
                                  "Description": "E35/1S154 - Zapletal 323808",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=UKB-D-459541OU,OU=VS Petra Tesiny,OU=Centrum strukturni biologie,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-459541OU",
                              "Description": "E35/1S154 - Slaba",
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=UKB-D-459541,OU=UKB-D-459541OU,OU=VS Petra Tesiny,OU=Centrum strukturni biologie,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-459541",
                                  "Description": "E35/1S154 - Slaba",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=VS Gabriela Dema,OU=Centrum strukturni biologie,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "VS Gabriela Dema",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=UKB-D-426766OU,OU=VS Gabriela Dema,OU=Centrum strukturni biologie,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-426766OU",
                              "Description": "E35/1S086 \u2013 Brabencova",
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=UKB-D-426766,OU=UKB-D-426766OU,OU=VS Gabriela Dema,OU=Centrum strukturni biologie,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-426766",
                                  "Description": "E35/1S086 \u2013 Brabencova",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=UKB-D-433168OU,OU=VS Gabriela Dema,OU=Centrum strukturni biologie,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "UKB-D-433168OU",
                              "Description": "E35/1S086 - Pastuchova",
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=UKB-D-433168,OU=UKB-D-433168OU,OU=VS Gabriela Dema,OU=Centrum strukturni biologie,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "UKB-D-433168",
                                  "Description": "E35/1S086 - Pastuchova",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=VS Lukase Zidka,OU=Centrum strukturni biologie,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "VS Lukase Zidka",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=VS Lukase Trantirka,OU=Centrum strukturni biologie,OU=CEITEC,OU=UKB,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "VS Lukase Trantirka",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    }
                  ],
                  "Computers": [],
                  "Type": "OrganizationalUnit"
                }
              ],
              "Computers": [],
              "Type": "OrganizationalUnit"
            },
            {
              "DistinguishedName": "OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
              "Name": "ICS",
              "Description": null,
              "OrganizationalUnits": [
                {
                  "DistinguishedName": "OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "UVT",
                  "Description": null,
                  "OrganizationalUnits": [
                    {
                      "DistinguishedName": "OU=Botanicka,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Botanicka",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=Komenskeho,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Komenskeho",
                      "Description": null,
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=137,OU=Komenskeho,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "137",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=Infopanely,OU=137,OU=Komenskeho,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Infopanely",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=ICS-D-DHM414848,OU=Infopanely,OU=137,OU=Komenskeho,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "ICS-D-DHM414848",
                                  "Description": "CPS panel 3 - kamery",
                                  "Type": "Computer"
                                },
                                {
                                  "DistinguishedName": "CN=ICS-D-CPS394635,OU=Infopanely,OU=137,OU=Komenskeho,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "ICS-D-CPS394635",
                                  "Description": "CPS panel 4",
                                  "Type": "Computer"
                                },
                                {
                                  "DistinguishedName": "CN=ICS-D-DHM417479,OU=Infopanely,OU=137,OU=Komenskeho,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "ICS-D-DHM417479",
                                  "Description": "CPS panel 1",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=ICS-D-DHM446689,OU=137,OU=Komenskeho,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "ICS-D-DHM446689",
                              "Description": "cpsobsluha3",
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=ICS-D-DHM446682,OU=137,OU=Komenskeho,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "ICS-D-DHM446682",
                              "Description": "Sabina",
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=ICS-D-DHM446691,OU=137,OU=Komenskeho,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "ICS-D-DHM446691",
                              "Description": "cpsobsluha1",
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=ICS-D-DHM446690,OU=137,OU=Komenskeho,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "ICS-D-DHM446690",
                              "Description": "cpsobsluha2",
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=139B,OU=Komenskeho,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "139B",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=ICS-D-DHM432999,OU=139B,OU=Komenskeho,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "ICS-D-DHM432999",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=ICS-D-DHM430067,OU=139B,OU=Komenskeho,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "ICS-D-DHM430067",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=139C,OU=Komenskeho,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "139C",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=ICS-D-CPS388805,OU=139C,OU=Komenskeho,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "ICS-D-CPS388805",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=196,OU=Komenskeho,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "196",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=ICS-D-CPS391481,OU=196,OU=Komenskeho,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "ICS-D-CPS391481",
                              "Description": "welcomescreen",
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=Sumavska,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Sumavska",
                      "Description": null,
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=1.NP,OU=Sumavska,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "1.NP",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=DPE,OU=1.NP,OU=Sumavska,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "DPE",
                              "Description": null,
                              "OrganizationalUnits": [
                                {
                                  "DistinguishedName": "OU=G121b,OU=DPE,OU=1.NP,OU=Sumavska,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "G121b",
                                  "Description": null,
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=ICS-D-DHM359334,OU=G121b,OU=DPE,OU=1.NP,OU=Sumavska,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "ICS-D-DHM359334",
                                      "Description": null,
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=G115,OU=DPE,OU=1.NP,OU=Sumavska,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "G115",
                                  "Description": null,
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=ICS-D-DHM400178,OU=G115,OU=DPE,OU=1.NP,OU=Sumavska,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "ICS-D-DHM400178",
                                      "Description": null,
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                }
                              ],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=DKSD,OU=1.NP,OU=Sumavska,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "DKSD",
                              "Description": null,
                              "OrganizationalUnits": [
                                {
                                  "DistinguishedName": "OU=G191a,OU=DKSD,OU=1.NP,OU=Sumavska,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "G191a",
                                  "Description": null,
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=ICS-D-DHM393317,OU=G191a,OU=DKSD,OU=1.NP,OU=Sumavska,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "ICS-D-DHM393317",
                                      "Description": null,
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=G191m,OU=DKSD,OU=1.NP,OU=Sumavska,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "G191m",
                                  "Description": null,
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=ICS-D-ZP48224,OU=G191m,OU=DKSD,OU=1.NP,OU=Sumavska,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "ICS-D-ZP48224",
                                      "Description": null,
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                }
                              ],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=G107,OU=1.NP,OU=Sumavska,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "G107",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=ICS-D-DHM418710,OU=G107,OU=1.NP,OU=Sumavska,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "ICS-D-DHM418710",
                                  "Description": null,
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=2.NP,OU=Sumavska,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "2.NP",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=G259,OU=2.NP,OU=Sumavska,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "G259",
                              "Description": null,
                              "OrganizationalUnits": [
                                {
                                  "DistinguishedName": "OU=Ivan,OU=G259,OU=2.NP,OU=Sumavska,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "Ivan",
                                  "Description": null,
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=ICS-D-DHM394633,OU=Ivan,OU=G259,OU=2.NP,OU=Sumavska,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "ICS-D-DHM394633",
                                      "Description": null,
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=Ondra,OU=G259,OU=2.NP,OU=Sumavska,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "Ondra",
                                  "Description": null,
                                  "OrganizationalUnits": [],
                                  "Computers": [],
                                  "Type": "OrganizationalUnit"
                                }
                              ],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=ICS-D-DHM413976,OU=G259,OU=2.NP,OU=Sumavska,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "ICS-D-DHM413976",
                                  "Description": null,
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=3.NP,OU=Sumavska,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "3.NP",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=DITI,OU=3.NP,OU=Sumavska,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "DITI",
                              "Description": null,
                              "OrganizationalUnits": [
                                {
                                  "DistinguishedName": "OU=G319,OU=DITI,OU=3.NP,OU=Sumavska,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "G319",
                                  "Description": null,
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=ICS-D-DHM416861,OU=G319,OU=DITI,OU=3.NP,OU=Sumavska,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "ICS-D-DHM416861",
                                      "Description": null,
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                }
                              ],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=G334,OU=3.NP,OU=Sumavska,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "G334",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=ICS-D-DHM400154,OU=G334,OU=3.NP,OU=Sumavska,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "ICS-D-DHM400154",
                                  "Description": "Kala",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=DITS,OU=3.NP,OU=Sumavska,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "DITS",
                              "Description": null,
                              "OrganizationalUnits": [
                                {
                                  "DistinguishedName": "OU=G394,OU=DITS,OU=3.NP,OU=Sumavska,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "G394",
                                  "Description": null,
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=ICS-D-DHM449257,OU=G394,OU=DITS,OU=3.NP,OU=Sumavska,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "ICS-D-DHM449257",
                                      "Description": null,
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=G381,OU=DITS,OU=3.NP,OU=Sumavska,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "G381",
                                  "Description": null,
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=ICS-D-DHM386136,OU=G381,OU=DITS,OU=3.NP,OU=Sumavska,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "ICS-D-DHM386136",
                                      "Description": "Ondrej Buday PC",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                }
                              ],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=G346,OU=3.NP,OU=Sumavska,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "G346",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=ICS-D-DHM391794,OU=G346,OU=3.NP,OU=Sumavska,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "ICS-D-DHM391794",
                                  "Description": "Repisova",
                                  "Type": "Computer"
                                },
                                {
                                  "DistinguishedName": "CN=ICS-D-DHM409805,OU=G346,OU=3.NP,OU=Sumavska,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "ICS-D-DHM409805",
                                  "Description": null,
                                  "Type": "Computer"
                                },
                                {
                                  "DistinguishedName": "CN=ICS-D-DHM449747,OU=G346,OU=3.NP,OU=Sumavska,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "ICS-D-DHM449747",
                                  "Description": null,
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=DPE,OU=3.NP,OU=Sumavska,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "DPE",
                              "Description": null,
                              "OrganizationalUnits": [
                                {
                                  "DistinguishedName": "OU=G366,OU=DPE,OU=3.NP,OU=Sumavska,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "G366",
                                  "Description": null,
                                  "OrganizationalUnits": [],
                                  "Computers": [],
                                  "Type": "OrganizationalUnit"
                                }
                              ],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "SumavskaTower",
                      "Description": null,
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=6.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "6.NP",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=6.12,OU=6.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "6.12",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=6.13,OU=6.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "6.13",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=6.14,OU=6.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "6.14",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=6.15,OU=6.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "6.15",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=6.16,OU=6.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "6.16",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=6.17,OU=6.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "6.17",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=6.18,OU=6.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "6.18",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=6.19,OU=6.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "6.19",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=6.20,OU=6.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "6.20",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=6.25,OU=6.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "6.25",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=ICS-D-DHM398758,OU=6.25,OU=6.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "ICS-D-DHM398758",
                                  "Description": "Stuchlik",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=6.26,OU=6.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "6.26",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=Kiosk,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "Kiosk",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=ICS-D-DHM446547,OU=Kiosk,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "ICS-D-DHM446547",
                              "Description": "sklad",
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=ICS-D-ZP50606,OU=Kiosk,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "ICS-D-ZP50606",
                              "Description": "Kiosk1",
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=ICS-D-ZP50607,OU=Kiosk,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "ICS-D-ZP50607",
                              "Description": "Kiosk2",
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=ICS-D-ZP50609,OU=Kiosk,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "ICS-D-ZP50609",
                              "Description": "Kiosk3",
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=ICS-D-ZP50610,OU=Kiosk,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "ICS-D-ZP50610",
                              "Description": "Kiosk4",
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=ICS-D-ZP50611,OU=Kiosk,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "ICS-D-ZP50611",
                              "Description": "Kiosk5",
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=ICS-D-ZP50608,OU=Kiosk,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "ICS-D-ZP50608",
                              "Description": "Kiosk",
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=5.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "5.NP",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=5.12,OU=5.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "5.12",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=ICS-D-DHM418242,OU=5.12,OU=5.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "ICS-D-DHM418242",
                                  "Description": "Rockova",
                                  "Type": "Computer"
                                },
                                {
                                  "DistinguishedName": "CN=ICS-D-DHM386160,OU=5.12,OU=5.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "ICS-D-DHM386160",
                                  "Description": "Boukalova",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=5.13,OU=5.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "5.13",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=5.14,OU=5.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "5.14",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=5.15,OU=5.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "5.15",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=5.16,OU=5.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "5.16",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=ICS-D-DHM406913,OU=5.16,OU=5.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "ICS-D-DHM406913",
                                  "Description": "Krejci",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=5.17,OU=5.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "5.17",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=5.18,OU=5.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "5.18",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=5.19,OU=5.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "5.19",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=ICS-D-DHM449250,OU=5.19,OU=5.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "ICS-D-DHM449250",
                                  "Description": null,
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=5.20,OU=5.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "5.20",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=5.25,OU=5.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "5.25",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=5.26,OU=5.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "5.26",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=7.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "7.NP",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=7.12,OU=7.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "7.12",
                              "Description": null,
                              "OrganizationalUnits": [
                                {
                                  "DistinguishedName": "OU=Ivosak,OU=7.12,OU=7.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "Ivosak",
                                  "Description": null,
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=ICS-D-DHM428875,OU=Ivosak,OU=7.12,OU=7.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "ICS-D-DHM428875",
                                      "Description": "Ivan",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=SD / dohled,OU=7.12,OU=7.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "SD / dohled",
                                  "Description": null,
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=ICS-D-DHM411168,OU=SD / dohled,OU=7.12,OU=7.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "ICS-D-DHM411168",
                                      "Description": null,
                                      "Type": "Computer"
                                    },
                                    {
                                      "DistinguishedName": "CN=ICS-D-DHM418178,OU=SD / dohled,OU=7.12,OU=7.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "ICS-D-DHM418178",
                                      "Description": null,
                                      "Type": "Computer"
                                    },
                                    {
                                      "DistinguishedName": "CN=ICS-D-DHM392150,OU=SD / dohled,OU=7.12,OU=7.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "ICS-D-DHM392150",
                                      "Description": null,
                                      "Type": "Computer"
                                    },
                                    {
                                      "DistinguishedName": "CN=ICS-D-DHM393319,OU=SD / dohled,OU=7.12,OU=7.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "ICS-D-DHM393319",
                                      "Description": "SD/dohled2",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                }
                              ],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=ICS-D-DHM446548,OU=7.12,OU=7.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "ICS-D-DHM446548",
                                  "Description": null,
                                  "Type": "Computer"
                                },
                                {
                                  "DistinguishedName": "CN=ICS-D-DHM406915,OU=7.12,OU=7.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "ICS-D-DHM406915",
                                  "Description": "Operator",
                                  "Type": "Computer"
                                },
                                {
                                  "DistinguishedName": "CN=ICS-D-DHM446549,OU=7.12,OU=7.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "ICS-D-DHM446549",
                                  "Description": null,
                                  "Type": "Computer"
                                },
                                {
                                  "DistinguishedName": "CN=ICS-D-DHM418177,OU=7.12,OU=7.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "ICS-D-DHM418177",
                                  "Description": "sklad",
                                  "Type": "Computer"
                                },
                                {
                                  "DistinguishedName": "CN=ICS-D-DHM430076,OU=7.12,OU=7.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "ICS-D-DHM430076",
                                  "Description": "Baran",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=DIS,OU=7.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "DIS",
                              "Description": null,
                              "OrganizationalUnits": [
                                {
                                  "DistinguishedName": "OU=7.24,OU=DIS,OU=7.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "7.24",
                                  "Description": null,
                                  "OrganizationalUnits": [],
                                  "Computers": [],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=7.13,OU=DIS,OU=7.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "7.13",
                                  "Description": null,
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=ICS-D-DHM461297,OU=7.13,OU=DIS,OU=7.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "ICS-D-DHM461297",
                                      "Description": "Ocelka",
                                      "Type": "Computer"
                                    },
                                    {
                                      "DistinguishedName": "CN=ICS-D-DHM416860,OU=7.13,OU=DIS,OU=7.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "ICS-D-DHM416860",
                                      "Description": "Tajovsky",
                                      "Type": "Computer"
                                    },
                                    {
                                      "DistinguishedName": "CN=ICS-D-DHM422140,OU=7.13,OU=DIS,OU=7.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "ICS-D-DHM422140",
                                      "Description": "Tajovsky",
                                      "Type": "Computer"
                                    },
                                    {
                                      "DistinguishedName": "CN=ICS-D-DHM428938,OU=7.13,OU=DIS,OU=7.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "ICS-D-DHM428938",
                                      "Description": "Tajovsky",
                                      "Type": "Computer"
                                    },
                                    {
                                      "DistinguishedName": "CN=ICS-D-DHM386140,OU=7.13,OU=DIS,OU=7.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "ICS-D-DHM386140",
                                      "Description": "Spurna",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=7.14,OU=DIS,OU=7.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "7.14",
                                  "Description": null,
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=ICS-D-DHM397690,OU=7.14,OU=DIS,OU=7.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "ICS-D-DHM397690",
                                      "Description": "Ocelkova",
                                      "Type": "Computer"
                                    },
                                    {
                                      "DistinguishedName": "CN=ICS-D-DHM457700,OU=7.14,OU=DIS,OU=7.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "ICS-D-DHM457700",
                                      "Description": "Gavlas",
                                      "Type": "Computer"
                                    },
                                    {
                                      "DistinguishedName": "CN=ICS-D-DHM416862,OU=7.14,OU=DIS,OU=7.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "ICS-D-DHM416862",
                                      "Description": "Ocelkova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=7.15,OU=DIS,OU=7.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "7.15",
                                  "Description": null,
                                  "OrganizationalUnits": [],
                                  "Computers": [],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=7.16,OU=DIS,OU=7.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "7.16",
                                  "Description": null,
                                  "OrganizationalUnits": [],
                                  "Computers": [],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=7.17,OU=DIS,OU=7.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "7.17",
                                  "Description": null,
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=ICS-D-DHM402912,OU=7.17,OU=DIS,OU=7.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "ICS-D-DHM402912",
                                      "Description": "Holicova",
                                      "Type": "Computer"
                                    },
                                    {
                                      "DistinguishedName": "CN=ICS-D-DHM406889,OU=7.17,OU=DIS,OU=7.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "ICS-D-DHM406889",
                                      "Description": "Mauderova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=7.18,OU=DIS,OU=7.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "7.18",
                                  "Description": null,
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=ICS-D-DHM449009,OU=7.18,OU=DIS,OU=7.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "ICS-D-DHM449009",
                                      "Description": "Capek",
                                      "Type": "Computer"
                                    },
                                    {
                                      "DistinguishedName": "CN=ICS-D-DHM422138,OU=7.18,OU=DIS,OU=7.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "ICS-D-DHM422138",
                                      "Description": "Barca",
                                      "Type": "Computer"
                                    },
                                    {
                                      "DistinguishedName": "CN=ICS-D-DHM446702,OU=7.18,OU=DIS,OU=7.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "ICS-D-DHM446702",
                                      "Description": "Barca",
                                      "Type": "Computer"
                                    },
                                    {
                                      "DistinguishedName": "CN=ICS-D-DHM425220,OU=7.18,OU=DIS,OU=7.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "ICS-D-DHM425220",
                                      "Description": "Burian",
                                      "Type": "Computer"
                                    },
                                    {
                                      "DistinguishedName": "CN=ICS-D-DHM449010,OU=7.18,OU=DIS,OU=7.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "ICS-D-DHM449010",
                                      "Description": "Burian",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=7.19,OU=DIS,OU=7.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "7.19",
                                  "Description": null,
                                  "OrganizationalUnits": [],
                                  "Computers": [],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=7.20,OU=DIS,OU=7.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "7.20",
                                  "Description": null,
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=ICS-D-DHM422139,OU=7.20,OU=DIS,OU=7.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "ICS-D-DHM422139",
                                      "Description": "Kohoutkova",
                                      "Type": "Computer"
                                    },
                                    {
                                      "DistinguishedName": "CN=ICS-D-DHM402913,OU=7.20,OU=DIS,OU=7.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "ICS-D-DHM402913",
                                      "Description": "Machac",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=7.25,OU=DIS,OU=7.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "7.25",
                                  "Description": null,
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=ICS-D-DHM438719,OU=7.25,OU=DIS,OU=7.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "ICS-D-DHM438719",
                                      "Description": null,
                                      "Type": "Computer"
                                    },
                                    {
                                      "DistinguishedName": "CN=ICS-D-DHM391814,OU=7.25,OU=DIS,OU=7.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "ICS-D-DHM391814",
                                      "Description": "Kacova",
                                      "Type": "Computer"
                                    },
                                    {
                                      "DistinguishedName": "CN=ICS-D-DHM446700,OU=7.25,OU=DIS,OU=7.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "ICS-D-DHM446700",
                                      "Description": "Bartek",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                }
                              ],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=8.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "8.NP",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=8.12,OU=8.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "8.12",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=8.13,OU=8.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "8.13",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=8.14,OU=8.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "8.14",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=8.15,OU=8.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "8.15",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=8.16,OU=8.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "8.16",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=8.17,OU=8.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "8.17",
                              "Description": null,
                              "OrganizationalUnits": [
                                {
                                  "DistinguishedName": "OU=KotlikpcOU,OU=8.17,OU=8.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "KotlikpcOU",
                                  "Description": null,
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=ICS-D-DHM393846,OU=KotlikpcOU,OU=8.17,OU=8.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "ICS-D-DHM393846",
                                      "Description": "Martin Kotlik PC",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                }
                              ],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=ICS-D-DHM428874,OU=8.17,OU=8.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "ICS-D-DHM428874",
                                  "Description": "Kolomaznik",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=8.18,OU=8.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "8.18",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=8.19,OU=8.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "8.19",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=8.20,OU=8.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "8.20",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=8.25,OU=8.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "8.25",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=9.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "9.NP",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=9.12,OU=9.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "9.12",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=9.13,OU=9.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "9.13",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=9.14,OU=9.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "9.14",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=9.15,OU=9.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "9.15",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=9.16,OU=9.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "9.16",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=9.17,OU=9.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "9.17",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=9.18,OU=9.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "9.18",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=9.19,OU=9.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "9.19",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=9.20,OU=9.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "9.20",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=9.25,OU=9.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "9.25",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=9.26,OU=9.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "9.26",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=15.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "15.NP",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=15.11a,OU=15.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "15.11a",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=ICS-D-DHM401620,OU=15.11a,OU=15.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "ICS-D-DHM401620",
                                  "Description": "Konecna",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=15.11b,OU=15.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "15.11b",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=ICS-D-DHM418179,OU=15.11b,OU=15.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "ICS-D-DHM418179",
                                  "Description": "Unzeitigova",
                                  "Type": "Computer"
                                },
                                {
                                  "DistinguishedName": "CN=ICS-D-DHM417477,OU=15.11b,OU=15.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "ICS-D-DHM417477",
                                  "Description": "Janouskova",
                                  "Type": "Computer"
                                },
                                {
                                  "DistinguishedName": "CN=ICS-D-DHM446694,OU=15.11b,OU=15.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "ICS-D-DHM446694",
                                  "Description": "Sutova",
                                  "Type": "Computer"
                                },
                                {
                                  "DistinguishedName": "CN=ICS-D-DHM401619,OU=15.11b,OU=15.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "ICS-D-DHM401619",
                                  "Description": "Rubinova",
                                  "Type": "Computer"
                                },
                                {
                                  "DistinguishedName": "CN=ICS-D-DHM393529,OU=15.11b,OU=15.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "ICS-D-DHM393529",
                                  "Description": "Kolomaznikova",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=15.11c,OU=15.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "15.11c",
                              "Description": null,
                              "OrganizationalUnits": [
                                {
                                  "DistinguishedName": "OU=Kasprzakova,OU=15.11c,OU=15.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "Kasprzakova",
                                  "Description": null,
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=ICS-D-DHM398756,OU=Kasprzakova,OU=15.11c,OU=15.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "ICS-D-DHM398756",
                                      "Description": "Kasprzakova",
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                }
                              ],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=ICS-D-DHM392928,OU=15.11c,OU=15.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "ICS-D-DHM392928",
                                  "Description": "Seliga",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=15.11d,OU=15.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "15.11d",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=15.11e,OU=15.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "15.11e",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=15.12,OU=15.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "15.12",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=15.13,OU=15.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "15.13",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=15.14,OU=15.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "15.14",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=15.15,OU=15.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "15.15",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=15.16,OU=15.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "15.16",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=15.17,OU=15.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "15.17",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=ICS-D-DHM456212,OU=15.17,OU=15.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "ICS-D-DHM456212",
                                  "Description": "Vojtova",
                                  "Type": "Computer"
                                },
                                {
                                  "DistinguishedName": "CN=ICS-D-DHM400491,OU=15.17,OU=15.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "ICS-D-DHM400491",
                                  "Description": "Seckarova",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=15.18,OU=15.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "15.18",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=15.19,OU=15.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "15.19",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=15.21,OU=15.NP,OU=SumavskaTower,OU=UVT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "15.21",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    }
                  ],
                  "Computers": [],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=CZS,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "CZS",
                  "Description": null,
                  "OrganizationalUnits": [
                    {
                      "DistinguishedName": "OU=306,OU=CZS,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "306",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=ICS-D-CZS474984,OU=306,OU=CZS,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CZS474984",
                          "Description": "Vicarova",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=325,OU=CZS,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "325",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=307a,OU=CZS,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "307a",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=ICS-D-CZS461871,OU=307a,OU=CZS,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CZS461871",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CZS474980,OU=307a,OU=CZS,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CZS474980",
                          "Description": "Koprivova",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=384,OU=CZS,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "384",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=ICS-D-CZS414382,OU=384,OU=CZS,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CZS414382",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=304,OU=CZS,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "304",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=ICS-D-CZS461892,OU=304,OU=CZS,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CZS461892",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CZS474983,OU=304,OU=CZS,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CZS474983",
                          "Description": "Sedlackova",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CZS461875,OU=304,OU=CZS,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CZS461875",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=323,OU=CZS,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "323",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=ICS-D-CZS461886,OU=323,OU=CZS,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CZS461886",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CZS474981,OU=323,OU=CZS,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CZS474981",
                          "Description": "Sichova",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=321,OU=CZS,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "321",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=ICS-D-CZS461888,OU=321,OU=CZS,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CZS461888",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CZS414389,OU=321,OU=CZS,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CZS414389",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=326,OU=CZS,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "326",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=ICS-D-CZS414383,OU=326,OU=CZS,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CZS414383",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CZS414391,OU=326,OU=CZS,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CZS414391",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CZS414385,OU=326,OU=CZS,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CZS414385",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CZS414390,OU=326,OU=CZS,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CZS414390",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CZS414387,OU=326,OU=CZS,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CZS414387",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=322,OU=CZS,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "322",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=ICS-D-CZS322,OU=322,OU=CZS,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CZS322",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CZS450341,OU=322,OU=CZS,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CZS450341",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=305,OU=CZS,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "305",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=ICS-D-CZS461878,OU=305,OU=CZS,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CZS461878",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CZS461884,OU=305,OU=CZS,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CZS461884",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CZS461891,OU=305,OU=CZS,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CZS461891",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CZS474982,OU=305,OU=CZS,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CZS474982",
                          "Description": "Schullerova",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CZS474985,OU=305,OU=CZS,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CZS474985",
                          "Description": "Jezkova",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CZS425552,OU=305,OU=CZS,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CZS425552",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    }
                  ],
                  "Computers": [],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=CJV,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "CJV",
                  "Description": null,
                  "OrganizationalUnits": [
                    {
                      "DistinguishedName": "OU=U408,OU=CJV,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "U408",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=214,OU=CJV,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "214",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=216,OU=CJV,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "216",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=217,OU=CJV,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "217",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=ICS-D-CJV393470,OU=217,OU=CJV,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CJV393470",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=219,OU=CJV,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "219",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=214A,OU=CJV,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "214A",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=U406,OU=CJV,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "U406",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=212,OU=CJV,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "212",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=215,OU=CJV,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "215",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=U407,OU=CJV,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "U407",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    }
                  ],
                  "Computers": [],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=CTT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "CTT",
                  "Description": null,
                  "OrganizationalUnits": [
                    {
                      "DistinguishedName": "OU=228,OU=CTT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "228",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=ICS-D-CTT394643,OU=228,OU=CTT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CTT394643",
                          "Description": "Pustova",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CTT394632,OU=228,OU=CTT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CTT394632",
                          "Description": "Vlasakova",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=220,OU=CTT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "220",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=ICS-D-CTT394634,OU=220,OU=CTT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CTT394634",
                          "Description": "Novakova",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=223,OU=CTT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "223",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=ICS-D-CTT394645,OU=223,OU=CTT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CTT394645",
                          "Description": "Vondrackova",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CTT394651,OU=223,OU=CTT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CTT394651",
                          "Description": "Vavrova",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=227,OU=CTT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "227",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=ICS-D-CTT394653,OU=227,OU=CTT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CTT394653",
                          "Description": "Janouskovcova",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CTT453977,OU=227,OU=CTT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CTT453977",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CTT439491,OU=227,OU=CTT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CTT439491",
                          "Description": "Buril",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CTT394637,OU=227,OU=CTT,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CTT394637",
                          "Description": "Stenclova",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    }
                  ],
                  "Computers": [],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=Telc,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "Telc",
                  "Description": null,
                  "OrganizationalUnits": [
                    {
                      "DistinguishedName": "OU=014,OU=Telc,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "014",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=ICS-D-UCTZ014,OU=014,OU=Telc,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-UCTZ014",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=016,OU=Telc,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "016",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=ICS-D-UCTZ016,OU=016,OU=Telc,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-UCTZ016",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=103,OU=Telc,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "103",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=ICS-D-UCTZ103,OU=103,OU=Telc,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-UCTZ103",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=104,OU=Telc,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "104",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=ICS-D-UCTZ104,OU=104,OU=Telc,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-UCTZ104",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=204,OU=Telc,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "204",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=ICS-D-UCTZ204,OU=204,OU=Telc,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-UCTZ204",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=216,OU=Telc,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "216",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=ICS-D-UCTZ216A1,OU=216,OU=Telc,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-UCTZ216A1",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-UCTZ216A2,OU=216,OU=Telc,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-UCTZ216A2",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-UCTZ216B,OU=216,OU=Telc,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-UCTZ216B",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=221,OU=Telc,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "221",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=ICS-D-UCTZ221,OU=221,OU=Telc,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-UCTZ221",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=346,OU=Telc,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "346",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=ICS-D-UCTZ346,OU=346,OU=Telc,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-UCTZ346",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    }
                  ],
                  "Computers": [],
                  "Type": "OrganizationalUnit"
                }
              ],
              "Computers": [
                {
                  "DistinguishedName": "CN=TESTnewSCCMdiscovery3,OU=ICS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "TESTnewSCCMdiscovery3",
                  "Description": null,
                  "Type": "Computer"
                }
              ],
              "Type": "OrganizationalUnit"
            },
            {
              "DistinguishedName": "OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
              "Name": "FSS",
              "Description": "cikt@fss.muni.cz",
              "OrganizationalUnits": [
                {
                  "DistinguishedName": "OU=3NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "3NP",
                  "Description": null,
                  "OrganizationalUnits": [
                    {
                      "DistinguishedName": "OU=349,OU=3NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "349",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FSS-D-452214,OU=349,OU=3NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FSS-D-452214",
                          "Description": "Shukla",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=350,OU=3NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "350",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FSS-D-452215,OU=350,OU=3NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FSS-D-452215",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=310,OU=3NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "310",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FSS-D-474951,OU=310,OU=3NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FSS-D-474951",
                          "Description": "Stibral",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=311,OU=3NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "311",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FSS-D-418252,OU=311,OU=3NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FSS-D-418252",
                          "Description": "KES Zaloha",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FSS-D-429860,OU=311,OU=3NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FSS-D-429860",
                          "Description": "KES Zaloha",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=313,OU=3NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "313",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FSS-D-474952,OU=313,OU=3NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FSS-D-474952",
                          "Description": "Gal\u010Danov\u00E1",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=317,OU=3NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "317",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FSS-D-474953,OU=317,OU=3NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FSS-D-474953",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FSS-D-418251,OU=317,OU=3NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FSS-D-418251",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FSS-D-418253,OU=317,OU=3NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FSS-D-418253",
                          "Description": "Mikulicov\u00E1",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=359,OU=3NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "359",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FSS-D-414312,OU=359,OU=3NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FSS-D-414312",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FSS-D-414317,OU=359,OU=3NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FSS-D-414317",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FSS-D-414316,OU=359,OU=3NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FSS-D-414316",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=312,OU=3NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "312",
                      "Description": null,
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=FSS-D-474948sw,OU=312,OU=3NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FSS-D-474948sw",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=FSS-D-474948,OU=FSS-D-474948sw,OU=312,OU=3NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-474948",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FSS-D-474950,OU=312,OU=3NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FSS-D-474950",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FSS-D-474949,OU=312,OU=3NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FSS-D-474949",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=357,OU=3NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "357",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FSS-D-433047,OU=357,OU=3NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FSS-D-433047",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=368,OU=3NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "368",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FSS-D-414313,OU=368,OU=3NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FSS-D-414313",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FSS-D-414314,OU=368,OU=3NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FSS-D-414314",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=364,OU=3NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "364",
                      "Description": null,
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=FSS-D-414307sw,OU=364,OU=3NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FSS-D-414307sw",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=FSS-D-414307,OU=FSS-D-414307sw,OU=364,OU=3NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-414307",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    }
                  ],
                  "Computers": [],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=5NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "5NP",
                  "Description": null,
                  "OrganizationalUnits": [
                    {
                      "DistinguishedName": "OU=564,OU=5NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "564",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FSS-D-421075,OU=564,OU=5NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FSS-D-421075",
                          "Description": "Plasov\u00E1",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FSS-D-421074,OU=564,OU=5NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FSS-D-421074",
                          "Description": "Zelenkov\u00E1",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=508,OU=5NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "508",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FSS-D-419367,OU=508,OU=5NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FSS-D-419367",
                          "Description": "New m\u00EDsto M\u00E1\u0161i",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=555,OU=5NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "555",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FSS-D-395272,OU=555,OU=5NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FSS-D-395272",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=557,OU=5NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "557",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FSS-D-466258,OU=557,OU=5NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FSS-D-466258",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FSS-D-466259,OU=557,OU=5NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FSS-D-466259",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FSS-D-466260,OU=557,OU=5NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FSS-D-466260",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FSS-D-466261,OU=557,OU=5NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FSS-D-466261",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FSS-D-466262,OU=557,OU=5NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FSS-D-466262",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FSS-D-466263,OU=557,OU=5NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FSS-D-466263",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=528,OU=5NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "528",
                      "Description": null,
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=FSS-D-468517sw,OU=528,OU=5NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FSS-D-468517sw",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=FSS-D-468517,OU=FSS-D-468517sw,OU=528,OU=5NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-468517",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FSS-D-353606,OU=528,OU=5NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FSS-D-353606",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=511,OU=5NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "511",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FSS-D-421070,OU=511,OU=5NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FSS-D-421070",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=547,OU=5NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "547",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FSS-D-466587,OU=547,OU=5NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FSS-D-466587",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=563,OU=5NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "563",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FSS-D-421077,OU=563,OU=5NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FSS-D-421077",
                          "Description": "Navr\u00E1tilov\u00E1",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=553,OU=5NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "553",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FSS-D-466605,OU=553,OU=5NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FSS-D-466605",
                          "Description": "Lorencov\u00E1",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=566,OU=5NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "566",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FSS-D-421080,OU=566,OU=5NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FSS-D-421080",
                          "Description": "Otava",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FSS-D-421238,OU=566,OU=5NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FSS-D-421238",
                          "Description": "Navr\u00E1til",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=509,OU=5NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "509",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FSS-D-418211,OU=509,OU=5NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FSS-D-418211",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    }
                  ],
                  "Computers": [],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=1NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "1NP",
                  "Description": null,
                  "OrganizationalUnits": [
                    {
                      "DistinguishedName": "OU=121,OU=1NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "121",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FSS-D-439229,OU=121,OU=1NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FSS-D-439229",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FSS-D-435936,OU=121,OU=1NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FSS-D-435936",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FSS-D-418221,OU=121,OU=1NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FSS-D-418221",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=142,OU=1NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "142",
                      "Description": null,
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=FSS-D-418900sw,OU=142,OU=1NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FSS-D-418900sw",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=FSS-D-418900,OU=FSS-D-418900sw,OU=142,OU=1NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-418900",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=115,OU=1NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "115",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FSS-D-414334,OU=115,OU=1NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FSS-D-414334",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=138,OU=1NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "138",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FSS-D-474611,OU=138,OU=1NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FSS-D-474611",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FSS-D-474613,OU=138,OU=1NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FSS-D-474613",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=139,OU=1NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "139",
                      "Description": null,
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=FSS-D-418897sw,OU=139,OU=1NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FSS-D-418897sw",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=FSS-D-418897,OU=FSS-D-418897sw,OU=139,OU=1NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-418897",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    }
                  ],
                  "Computers": [],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=Instalace,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "Instalace",
                  "Description": null,
                  "OrganizationalUnits": [],
                  "Computers": [
                    {
                      "DistinguishedName": "CN=FSS-D-468374,OU=Instalace,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FSS-D-468374",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FSS-D-468373,OU=Instalace,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FSS-D-468373",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FSS-D-468369,OU=Instalace,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FSS-D-468369",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FSS-D-468368,OU=Instalace,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FSS-D-468368",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FSS-D-468367,OU=Instalace,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FSS-D-468367",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FSS-D-461879,OU=Instalace,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FSS-D-461879",
                      "Description": "Macek",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FSS-D-474612,OU=Instalace,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FSS-D-474612",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FSS-D-466595,OU=Instalace,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FSS-D-466595",
                      "Description": "Perdoch Sladk\u00E1",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FSS-D-418768,OU=Instalace,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FSS-D-418768",
                      "Description": "\u010Cern\u00FD",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FSS-D-474823,OU=Instalace,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FSS-D-474823",
                      "Description": "Rafailov",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FSS-D-474822,OU=Instalace,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FSS-D-474822",
                      "Description": "Burgr",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FSS-D-474820,OU=Instalace,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FSS-D-474820",
                      "Description": "Ve\u010De\u0159a",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FSS-D-468362,OU=Instalace,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FSS-D-468362",
                      "Description": "Chovan\u010D\u00EDk",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FSS-D-457948,OU=Instalace,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FSS-D-457948",
                      "Description": "Fornacciari",
                      "Type": "Computer"
                    }
                  ],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=4NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "4NP",
                  "Description": null,
                  "OrganizationalUnits": [
                    {
                      "DistinguishedName": "OU=457,OU=4NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "457",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FSS-D-381002,OU=457,OU=4NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FSS-D-381002",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=443,OU=4NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "443",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FSS-D-457396,OU=443,OU=4NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FSS-D-457396",
                          "Description": "prof. K\u0159\u00ED\u017E",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=454,OU=4NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "454",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FSS-D-453713,OU=454,OU=4NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FSS-D-453713",
                          "Description": "Bizik",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=426,OU=4NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "426",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FSS-D-468365,OU=426,OU=4NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FSS-D-468365",
                          "Description": "Kuchy\u0148kov\u00E1",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FSS-D-468361,OU=426,OU=4NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FSS-D-468361",
                          "Description": "Hodul\u00E1k",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=459,OU=4NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "459",
                      "Description": null,
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=FSS-D-467526sw,OU=459,OU=4NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FSS-D-467526sw",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=FSS-D-467526,OU=FSS-D-467526sw,OU=459,OU=4NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-467526",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=435,OU=4NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "435",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FSS-D-453712,OU=435,OU=4NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FSS-D-453712",
                          "Description": "Ko\u010Fouskov\u00E1",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=440,OU=4NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "440",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FSS-D-457094,OU=440,OU=4NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FSS-D-457094",
                          "Description": "Havl\u00EDk",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=461,OU=4NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "461",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FSS-D-474545,OU=461,OU=4NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FSS-D-474545",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=456,OU=4NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "456",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FSS-D-392253,OU=456,OU=4NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FSS-D-392253",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=455a,OU=4NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "455a",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FSS-D-418766,OU=455a,OU=4NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FSS-D-418766",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    }
                  ],
                  "Computers": [],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=2NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "2NP",
                  "Description": null,
                  "OrganizationalUnits": [
                    {
                      "DistinguishedName": "OU=227,OU=2NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "227",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FSS-D-349721,OU=227,OU=2NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FSS-D-349721",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FSS-D-466598,OU=227,OU=2NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FSS-D-466598",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=244,OU=2NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "244",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FSS-D-461880,OU=244,OU=2NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FSS-D-461880",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=246,OU=2NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "246",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FSS-D-474546,OU=246,OU=2NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FSS-D-474546",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=228,OU=2NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "228",
                      "Description": null,
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=FSS-D-466600sw,OU=228,OU=2NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FSS-D-466600sw",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=FSS-D-466600,OU=FSS-D-466600sw,OU=228,OU=2NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-466600",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=TEST,OU=228,OU=2NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEST",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=FSS-D-401517,OU=TEST,OU=228,OU=2NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-401517",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=FSS-D-422911sw,OU=228,OU=2NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FSS-D-422911sw",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=FSS-D-422911,OU=FSS-D-422911sw,OU=228,OU=2NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-422911",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=250,OU=2NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "250",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FSS-D-461833,OU=250,OU=2NP,OU=FSS,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FSS-D-461833",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    }
                  ],
                  "Computers": [],
                  "Type": "OrganizationalUnit"
                }
              ],
              "Computers": [],
              "Type": "OrganizationalUnit"
            },
            {
              "DistinguishedName": "OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
              "Name": "LAW",
              "Description": "cit@law.muni.cz",
              "OrganizationalUnits": [
                {
                  "DistinguishedName": "OU=CIT,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "CIT",
                  "Description": "Pocitace na odd. CIT",
                  "OrganizationalUnits": [
                    {
                      "DistinguishedName": "OU=CIT_root,OU=CIT,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "CIT_root",
                      "Description": "Kmenov\u00ED zam\u011Bstnanci CITu",
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=70079OU,OU=CIT_root,OU=CIT,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "70079OU",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=LAW-D-ZEKPC,OU=70079OU,OU=CIT_root,OU=CIT,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-ZEKPC",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=132505OU,OU=CIT_root,OU=CIT,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "132505OU",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=LAW-D-467188,OU=132505OU,OU=CIT_root,OU=CIT,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-467188",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=CIT_prg,OU=CIT,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "CIT_prg",
                      "Description": "Program\u00E1to\u0159i CITu",
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=555179OU,OU=CIT_prg,OU=CIT,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "555179OU",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=LAW-D-468187,OU=555179OU,OU=CIT_prg,OU=CIT,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-468187",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=536252OU,OU=CIT_prg,OU=CIT,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "536252OU",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=LAW-D-467187,OU=536252OU,OU=CIT_prg,OU=CIT,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-467187",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    }
                  ],
                  "Computers": [],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=ProvozniPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "ProvozniPC",
                  "Description": "Provozni pocitace LAW",
                  "OrganizationalUnits": [
                    {
                      "DistinguishedName": "OU=Odd ZVV - ENG,OU=ProvozniPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Odd ZVV - ENG",
                      "Description": "Computers for foreign lecturers",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=LAW-D-375376,OU=Odd ZVV - ENG,OU=ProvozniPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-375376",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=Odd PUB,OU=ProvozniPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Odd PUB",
                      "Description": "Provozni PC Odd PUB",
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=VratnicePC,OU=Odd PUB,OU=ProvozniPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "VratnicePC",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=LAW-D-368879,OU=VratnicePC,OU=Odd PUB,OU=ProvozniPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-368879",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=UK,OU=ProvozniPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UK",
                      "Description": "Provozni PC Knihovny",
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=KameryPC,OU=UK,OU=ProvozniPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "KameryPC",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=LAW-D-419796,OU=KameryPC,OU=UK,OU=ProvozniPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-419796",
                              "Description": "Kamery v knihovne",
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=CIT,OU=ProvozniPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "CIT",
                      "Description": null,
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=AVT,OU=CIT,OU=ProvozniPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "AVT",
                          "Description": "PC pro AV techniku",
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=LAW-D-394670,OU=AVT,OU=CIT,OU=ProvozniPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-394670",
                              "Description": "Host for VM LAW-D-AVT",
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-AVT,OU=AVT,OU=CIT,OU=ProvozniPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-AVT",
                              "Description": "AVT virtual machine",
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    }
                  ],
                  "Computers": [],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "ZamPC",
                  "Description": "Pocitace zamestnancu LAW",
                  "OrganizationalUnits": [
                    {
                      "DistinguishedName": "OU=Install,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Install",
                      "Description": "Instalacni OU",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=LAW-D-474580,OU=Install,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-474580",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=Test,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Test",
                      "Description": "Testovaci PC",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=LAW-D-375379,OU=Test,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-375379",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=Cen DV - 14229720,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Cen DV - 14229720",
                      "Description": "Centrum dalsiho vzdelavani (14229720)",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=LAW-D-372465,OU=Cen DV - 14229720,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-372465",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-394663,OU=Cen DV - 14229720,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-394663",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-394668,OU=Cen DV - 14229720,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-394668",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=Cen IT - 14229830,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Cen IT - 14229830",
                      "Description": "Centrum informacnich technologii (14229830) - Wplace-229830",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=LAW-D-394666,OU=Cen IT - 14229830,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-394666",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=Cen IT FI - 14339830,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Cen IT FI - 14339830",
                      "Description": "Centrum vypocetni techniky Fakulty informatiky, pracoviste PrF MU (14339830) - Wplace-339830",
                      "OrganizationalUnits": [],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=Cen JV - 14229396,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Cen JV - 14229396",
                      "Description": "Centrum jazykoveho vzdelavani MU (14229396) - Wplace-962200",
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=404,OU=Cen JV - 14229396,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "404",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=LAW-D-457031,OU=404,OU=Cen JV - 14229396,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-457031",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-457032,OU=404,OU=Cen JV - 14229396,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-457032",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-457030,OU=404,OU=Cen JV - 14229396,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-457030",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-457033,OU=404,OU=Cen JV - 14229396,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-457033",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-457034,OU=404,OU=Cen JV - 14229396,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-457034",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=405,OU=Cen JV - 14229396,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "405",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=LAW-D-457037,OU=405,OU=Cen JV - 14229396,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-457037",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-457035,OU=405,OU=Cen JV - 14229396,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-457035",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-457036,OU=405,OU=Cen JV - 14229396,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-457036",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=409,OU=Cen JV - 14229396,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "409",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=LAW-D-457038,OU=409,OU=Cen JV - 14229396,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-457038",
                              "Description": "OS ENG langugage",
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-457039,OU=409,OU=Cen JV - 14229396,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-457039",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=410,OU=Cen JV - 14229396,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "410",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=LAW-D-457041,OU=410,OU=Cen JV - 14229396,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-457041",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-457040,OU=410,OU=Cen JV - 14229396,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-457040",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=Dek - 14229911,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Dek - 14229911",
                      "Description": "Sekretariat dekana (14229911) - Wplace-229911",
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=105,OU=Dek - 14229911,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "105",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=LAW-D-386928,OU=105,OU=Dek - 14229911,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-386928",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-394759,OU=105,OU=Dek - 14229911,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-394759",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=107,OU=Dek - 14229911,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "107",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=LAW-D-394669,OU=107,OU=Dek - 14229911,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-394669",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=059,OU=Dek - 14229911,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "059",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=LAW-D-449717,OU=059,OU=Dek - 14229911,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-449717",
                              "Description": "Podatelna",
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=EO - 14229914,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "EO - 14229914",
                      "Description": "Ekonomicke oddeleni (14229914) - Wplace-229914",
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=059,OU=EO - 14229914,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "059",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=LAW-D-444363,OU=059,OU=EO - 14229914,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-444363",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=S1xx,OU=EO - 14229914,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "S1xx",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=LAW-D-386921,OU=S1xx,OU=EO - 14229914,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-386921",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-386926,OU=S1xx,OU=EO - 14229914,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-386926",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-402452,OU=S1xx,OU=EO - 14229914,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-402452",
                              "Description": "byl p\u016Fj\u010Den na person\u00E1ln\u00ED",
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-394658,OU=S1xx,OU=EO - 14229914,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-394658",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-391190,OU=S1xx,OU=EO - 14229914,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-391190",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=044,OU=EO - 14229914,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "044",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=LAW-D-391182,OU=044,OU=EO - 14229914,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-391182",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=Kat CivProc - 14222500,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Kat CivProc - 14222500",
                      "Description": "Katedra civilniho prava procesniho (14222500) - Wplace-222500",
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=262,OU=Kat CivProc - 14222500,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "262",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=LAW-D-402432,OU=262,OU=Kat CivProc - 14222500,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-402432",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-383075,OU=262,OU=Kat CivProc - 14222500,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-383075",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-402428,OU=262,OU=Kat CivProc - 14222500,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-402428",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=264,OU=Kat CivProc - 14222500,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "264",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=LAW-D-383073,OU=264,OU=Kat CivProc - 14222500,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-383073",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-402438,OU=264,OU=Kat CivProc - 14222500,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-402438",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=263,OU=Kat CivProc - 14222500,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "263",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=LAW-D-372461,OU=263,OU=Kat CivProc - 14222500,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-372461",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=Kat DSP - 14221300,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Kat DSP - 14221300",
                      "Description": "Katedra dejin statu a prava (14221300) - Wplace-221300",
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=312,OU=Kat DSP - 14221300,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "312",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=S145,OU=Kat DSP - 14221300,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "S145",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=LAW-D-402544,OU=S145,OU=Kat DSP - 14221300,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-402544",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-402547,OU=S145,OU=Kat DSP - 14221300,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-402547",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-457042,OU=S145,OU=Kat DSP - 14221300,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-457042",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-402535,OU=S145,OU=Kat DSP - 14221300,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-402535",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=323A,OU=Kat DSP - 14221300,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "323A",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=LAW-D-383061,OU=323A,OU=Kat DSP - 14221300,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-383061",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=Kat FPNH - 14221900,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Kat FPNH - 14221900",
                      "Description": "Katedra financniho prava a narodniho hospodarstvi (14221900) - Wplace-221900",
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=206,OU=Kat FPNH - 14221900,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "206",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=LAW-D-375359,OU=206,OU=Kat FPNH - 14221900,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-375359",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-375368,OU=206,OU=Kat FPNH - 14221900,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-375368",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-439006,OU=206,OU=Kat FPNH - 14221900,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-439006",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-386937,OU=206,OU=Kat FPNH - 14221900,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-386937",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=308,OU=Kat FPNH - 14221900,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "308",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=S147,OU=Kat FPNH - 14221900,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "S147",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=LAW-D-402543,OU=S147,OU=Kat FPNH - 14221900,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-402543",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-402533,OU=S147,OU=Kat FPNH - 14221900,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-402533",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-402536,OU=S147,OU=Kat FPNH - 14221900,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-402536",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-402429,OU=S147,OU=Kat FPNH - 14221900,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-402429",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=S148,OU=Kat FPNH - 14221900,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "S148",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=LAW-D-402554,OU=S148,OU=Kat FPNH - 14221900,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-402554",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-402538,OU=S148,OU=Kat FPNH - 14221900,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-402538",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-449724,OU=S148,OU=Kat FPNH - 14221900,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-449724",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-402537,OU=S148,OU=Kat FPNH - 14221900,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-402537",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=237,OU=Kat FPNH - 14221900,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "237",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=LAW-D-372464,OU=237,OU=Kat FPNH - 14221900,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-372464",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=322,OU=Kat FPNH - 14221900,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "322",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=163,OU=Kat FPNH - 14221900,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "163",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=LAW-D-444368,OU=163,OU=Kat FPNH - 14221900,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-444368",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=255,OU=Kat FPNH - 14221900,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "255",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=LAW-D-386935,OU=255,OU=Kat FPNH - 14221900,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-386935",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=104,OU=Kat FPNH - 14221900,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "104",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=LAW-D-391192,OU=104,OU=Kat FPNH - 14221900,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-391192",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=322A,OU=Kat FPNH - 14221900,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "322A",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=Kat MEP - 14221700,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Kat MEP - 14221700",
                      "Description": "Katedra mezinarodniho a evropskeho prava (14221700) - Wplace-221700",
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=236,OU=Kat MEP - 14221700,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "236",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=LAW-D-439005,OU=236,OU=Kat MEP - 14221700,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-439005",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=104,OU=Kat MEP - 14221700,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "104",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=LAW-D-444371,OU=104,OU=Kat MEP - 14221700,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-444371",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=239,OU=Kat MEP - 14221700,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "239",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=LAW-D-383066,OU=239,OU=Kat MEP - 14221700,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-383066",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=211A,OU=Kat MEP - 14221700,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "211A",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=LAW-D-383062,OU=211A,OU=Kat MEP - 14221700,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-383062",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=234,OU=Kat MEP - 14221700,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "234",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=LAW-D-391179,OU=234,OU=Kat MEP - 14221700,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-391179",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-402440,OU=234,OU=Kat MEP - 14221700,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-402440",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=232,OU=Kat MEP - 14221700,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "232",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=LAW-D-439008,OU=232,OU=Kat MEP - 14221700,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-439008",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=233,OU=Kat MEP - 14221700,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "233",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=LAW-D-386940,OU=233,OU=Kat MEP - 14221700,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-386940",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-383077,OU=233,OU=Kat MEP - 14221700,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-383077",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=238,OU=Kat MEP - 14221700,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "238",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=LAW-D-402551,OU=238,OU=Kat MEP - 14221700,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-402551",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-391178,OU=238,OU=Kat MEP - 14221700,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-391178",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=237,OU=Kat MEP - 14221700,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "237",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=LAW-D-444367,OU=237,OU=Kat MEP - 14221700,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-444367",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=Kat OBCP - 14221500,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Kat OBCP - 14221500",
                      "Description": "Katedra obcanskeho prava (14221500) - Wplace-221500",
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=254,OU=Kat OBCP - 14221500,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "254",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=LAW-D-402430,OU=254,OU=Kat OBCP - 14221500,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-402430",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=207,OU=Kat OBCP - 14221500,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "207",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=LAW-D-383072,OU=207,OU=Kat OBCP - 14221500,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-383072",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=256,OU=Kat OBCP - 14221500,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "256",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=LAW-D-386923,OU=256,OU=Kat OBCP - 14221500,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-386923",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-402553,OU=256,OU=Kat OBCP - 14221500,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-402553",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=205,OU=Kat OBCP - 14221500,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "205",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=LAW-D-391173,OU=205,OU=Kat OBCP - 14221500,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-391173",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-439010,OU=205,OU=Kat OBCP - 14221500,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-439010",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=265,OU=Kat OBCP - 14221500,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "265",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=LAW-D-391187,OU=265,OU=Kat OBCP - 14221500,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-391187",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=266,OU=Kat OBCP - 14221500,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "266",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=LAW-D-439004,OU=266,OU=Kat OBCP - 14221500,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-439004",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=260,OU=Kat OBCP - 14221500,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "260",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=LAW-D-402427,OU=260,OU=Kat OBCP - 14221500,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-402427",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-375381,OU=260,OU=Kat OBCP - 14221500,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-375381",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-386916,OU=260,OU=Kat OBCP - 14221500,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-386916",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=255,OU=Kat OBCP - 14221500,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "255",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=LAW-D-444362,OU=255,OU=Kat OBCP - 14221500,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-444362",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=Kat ObchP - 14221800,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Kat ObchP - 14221800",
                      "Description": "Katedra obchodniho prava (14221800) - Wplace-221800",
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=245,OU=Kat ObchP - 14221800,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "245",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=LAW-D-444366,OU=245,OU=Kat ObchP - 14221800,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-444366",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-372463,OU=245,OU=Kat ObchP - 14221800,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-372463",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-383079,OU=245,OU=Kat ObchP - 14221800,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-383079",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-383070,OU=245,OU=Kat ObchP - 14221800,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-383070",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=244,OU=Kat ObchP - 14221800,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "244",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=LAW-D-449720,OU=244,OU=Kat ObchP - 14221800,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-449720",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-383069,OU=244,OU=Kat ObchP - 14221800,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-383069",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-402426,OU=244,OU=Kat ObchP - 14221800,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-402426",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=211A,OU=Kat ObchP - 14221800,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "211A",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=LAW-D-391191,OU=211A,OU=Kat ObchP - 14221800,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-391191",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=248,OU=Kat ObchP - 14221800,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "248",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=LAW-D-386922,OU=248,OU=Kat ObchP - 14221800,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-386922",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=246,OU=Kat ObchP - 14221800,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "246",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=LAW-D-394660,OU=246,OU=Kat ObchP - 14221800,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-394660",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=247,OU=Kat ObchP - 14221800,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "247",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=LAW-D-394673,OU=247,OU=Kat ObchP - 14221800,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-394673",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-394655,OU=247,OU=Kat ObchP - 14221800,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-394655",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=Kat PT - 14221100,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Kat PT - 14221100",
                      "Description": "Katedra pravni teorie (14221100) - Wplace-221100",
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=S149,OU=Kat PT - 14221100,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "S149",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=LAW-D-402545,OU=S149,OU=Kat PT - 14221100,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-402545",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-402546,OU=S149,OU=Kat PT - 14221100,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-402546",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-402548,OU=S149,OU=Kat PT - 14221100,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-402548",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-449723,OU=S149,OU=Kat PT - 14221100,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-449723",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=320,OU=Kat PT - 14221100,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "320",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=309,OU=Kat PT - 14221100,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "309",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=306,OU=Kat PT - 14221100,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "306",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=305,OU=Kat PT - 14221100,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "305",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=212,OU=Kat PT - 14221100,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "212",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=LAW-D-391181,OU=212,OU=Kat PT - 14221100,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-391181",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=307,OU=Kat PT - 14221100,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "307",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=Kat PZP - 14222100,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Kat PZP - 14222100",
                      "Description": "Katedra prava zivotniho prostredi a pozemkoveho prava (14222100) - Wplace-222100",
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=223,OU=Kat PZP - 14222100,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "223",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=LAW-D-402437,OU=223,OU=Kat PZP - 14222100,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-402437",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-402436,OU=223,OU=Kat PZP - 14222100,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-402436",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=222,OU=Kat PZP - 14222100,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "222",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=LAW-D-386934,OU=222,OU=Kat PZP - 14222100,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-386934",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-439009,OU=222,OU=Kat PZP - 14222100,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-439009",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=225,OU=Kat PZP - 14222100,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "225",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=LAW-D-449725,OU=225,OU=Kat PZP - 14222100,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-449725",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-375377,OU=225,OU=Kat PZP - 14222100,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-375377",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=220,OU=Kat PZP - 14222100,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "220",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=LAW-D-386925,OU=220,OU=Kat PZP - 14222100,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-386925",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=Kat SVSP - 14221400,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Kat SVSP - 14221400",
                      "Description": "Katedra spravni vedy a spravniho prava (14221400) - Wplace-221400",
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=163,OU=Kat SVSP - 14221400,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "163",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=LAW-D-391183,OU=163,OU=Kat SVSP - 14221400,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-391183",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=152,OU=Kat SVSP - 14221400,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "152",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=LAW-D-372459,OU=152,OU=Kat SVSP - 14221400,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-372459",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-402441,OU=152,OU=Kat SVSP - 14221400,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-402441",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=150,OU=Kat SVSP - 14221400,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "150",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=LAW-D-391186,OU=150,OU=Kat SVSP - 14221400,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-391186",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=154,OU=Kat SVSP - 14221400,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "154",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=LAW-D-402431,OU=154,OU=Kat SVSP - 14221400,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-402431",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=155,OU=Kat SVSP - 14221400,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "155",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=LAW-D-391194,OU=155,OU=Kat SVSP - 14221400,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-391194",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-375360,OU=155,OU=Kat SVSP - 14221400,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-375360",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=157,OU=Kat SVSP - 14221400,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "157",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=LAW-D-372458,OU=157,OU=Kat SVSP - 14221400,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-372458",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-457029,OU=157,OU=Kat SVSP - 14221400,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-457029",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-372468,OU=157,OU=Kat SVSP - 14221400,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-372468",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=159,OU=Kat SVSP - 14221400,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "159",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=LAW-D-449718,OU=159,OU=Kat SVSP - 14221400,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-449718",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=Kat TP - 14221600,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Kat TP - 14221600",
                      "Description": "Katedra trestniho prava (14221600) - Wplace-221600",
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=227,OU=Kat TP - 14221600,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "227",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=LAW-D-402433,OU=227,OU=Kat TP - 14221600,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-402433",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=230,OU=Kat TP - 14221600,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "230",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=LAW-D-391177,OU=230,OU=Kat TP - 14221600,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-391177",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=231,OU=Kat TP - 14221600,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "231",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=LAW-D-402443,OU=231,OU=Kat TP - 14221600,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-402443",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-402445,OU=231,OU=Kat TP - 14221600,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-402445",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-402435,OU=231,OU=Kat TP - 14221600,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-402435",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=226,OU=Kat TP - 14221600,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "226",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=LAW-D-383078,OU=226,OU=Kat TP - 14221600,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-383078",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-402486,OU=226,OU=Kat TP - 14221600,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-402486",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=229,OU=Kat TP - 14221600,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "229",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=LAW-D-383064,OU=229,OU=Kat TP - 14221600,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-383064",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=Kat UPP - 14221200,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Kat UPP - 14221200",
                      "Description": "Katedra ustavniho prava a politologie (14221200) - Wplace-221200",
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=253,OU=Kat UPP - 14221200,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "253",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=LAW-D-394667,OU=253,OU=Kat UPP - 14221200,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-394667",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-386927,OU=253,OU=Kat UPP - 14221200,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-386927",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=147,OU=Kat UPP - 14221200,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "147",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=LAW-D-386932,OU=147,OU=Kat UPP - 14221200,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-386932",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-402434,OU=147,OU=Kat UPP - 14221200,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-402434",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=143,OU=Kat UPP - 14221200,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "143",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=LAW-D-439003,OU=143,OU=Kat UPP - 14221200,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-439003",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-383076,OU=143,OU=Kat UPP - 14221200,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-383076",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=146,OU=Kat UPP - 14221200,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "146",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=LAW-D-402439,OU=146,OU=Kat UPP - 14221200,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-402439",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-372456,OU=146,OU=Kat UPP - 14221200,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-372456",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=144,OU=Kat UPP - 14221200,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "144",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=LAW-D-383071,OU=144,OU=Kat UPP - 14221200,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-383071",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-444364,OU=144,OU=Kat UPP - 14221200,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-444364",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=Odd DSRZ - 14229918,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Odd DSRZ - 14229918",
                      "Description": "Oddeleni pro doktorske studium a rigorozni rizeni (14229918) - Wplace-229918",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=LAW-D-457044,OU=Odd DSRZ - 14229918,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-457044",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-383065,OU=Odd DSRZ - 14229918,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-383065",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-457027,OU=Odd DSRZ - 14229918,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-457027",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-372475,OU=Odd DSRZ - 14229918,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-372475",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=Odd PUB - 14229880,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Odd PUB - 14229880",
                      "Description": "Oddeleni provozu a udrzby budovy (14229880) - Wplace-229880",
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=Udrzba,OU=Odd PUB - 14229880,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "Udrzba",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=009,OU=Udrzba,OU=Odd PUB - 14229880,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "009",
                              "Description": null,
                              "OrganizationalUnits": [
                                {
                                  "DistinguishedName": "OU=32830OU,OU=009,OU=Udrzba,OU=Odd PUB - 14229880,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "32830OU",
                                  "Description": null,
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=LAW-D-467186,OU=32830OU,OU=009,OU=Udrzba,OU=Odd PUB - 14229880,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "LAW-D-467186",
                                      "Description": null,
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                }
                              ],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=LAW-D-391188,OU=009,OU=Udrzba,OU=Odd PUB - 14229880,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "LAW-D-391188",
                                  "Description": null,
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=S111,OU=Udrzba,OU=Odd PUB - 14229880,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "S111",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=LAW-D-444369,OU=S111,OU=Udrzba,OU=Odd PUB - 14229880,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "LAW-D-444369",
                                  "Description": null,
                                  "Type": "Computer"
                                },
                                {
                                  "DistinguishedName": "CN=LAW-D-444370,OU=S111,OU=Udrzba,OU=Odd PUB - 14229880,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "LAW-D-444370",
                                  "Description": null,
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=Zvukari,OU=Odd PUB - 14229880,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "Zvukari",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=LAW-D-439007,OU=Zvukari,OU=Odd PUB - 14229880,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-439007",
                              "Description": "PC pro zvukove brigadniky",
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-380604,OU=Zvukari,OU=Odd PUB - 14229880,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-380604",
                              "Description": "PC zvukaru",
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=Odd VVM - 14229740,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Odd VVM - 14229740",
                      "Description": "Oddeleni vnejsich vztahu a marketingu (14229740) - Wplace-229740",
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=3027OU,OU=Odd VVM - 14229740,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "3027OU",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=LAW-D-449989,OU=3027OU,OU=Odd VVM - 14229740,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-449989",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=LAW-D-441154,OU=Odd VVM - 14229740,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-441154",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-394672,OU=Odd VVM - 14229740,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-394672",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-449721,OU=Odd VVM - 14229740,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-449721",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=Odd VVPP - 14229917,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Odd VVPP - 14229917",
                      "Description": "Oddeleni pro vedu, vyzkum a projektovou podporu (14229917) - Wplace-229917",
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=S176,OU=Odd VVPP - 14229917,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "S176",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=LAW-D-394674,OU=S176,OU=Odd VVPP - 14229917,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-394674",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=S174,OU=Odd VVPP - 14229917,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "S174",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=LAW-D-375375,OU=S174,OU=Odd VVPP - 14229917,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-375375",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-375362,OU=S174,OU=Odd VVPP - 14229917,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-375362",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=S175,OU=Odd VVPP - 14229917,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "S175",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=LAW-D-394656,OU=S175,OU=Odd VVPP - 14229917,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-394656",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-391193,OU=S175,OU=Odd VVPP - 14229917,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-391193",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-386931,OU=S175,OU=Odd VVPP - 14229917,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-386931",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-394664,OU=S175,OU=Odd VVPP - 14229917,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-394664",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=Odd ZV - 14229916,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Odd ZV - 14229916",
                      "Description": "Oddeleni pro zahranicni vztahy (14229916) - Wplace-229916",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=LAW-D-383067,OU=Odd ZV - 14229916,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-383067",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-449726,OU=Odd ZV - 14229916,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-449726",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=PaMO - 14229912,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "PaMO - 14229912",
                      "Description": "Personalni a mzdove oddeleni (14229912) - Wplace-229912",
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=017,OU=PaMO - 14229912,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "017",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=LAW-D-439002,OU=017,OU=PaMO - 14229912,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-439002",
                              "Description": "Pipalova",
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=019,OU=PaMO - 14229912,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "019",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=LAW-D-375365,OU=019,OU=PaMO - 14229912,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-375365",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-391180,OU=019,OU=PaMO - 14229912,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-391180",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-394671,OU=019,OU=PaMO - 14229912,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-394671",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=PGS,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "PGS",
                      "Description": "Mistnost pro doktorske studium",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=LAW-D-PGS01,OU=PGS,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-PGS01",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-PGS02,OU=PGS,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-PGS02",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-PGS03,OU=PGS,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-PGS03",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-PGS04,OU=PGS,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-PGS04",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-PGS05,OU=PGS,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-PGS05",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-PGS06,OU=PGS,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-PGS06",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-PGS07,OU=PGS,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-PGS07",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-PGS08,OU=PGS,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-PGS08",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-PGS09,OU=PGS,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-PGS09",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-PGS10,OU=PGS,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-PGS10",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-PGS11,OU=PGS,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-PGS11",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-PGS12,OU=PGS,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-PGS12",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=SO - 14229913,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "SO - 14229913",
                      "Description": "Studijni oddeleni (14229913) - Wplace-229913",
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=3811OU,OU=SO - 14229913,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "3811OU",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=LAW-D-457028,OU=3811OU,OU=SO - 14229913,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-457028",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=LAW-D-457043,OU=SO - 14229913,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-457043",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-375355,OU=SO - 14229913,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-375355",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-394675,OU=SO - 14229913,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-394675",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-394661,OU=SO - 14229913,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-394661",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-394659,OU=SO - 14229913,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-394659",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-394665,OU=SO - 14229913,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-394665",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-375370,OU=SO - 14229913,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-375370",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-394676,OU=SO - 14229913,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-394676",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-449722,OU=SO - 14229913,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-449722",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=UK - 14229840,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UK - 14229840",
                      "Description": "Ustredni knihovna (14229840) - Wplace-229840",
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=HorniPult,OU=UK - 14229840,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "HorniPult",
                          "Description": "Pocitace u vstupu do knihovny (tecko)",
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=LAW-D-402541,OU=HorniPult,OU=UK - 14229840,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-402541",
                              "Description": "Knihovna horni pult",
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=SpodniPulty,OU=UK - 14229840,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SpodniPulty",
                          "Description": "Pocitace ve spodni casti knihovny",
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=LAW-D-402540,OU=SpodniPulty,OU=UK - 14229840,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-402540",
                              "Description": "Knihovna spodni pult 2",
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-402539,OU=SpodniPulty,OU=UK - 14229840,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-402539",
                              "Description": "Knihovna spodni pult 1",
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=OpravnaPC,OU=UK - 14229840,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "OpravnaPC",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=LAW-D-465532,OU=OpravnaPC,OU=UK - 14229840,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-465532",
                              "Description": "PC v opravne knih",
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=Kancelare,OU=UK - 14229840,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "Kancelare",
                          "Description": "Pocitace v kancelarich knihovny",
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=LAW-D-455487,OU=Kancelare,OU=UK - 14229840,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-455487",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-455491,OU=Kancelare,OU=UK - 14229840,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-455491",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-455493,OU=Kancelare,OU=UK - 14229840,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-455493",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-457025,OU=Kancelare,OU=UK - 14229840,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-457025",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-455488,OU=Kancelare,OU=UK - 14229840,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-455488",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-455484,OU=Kancelare,OU=UK - 14229840,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-455484",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-455492,OU=Kancelare,OU=UK - 14229840,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-455492",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-455485,OU=Kancelare,OU=UK - 14229840,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-455485",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-455486,OU=Kancelare,OU=UK - 14229840,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-455486",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-457026,OU=Kancelare,OU=UK - 14229840,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-457026",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-455489,OU=Kancelare,OU=UK - 14229840,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-455489",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-455490,OU=Kancelare,OU=UK - 14229840,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-455490",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=Ustav OS - 14222400,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Ustav OS - 14222400",
                      "Description": "Ustav pro otazky soudnictvi (14222400) - Wplace-222400",
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=142,OU=Ustav OS - 14222400,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "142",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=LAW-D-394657,OU=142,OU=Ustav OS - 14222400,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-394657",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=400,OU=Ustav OS - 14222400,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "400",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=LAW-D-386938,OU=400,OU=Ustav OS - 14222400,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-386938",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-386929,OU=400,OU=Ustav OS - 14222400,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-386929",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-386942,OU=400,OU=Ustav OS - 14222400,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-386942",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-386915,OU=400,OU=Ustav OS - 14222400,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-386915",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-386918,OU=400,OU=Ustav OS - 14222400,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-386918",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-386920,OU=400,OU=Ustav OS - 14222400,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-386920",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-386933,OU=400,OU=Ustav OS - 14222400,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-386933",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-386936,OU=400,OU=Ustav OS - 14222400,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-386936",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-386914,OU=400,OU=Ustav OS - 14222400,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-386914",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=Ustav PT - 14222200,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Ustav PT - 14222200",
                      "Description": "Ustav prava a technologii (14222200) - Wplace-222200",
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=213,OU=Ustav PT - 14222200,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "213",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=LAW-D-375373,OU=213,OU=Ustav PT - 14222200,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-375373",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-386919,OU=213,OU=Ustav PT - 14222200,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-386919",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-444365,OU=213,OU=Ustav PT - 14222200,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-444365",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=S146,OU=Ustav PT - 14222200,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "S146",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=LAW-D-402534,OU=S146,OU=Ustav PT - 14222200,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-402534",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-402550,OU=S146,OU=Ustav PT - 14222200,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-402550",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-402552,OU=S146,OU=Ustav PT - 14222200,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-402552",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-449719,OU=S146,OU=Ustav PT - 14222200,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-449719",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    }
                  ],
                  "Computers": [
                    {
                      "DistinguishedName": "CN=LAW-D-402453,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "LAW-D-402453",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=LAW-D-402509,OU=ZamPC,OU=LAW,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "LAW-D-402509",
                      "Description": null,
                      "Type": "Computer"
                    }
                  ],
                  "Type": "OrganizationalUnit"
                }
              ],
              "Computers": [],
              "Type": "OrganizationalUnit"
            },
            {
              "DistinguishedName": "OU=PED,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
              "Name": "PED",
              "Description": "kryzan@ped.muni.cz",
              "OrganizationalUnits": [],
              "Computers": [],
              "Type": "OrganizationalUnit"
            },
            {
              "DistinguishedName": "OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
              "Name": "FAF",
              "Description": "cit@pharm.muni.cz",
              "OrganizationalUnits": [
                {
                  "DistinguishedName": "OU=45,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "45",
                  "Description": null,
                  "OrganizationalUnits": [
                    {
                      "DistinguishedName": "OU=118,OU=45,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "118",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FAF-D-427570,OU=118,OU=45,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FAF-D-427570",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=236,OU=45,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "236",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FAF-D-427599,OU=236,OU=45,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FAF-D-427599",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=240,OU=45,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "240",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FAF-D-387829,OU=240,OU=45,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FAF-D-387829",
                          "Description": "528110 - Juraj Marku\u0161",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FAF-D-387822,OU=240,OU=45,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FAF-D-387822",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FAF-D-447605,OU=240,OU=45,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FAF-D-447605",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FAF-D-387831,OU=240,OU=45,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FAF-D-387831",
                          "Description": "507066 - Martin Helcman",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=308,OU=45,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "308",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FAF-D-427465,OU=308,OU=45,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FAF-D-427465",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FAF-D-45UMF02,OU=308,OU=45,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FAF-D-45UMF02",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=237,OU=45,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "237",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FAF-D-427227,OU=237,OU=45,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FAF-D-427227",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=239,OU=45,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "239",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FAF-D-45UPL03,OU=239,OU=45,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FAF-D-45UPL03",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FAF-D-45UPL07,OU=239,OU=45,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FAF-D-45UPL07",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FAF-D-427528,OU=239,OU=45,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FAF-D-427528",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FAF-D-45UPL08,OU=239,OU=45,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FAF-D-45UPL08",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=72,OU=45,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "72",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FAF-D-387814,OU=72,OU=45,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FAF-D-387814",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=219,OU=45,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "219",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FAF-D-45UPL04,OU=219,OU=45,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FAF-D-45UPL04",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=246,OU=45,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "246",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FAF-D-427902,OU=246,OU=45,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FAF-D-427902",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FAF-D-387824,OU=246,OU=45,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FAF-D-387824",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=310,OU=45,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "310",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FAF-D-427226,OU=310,OU=45,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FAF-D-427226",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FAF-D-427225,OU=310,OU=45,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FAF-D-427225",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FAF-D-45UMF01,OU=310,OU=45,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FAF-D-45UMF01",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=157,OU=45,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "157",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FAF-D-444920,OU=157,OU=45,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FAF-D-444920",
                          "Description": "Marie Valentov\u00E1",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=125,OU=45,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "125",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FAF-D-427414,OU=125,OU=45,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FAF-D-427414",
                          "Description": "Podatelna Senkyrova",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FAF-D-427256,OU=125,OU=45,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FAF-D-427256",
                          "Description": "CJV",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FAF-D-473598,OU=125,OU=45,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FAF-D-473598",
                          "Description": "CJV",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=UMF,OU=45,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UMF",
                      "Description": null,
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=325,OU=UMF,OU=45,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "325",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=FAF-D-449422,OU=325,OU=UMF,OU=45,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FAF-D-449422",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=210,OU=45,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "210",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FAF-D-45UPL01,OU=210,OU=45,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FAF-D-45UPL01",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FAF-D-45UPL02,OU=210,OU=45,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FAF-D-45UPL02",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=156,OU=45,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "156",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FAF-D-JUNOVA,OU=156,OU=45,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FAF-D-JUNOVA",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FAF-D-427706,OU=156,OU=45,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FAF-D-427706",
                          "Description": "Vendula Star\u00E1",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=109,OU=45,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "109",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FAF-D-427560,OU=109,OU=45,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FAF-D-427560",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=322,OU=45,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "322",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FAF-D-346788,OU=322,OU=45,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FAF-D-346788",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=208,OU=45,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "208",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FAF-D-437443,OU=208,OU=45,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FAF-D-437443",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=120,OU=45,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "120",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FAF-D-427448,OU=120,OU=45,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FAF-D-427448",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FAF-D-427464,OU=120,OU=45,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FAF-D-427464",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=162,OU=45,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "162",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FAF-D-427544,OU=162,OU=45,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FAF-D-427544",
                          "Description": "Lucie Coufalov\u00E1",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FAF-D-427264,OU=162,OU=45,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FAF-D-427264",
                          "Description": "Barbora Tomkov\u00E1",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FAF-D-427255,OU=162,OU=45,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FAF-D-427255",
                          "Description": "489184 - Daniela \u010Cern\u00E1",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=242,OU=45,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "242",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FAF-D-427529,OU=242,OU=45,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FAF-D-427529",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=247,OU=45,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "247",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FAF-D-45UPL05,OU=247,OU=45,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FAF-D-45UPL05",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FAF-D-45UPL06,OU=247,OU=45,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FAF-D-45UPL06",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=204,OU=45,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "204",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FAF-D-427527,OU=204,OU=45,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FAF-D-427527",
                          "Description": "Hlavat\u00E1",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=122,OU=45,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "122",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FAF-D-427501,OU=122,OU=45,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FAF-D-427501",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=309,OU=45,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "309",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FAF-D-449420,OU=309,OU=45,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FAF-D-449420",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FAF-D-PECHACKOV,OU=309,OU=45,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FAF-D-PECHACKOV",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FAF-D-427540,OU=309,OU=45,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FAF-D-427540",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=326,OU=45,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "326",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FAF-D-427519,OU=326,OU=45,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FAF-D-427519",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=108,OU=45,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "108",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FAF-D-445014,OU=108,OU=45,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FAF-D-445014",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    }
                  ],
                  "Computers": [],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=18,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "18",
                  "Description": null,
                  "OrganizationalUnits": [
                    {
                      "DistinguishedName": "OU=212,OU=18,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "212",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FAF-D-387823,OU=212,OU=18,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FAF-D-387823",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    }
                  ],
                  "Computers": [],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=44,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "44",
                  "Description": null,
                  "OrganizationalUnits": [
                    {
                      "DistinguishedName": "OU=130,OU=44,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "130",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FAF-D-427556,OU=130,OU=44,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FAF-D-427556",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=310,OU=44,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "310",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FAF-D-387828,OU=310,OU=44,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FAF-D-387828",
                          "Description": "507385 - Jaroslava Jamrichova",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=212,OU=44,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "212",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FAF-D-440964,OU=212,OU=44,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FAF-D-440964",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=139,OU=44,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "139",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FAF-D-427557,OU=139,OU=44,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FAF-D-427557",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FAF-D-427436,OU=139,OU=44,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FAF-D-427436",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FAF-D-427555,OU=139,OU=44,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FAF-D-427555",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=015,OU=44,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "015",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=112,OU=44,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "112",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FAF-D-427228,OU=112,OU=44,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FAF-D-427228",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=309,OU=44,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "309",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FAF-D-44310,OU=309,OU=44,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FAF-D-44310",
                          "Description": "507330 - Karolina Seligova",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=307,OU=44,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "307",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FAF-D-UHER,OU=307,OU=44,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FAF-D-UHER",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=312,OU=44,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "312",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FAF-D-427532,OU=312,OU=44,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FAF-D-427532",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=109,OU=44,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "109",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FAF-D-427860,OU=109,OU=44,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FAF-D-427860",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    }
                  ],
                  "Computers": [],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=NEZA\u0158AZENO,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "NEZA\u0158AZENO",
                  "Description": null,
                  "OrganizationalUnits": [],
                  "Computers": [
                    {
                      "DistinguishedName": "CN=FAF-D-387825,OU=NEZA\u0158AZENO,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FAF-D-387825",
                      "Description": null,
                      "Type": "Computer"
                    }
                  ],
                  "Type": "OrganizationalUnit"
                }
              ],
              "Computers": [
                {
                  "DistinguishedName": "CN=FAF-D-473597,OU=FAF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "FAF-D-473597",
                  "Description": null,
                  "Type": "Computer"
                }
              ],
              "Type": "OrganizationalUnit"
            },
            {
              "DistinguishedName": "OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
              "Name": "FF",
              "Description": "cit@phil.muni.cz",
              "OrganizationalUnits": [
                {
                  "DistinguishedName": "OU=DejinyUmeni,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "DejinyUmeni",
                  "Description": null,
                  "OrganizationalUnits": [],
                  "Computers": [
                    {
                      "DistinguishedName": "CN=FF-D-DEJUM21,OU=DejinyUmeni,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-DEJUM21",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-DEJUM26,OU=DejinyUmeni,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-DEJUM26",
                      "Description": "CENTRUM",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-DEJUM32,OU=DejinyUmeni,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-DEJUM32",
                      "Description": "KNIHOVNA-BELTING",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-DEJUM31,OU=DejinyUmeni,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-DEJUM31",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-DEJUM04,OU=DejinyUmeni,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-DEJUM04",
                      "Description": "Ales Flidr - HP AIO",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-DEJUM09,OU=DejinyUmeni,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-DEJUM09",
                      "Description": "Doktorandi - flidr HP AIO",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-DEJUM10,OU=DejinyUmeni,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-DEJUM10",
                      "Description": "Doktorandi - Flidr HP AIO",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-DEJUM17,OU=DejinyUmeni,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-DEJUM17",
                      "Description": "Doktorandi - Flidr HP AIO",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-DEJUM18,OU=DejinyUmeni,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-DEJUM18",
                      "Description": "Doktorandi - Flidr HP AIO",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-DEJUM33,OU=DejinyUmeni,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-DEJUM33",
                      "Description": "Doktorandi - Flidr HP AIO",
                      "Type": "Computer"
                    }
                  ],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=Muzikologie,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "Muzikologie",
                  "Description": null,
                  "OrganizationalUnits": [],
                  "Computers": [
                    {
                      "DistinguishedName": "CN=FF-D-MUZIK07,OU=Muzikologie,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-MUZIK07",
                      "Description": "Jitka Leflikova (m. 07008)",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-MUZIK08,OU=Muzikologie,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-MUZIK08",
                      "Description": "Zahradka",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-MUZIK13,OU=Muzikologie,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-MUZIK13",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-MUZIK20,OU=Muzikologie,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-MUZIK20",
                      "Description": "Manas AIO Acer 8gen",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-MUZIK15,OU=Muzikologie,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-MUZIK15",
                      "Description": "CEVELA-KNIHOVNA",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-MUZIK25,OU=Muzikologie,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-MUZIK25",
                      "Description": "Tesa\u0159- Acer Veriton",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-MUZIK24,OU=Muzikologie,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-MUZIK24",
                      "Description": "Perutkov\u00E1 - Acer Veriton",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-MUZIK02,OU=Muzikologie,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-MUZIK02",
                      "Description": "CSERES- Veriton",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-MUZIK37,OU=Muzikologie,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-MUZIK37",
                      "Description": "doktorandi - ASUS AIO",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-MUZIK03,OU=Muzikologie,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-MUZIK03",
                      "Description": "doktorandi-asus",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-MUZIK05,OU=Muzikologie,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-MUZIK05",
                      "Description": "Koudelkov\u00E1 - Veriton",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-MUZIK26,OU=Muzikologie,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-MUZIK26",
                      "Description": "Kalina",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-MUZIK04,OU=Muzikologie,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-MUZIK04",
                      "Description": "M.franek",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-MUZIK18,OU=Muzikologie,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-MUZIK18",
                      "Description": "Leflikova-ASUS",
                      "Type": "Computer"
                    }
                  ],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=Pedagogika,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "Pedagogika",
                  "Description": null,
                  "OrganizationalUnits": [],
                  "Computers": [
                    {
                      "DistinguishedName": "CN=FF-D-PED20,OU=Pedagogika,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-PED20",
                      "Description": "Kl\u00E1ra Harv\u00E1nkov\u00E1 (C414) [i5-4590S]",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-PED06,OU=Pedagogika,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-PED06",
                      "Description": "Martin Sedl\u00E1\u010Dek (C415)  At7  [i5-4590S]",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-PED09,OU=Pedagogika,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-PED09",
                      "Description": "Bohum\u00EDra Lazarov\u00E1 (C424) [i5-4590S]",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-PED17,OU=Pedagogika,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-PED17",
                      "Description": "Zuzana Salamounova / Ingrid Cejkova  (C427)",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-PED08,OU=Pedagogika,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-PED08",
                      "Description": "Pol nahradni",
                      "Type": "Computer"
                    }
                  ],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=CIT,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "CIT",
                  "Description": null,
                  "OrganizationalUnits": [
                    {
                      "DistinguishedName": "OU=ELF,OU=CIT,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ELF",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FF-D-CIT36,OU=ELF,OU=CIT,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-CIT36",
                          "Description": "DHM 462494, ELF, G.107, HP i5, .5T SSD, 2024-01",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-CIT37,OU=ELF,OU=CIT,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-CIT37",
                          "Description": "DHM 462495, ELF, G.107, HP i5, .5T SSD, 2024-01",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-CIT38,OU=ELF,OU=CIT,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-CIT38",
                          "Description": "DHM 46249x, ELF, G.107, HP i7, 1T SSD, 2024-01",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-CIT18,OU=ELF,OU=CIT,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-CIT18",
                          "Description": "DHM 400335, Jakub Jarina",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=CIT22,OU=CIT,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "CIT22",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=CIT19s,OU=CIT,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "CIT19s",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FF-D-CIT19,OU=CIT19s,OU=CIT,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-CIT19",
                          "Description": "DHM 392808, podpora ELF, MR, JJ [i5-6400]",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=CIT33,OU=CIT,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "CIT33",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FF-D-CIT33,OU=CIT33,OU=CIT,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-CIT33",
                          "Description": "DHM 423382 Tomas [i7-9700]",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    }
                  ],
                  "Computers": [
                    {
                      "DistinguishedName": "CN=FF-D-CIT13,OU=CIT,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-CIT13",
                      "Description": "Zdenek Zahora (B1)  [i7-4790]",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-CIT34,OU=CIT,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-CIT34",
                      "Description": "DHM 429738 Michael",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-CIT22,OU=CIT,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-CIT22",
                      "Description": "DHM 448166 Daniel Mik\u0161\u00EDk",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-CIT15,OU=CIT,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-CIT15",
                      "Description": "Josef",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-CIT98,OU=CIT,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-CIT98",
                      "Description": "DHM 400804 ACER",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-CIT32,OU=CIT,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-CIT32",
                      "Description": "DHM 423384, David",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-CIT07,OU=CIT,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-CIT07",
                      "Description": "zasedacka",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-CIT23,OU=CIT,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-CIT23",
                      "Description": "videostudio_strih2",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-CIT11,OU=CIT,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-CIT11",
                      "Description": "ZP 45746 u Ondry",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-CIT28,OU=CIT,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-CIT28",
                      "Description": "DHM 419909 Zdena M\u00E1ckov\u00E1 AIO  [i5-8600]",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-CIT35,OU=CIT,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-CIT35",
                      "Description": "Videostudio - 1/2025",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-CIT04,OU=CIT,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-CIT04",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-CIT90,OU=CIT,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-CIT90",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-CIT91,OU=CIT,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-CIT91",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-CIT92,OU=CIT,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-CIT92",
                      "Description": null,
                      "Type": "Computer"
                    }
                  ],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=Psychologie,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "Psychologie",
                  "Description": null,
                  "OrganizationalUnits": [
                    {
                      "DistinguishedName": "OU=Bila,OU=Psychologie,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Bila",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FF-D-PSYCHO15,OU=Bila,OU=Psychologie,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-PSYCHO15",
                          "Description": "Doktorandi (C513)",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-PSYCHO10,OU=Bila,OU=Psychologie,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-PSYCHO10",
                          "Description": "Doktorandi (C513)",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-PSYCHO26,OU=Bila,OU=Psychologie,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-PSYCHO26",
                          "Description": "Doktorandi (C513) sklo",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-PSYCHO07,OU=Bila,OU=Psychologie,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-PSYCHO07",
                          "Description": "Doktorandi (C513) sklo",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-PSYCHO11,OU=Bila,OU=Psychologie,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-PSYCHO11",
                          "Description": "Doktorandi (C513)",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    }
                  ],
                  "Computers": [
                    {
                      "DistinguishedName": "CN=FF-D-PSYCHO16,OU=Psychologie,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-PSYCHO16",
                      "Description": "DHM 438190 Michal Hrdli\u010Dka (C515) [i5-8265U]",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-PSYCHO28,OU=Psychologie,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-PSYCHO28",
                      "Description": "volny",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-PSYCHO03,OU=Psychologie,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-PSYCHO03",
                      "Description": "volny",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-PSYCHO20,OU=Psychologie,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-PSYCHO20",
                      "Description": "Zdenka Stranska (C518)",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-PSYCHO29,OU=Psychologie,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-PSYCHO29",
                      "Description": "DHM 438181 Hana P\u0159ikrylov\u00E1 Ku\u010Derov\u00E1 (C527) [i5-8265U]",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-PSYCHO30,OU=Psychologie,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-PSYCHO30",
                      "Description": null,
                      "Type": "Computer"
                    }
                  ],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=CJV,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "CJV",
                  "Description": null,
                  "OrganizationalUnits": [],
                  "Computers": [
                    {
                      "DistinguishedName": "CN=FF-D-CJV11,OU=CJV,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-CJV11",
                      "Description": "Vedouc\u00ED CJV - Lenovo AIO",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-CJV08,OU=CJV,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-CJV08",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-CJV07,OU=CJV,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-CJV07",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-CJV01,OU=CJV,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-CJV01",
                      "Description": null,
                      "Type": "Computer"
                    }
                  ],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=Estetika,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "Estetika",
                  "Description": null,
                  "OrganizationalUnits": [],
                  "Computers": [],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=Etnologie,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "Etnologie",
                  "Description": null,
                  "OrganizationalUnits": [],
                  "Computers": [
                    {
                      "DistinguishedName": "CN=FF-D-ETNOLOG01,OU=Etnologie,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-ETNOLOG01",
                      "Description": "DHM 327119 , 2023-03",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-ETNOLOG18,OU=Etnologie,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-ETNOLOG18",
                      "Description": "Alena K\u0159\u00ED\u017Eov\u00E1, 17406, J.304, HP Pro Tower, 2024-10",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-ETNOLOG04,OU=Etnologie,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-ETNOLOG04",
                      "Description": "Martina Pavlicov\u00E1, 406, DHM 400822, Acer Veriton, 2024-09 v\u00FDm.",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-ETNOLOG16,OU=Etnologie,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-ETNOLOG16",
                      "Description": "Eva Chovancov\u00E1, 112294, 2024-02",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-ETNOLOG15,OU=Etnologie,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-ETNOLOG15",
                      "Description": "Polou\u010Dek, 2023-03",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-ETNOLOG17,OU=Etnologie,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-ETNOLOG17",
                      "Description": "Barbora Navr\u00E1tilov\u00E1, 146454, J.303, HP Pro Tower, 2024-10",
                      "Type": "Computer"
                    }
                  ],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=Dekanat,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "Dekanat",
                  "Description": null,
                  "OrganizationalUnits": [
                    {
                      "DistinguishedName": "OU=Studijni,OU=Dekanat,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Studijni",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FF-D-STUDIJNI01,OU=Studijni,OU=Dekanat,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-STUDIJNI01",
                          "Description": "DHM 448060 Pavl\u00EDna \u0160v\u00E1stov\u00E1 (C112) [i5-11500B]",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-STUDIJNI02,OU=Studijni,OU=Dekanat,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-STUDIJNI02",
                          "Description": "DHM 448041 Stanislava Tich\u00E1 (C111) [i5-11500B]",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-STUDIJNI17,OU=Studijni,OU=Dekanat,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-STUDIJNI17",
                          "Description": "DHM 448064 Monika Bystr\u00E1 (C113) [i5-11500B]",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-STUDIJNI03,OU=Studijni,OU=Dekanat,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-STUDIJNI03",
                          "Description": "DHM 448042 Zde\u0148ka Doln\u00EDkov\u00E1 (C111) [i5-11500B]",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-STUDIJNI09,OU=Studijni,OU=Dekanat,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-STUDIJNI09",
                          "Description": "DHM 448073 Andrea Packov\u00E1 (C114) [i5-11500B]",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-STUDIJNI07,OU=Studijni,OU=Dekanat,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-STUDIJNI07",
                          "Description": "DHM 419914 [i5-8400]",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-STUDIJNI04,OU=Studijni,OU=Dekanat,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-STUDIJNI04",
                          "Description": "DHM 448057 Ji\u0159\u00ED \u017Doudl\u00EDk (C113) [i5-11500B]",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-STUDIJNI10,OU=Studijni,OU=Dekanat,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-STUDIJNI10",
                          "Description": "DHM  422113 Jana Dole\u017Eelov\u00E1 (C110)  [i5-8400T]",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-STUDIJNI15,OU=Studijni,OU=Dekanat,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-STUDIJNI15",
                          "Description": "DHM 419913 Lucie Kr\u00E1l\u00EDkov\u00E1 (C114) [i5-8600]",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-STUDIJNI12,OU=Studijni,OU=Dekanat,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-STUDIJNI12",
                          "Description": "DHM 448062 Kate\u0159ina Boberov\u00E1 (C115b) [i5-11500B]",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-STUDIJNI05,OU=Studijni,OU=Dekanat,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-STUDIJNI05",
                          "Description": "DHM 448067 Lucie Mart\u00EDnkov\u00E1 (C115b) [i5-11500B]",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-STUDIJNI06,OU=Studijni,OU=Dekanat,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-STUDIJNI06",
                          "Description": "DHM 448068 Magdal\u00E9na Dvo\u0159\u00E1\u010Dkov\u00E1 (C110) [i5-11500B]",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-STUDIJNI08,OU=Studijni,OU=Dekanat,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-STUDIJNI08",
                          "Description": "DHM 448070 Jana Kuncov\u00E1 (C114) [i5-11500B]",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=Ekonomicke,OU=Dekanat,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Ekonomicke",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FF-D-EKO06,OU=Ekonomicke,OU=Dekanat,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-EKO06",
                          "Description": "Jitka Kapinusov\u00E1 (C221) [R5-5650GE]",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-EKO07,OU=Ekonomicke,OU=Dekanat,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-EKO07",
                          "Description": "Petra Koudelkov\u00E1 (C231) [i5-13500T]",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-EKO05,OU=Ekonomicke,OU=Dekanat,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-EKO05",
                          "Description": "DHM448071 Michaela Jedli\u010Dkov\u00E1 Svobodov\u00E1 [i5-11500B]",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-EKO13,OU=Ekonomicke,OU=Dekanat,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-EKO13",
                          "Description": "DHM 448056 [i5-11500] CIT",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-EKO04,OU=Ekonomicke,OU=Dekanat,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-EKO04",
                          "Description": "DHM 428840 Irena \u0160mahelov\u00E1 (C230) [R5-5650GE]",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-EKO12,OU=Ekonomicke,OU=Dekanat,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-EKO12",
                          "Description": "Jana Sedl\u00E1\u010Dkov\u00E1 [R5-5625U]",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-EKO01,OU=Ekonomicke,OU=Dekanat,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-EKO01",
                          "Description": "DHM 448061 Marcela Mrkosov\u00E1 [i5-11500B]",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-EKO14,OU=Ekonomicke,OU=Dekanat,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-EKO14",
                          "Description": "DHM 421685 Veronika Sta\u0148kov\u00E1 / Martina Dudkov\u00E1 [i5-8400] up",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=Osobni,OU=Dekanat,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Osobni",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FF-D-OSOBNI06,OU=Osobni,OU=Dekanat,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-OSOBNI06",
                          "Description": "DHM 448037 Kamila Hoja\u010Dov\u00E1 (C210) [i5-11500B]",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-OSOBNI04,OU=Osobni,OU=Dekanat,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-OSOBNI04",
                          "Description": "Michaela \u0160vih\u00E1lkov\u00E1 (C208) [i5-11500B]",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-OSOBNI07,OU=Osobni,OU=Dekanat,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-OSOBNI07",
                          "Description": "DHM 448039 Veronika Jan\u00EDkova (C211) [i5-11500B]",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-OSOBNI01,OU=Osobni,OU=Dekanat,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-OSOBNI01",
                          "Description": "DHM 448038 Eva Br\u00E1zdov\u00E1 Urbanov\u00E1 (C210) [i5-11500B]",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-OSOBNI02,OU=Osobni,OU=Dekanat,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-OSOBNI02",
                          "Description": "Jana Mare\u010Dkov\u00E1 (C209a) [i5-11500B]",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=Veda,OU=Dekanat,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Veda",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FF-D-VEDA03,OU=Veda,OU=Dekanat,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-VEDA03",
                          "Description": "DHM 419910 Pavla Luke\u0161ov\u00E1 (C234c) [i5-8600]",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-VEDA06,OU=Veda,OU=Dekanat,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-VEDA06",
                          "Description": "DHM 448054 Al\u017Eb\u011Bta Dvo\u0159\u00E1kov\u00E1 (C234d) [i5-11500B]",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-VEDA01,OU=Veda,OU=Dekanat,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-VEDA01",
                          "Description": "DHM 419921 Marketa Jur\u00E1\u0161ov\u00E1 (C226b) Acrobat 10  [Aio i5-8600]",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-VEDA05,OU=Veda,OU=Dekanat,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-VEDA05",
                          "Description": "DHM 447711 Dagmar Navr\u00E1tilov\u00E1 (C234e) [i5-11500B]",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-VEDA07,OU=Veda,OU=Dekanat,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-VEDA07",
                          "Description": "DHM 419915 Marie Sk\u0159ivanov\u00E1 (C226a)  [i5-8600]",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=Zahranicni,OU=Dekanat,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Zahranicni",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FF-D-OVV04,OU=Zahranicni,OU=Dekanat,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-OVV04",
                          "Description": "DHM 448031 Mark\u00E9ta Stul\u00EDrov\u00E1 (C116) [i5-11500B]",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-OVV05,OU=Zahranicni,OU=Dekanat,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-OVV05",
                          "Description": "DHM 447702 Kate\u0159ina Najbrtov\u00E1 (E) [i5-11500B]",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-OVV02,OU=Zahranicni,OU=Dekanat,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-OVV02",
                          "Description": "DHM 448065 Ond\u0159ej Krajtl (C116) [i5-11500B]",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-OVV03,OU=Zahranicni,OU=Dekanat,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-OVV03",
                          "Description": "DHM 448066 Petr Such\u00E1\u010Dek (C116) [i5-11500B]",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-DEKANAT15,OU=Zahranicni,OU=Dekanat,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-DEKANAT15",
                          "Description": "DHM 447703 Zde\u0148ka Kafed\u017Ei\u010D (C) [i5-11500B]",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-OVV01,OU=Zahranicni,OU=Dekanat,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-OVV01",
                          "Description": "DHM 448063 Tomas Weissar (C116) [i5-11500B]",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=Udrzba,OU=Dekanat,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Udrzba",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FF-D-UDRZBA01,OU=Udrzba,OU=Dekanat,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-UDRZBA01",
                          "Description": "BUD. M",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-VRATNICEG,OU=Udrzba,OU=Dekanat,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-VRATNICEG",
                          "Description": "Vratnice G  [i5-11500B]",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-UDRZBA10,OU=Udrzba,OU=Dekanat,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-UDRZBA10",
                          "Description": "DHM 447704 Pavel Jansk\u00FD (C130) [i5-11500B]",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-UDRZBA09,OU=Udrzba,OU=Dekanat,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-UDRZBA09",
                          "Description": "DHM 447709 (D pod vratnici) [i5-11500B]",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=Sekretariat,OU=Dekanat,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Sekretariat",
                      "Description": null,
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=SQtisk,OU=Sekretariat,OU=Dekanat,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SQtisk",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=FF-D-DEKANAT09,OU=SQtisk,OU=Sekretariat,OU=Dekanat,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FF-D-DEKANAT09",
                              "Description": "DHM  422118 Lenka Sobotkov\u00E1 (B2) [i5-8400T]",
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FF-D-DEKANAT10,OU=SQtisk,OU=Sekretariat,OU=Dekanat,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FF-D-DEKANAT10",
                              "Description": "DHM  448036 Kate\u0159ina Kalov\u00E1 (C119)  [i5-11500B]",
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FF-D-DEKANAT12,OU=Sekretariat,OU=Dekanat,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-DEKANAT12",
                          "Description": "Petra Ko\u0161umbersk\u00E1 (C213) [i5-11500B]",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-DEKANAT06,OU=Sekretariat,OU=Dekanat,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-DEKANAT06",
                          "Description": "Ji\u0159\u00ED Vel\u00EDnsk\u00FD [R7-5750GE]",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-DEKANAT14,OU=Sekretariat,OU=Dekanat,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-DEKANAT14",
                          "Description": "Dana Megov\u00E1 [i5-10400]",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-DEKANAT13,OU=Sekretariat,OU=Dekanat,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-DEKANAT13",
                          "Description": "DHM  448051 Milena Kamen\u00ED\u010Dkov\u00E1 [i5-11500B]",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=Zasedacka,OU=Dekanat,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Zasedacka",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FF-D-ZASEDACKA,OU=Zasedacka,OU=Dekanat,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-ZASEDACKA",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    }
                  ],
                  "Computers": [],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=FilmovaVeda,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "FilmovaVeda",
                  "Description": null,
                  "OrganizationalUnits": [],
                  "Computers": [],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=Filozofie,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "Filozofie",
                  "Description": null,
                  "OrganizationalUnits": [],
                  "Computers": [
                    {
                      "DistinguishedName": "CN=FF-D-FILOZOF18,OU=Filozofie,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-FILOZOF18",
                      "Description": "Radim B\u011Blohrad (A111)",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-FILOZOF21,OU=Filozofie,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-FILOZOF21",
                      "Description": "DHM 454152 Marek Picha (A119)",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-FILOZOF07,OU=Filozofie,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-FILOZOF07",
                      "Description": "Josef Petr\u017Eelka (A107)",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-FILOZOF16,OU=Filozofie,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-FILOZOF16",
                      "Description": "DHM 441454 Br\u00E1zda Radim (A111)",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-FILOZOF17,OU=Filozofie,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-FILOZOF17",
                      "Description": "volny",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-FILOZOF05,OU=Filozofie,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-FILOZOF05",
                      "Description": "volny",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-FILOZOF02,OU=Filozofie,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-FILOZOF02",
                      "Description": "DHM 441455 Pichov\u00E1 Dagmar (A118) [i5-9500]",
                      "Type": "Computer"
                    }
                  ],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=Germanistika,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "Germanistika",
                  "Description": null,
                  "OrganizationalUnits": [],
                  "Computers": [
                    {
                      "DistinguishedName": "CN=FF-D-GERMAN03,OU=Germanistika,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-GERMAN03",
                      "Description": "2024-10",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-GERMAN19,OU=Germanistika,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-GERMAN19",
                      "Description": "Jan Bud\u0148\u00E1k, 187769, J.417, 2023-12",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-GERMAN15,OU=Germanistika,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-GERMAN15",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-GERMAN09,OU=Germanistika,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-GERMAN09",
                      "Description": "ENG, DHM 376452, 2024-12",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-GERMAN13,OU=Germanistika,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-GERMAN13",
                      "Description": "DHM 376458, 2024-12",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-GERMAN24,OU=Germanistika,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-GERMAN24",
                      "Description": "Bratina, J.409, 2023-11",
                      "Type": "Computer"
                    }
                  ],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=Historie,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "Historie",
                  "Description": null,
                  "OrganizationalUnits": [],
                  "Computers": [
                    {
                      "DistinguishedName": "CN=FF-D-HISTORIE21,OU=Historie,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-HISTORIE21",
                      "Description": "Eva Bu\u0161ov\u00E1 (i5-7500)",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-HISTORIE06,OU=Historie,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-HISTORIE06",
                      "Description": "Ji\u0159\u00ED N\u011Bmec (A.415) [i5-6400]",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-HISTORIE20,OU=Historie,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-HISTORIE20",
                      "Description": "DHM454509 Ji\u0159\u00ED Hanu\u0161 (B1.408) [AMD Ryzen 5 PRO 5650G]",
                      "Type": "Computer"
                    }
                  ],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=Japanistika,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "Japanistika",
                  "Description": null,
                  "OrganizationalUnits": [],
                  "Computers": [
                    {
                      "DistinguishedName": "CN=FF-D-JAPAN07,OU=Japanistika,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-JAPAN07",
                      "Description": "DHM 376761 Zuzana Kubov\u010D\u00E1kov\u00E1 (B1.211) [i5-4590]",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-JAPAN02,OU=Japanistika,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-JAPAN02",
                      "Description": "DHM 376764 Marek Mikes (B1.212) [i5-4590]",
                      "Type": "Computer"
                    }
                  ],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=Jazykoveda,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "Jazykoveda",
                  "Description": null,
                  "OrganizationalUnits": [],
                  "Computers": [
                    {
                      "DistinguishedName": "CN=FF-D-JAZYK20,OU=Jazykoveda,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-JAZYK20",
                      "Description": "J.1??, HP ProOne 440 AiO, 2024-01",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-JAZYK21,OU=Jazykoveda,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-JAZYK21",
                      "Description": "J.1??, HP ProOne 440 AiO, 2024-01",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-JAZYK22,OU=Jazykoveda,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-JAZYK22",
                      "Description": "J.1??, HP ProOne 440 AiO, 2024-01",
                      "Type": "Computer"
                    }
                  ],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=KISK,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "KISK",
                  "Description": null,
                  "OrganizationalUnits": [],
                  "Computers": [
                    {
                      "DistinguishedName": "CN=FF-D-KISK05,OU=KISK,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-KISK05",
                      "Description": "Michal \u010Cern\u00FD (C122) [i5-10500]",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-KISK03,OU=KISK,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-KISK03",
                      "Description": "Ladislava Zbiejczuk Such\u00E1 (C123) [i5-13500]",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-KISK11,OU=KISK,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-KISK11",
                      "Description": "DHM 414965 Pavla Vizv\u00E1ry (C128) [i5-7500]",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-KISK10,OU=KISK,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-KISK10",
                      "Description": "DHM 376768 Monika Martonov\u00E1 (C127) [i5-4590]",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-KISK08,OU=KISK,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-KISK08",
                      "Description": "Pavl\u00EDna Maz\u00E1\u010Dov\u00E1 (C122) [AMD 5750GE]",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-KISK22,OU=KISK,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-KISK22",
                      "Description": "DHM 376439 Jan Harnu\u0161ek (C126) [i5-4590S]",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-KISK12,OU=KISK,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-KISK12",
                      "Description": "DHM 357630 [i5-3470s]",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-KISK13,OU=KISK,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-KISK13",
                      "Description": "- DHM 376440 Eli\u0161ka Jane\u010Dkov\u00E1 (C123) [i5-4590S]",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-KISK16,OU=KISK,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-KISK16",
                      "Description": "Eli\u0161ka Jane\u010Dkov\u00E1 (C123) [i5-14500]",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-KISK14,OU=KISK,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-KISK14",
                      "Description": "Petr Skyrik (C130)",
                      "Type": "Computer"
                    }
                  ],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=Literatura,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "Literatura",
                  "Description": null,
                  "OrganizationalUnits": [],
                  "Computers": [
                    {
                      "DistinguishedName": "CN=FF-D-LITERAT07,OU=Literatura,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-LITERAT07",
                      "Description": "DHM 401276",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-LITERAT17,OU=Literatura,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-LITERAT17",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-LITERAT20,OU=Literatura,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-LITERAT20",
                      "Description": "ZP 51581 Marie Hanzelkov\u00E1 (D.513) [i5-7500]",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-LITERAT02,OU=Literatura,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-LITERAT02",
                      "Description": "ZP 51585 Hashemi (D.517) [i5-7500]",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-LITERAT03,OU=Literatura,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-LITERAT03",
                      "Description": "DHM 401383 Michal Fr\u00E1nek (D.514) [i7-7700]",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-LITERAT04,OU=Literatura,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-LITERAT04",
                      "Description": "DHM 415117 Luisa Nov\u00E1kov\u00E1 (D.513) [i5-7500]",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-LITERAT10,OU=Literatura,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-LITERAT10",
                      "Description": "ZP 51571 Miroslav Balastik (D.515)  [i5-7500]",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-LITERAT06,OU=Literatura,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-LITERAT06",
                      "Description": "ZP 51577 (D.512)  [i5-7500]",
                      "Type": "Computer"
                    }
                  ],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=Religionistika,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "Religionistika",
                  "Description": null,
                  "OrganizationalUnits": [
                    {
                      "DistinguishedName": "OU=SQTisk,OU=Religionistika,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "SQTisk",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FF-D-RELIG11,OU=SQTisk,OU=Religionistika,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-RELIG11",
                          "Description": "Projekce, J.-103, HP ProOne 440 G9, SN: 8CN415016G, 2024-06",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-RELIG12,OU=SQTisk,OU=Religionistika,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-RELIG12",
                          "Description": "V\u00FDklenek, J.-104, HP ProOne 440 G9, SN: 8CN415016C, 2024-06",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    }
                  ],
                  "Computers": [
                    {
                      "DistinguishedName": "CN=FF-D-RELIG05,OU=Religionistika,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-RELIG05",
                      "Description": "Krist\u00FDna \u010Ci\u017Em\u00E1\u0159ov\u00E1, J.511, 2024-02",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-RELIG08,OU=Religionistika,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-RELIG08",
                      "Description": "Barbora Jan\u010Deov\u00E1, J.511, 2024-03",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-RELIG04,OU=Religionistika,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-RELIG04",
                      "Description": "zasedacka - L",
                      "Type": "Computer"
                    }
                  ],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=Romanistika,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "Romanistika",
                  "Description": null,
                  "OrganizationalUnits": [],
                  "Computers": [
                    {
                      "DistinguishedName": "CN=FF-D-ROMAN22,OU=Romanistika,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-ROMAN22",
                      "Description": "Rodr\u00EDguez Garc\u00EDa Cristina, G.101, 2023-04",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-ROMAN29,OU=Romanistika,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-ROMAN29",
                      "Description": "Iva Svobodov\u00E1, G.216, DHM 348929, 2024-02",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-ROMAN13,OU=Romanistika,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-ROMAN13",
                      "Description": "Daniela Vagata, G.117, DHM 376463, ENG, 2024-02",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-ROMAN14,OU=Romanistika,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-ROMAN14",
                      "Description": "Pavla Hradeck\u00E1, G.109, 2023-04",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-ROMAN24,OU=Romanistika,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-ROMAN24",
                      "Description": "Petr Dytrt, G.101, 2023-10, DHM 400845",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-ROMAN21,OU=Romanistika,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-ROMAN21",
                      "Description": "Rakov\u00E1 Zuzana, Daniela Shalom Vagata, G.214, 2023-04",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-ROMAN02,OU=Romanistika,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-ROMAN02",
                      "Description": "Petr Stehl\u00EDk, G.115, 2023-05",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-ROMAN35,OU=Romanistika,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-ROMAN35",
                      "Description": "Javorov\u00E1 \u0160vandov\u00E1 a Brabec, G.215, 2023-04",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-ROMAN37,OU=Romanistika,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-ROMAN37",
                      "Description": "Eva Lalkovi\u010Dov\u00E1, 397897, G.109a, DHM 438174, 2025-02",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-ROMAN04,OU=Romanistika,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-ROMAN04",
                      "Description": "Libu\u0161e Svobodov\u00E1, G.102, 2023-04",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-ROMAN16,OU=Romanistika,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-ROMAN16",
                      "Description": "Petr Kylou\u0161ek, G.110, 2023-05",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-ROMAN25,OU=Romanistika,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-ROMAN25",
                      "Description": "Christophe G\u00E9rard L. Cusimano, G.109, 2023-05",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-ROMAN32,OU=Romanistika,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-ROMAN32",
                      "Description": "sd\u00EDlen\u00FD, G.101, 2023-04",
                      "Type": "Computer"
                    }
                  ],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=Sinologie,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "Sinologie",
                  "Description": null,
                  "OrganizationalUnits": [],
                  "Computers": [],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=UKS,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "UKS",
                  "Description": null,
                  "OrganizationalUnits": [
                    {
                      "DistinguishedName": "OU=Doktorandi B2.307,OU=UKS,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Doktorandi B2.307",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FF-D-KLAS04,OU=Doktorandi B2.307,OU=UKS,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-KLAS04",
                          "Description": "- DHM 376455 Doktorandi (B2.307) [i5-4590s] e",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-KLAS06,OU=Doktorandi B2.307,OU=UKS,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-KLAS06",
                          "Description": "- DHM 376476 Doktorandi (B2.307) [i5-4590s] e",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-KLAS13,OU=Doktorandi B2.307,OU=UKS,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-KLAS13",
                          "Description": "- DHM 376473 Doktorandi (B2.307) [i5-4590s]",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-KLAS25,OU=Doktorandi B2.307,OU=UKS,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-KLAS25",
                          "Description": "- DHM 376457 Doktorandi (B2.307) [i5-4590s]",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-KLAS38,OU=Doktorandi B2.307,OU=UKS,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-KLAS38",
                          "Description": "- DHM 376441 Doktorandi (B2.307) [i5-4590s] e",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    }
                  ],
                  "Computers": [
                    {
                      "DistinguishedName": "CN=FF-D-KLAS18,OU=UKS,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-KLAS18",
                      "Description": "DHM 444162 Petros Marazopoulos (A219) [i7-11700]",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-KLAS26,OU=UKS,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-KLAS26",
                      "Description": "nachystano",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-KLAS28,OU=UKS,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-KLAS28",
                      "Description": "DHM 449670 Danu\u0161a \u010Ci\u017Em\u00EDkov\u00E1 (A210) [i5-11500B]",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-KLAS01,OU=UKS,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-KLAS01",
                      "Description": "nachystano",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-KLAS03,OU=UKS,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-KLAS03",
                      "Description": "nachystano",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-KLAS08,OU=UKS,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-KLAS08",
                      "Description": "nachystano",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-KLAS10,OU=UKS,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-KLAS10",
                      "Description": "nachystano",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-KLAS11,OU=UKS,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-KLAS11",
                      "Description": "nachystano",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-KLAS12,OU=UKS,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-KLAS12",
                      "Description": "nachystano",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-KLAS17,OU=UKS,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-KLAS17",
                      "Description": "nachystano",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-KLAS20,OU=UKS,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-KLAS20",
                      "Description": "nachystano",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-KLAS21,OU=UKS,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-KLAS21",
                      "Description": "nachystano",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-KLAS23,OU=UKS,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-KLAS23",
                      "Description": "nachystano",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-KLAS24,OU=UKS,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-KLAS24",
                      "Description": "nachystano",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-KLAS27,OU=UKS,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-KLAS27",
                      "Description": "nachystano",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-KLAS30,OU=UKS,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-KLAS30",
                      "Description": "nachystano",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-KLAS32,OU=UKS,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-KLAS32",
                      "Description": "nachystano",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-KLAS36,OU=UKS,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-KLAS36",
                      "Description": "Knihovna UKS (A217)",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-KLAS40,OU=UKS,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-KLAS40",
                      "Description": "nachystano",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-KLAS37,OU=UKS,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-KLAS37",
                      "Description": "Knihovna UKS (A217)",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-KLAS15,OU=UKS,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-KLAS15",
                      "Description": "Bednarikova Jarmila (A220)",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-KLAS07,OU=UKS,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-KLAS07",
                      "Description": "Jitka Erlebachova (A212)",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-KLAS02,OU=UKS,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-KLAS02",
                      "Description": "Zuzana \u010Cerm\u00E1kov\u00E1 Luk\u0161ov\u00E1 (A207) [i3-4150]",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-KLAS14,OU=UKS,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-KLAS14",
                      "Description": "Inna Mateiciucov\u00E1",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-KLAS29,OU=UKS,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-KLAS29",
                      "Description": "Jana Stekl\u00E1 (A215)",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-KLAS09,OU=UKS,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-KLAS09",
                      "Description": "Helena Krm\u00ED\u010Dkov\u00E1 (A211)",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-KLAS19,OU=UKS,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-KLAS19",
                      "Description": "DHM 441849 Nicole Votavov\u00E1 Sumelidisov\u00E1 (A219) [i5-10400]",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-KLAS31,OU=UKS,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-KLAS31",
                      "Description": "Urbanov\u00E1 Daniela (A214) [i5-13500T]",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-KLAS16,OU=UKS,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-KLAS16",
                      "Description": "DHM 423421 Katarina Petrovicova (A210) [i7-8700]",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-KLAS05,OU=UKS,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-KLAS05",
                      "Description": "Kate\u0159ina Bo\u010Dkov\u00E1 Loudov\u00E1  (A213) [i5-13500T]",
                      "Type": "Computer"
                    }
                  ],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=Knihovna,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "Knihovna",
                  "Description": null,
                  "OrganizationalUnits": [
                    {
                      "DistinguishedName": "OU=Infopult,OU=Knihovna,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Infopult",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FF-D-PULTC01,OU=Infopult,OU=Knihovna,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-PULTC01",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-PULTA01,OU=Infopult,OU=Knihovna,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-PULTA01",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-PULTB02,OU=Infopult,OU=Knihovna,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-PULTB02",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-PULTC02,OU=Infopult,OU=Knihovna,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-PULTC02",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-PULTD02,OU=Infopult,OU=Knihovna,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-PULTD02",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=2.Patro,OU=Knihovna,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "2.Patro",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=Akvizice,OU=Knihovna,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Akvizice",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    }
                  ],
                  "Computers": [
                    {
                      "DistinguishedName": "CN=FF-D-ENDER05,OU=Knihovna,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-ENDER05",
                      "Description": "Stanislav Hasil [i3-6100]",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-ENDER37,OU=Knihovna,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-ENDER37",
                      "Description": "Alena Kmochov\u00E1 [i3-3240]",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-ENDER36,OU=Knihovna,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-ENDER36",
                      "Description": "Eva Jandov\u00E1 [i3-2120]",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-ENDER29,OU=Knihovna,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-ENDER29",
                      "Description": "Jitka Skl\u00E1dan\u00E1 [i3-6100]",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-ENDER27,OU=Knihovna,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-ENDER27",
                      "Description": "[i3-6100]",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-ENDER33,OU=Knihovna,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-ENDER33",
                      "Description": "Ji\u0159\u00ED \u017Dg\u00E1ni\u010D \u0160prdl\u00EDk Acrobat11 (B2.-205) [i3-4150]",
                      "Type": "Computer"
                    }
                  ],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=Archeologie,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "Archeologie",
                  "Description": null,
                  "OrganizationalUnits": [],
                  "Computers": [
                    {
                      "DistinguishedName": "CN=FF-D-ARCHEO35,OU=Archeologie,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-ARCHEO35",
                      "Description": "Kirsch - Lenovo Thinkcentre M75t",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-ARCHEO36,OU=Archeologie,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-ARCHEO36",
                      "Description": "Lenovo Thinkcentre M75t",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-ARCHEO34,OU=Archeologie,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-ARCHEO34",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-ARCHEO05,OU=Archeologie,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-ARCHEO05",
                      "Description": "Tereza Zemancov\u00E1 (M24)",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-ARCHEO39,OU=Archeologie,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-ARCHEO39",
                      "Description": "lenovo Thinkcentre M70t G5",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-ARCHEO25,OU=Archeologie,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-ARCHEO25",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-ARCHEO20,OU=Archeologie,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-ARCHEO20",
                      "Description": "Jitka \u0160ib\u00ED\u010Dkov\u00E1",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-ARCHEO37,OU=Archeologie,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-ARCHEO37",
                      "Description": "ARCHEO-3DPRINT",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-ARCHEO38,OU=Archeologie,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-ARCHEO38",
                      "Description": "ARCHEO-3DPRINT",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-ARCHEO03,OU=Archeologie,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-ARCHEO03",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-ARCHEO33,OU=Archeologie,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-ARCHEO33",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-ATRIUM1,OU=Archeologie,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-ATRIUM1",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-ARCHEO11,OU=Archeologie,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-ARCHEO11",
                      "Description": "pc-vypocet",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-ARCHEO29,OU=Archeologie,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-ARCHEO29",
                      "Description": "3DTISK",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-ARCHEO17,OU=Archeologie,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-ARCHEO17",
                      "Description": "Tencer",
                      "Type": "Computer"
                    }
                  ],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=Divadlo,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "Divadlo",
                  "Description": null,
                  "OrganizationalUnits": [],
                  "Computers": [
                    {
                      "DistinguishedName": "CN=FF-D-DIVADLO16,OU=Divadlo,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-DIVADLO16",
                      "Description": "Lenka Bystr\u00E1, 117215, G.-108, DHM 400848, Acer Veriton Z4820G, 2024-10",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-DIVADLO17,OU=Divadlo,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-DIVADLO17",
                      "Description": "Miloslav Jur\u00E1ni, 254182, G.-109, DHM 454144, Lenovo ThinkCentre M75q G2, 2024-10",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-DIVADLO10,OU=Divadlo,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-DIVADLO10",
                      "Description": "G.-103, 2024-04",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-DIVADLO11,OU=Divadlo,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-DIVADLO11",
                      "Description": "G.-103, 2024-04",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-DIVADLO15,OU=Divadlo,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-DIVADLO15",
                      "Description": "G.-103, HP ProOne 440 G9, SN: 8CN4080C2V, 2024-04",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-DIVADLO19,OU=Divadlo,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-DIVADLO19",
                      "Description": "Ad\u00E9la Ohnheiserov\u00E1, 526811, G.-103, HP Pro Tower G9, SN: CZC4027CXV, 2024-04",
                      "Type": "Computer"
                    }
                  ],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=PVH,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "PVH",
                  "Description": null,
                  "OrganizationalUnits": [],
                  "Computers": [
                    {
                      "DistinguishedName": "CN=FF-D-PVH04,OU=PVH,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-PVH04",
                      "Description": "(B1.109)",
                      "Type": "Computer"
                    }
                  ],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=Cestina,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "Cestina",
                  "Description": null,
                  "OrganizationalUnits": [],
                  "Computers": [
                    {
                      "DistinguishedName": "CN=FF-D-CESTINA30,OU=Cestina,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-CESTINA30",
                      "Description": "DHM 376474 (B2.206) [i5-4590s]",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-CESTINA11,OU=Cestina,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-CESTINA11",
                      "Description": "KCC - (D414)",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-CESTINA17,OU=Cestina,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-CESTINA17",
                      "Description": "DHM 349604 Simona Khatebov\u00E1 / Martin B\u0159ezina (D412)",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-CESTINA03,OU=Cestina,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-CESTINA03",
                      "Description": "Hana \u017Di\u017Ekov\u00E1 (D314) [i5-11500B]",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-CESTINA05,OU=Cestina,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-CESTINA05",
                      "Description": "DHM 457124 Radek \u010Cech (D312)",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-CESTINA25,OU=Cestina,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-CESTINA25",
                      "Description": "DHM 448168 Anna Polonska (D411) [i5-11500B]",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-CESTINA26,OU=Cestina,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-CESTINA26",
                      "Description": "KCC Gorana Kamen\u00EDkov\u00E1 (D415)",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-CESTINA10,OU=Cestina,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-CESTINA10",
                      "Description": "DHM 416880 Pavel Caha (D312) [i5-7500]",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-CESTINA14,OU=Cestina,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-CESTINA14",
                      "Description": "Michaela Boh\u00E1\u010Dov\u00E1 (D315)",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-CESTINA22,OU=Cestina,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-CESTINA22",
                      "Description": "KCC - Silvia Podsednik (D414)",
                      "Type": "Computer"
                    }
                  ],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=Humelab,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "Humelab",
                  "Description": null,
                  "OrganizationalUnits": [],
                  "Computers": [
                    {
                      "DistinguishedName": "CN=FF-D-B31,OU=Humelab,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-B31",
                      "Description": null,
                      "Type": "Computer"
                    }
                  ],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=Slavistika,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "Slavistika",
                  "Description": null,
                  "OrganizationalUnits": [],
                  "Computers": [
                    {
                      "DistinguishedName": "CN=FF-D-SLAV20,OU=Slavistika,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-SLAV20",
                      "Description": "\u0160\u00E1rka Toncrov\u00E1 (A312)",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-SLAV08,OU=Slavistika,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-SLAV08",
                      "Description": "Pavel Krej\u010D\u00ED (A316)",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-SLAV30,OU=Slavistika,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-SLAV30",
                      "Description": "DHM 400855 Josef \u0160aur (A310)",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-SLAV15,OU=Slavistika,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-SLAV15",
                      "Description": "V\u00E1clav \u0160t\u011Bp\u00E1nek (A311)",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-SLAV07,OU=Slavistika,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-SLAV07",
                      "Description": "Mirna Stehl\u00EDkov\u00E1 (A317)",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-SLAV09,OU=Slavistika,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-SLAV09",
                      "Description": "Elena Krej\u010Dov\u00E1 (A317)",
                      "Type": "Computer"
                    }
                  ],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=Anglistika,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "Anglistika",
                  "Description": null,
                  "OrganizationalUnits": [],
                  "Computers": [
                    {
                      "DistinguishedName": "CN=FF-D-ANGL13,OU=Anglistika,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-ANGL13",
                      "Description": "Vl\u010Dkov\u00E1 Jitka (G213) Acer, 2023-09",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-ANGL11,OU=Anglistika,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-ANGL11",
                      "Description": "Simona Kalov\u00E1, G.318, Lenovo ThinkCentre M75t, 2023-05",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-ANGL16,OU=Anglistika,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-ANGL16",
                      "Description": "Lekto\u0159i Veve\u0159\u00ED , HP AIO 440, 2024-12",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-ANGL44,OU=Anglistika,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-ANGL44",
                      "Description": "Mark\u00E9ta Pila\u0159ov\u00E1, Veve\u0159\u00ED, HP Tower 400, 2024-12",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-ANGL31,OU=Anglistika,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-ANGL31",
                      "Description": "Tom\u00E1\u0161 Posp\u00ED\u0161il, G.312, DHM 388463, 2023-10",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-ANGL09,OU=Anglistika,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-ANGL09",
                      "Description": "Jana Pelclov\u00E1, 39970, HP Pro Tower 400 G9 PCI Desktop PC, 2024-11",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-ANGL14,OU=Anglistika,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-ANGL14",
                      "Description": "Linda Nepivodov\u00E1, 52390, HP Pro Tower 400 G9 PCI Desktop PC, 2024-11",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-ANGL17,OU=Anglistika,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-ANGL17",
                      "Description": "Renata Kamenick\u00E1, 458, HP Pro Tower 400 G9 PCI Desktop PC. 2024-11",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-ANGL26,OU=Anglistika,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-ANGL26",
                      "Description": "Jeffrey Alan Vanderziel, 37043, HP Pro Tower 400 G9 PCI Desktop PC, 2024-11",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-ANGL37,OU=Anglistika,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-ANGL37",
                      "Description": "Jeffrey Alan Smith, HP Pro Tower 400 G9 PCI Desktop PC, 2024-11",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-ANGL20,OU=Anglistika,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-ANGL20",
                      "Description": "Tom\u00E1\u0161 Ka\u010Der, G304, Lenovo ThinkCentre M75t, 2023-05",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-ANGL38,OU=Anglistika,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-ANGL38",
                      "Description": "Filip Krajn\u00EDk, 74722, HP Pro Tower 400 G9 PCI Desktop PC, 2024-11",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-ANGL02,OU=Anglistika,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-ANGL02",
                      "Description": "Nad\u011B\u017Eda Kudrn\u00E1\u010Dov\u00E1, G.316, Lenovo ThinkCentre M75t, 2023-06",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-ANGL04,OU=Anglistika,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-ANGL04",
                      "Description": "Martina Hor\u00E1kov\u00E1, G.309, Lenovo ThinkCentre M75t, 2023-06",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-ANGL45,OU=Anglistika,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-ANGL45",
                      "Description": "doktorandi, G208, HP ProOne 440 23.8 inch G9 AiO, 2024-12",
                      "Type": "Computer"
                    }
                  ],
                  "Type": "OrganizationalUnit"
                }
              ],
              "Computers": [
                {
                  "DistinguishedName": "CN=FF-D-LITARAT20,OU=FF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "FF-D-LITARAT20",
                  "Description": null,
                  "Type": "Computer"
                }
              ],
              "Type": "OrganizationalUnit"
            },
            {
              "DistinguishedName": "OU=RCX,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
              "Name": "RCX",
              "Description": "rcx-it@recetox.muni.cz",
              "OrganizationalUnits": [
                {
                  "DistinguishedName": "OU=318,OU=RCX,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "318",
                  "Description": "Default Recetox devices",
                  "OrganizationalUnits": [
                    {
                      "DistinguishedName": "OU=RemoteStation,OU=318,OU=RCX,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "RemoteStation",
                      "Description": "Device with remote access",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=RCX-D-03118,OU=RemoteStation,OU=318,OU=RCX,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "RCX-D-03118",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=RCX-D-03164,OU=RemoteStation,OU=318,OU=RCX,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "RCX-D-03164",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=RCX-D-03119,OU=RemoteStation,OU=318,OU=RCX,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "RCX-D-03119",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=RCX-D-03120,OU=RemoteStation,OU=318,OU=RCX,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "RCX-D-03120",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=Default,OU=318,OU=RCX,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Default",
                      "Description": "Basic device with standard setup",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=RCX-D-02314,OU=Default,OU=318,OU=RCX,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "RCX-D-02314",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=RCX-D-03117,OU=Default,OU=318,OU=RCX,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "RCX-D-03117",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=RCX-D-00880,OU=Default,OU=318,OU=RCX,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "RCX-D-00880",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=NUD,OU=318,OU=RCX,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "NUD",
                      "Description": "Not user device, shared device",
                      "OrganizationalUnits": [],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=Test,OU=318,OU=RCX,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Test",
                      "Description": "Predeployment and testing unit",
                      "OrganizationalUnits": [],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=AdminsLocal,OU=318,OU=RCX,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "AdminsLocal",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=RCX-D-01202,OU=AdminsLocal,OU=318,OU=RCX,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "RCX-D-01202",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=RCX-D-03859,OU=AdminsLocal,OU=318,OU=RCX,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "RCX-D-03859",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    }
                  ],
                  "Computers": [],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=31805,OU=RCX,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "31805",
                  "Description": "Loschmidt laboratories specific setup",
                  "OrganizationalUnits": [
                    {
                      "DistinguishedName": "OU=Default,OU=31805,OU=RCX,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Default",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=RCX-D-01018,OU=Default,OU=31805,OU=RCX,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "RCX-D-01018",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=RCX-D-00935,OU=Default,OU=31805,OU=RCX,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "RCX-D-00935",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=RCX-D-01273,OU=Default,OU=31805,OU=RCX,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "RCX-D-01273",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=RCX-D-01295,OU=Default,OU=31805,OU=RCX,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "RCX-D-01295",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=RCX-D-01024,OU=Default,OU=31805,OU=RCX,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "RCX-D-01024",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=RCX-D-01208,OU=Default,OU=31805,OU=RCX,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "RCX-D-01208",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=RCX-D-03864,OU=Default,OU=31805,OU=RCX,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "RCX-D-03864",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=RCX-D-03575,OU=Default,OU=31805,OU=RCX,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "RCX-D-03575",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    }
                  ],
                  "Computers": [],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=31804,OU=RCX,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "31804",
                  "Description": null,
                  "OrganizationalUnits": [
                    {
                      "DistinguishedName": "OU=318041,OU=31804,OU=RCX,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "318041",
                      "Description": null,
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=SharedDevice,OU=318041,OU=31804,OU=RCX,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SharedDevice",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=RCX-D-03852,OU=SharedDevice,OU=318041,OU=31804,OU=RCX,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "RCX-D-03852",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=RCX-D-03851,OU=SharedDevice,OU=318041,OU=31804,OU=RCX,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "RCX-D-03851",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=RCX-D-03850,OU=SharedDevice,OU=318041,OU=31804,OU=RCX,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "RCX-D-03850",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=Default,OU=318041,OU=31804,OU=RCX,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "Default",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=AdminsLocal,OU=318041,OU=31804,OU=RCX,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "AdminsLocal",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=RCX-D-03853,OU=AdminsLocal,OU=318041,OU=31804,OU=RCX,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "RCX-D-03853",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=318042,OU=31804,OU=RCX,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "318042",
                      "Description": null,
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=SharedDevice,OU=318042,OU=31804,OU=RCX,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SharedDevice",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=RCX-D-03854,OU=SharedDevice,OU=318042,OU=31804,OU=RCX,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "RCX-D-03854",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=RCX-D-03855,OU=SharedDevice,OU=318042,OU=31804,OU=RCX,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "RCX-D-03855",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=RCX-D-03856,OU=SharedDevice,OU=318042,OU=31804,OU=RCX,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "RCX-D-03856",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=RCX-D-03857,OU=SharedDevice,OU=318042,OU=31804,OU=RCX,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "RCX-D-03857",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=Default,OU=318042,OU=31804,OU=RCX,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "Default",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=AdminsLocal,OU=318042,OU=31804,OU=RCX,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "AdminsLocal",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    }
                  ],
                  "Computers": [],
                  "Type": "OrganizationalUnit"
                }
              ],
              "Computers": [],
              "Type": "OrganizationalUnit"
            },
            {
              "DistinguishedName": "OU=IBA,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
              "Name": "IBA",
              "Description": "krejci@iba.muni.cz",
              "OrganizationalUnits": [],
              "Computers": [],
              "Type": "OrganizationalUnit"
            },
            {
              "DistinguishedName": "OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
              "Name": "RMU",
              "Description": "lvt@rect.muni.cz",
              "OrganizationalUnits": [
                {
                  "DistinguishedName": "OU=PrF,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "PrF",
                  "Description": null,
                  "OrganizationalUnits": [
                    {
                      "DistinguishedName": "OU=Aula,OU=PrF,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Aula",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=RMU-D-PrF-AULA,OU=Aula,OU=PrF,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "RMU-D-PrF-AULA",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=Aula2,OU=PrF,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Aula2",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=RMU-D-PrF-AULA2,OU=Aula2,OU=PrF,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "RMU-D-PrF-AULA2",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    }
                  ],
                  "Computers": [],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=Zerotinovo nam. 3,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "Zerotinovo nam. 3",
                  "Description": null,
                  "OrganizationalUnits": [
                    {
                      "DistinguishedName": "OU=3.patro,OU=Zerotinovo nam. 3,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "3.patro",
                      "Description": null,
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=m313,OU=3.patro,OU=Zerotinovo nam. 3,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "m313",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=Merinska Jitka,OU=m313,OU=3.patro,OU=Zerotinovo nam. 3,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Merinska Jitka",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=RMU-D-28979,OU=Merinska Jitka,OU=m313,OU=3.patro,OU=Zerotinovo nam. 3,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "RMU-D-28979",
                                  "Description": null,
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=Archiv zapujcky,OU=m313,OU=3.patro,OU=Zerotinovo nam. 3,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Archiv zapujcky",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=Katovska Jitka,OU=m313,OU=3.patro,OU=Zerotinovo nam. 3,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Katovska Jitka",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=RMU-D-64987,OU=Katovska Jitka,OU=m313,OU=3.patro,OU=Zerotinovo nam. 3,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "RMU-D-64987",
                                  "Description": null,
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=Kacetlova Jaroslava,OU=m313,OU=3.patro,OU=Zerotinovo nam. 3,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Kacetlova Jaroslava",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=RMU-D-64319,OU=Kacetlova Jaroslava,OU=m313,OU=3.patro,OU=Zerotinovo nam. 3,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "RMU-D-64319",
                                  "Description": null,
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=Archiv zapujcky,OU=3.patro,OU=Zerotinovo nam. 3,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "Archiv zapujcky",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=RMU-N-AMU6,OU=Archiv zapujcky,OU=3.patro,OU=Zerotinovo nam. 3,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "RMU-N-AMU6",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=m334,OU=3.patro,OU=Zerotinovo nam. 3,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "m334",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=Knichal David,OU=m334,OU=3.patro,OU=Zerotinovo nam. 3,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Knichal David",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=RMU-D-361557,OU=Knichal David,OU=m334,OU=3.patro,OU=Zerotinovo nam. 3,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "RMU-D-361557",
                                  "Description": null,
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=Novakova Martina,OU=m334,OU=3.patro,OU=Zerotinovo nam. 3,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Novakova Martina",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=RMU-D-449154,OU=Novakova Martina,OU=m334,OU=3.patro,OU=Zerotinovo nam. 3,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "RMU-D-449154",
                                  "Description": null,
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    }
                  ],
                  "Computers": [],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "Zerotinovo nam. 9",
                  "Description": null,
                  "OrganizationalUnits": [
                    {
                      "DistinguishedName": "OU=5. patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "5. patro",
                      "Description": null,
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=m526,OU=5. patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "m526",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=Nantlova Sona,OU=m526,OU=5. patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Nantlova Sona",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=RMU-D-15224,OU=Nantlova Sona,OU=m526,OU=5. patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "RMU-D-15224",
                                  "Description": null,
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=m527,OU=5. patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "m527",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=Blaha Jan,OU=m527,OU=5. patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Blaha Jan",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=RMU-D-50948,OU=Blaha Jan,OU=m527,OU=5. patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "RMU-D-50948",
                                  "Description": null,
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=m552,OU=5. patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "m552",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=Strakova Tatana,OU=m552,OU=5. patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Strakova Tatana",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=RMU-D-168,OU=Strakova Tatana,OU=m552,OU=5. patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "RMU-D-168",
                                  "Description": null,
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=m588,OU=5. patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "m588",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=Prochazka Jan,OU=m588,OU=5. patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Prochazka Jan",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=RMU-D-84,OU=Prochazka Jan,OU=m588,OU=5. patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "RMU-D-84",
                                  "Description": null,
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=Sauer Martin,OU=m588,OU=5. patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Sauer Martin",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=RMU-D-116627,OU=Sauer Martin,OU=m588,OU=5. patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "RMU-D-116627",
                                  "Description": null,
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=m525,OU=5. patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "m525",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=Oleksikova Katerina,OU=m525,OU=5. patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Oleksikova Katerina",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=RMU-D-240814,OU=Oleksikova Katerina,OU=m525,OU=5. patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "RMU-D-240814",
                                  "Description": null,
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=m553,OU=5. patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "m553",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=Repcikova Viktoria,OU=m553,OU=5. patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Repcikova Viktoria",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=RMU-D-482967,OU=Repcikova Viktoria,OU=m553,OU=5. patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "RMU-D-482967",
                                  "Description": null,
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=Kalovska Martina,OU=m553,OU=5. patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Kalovska Martina",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=RMU-D-7500,OU=Kalovska Martina,OU=m553,OU=5. patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "RMU-D-7500",
                                  "Description": null,
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=Svancarova Dagmar,OU=m553,OU=5. patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Svancarova Dagmar",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=RMU-D-118042,OU=Svancarova Dagmar,OU=m553,OU=5. patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "RMU-D-118042",
                                  "Description": null,
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=4.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "4.patro",
                      "Description": null,
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=m414,OU=4.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "m414",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=Poc David,OU=m414,OU=4.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Poc David",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=RMU-D-49730,OU=Poc David,OU=m414,OU=4.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "RMU-D-49730",
                                  "Description": null,
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=m416,OU=4.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "m416",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=Chlubna Hana,OU=m416,OU=4.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Chlubna Hana",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=RMU-D-187766,OU=Chlubna Hana,OU=m416,OU=4.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "RMU-D-187766",
                                  "Description": null,
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=Kubandova Tamara,OU=m416,OU=4.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Kubandova Tamara",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=RMU-D-54015,OU=Kubandova Tamara,OU=m416,OU=4.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "RMU-D-54015",
                                  "Description": null,
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=m442,OU=4.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "m442",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=Dobes Filip,OU=m442,OU=4.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Dobes Filip",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=RMU-D-559609,OU=Dobes Filip,OU=m442,OU=4.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "RMU-D-559609",
                                  "Description": null,
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=m474,OU=4.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "m474",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=Horynova Nadezda,OU=m474,OU=4.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Horynova Nadezda",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=RMU-D-2551,OU=Horynova Nadezda,OU=m474,OU=4.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "RMU-D-2551",
                                  "Description": null,
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=m426,OU=4.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "m426",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=RMU-D-17886,OU=m426,OU=4.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "RMU-D-17886",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=m454,OU=4.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "m454",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=Kudova Lenka,OU=m454,OU=4.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Kudova Lenka",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=RMU-D-238507,OU=Kudova Lenka,OU=m454,OU=4.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "RMU-D-238507",
                                  "Description": null,
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=m472,OU=4.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "m472",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=Jurinova Dominika,OU=m472,OU=4.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Jurinova Dominika",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=RMU-D-233116,OU=Jurinova Dominika,OU=m472,OU=4.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "RMU-D-233116",
                                  "Description": null,
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=Pudelkova Andrea,OU=m472,OU=4.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Pudelkova Andrea",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=RMU-D-97899,OU=Pudelkova Andrea,OU=m472,OU=4.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "RMU-D-97899",
                                  "Description": null,
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=m419,OU=4.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "m419",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=RMU-D-241936,OU=m419,OU=4.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "RMU-D-241936",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=3.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "3.patro",
                      "Description": null,
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=m318,OU=3.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "m318",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=Vejrostova Marketa,OU=m318,OU=3.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Vejrostova Marketa",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=RMU-D-7948,OU=Vejrostova Marketa,OU=m318,OU=3.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "RMU-D-7948",
                                  "Description": null,
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=Mrazkova Radka,OU=m318,OU=3.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Mrazkova Radka",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=RMU-D-402076,OU=Mrazkova Radka,OU=m318,OU=3.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "RMU-D-402076",
                                  "Description": null,
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=m328,OU=3.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "m328",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=Jandova Monika,OU=m328,OU=3.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Jandova Monika",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=RMU-D-13752,OU=Jandova Monika,OU=m328,OU=3.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "RMU-D-13752",
                                  "Description": null,
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=m340,OU=3.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "m340",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=Kulhava Veronika,OU=m340,OU=3.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Kulhava Veronika",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=RMU-D-109880,OU=Kulhava Veronika,OU=m340,OU=3.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "RMU-D-109880",
                                  "Description": null,
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=m334,OU=3.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "m334",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=Ambrozova Michaela,OU=m334,OU=3.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Ambrozova Michaela",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=RMU-D-75951,OU=Ambrozova Michaela,OU=m334,OU=3.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "RMU-D-75951",
                                  "Description": null,
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=Mutnanska Hana,OU=m334,OU=3.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Mutnanska Hana",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=RMU-D-240894,OU=Mutnanska Hana,OU=m334,OU=3.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "RMU-D-240894",
                                  "Description": null,
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=m317,OU=3.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "m317",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=Sopikova Barbora,OU=m317,OU=3.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Sopikova Barbora",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=RMU-D-263396,OU=Sopikova Barbora,OU=m317,OU=3.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "RMU-D-263396",
                                  "Description": null,
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=m321,OU=3.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "m321",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=Hofirek Ondrej,OU=m321,OU=3.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Hofirek Ondrej",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=RMU-D-41209,OU=Hofirek Ondrej,OU=m321,OU=3.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "RMU-D-41209",
                                  "Description": null,
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=Cackova Petra,OU=m321,OU=3.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Cackova Petra",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=RMU-D-60548A,OU=Cackova Petra,OU=m321,OU=3.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "RMU-D-60548A",
                                  "Description": null,
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=m320,OU=3.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "m320",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=Pospisilova Sarka,OU=m320,OU=3.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Pospisilova Sarka",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=RMU-D-38791,OU=Pospisilova Sarka,OU=m320,OU=3.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "RMU-D-38791",
                                  "Description": null,
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=m330,OU=3.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "m330",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=Zlatuskova Iva,OU=m330,OU=3.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Zlatuskova Iva",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=RMU-D-2351,OU=Zlatuskova Iva,OU=m330,OU=3.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "RMU-D-2351",
                                  "Description": null,
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=m365,OU=3.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "m365",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=Ivankova Karolina,OU=m365,OU=3.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Ivankova Karolina",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=RMU-D-18508,OU=Ivankova Karolina,OU=m365,OU=3.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "RMU-D-18508",
                                  "Description": null,
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=Stipska Nikola,OU=m365,OU=3.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Stipska Nikola",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=RMU-D-429105,OU=Stipska Nikola,OU=m365,OU=3.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "RMU-D-429105",
                                  "Description": null,
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=m368,OU=3.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "m368",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=Brigadnik,OU=m368,OU=3.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Brigadnik",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=RMU-D-STU-BRIG1,OU=Brigadnik,OU=m368,OU=3.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "RMU-D-STU-BRIG1",
                                  "Description": null,
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=m350,OU=3.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "m350",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=Drozd Radomir,OU=m350,OU=3.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Drozd Radomir",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=RMU-D-243217,OU=Drozd Radomir,OU=m350,OU=3.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "RMU-D-243217",
                                  "Description": null,
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=m369,OU=3.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "m369",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=Kapounova Lucie,OU=m369,OU=3.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Kapounova Lucie",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=RMU-D-108197,OU=Kapounova Lucie,OU=m369,OU=3.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "RMU-D-108197",
                                  "Description": null,
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=m361,OU=3.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "m361",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=Taranza David,OU=m361,OU=3.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Taranza David",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=RMU-D-62934,OU=Taranza David,OU=m361,OU=3.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "RMU-D-62934",
                                  "Description": null,
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=m338,OU=3.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "m338",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=m339,OU=3.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "m339",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=Kysilkova Alexandra,OU=m339,OU=3.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Kysilkova Alexandra",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=RMU-D-241235,OU=Kysilkova Alexandra,OU=m339,OU=3.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "RMU-D-241235",
                                  "Description": null,
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=Mezlikova Alena,OU=m339,OU=3.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Mezlikova Alena",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=RMU-D-78589,OU=Mezlikova Alena,OU=m339,OU=3.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "RMU-D-78589",
                                  "Description": null,
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=Dvorakova Kristina,OU=m339,OU=3.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Dvorakova Kristina",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=RMU-D-113566,OU=Dvorakova Kristina,OU=m339,OU=3.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "RMU-D-113566",
                                  "Description": null,
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=Danielova Renata,OU=m339,OU=3.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Danielova Renata",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=RMU-D-21481,OU=Danielova Renata,OU=m339,OU=3.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "RMU-D-21481",
                                  "Description": null,
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=Foltyn Jiri,OU=m339,OU=3.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Foltyn Jiri",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=RMU-D-116837A,OU=Foltyn Jiri,OU=m339,OU=3.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "RMU-D-116837A",
                                  "Description": null,
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=1.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "1.patro",
                      "Description": null,
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=m121,OU=1.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "m121",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=Brigadnik,OU=m121,OU=1.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Brigadnik",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=RMU-D-OVVM-B1,OU=Brigadnik,OU=m121,OU=1.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "RMU-D-OVVM-B1",
                                  "Description": null,
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=Diatelova Nepovimova Irena,OU=m121,OU=1.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Diatelova Nepovimova Irena",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=RMU-D-379183,OU=Diatelova Nepovimova Irena,OU=m121,OU=1.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "RMU-D-379183",
                                  "Description": null,
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=m185,OU=1.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "m185",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=Carbochova Veronika,OU=m185,OU=1.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Carbochova Veronika",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=RMU-D-438391,OU=Carbochova Veronika,OU=m185,OU=1.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "RMU-D-438391",
                                  "Description": null,
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=2.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "2.patro",
                      "Description": null,
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=m251,OU=2.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "m251",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=Rychtarikova Michaela,OU=m251,OU=2.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Rychtarikova Michaela",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=RMU-D-61260,OU=Rychtarikova Michaela,OU=m251,OU=2.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "RMU-D-61260",
                                  "Description": null,
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=m273,OU=2.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "m273",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=Cadova Dagmar,OU=m273,OU=2.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Cadova Dagmar",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=RMU-D-250915,OU=Cadova Dagmar,OU=m273,OU=2.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "RMU-D-250915",
                                  "Description": null,
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=Simonova Silva,OU=m273,OU=2.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Simonova Silva",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=RMU-D-451100,OU=Simonova Silva,OU=m273,OU=2.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "RMU-D-451100",
                                  "Description": null,
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=m219,OU=2.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "m219",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=Ondrova Olga,OU=m219,OU=2.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Ondrova Olga",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=RMU-D-109837,OU=Ondrova Olga,OU=m219,OU=2.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "RMU-D-109837",
                                  "Description": null,
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=m260,OU=2.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "m260",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=Okanik Vlastislav,OU=m260,OU=2.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Okanik Vlastislav",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=RMU-D-242032,OU=Okanik Vlastislav,OU=m260,OU=2.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "RMU-D-242032",
                                  "Description": null,
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=m258,OU=2.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "m258",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=Rajtarova Leona,OU=m258,OU=2.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Rajtarova Leona",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=RMU-D-118326,OU=Rajtarova Leona,OU=m258,OU=2.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "RMU-D-118326",
                                  "Description": null,
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=Pinos Stanislav,OU=m258,OU=2.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Pinos Stanislav",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=RMU-D-111285,OU=Pinos Stanislav,OU=m258,OU=2.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "RMU-D-111285",
                                  "Description": null,
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=m217,OU=2.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "m217",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=Chladkova Jaroslava,OU=m217,OU=2.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Chladkova Jaroslava",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=RMU-D-110681,OU=Chladkova Jaroslava,OU=m217,OU=2.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "RMU-D-110681",
                                  "Description": null,
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=m214,OU=2.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "m214",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=RMU-D-ICT2,OU=m214,OU=2.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "RMU-D-ICT2",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=RMU-D-ICT5,OU=m214,OU=2.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "RMU-D-ICT5",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=RMU-D-111134,OU=m214,OU=2.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "RMU-D-111134",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=RMU-D-231082,OU=m214,OU=2.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "RMU-D-231082",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=RMU-D-115353,OU=m214,OU=2.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "RMU-D-115353",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=RMU-D-VRATZER9,OU=m214,OU=2.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "RMU-D-VRATZER9",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=RMU-D-ICT9,OU=m214,OU=2.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "RMU-D-ICT9",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=RMU-D-KOM2M257,OU=m214,OU=2.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "RMU-D-KOM2M257",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=RMU-D-550,OU=m214,OU=2.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "RMU-D-550",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=RMU-D-MINIPC,OU=m214,OU=2.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "RMU-D-MINIPC",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=RMU-D-ICT1,OU=m214,OU=2.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "RMU-D-ICT1",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=RMU-D-137219,OU=m214,OU=2.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "RMU-D-137219",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=RMU-D-42438,OU=m214,OU=2.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "RMU-D-42438",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=RMU-D-167252,OU=m214,OU=2.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "RMU-D-167252",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=RMU-D-382520,OU=m214,OU=2.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "RMU-D-382520",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=RMU-D-449577,OU=m214,OU=2.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "RMU-D-449577",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=m253,OU=2.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "m253",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=Faitova Dana,OU=m253,OU=2.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Faitova Dana",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=RMU-D-253322,OU=Faitova Dana,OU=m253,OU=2.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "RMU-D-253322",
                                  "Description": null,
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    }
                  ],
                  "Computers": [],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=Komenskeho nam. 2,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "Komenskeho nam. 2",
                  "Description": null,
                  "OrganizationalUnits": [
                    {
                      "DistinguishedName": "OU=4.patro,OU=Komenskeho nam. 2,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "4.patro",
                      "Description": null,
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=m441C,OU=4.patro,OU=Komenskeho nam. 2,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "m441C",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=Figura Richard,OU=m441C,OU=4.patro,OU=Komenskeho nam. 2,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Figura Richard",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=RMU-D-471467,OU=Figura Richard,OU=m441C,OU=4.patro,OU=Komenskeho nam. 2,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "RMU-D-471467",
                                  "Description": null,
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=1.patro,OU=Komenskeho nam. 2,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "1.patro",
                      "Description": null,
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=Vratnice,OU=1.patro,OU=Komenskeho nam. 2,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "Vratnice",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=RMU-D-VRATKOM2,OU=Vratnice,OU=1.patro,OU=Komenskeho nam. 2,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "RMU-D-VRATKOM2",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=2.patro,OU=Komenskeho nam. 2,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "2.patro",
                      "Description": null,
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=m249,OU=2.patro,OU=Komenskeho nam. 2,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "m249",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=-1.patro,OU=Komenskeho nam. 2,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "-1.patro",
                      "Description": null,
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=S131,OU=-1.patro,OU=Komenskeho nam. 2,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "S131",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=Hernik Libor,OU=S131,OU=-1.patro,OU=Komenskeho nam. 2,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Hernik Libor",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=RMU-D-159,OU=Hernik Libor,OU=S131,OU=-1.patro,OU=Komenskeho nam. 2,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "RMU-D-159",
                                  "Description": null,
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=3. patro,OU=Komenskeho nam. 2,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "3. patro",
                      "Description": null,
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=m349,OU=3. patro,OU=Komenskeho nam. 2,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "m349",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=Fojtikova Marie,OU=m349,OU=3. patro,OU=Komenskeho nam. 2,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Fojtikova Marie",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=RMU-D-550B,OU=Fojtikova Marie,OU=m349,OU=3. patro,OU=Komenskeho nam. 2,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "RMU-D-550B",
                                  "Description": null,
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=Kalandrikova Eva,OU=m349,OU=3. patro,OU=Komenskeho nam. 2,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Kalandrikova Eva",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=RMU-D-382520B,OU=Kalandrikova Eva,OU=m349,OU=3. patro,OU=Komenskeho nam. 2,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "RMU-D-382520B",
                                  "Description": null,
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=m350,OU=3. patro,OU=Komenskeho nam. 2,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "m350",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=Olsanova Eliska,OU=m350,OU=3. patro,OU=Komenskeho nam. 2,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Olsanova Eliska",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=RMU-D-449577B,OU=Olsanova Eliska,OU=m350,OU=3. patro,OU=Komenskeho nam. 2,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "RMU-D-449577B",
                                  "Description": null,
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=m327,OU=3. patro,OU=Komenskeho nam. 2,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "m327",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=Adday Fatima,OU=m327,OU=3. patro,OU=Komenskeho nam. 2,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Adday Fatima",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=RMU-D-489199,OU=Adday Fatima,OU=m327,OU=3. patro,OU=Komenskeho nam. 2,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "RMU-D-489199",
                                  "Description": null,
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=Cerpek1,OU=m327,OU=3. patro,OU=Komenskeho nam. 2,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Cerpek1",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=RMU-D-CERPEK1,OU=Cerpek1,OU=m327,OU=3. patro,OU=Komenskeho nam. 2,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "RMU-D-CERPEK1",
                                  "Description": null,
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    }
                  ],
                  "Computers": [],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=Munishop,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "Munishop",
                  "Description": null,
                  "OrganizationalUnits": [
                    {
                      "DistinguishedName": "OU=m111,OU=Munishop,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "m111",
                      "Description": null,
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=Novackova Alena,OU=m111,OU=Munishop,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "Novackova Alena",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=RMU-D-100268,OU=Novackova Alena,OU=m111,OU=Munishop,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "RMU-D-100268",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=RMU-D-MUNISHOP,OU=m111,OU=Munishop,OU=RMU,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "RMU-D-MUNISHOP",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    }
                  ],
                  "Computers": [],
                  "Type": "OrganizationalUnit"
                }
              ],
              "Computers": [],
              "Type": "OrganizationalUnit"
            },
            {
              "DistinguishedName": "OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
              "Name": "SCI",
              "Description": "ovt@sci.muni.cz",
              "OrganizationalUnits": [
                {
                  "DistinguishedName": "OU=Zahrada,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "Zahrada",
                  "Description": null,
                  "OrganizationalUnits": [
                    {
                      "DistinguishedName": "OU=Budova4,OU=Zahrada,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Budova4",
                      "Description": null,
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=01031,OU=Budova4,OU=Zahrada,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "01031",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=SCI-D-416114,OU=01031,OU=Budova4,OU=Zahrada,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "SCI-D-416114",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=01030a,OU=Budova4,OU=Zahrada,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "01030a",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=SCI-JANDOVA,OU=01030a,OU=Budova4,OU=Zahrada,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "SCI-JANDOVA",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=SCI-D-325970,OU=SCI-JANDOVA,OU=01030a,OU=Budova4,OU=Zahrada,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "SCI-D-325970",
                                  "Description": null,
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=01028,OU=Budova4,OU=Zahrada,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "01028",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=SCI-ONDRUSKOVA,OU=01028,OU=Budova4,OU=Zahrada,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "SCI-ONDRUSKOVA",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=SCI-D-450192,OU=SCI-ONDRUSKOVA,OU=01028,OU=Budova4,OU=Zahrada,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "SCI-D-450192",
                                  "Description": null,
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=01027,OU=Budova4,OU=Zahrada,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "01027",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=SCI-CHYTRA,OU=01027,OU=Budova4,OU=Zahrada,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "SCI-CHYTRA",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=SCI-D-346779,OU=SCI-CHYTRA,OU=01027,OU=Budova4,OU=Zahrada,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "SCI-D-346779",
                                  "Description": null,
                                  "Type": "Computer"
                                },
                                {
                                  "DistinguishedName": "CN=SCI-D-382709,OU=SCI-CHYTRA,OU=01027,OU=Budova4,OU=Zahrada,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "SCI-D-382709",
                                  "Description": null,
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=01026,OU=Budova4,OU=Zahrada,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "01026",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=SCI-KOTASOVA,OU=01026,OU=Budova4,OU=Zahrada,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "SCI-KOTASOVA",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=SCI-D-346805,OU=SCI-KOTASOVA,OU=01026,OU=Budova4,OU=Zahrada,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "SCI-D-346805",
                                  "Description": null,
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=SCI-LANIKOVA,OU=01026,OU=Budova4,OU=Zahrada,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "SCI-LANIKOVA",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=SCI-D-386302,OU=SCI-LANIKOVA,OU=01026,OU=Budova4,OU=Zahrada,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "SCI-D-386302",
                                  "Description": null,
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=Budova14,OU=Zahrada,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Budova14",
                      "Description": null,
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=SBIRKA,OU=Budova14,OU=Zahrada,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SBIRKA",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=SCI-D-346769,OU=SBIRKA,OU=Budova14,OU=Zahrada,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "SCI-D-346769",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    }
                  ],
                  "Computers": [],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "Dekanat",
                  "Description": null,
                  "OrganizationalUnits": [
                    {
                      "DistinguishedName": "OU=Budova06,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Budova06",
                      "Description": null,
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=1.PP,OU=Budova06,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "1.PP",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=-01002,OU=1.PP,OU=Budova06,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "-01002",
                              "Description": null,
                              "OrganizationalUnits": [
                                {
                                  "DistinguishedName": "OU=SCI-HAVLIK,OU=-01002,OU=1.PP,OU=Budova06,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "SCI-HAVLIK",
                                  "Description": null,
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=SCI-D-346800,OU=SCI-HAVLIK,OU=-01002,OU=1.PP,OU=Budova06,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "SCI-D-346800",
                                      "Description": null,
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                }
                              ],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=-01001,OU=1.PP,OU=Budova06,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "-01001",
                              "Description": null,
                              "OrganizationalUnits": [
                                {
                                  "DistinguishedName": "OU=SCI-HROMEK,OU=-01001,OU=1.PP,OU=Budova06,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "SCI-HROMEK",
                                  "Description": null,
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=SCI-D-382632,OU=SCI-HROMEK,OU=-01001,OU=1.PP,OU=Budova06,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "SCI-D-382632",
                                      "Description": null,
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=SCI-CENEK,OU=-01001,OU=1.PP,OU=Budova06,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "SCI-CENEK",
                                  "Description": null,
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=SCI-D-346767,OU=SCI-CENEK,OU=-01001,OU=1.PP,OU=Budova06,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "SCI-D-346767",
                                      "Description": null,
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                }
                              ],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=Budova08,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Budova08",
                      "Description": null,
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=1.PP,OU=Budova08,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "1.PP",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=-1005,OU=1.PP,OU=Budova08,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "-1005",
                              "Description": null,
                              "OrganizationalUnits": [
                                {
                                  "DistinguishedName": "OU=sci-riha2,OU=-1005,OU=1.PP,OU=Budova08,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "sci-riha2",
                                  "Description": null,
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=SCI-D-346778,OU=sci-riha2,OU=-1005,OU=1.PP,OU=Budova08,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "SCI-D-346778",
                                      "Description": null,
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                }
                              ],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=-1008,OU=1.PP,OU=Budova08,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "-1008",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=SCI-D-346771,OU=-1008,OU=1.PP,OU=Budova08,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "SCI-D-346771",
                                  "Description": null,
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=-1014,OU=1.PP,OU=Budova08,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "-1014",
                              "Description": null,
                              "OrganizationalUnits": [
                                {
                                  "DistinguishedName": "OU=sci-kucera,OU=-1014,OU=1.PP,OU=Budova08,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "sci-kucera",
                                  "Description": null,
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=SCI-D-346740,OU=sci-kucera,OU=-1014,OU=1.PP,OU=Budova08,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "SCI-D-346740",
                                      "Description": null,
                                      "Type": "Computer"
                                    },
                                    {
                                      "DistinguishedName": "CN=SCI-D-382636,OU=sci-kucera,OU=-1014,OU=1.PP,OU=Budova08,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "SCI-D-382636",
                                      "Description": null,
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                }
                              ],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=-1009b,OU=1.PP,OU=Budova08,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "-1009b",
                              "Description": null,
                              "OrganizationalUnits": [
                                {
                                  "DistinguishedName": "OU=SCI-SMETANA,OU=-1009b,OU=1.PP,OU=Budova08,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "SCI-SMETANA",
                                  "Description": null,
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=SCI-D-382638,OU=SCI-SMETANA,OU=-1009b,OU=1.PP,OU=Budova08,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "SCI-D-382638",
                                      "Description": null,
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                }
                              ],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=4.NP,OU=Budova08,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "4.NP",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=04016,OU=4.NP,OU=Budova08,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "04016",
                              "Description": null,
                              "OrganizationalUnits": [
                                {
                                  "DistinguishedName": "OU=sci-navratova,OU=04016,OU=4.NP,OU=Budova08,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "sci-navratova",
                                  "Description": null,
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=SCI-D-346761,OU=sci-navratova,OU=04016,OU=4.NP,OU=Budova08,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "SCI-D-346761",
                                      "Description": null,
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=sci-vlasinova,OU=04016,OU=4.NP,OU=Budova08,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "sci-vlasinova",
                                  "Description": null,
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=SCI-D-415701,OU=sci-vlasinova,OU=04016,OU=4.NP,OU=Budova08,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "SCI-D-415701",
                                      "Description": null,
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=sci-trnackova,OU=04016,OU=4.NP,OU=Budova08,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "sci-trnackova",
                                  "Description": null,
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=SCI-D-407194,OU=sci-trnackova,OU=04016,OU=4.NP,OU=Budova08,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "SCI-D-407194",
                                      "Description": null,
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                }
                              ],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=Budova10,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Budova10",
                      "Description": null,
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=01002,OU=Budova10,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "01002",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=sci-vratnice,OU=01002,OU=Budova10,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "sci-vratnice",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=SCI-D-346810,OU=sci-vratnice,OU=01002,OU=Budova10,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "SCI-D-346810",
                                  "Description": null,
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=01006,OU=Budova10,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "01006",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=sci-tomankova,OU=01006,OU=Budova10,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "sci-tomankova",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=SCI-D-382735,OU=sci-tomankova,OU=01006,OU=Budova10,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "SCI-D-382735",
                                  "Description": null,
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=sci-vasickova,OU=01006,OU=Budova10,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "sci-vasickova",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=SCI-D-346822,OU=sci-vasickova,OU=01006,OU=Budova10,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "SCI-D-346822",
                                  "Description": null,
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=sci-zovinec,OU=01006,OU=Budova10,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "sci-zovinec",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=SCI-D-346825,OU=sci-zovinec,OU=01006,OU=Budova10,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "SCI-D-346825",
                                  "Description": null,
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=01007,OU=Budova10,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "01007",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=sci-riha,OU=01007,OU=Budova10,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "sci-riha",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=SCI-D-346795,OU=sci-riha,OU=01007,OU=Budova10,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "SCI-D-346795",
                                  "Description": null,
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=Budova12,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Budova12",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=Budova03,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Budova03",
                      "Description": null,
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=01007,OU=Budova03,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "01007",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=sci-svancarova,OU=01007,OU=Budova03,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "sci-svancarova",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=SCI-D-346808,OU=sci-svancarova,OU=01007,OU=Budova03,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "SCI-D-346808",
                                  "Description": null,
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=sci-polakova,OU=01007,OU=Budova03,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "sci-polakova",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=SCI-D-346809,OU=sci-polakova,OU=01007,OU=Budova03,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "SCI-D-346809",
                                  "Description": null,
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=sci-svabova,OU=01007,OU=Budova03,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "sci-svabova",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=SCI-D-346754,OU=sci-svabova,OU=01007,OU=Budova03,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "SCI-D-346754",
                                  "Description": null,
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=sci-zufanova,OU=01007,OU=Budova03,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "sci-zufanova",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=SCI-D-346807,OU=sci-zufanova,OU=01007,OU=Budova03,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "SCI-D-346807",
                                  "Description": null,
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=INSTALL,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "INSTALL",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Budova01",
                      "Description": null,
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=2.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "2.NP",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=02013,OU=2.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "02013",
                              "Description": null,
                              "OrganizationalUnits": [
                                {
                                  "DistinguishedName": "OU=sci-cerna,OU=02013,OU=2.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "sci-cerna",
                                  "Description": null,
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=SCI-D-425942,OU=sci-cerna,OU=02013,OU=2.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "SCI-D-425942",
                                      "Description": null,
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                }
                              ],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=02015,OU=2.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "02015",
                              "Description": null,
                              "OrganizationalUnits": [
                                {
                                  "DistinguishedName": "OU=sci-potuckova,OU=02015,OU=2.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "sci-potuckova",
                                  "Description": null,
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=SCI-D-382643,OU=sci-potuckova,OU=02015,OU=2.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "SCI-D-382643",
                                      "Description": null,
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=sci-couralova,OU=02015,OU=2.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "sci-couralova",
                                  "Description": null,
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=SCI-D-346762,OU=sci-couralova,OU=02015,OU=2.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "SCI-D-346762",
                                      "Description": null,
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                }
                              ],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=02016,OU=2.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "02016",
                              "Description": null,
                              "OrganizationalUnits": [
                                {
                                  "DistinguishedName": "OU=sci-kurejova,OU=02016,OU=2.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "sci-kurejova",
                                  "Description": null,
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=SCI-D-407190,OU=sci-kurejova,OU=02016,OU=2.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "SCI-D-407190",
                                      "Description": null,
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=sci-pilerova,OU=02016,OU=2.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "sci-pilerova",
                                  "Description": null,
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=SCI-D-415703,OU=sci-pilerova,OU=02016,OU=2.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "SCI-D-415703",
                                      "Description": null,
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                }
                              ],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=02021,OU=2.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "02021",
                              "Description": null,
                              "OrganizationalUnits": [
                                {
                                  "DistinguishedName": "OU=sci-ambrozova,OU=02021,OU=2.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "sci-ambrozova",
                                  "Description": null,
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=SCI-D-382710,OU=sci-ambrozova,OU=02021,OU=2.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "SCI-D-382710",
                                      "Description": null,
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                }
                              ],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=Majetek,OU=2.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Majetek",
                              "Description": null,
                              "OrganizationalUnits": [
                                {
                                  "DistinguishedName": "OU=02007a,OU=Majetek,OU=2.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "02007a",
                                  "Description": null,
                                  "OrganizationalUnits": [
                                    {
                                      "DistinguishedName": "OU=sci-hsvobodova,OU=02007a,OU=Majetek,OU=2.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "sci-hsvobodova",
                                      "Description": null,
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=SCI-D-346747,OU=sci-hsvobodova,OU=02007a,OU=Majetek,OU=2.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "SCI-D-346747",
                                          "Description": null,
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    }
                                  ],
                                  "Computers": [],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=02007b,OU=Majetek,OU=2.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "02007b",
                                  "Description": null,
                                  "OrganizationalUnits": [
                                    {
                                      "DistinguishedName": "OU=sci-kocirova,OU=02007b,OU=Majetek,OU=2.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "sci-kocirova",
                                      "Description": null,
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=SCI-D-346768,OU=sci-kocirova,OU=02007b,OU=Majetek,OU=2.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "SCI-D-346768",
                                          "Description": null,
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=sci-rozikova,OU=02007b,OU=Majetek,OU=2.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "sci-rozikova",
                                      "Description": null,
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=SCI-D-346753,OU=sci-rozikova,OU=02007b,OU=Majetek,OU=2.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "SCI-D-346753",
                                          "Description": null,
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    }
                                  ],
                                  "Computers": [],
                                  "Type": "OrganizationalUnit"
                                }
                              ],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=Personalni,OU=2.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Personalni",
                              "Description": null,
                              "OrganizationalUnits": [
                                {
                                  "DistinguishedName": "OU=02018,OU=Personalni,OU=2.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "02018",
                                  "Description": null,
                                  "OrganizationalUnits": [
                                    {
                                      "DistinguishedName": "OU=sci-flochova,OU=02018,OU=Personalni,OU=2.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "sci-flochova",
                                      "Description": null,
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=SCI-D-415702,OU=sci-flochova,OU=02018,OU=Personalni,OU=2.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "SCI-D-415702",
                                          "Description": null,
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    }
                                  ],
                                  "Computers": [],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=02020,OU=Personalni,OU=2.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "02020",
                                  "Description": null,
                                  "OrganizationalUnits": [
                                    {
                                      "DistinguishedName": "OU=sci-kvapilova,OU=02020,OU=Personalni,OU=2.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "sci-kvapilova",
                                      "Description": null,
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=SCI-D-407195,OU=sci-kvapilova,OU=02020,OU=Personalni,OU=2.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "SCI-D-407195",
                                          "Description": null,
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=sci-mendlikova,OU=02020,OU=Personalni,OU=2.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "sci-mendlikova",
                                      "Description": null,
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=SCI-D-346806,OU=sci-mendlikova,OU=02020,OU=Personalni,OU=2.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "SCI-D-346806",
                                          "Description": null,
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=sci-pavlikova,OU=02020,OU=Personalni,OU=2.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "sci-pavlikova",
                                      "Description": null,
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=SCI-D-346766,OU=sci-pavlikova,OU=02020,OU=Personalni,OU=2.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "SCI-D-346766",
                                          "Description": null,
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    }
                                  ],
                                  "Computers": [],
                                  "Type": "OrganizationalUnit"
                                }
                              ],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=Vedeni,OU=2.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Vedeni",
                              "Description": null,
                              "OrganizationalUnits": [
                                {
                                  "DistinguishedName": "OU=02011a,OU=Vedeni,OU=2.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "02011a",
                                  "Description": null,
                                  "OrganizationalUnits": [
                                    {
                                      "DistinguishedName": "OU=sci-musilova,OU=02011a,OU=Vedeni,OU=2.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "sci-musilova",
                                      "Description": null,
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=SCI-D-400026,OU=sci-musilova,OU=02011a,OU=Vedeni,OU=2.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "SCI-D-400026",
                                          "Description": null,
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    }
                                  ],
                                  "Computers": [],
                                  "Type": "OrganizationalUnit"
                                }
                              ],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=3.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "3.NP",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=03006,OU=3.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "03006",
                              "Description": null,
                              "OrganizationalUnits": [
                                {
                                  "DistinguishedName": "OU=sci-juhaszova,OU=03006,OU=3.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "sci-juhaszova",
                                  "Description": null,
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=SCI-D-407193,OU=sci-juhaszova,OU=03006,OU=3.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "SCI-D-407193",
                                      "Description": null,
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=sci-vicherek,OU=03006,OU=3.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "sci-vicherek",
                                  "Description": null,
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=SCI-D-409283,OU=sci-vicherek,OU=03006,OU=3.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "SCI-D-409283",
                                      "Description": null,
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                }
                              ],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=1.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "1.NP",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=01006,OU=1.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "01006",
                              "Description": null,
                              "OrganizationalUnits": [
                                {
                                  "DistinguishedName": "OU=sci-kabarova,OU=01006,OU=1.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "sci-kabarova",
                                  "Description": null,
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=SCI-D-346801,OU=sci-kabarova,OU=01006,OU=1.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "SCI-D-346801",
                                      "Description": null,
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=sci-tumova,OU=01006,OU=1.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "sci-tumova",
                                  "Description": null,
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=SCI-D-346812,OU=sci-tumova,OU=01006,OU=1.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "SCI-D-346812",
                                      "Description": null,
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=sci-prochazkova,OU=01006,OU=1.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "sci-prochazkova",
                                  "Description": null,
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=SCI-D-346815,OU=sci-prochazkova,OU=01006,OU=1.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "SCI-D-346815",
                                      "Description": null,
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=sci-bajgarova,OU=01006,OU=1.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "sci-bajgarova",
                                  "Description": null,
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=SCI-D-346820,OU=sci-bajgarova,OU=01006,OU=1.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "SCI-D-346820",
                                      "Description": null,
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=sci-test,OU=01006,OU=1.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "sci-test",
                                  "Description": null,
                                  "OrganizationalUnits": [],
                                  "Computers": [],
                                  "Type": "OrganizationalUnit"
                                }
                              ],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=01007,OU=1.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "01007",
                              "Description": null,
                              "OrganizationalUnits": [
                                {
                                  "DistinguishedName": "OU=SCI-JANICKOVA,OU=01007,OU=1.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "SCI-JANICKOVA",
                                  "Description": null,
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=SCI-D-417086,OU=SCI-JANICKOVA,OU=01007,OU=1.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "SCI-D-417086",
                                      "Description": null,
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                }
                              ],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=01015,OU=1.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "01015",
                              "Description": null,
                              "OrganizationalUnits": [
                                {
                                  "DistinguishedName": "OU=sci-kvasnickova,OU=01015,OU=1.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "sci-kvasnickova",
                                  "Description": null,
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=SCI-D-346777,OU=sci-kvasnickova,OU=01015,OU=1.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "SCI-D-346777",
                                      "Description": null,
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=sci-pakostova,OU=01015,OU=1.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "sci-pakostova",
                                  "Description": null,
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=SCI-D-346758,OU=sci-pakostova,OU=01015,OU=1.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "SCI-D-346758",
                                      "Description": null,
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                }
                              ],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=01018,OU=1.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "01018",
                              "Description": null,
                              "OrganizationalUnits": [
                                {
                                  "DistinguishedName": "OU=sci-svobodova,OU=01018,OU=1.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "sci-svobodova",
                                  "Description": null,
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=SCI-D-382628,OU=sci-svobodova,OU=01018,OU=1.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "SCI-D-382628",
                                      "Description": null,
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=sci-johnova,OU=01018,OU=1.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "sci-johnova",
                                  "Description": null,
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=SCI-D-346817,OU=sci-johnova,OU=01018,OU=1.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "SCI-D-346817",
                                      "Description": null,
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=sci-kosinova,OU=01018,OU=1.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "sci-kosinova",
                                  "Description": null,
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=SCI-D-346818,OU=sci-kosinova,OU=01018,OU=1.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "SCI-D-346818",
                                      "Description": null,
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                }
                              ],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=01019,OU=1.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "01019",
                              "Description": null,
                              "OrganizationalUnits": [
                                {
                                  "DistinguishedName": "OU=sci-dolezalova,OU=01019,OU=1.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "sci-dolezalova",
                                  "Description": null,
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=SCI-D-393857,OU=sci-dolezalova,OU=01019,OU=1.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "SCI-D-393857",
                                      "Description": null,
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=sci-stary,OU=01019,OU=1.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "sci-stary",
                                  "Description": null,
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=SCI-D-346775,OU=sci-stary,OU=01019,OU=1.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "SCI-D-346775",
                                      "Description": null,
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                }
                              ],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=01019a,OU=1.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "01019a",
                              "Description": null,
                              "OrganizationalUnits": [
                                {
                                  "DistinguishedName": "OU=sci-kovarova,OU=01019a,OU=1.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "sci-kovarova",
                                  "Description": null,
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=SCI-D-440941,OU=sci-kovarova,OU=01019a,OU=1.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "SCI-D-440941",
                                      "Description": null,
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=sci-kolnikova,OU=01019a,OU=1.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "sci-kolnikova",
                                  "Description": null,
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=SCI-D-346755,OU=sci-kolnikova,OU=01019a,OU=1.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "SCI-D-346755",
                                      "Description": null,
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                }
                              ],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=01026,OU=1.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "01026",
                              "Description": null,
                              "OrganizationalUnits": [
                                {
                                  "DistinguishedName": "OU=sci-miskechova,OU=01026,OU=1.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "sci-miskechova",
                                  "Description": null,
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=SCI-D-346772,OU=sci-miskechova,OU=01026,OU=1.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "SCI-D-346772",
                                      "Description": null,
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                }
                              ],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=Studijni,OU=1.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "Studijni",
                              "Description": null,
                              "OrganizationalUnits": [
                                {
                                  "DistinguishedName": "OU=01009,OU=Studijni,OU=1.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "01009",
                                  "Description": null,
                                  "OrganizationalUnits": [
                                    {
                                      "DistinguishedName": "OU=sci-navratilova,OU=01009,OU=Studijni,OU=1.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "sci-navratilova",
                                      "Description": null,
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=SCI-D-346770,OU=sci-navratilova,OU=01009,OU=Studijni,OU=1.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "SCI-D-346770",
                                          "Description": null,
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=sci-ondrackova,OU=01009,OU=Studijni,OU=1.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "sci-ondrackova",
                                      "Description": null,
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=SCI-D-346776,OU=sci-ondrackova,OU=01009,OU=Studijni,OU=1.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "SCI-D-346776",
                                          "Description": null,
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    }
                                  ],
                                  "Computers": [],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=01010,OU=Studijni,OU=1.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "01010",
                                  "Description": null,
                                  "OrganizationalUnits": [
                                    {
                                      "DistinguishedName": "OU=sci-halasova,OU=01010,OU=Studijni,OU=1.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "sci-halasova",
                                      "Description": null,
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=SCI-D-346798,OU=sci-halasova,OU=01010,OU=Studijni,OU=1.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "SCI-D-346798",
                                          "Description": null,
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    },
                                    {
                                      "DistinguishedName": "OU=sci-rychtarikova,OU=01010,OU=Studijni,OU=1.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "sci-rychtarikova",
                                      "Description": null,
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=SCI-D-346751,OU=sci-rychtarikova,OU=01010,OU=Studijni,OU=1.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "SCI-D-346751",
                                          "Description": null,
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    }
                                  ],
                                  "Computers": [],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=01011,OU=Studijni,OU=1.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "01011",
                                  "Description": null,
                                  "OrganizationalUnits": [
                                    {
                                      "DistinguishedName": "OU=sci-mkorcekova,OU=01011,OU=Studijni,OU=1.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "sci-mkorcekova",
                                      "Description": null,
                                      "OrganizationalUnits": [],
                                      "Computers": [
                                        {
                                          "DistinguishedName": "CN=SCI-D-409280,OU=sci-mkorcekova,OU=01011,OU=Studijni,OU=1.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                          "Name": "SCI-D-409280",
                                          "Description": null,
                                          "Type": "Computer"
                                        }
                                      ],
                                      "Type": "OrganizationalUnit"
                                    }
                                  ],
                                  "Computers": [],
                                  "Type": "OrganizationalUnit"
                                }
                              ],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=OVT-01020,OU=1.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "OVT-01020",
                              "Description": null,
                              "OrganizationalUnits": [
                                {
                                  "DistinguishedName": "OU=INSTALL,OU=OVT-01020,OU=1.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "INSTALL",
                                  "Description": null,
                                  "OrganizationalUnits": [],
                                  "Computers": [],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=SCI-TESTDH,OU=OVT-01020,OU=1.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "SCI-TESTDH",
                                  "Description": null,
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=SCI-TEST2DH,OU=SCI-TESTDH,OU=OVT-01020,OU=1.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "SCI-TEST2DH",
                                      "Description": null,
                                      "Type": "Computer"
                                    },
                                    {
                                      "DistinguishedName": "CN=SCI-INSTALL,OU=SCI-TESTDH,OU=OVT-01020,OU=1.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "SCI-INSTALL",
                                      "Description": null,
                                      "Type": "Computer"
                                    },
                                    {
                                      "DistinguishedName": "CN=SCI-D-346783,OU=SCI-TESTDH,OU=OVT-01020,OU=1.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "SCI-D-346783",
                                      "Description": null,
                                      "Type": "Computer"
                                    },
                                    {
                                      "DistinguishedName": "CN=SCI-D-398973,OU=SCI-TESTDH,OU=OVT-01020,OU=1.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "SCI-D-398973",
                                      "Description": null,
                                      "Type": "Computer"
                                    },
                                    {
                                      "DistinguishedName": "CN=SCI-D-346782,OU=SCI-TESTDH,OU=OVT-01020,OU=1.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "SCI-D-346782",
                                      "Description": null,
                                      "Type": "Computer"
                                    },
                                    {
                                      "DistinguishedName": "CN=SCI-D-382707,OU=SCI-TESTDH,OU=OVT-01020,OU=1.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "SCI-D-382707",
                                      "Description": null,
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                },
                                {
                                  "DistinguishedName": "OU=SCI-KUBENA,OU=OVT-01020,OU=1.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "SCI-KUBENA",
                                  "Description": null,
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=SCI-D-392848,OU=SCI-KUBENA,OU=OVT-01020,OU=1.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "SCI-D-392848",
                                      "Description": null,
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                }
                              ],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=01023,OU=1.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "01023",
                              "Description": null,
                              "OrganizationalUnits": [
                                {
                                  "DistinguishedName": "OU=INSTALL,OU=01023,OU=1.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "INSTALL",
                                  "Description": null,
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=SCI-D-346749,OU=INSTALL,OU=01023,OU=1.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "SCI-D-346749",
                                      "Description": null,
                                      "Type": "Computer"
                                    },
                                    {
                                      "DistinguishedName": "CN=SCI-D-382727,OU=INSTALL,OU=01023,OU=1.NP,OU=Budova01,OU=Dekanat,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "SCI-D-382727",
                                      "Description": null,
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                }
                              ],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    }
                  ],
                  "Computers": [],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=Knihovna,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "Knihovna",
                  "Description": null,
                  "OrganizationalUnits": [
                    {
                      "DistinguishedName": "OU=1NP,OU=Knihovna,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "1NP",
                      "Description": null,
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=sci-vypujcni-1,OU=1NP,OU=Knihovna,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "sci-vypujcni-1",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=SCI-D-402110,OU=sci-vypujcni-1,OU=1NP,OU=Knihovna,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "SCI-D-402110",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=3NP,OU=Knihovna,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "3NP",
                      "Description": null,
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=3010,OU=3NP,OU=Knihovna,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "3010",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=sci-drimlova,OU=3010,OU=3NP,OU=Knihovna,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "sci-drimlova",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=SCI-D-461266,OU=sci-drimlova,OU=3010,OU=3NP,OU=Knihovna,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "SCI-D-461266",
                                  "Description": null,
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=sci-kourilova,OU=3010,OU=3NP,OU=Knihovna,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "sci-kourilova",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=SCI-D-456306,OU=sci-kourilova,OU=3010,OU=3NP,OU=Knihovna,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "SCI-D-456306",
                                  "Description": null,
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=sci-nemcova,OU=3010,OU=3NP,OU=Knihovna,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "sci-nemcova",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=SCI-D-461264,OU=sci-nemcova,OU=3010,OU=3NP,OU=Knihovna,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "SCI-D-461264",
                                  "Description": null,
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=sci-trencanska,OU=3010,OU=3NP,OU=Knihovna,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "sci-trencanska",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=SCI-D-346780,OU=sci-trencanska,OU=3010,OU=3NP,OU=Knihovna,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "SCI-D-346780",
                                  "Description": null,
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=3012,OU=3NP,OU=Knihovna,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "3012",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=sci-skarkova,OU=3012,OU=3NP,OU=Knihovna,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "sci-skarkova",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=SCI-D-346824,OU=sci-skarkova,OU=3012,OU=3NP,OU=Knihovna,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "SCI-D-346824",
                                  "Description": null,
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=3013,OU=3NP,OU=Knihovna,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "3013",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=sci-zalozni,OU=3013,OU=3NP,OU=Knihovna,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "sci-zalozni",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=SCI-D-346797,OU=sci-zalozni,OU=3013,OU=3NP,OU=Knihovna,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "SCI-D-346797",
                                  "Description": null,
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=sci-vokral,OU=3013,OU=3NP,OU=Knihovna,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "sci-vokral",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=SCI-D-402109,OU=sci-vokral,OU=3013,OU=3NP,OU=Knihovna,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "SCI-D-402109",
                                  "Description": null,
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=3014,OU=3NP,OU=Knihovna,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "3014",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=sci-velisek,OU=3014,OU=3NP,OU=Knihovna,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "sci-velisek",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=SCI-D-456307,OU=sci-velisek,OU=3014,OU=3NP,OU=Knihovna,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "SCI-D-456307",
                                  "Description": null,
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=sci-sipkova,OU=3014,OU=3NP,OU=Knihovna,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "sci-sipkova",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=sci-urbankova,OU=3014,OU=3NP,OU=Knihovna,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "sci-urbankova",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=SCI-D-461265,OU=sci-urbankova,OU=3014,OU=3NP,OU=Knihovna,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "SCI-D-461265",
                                  "Description": null,
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=sci-vypujcni-2,OU=3NP,OU=Knihovna,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "sci-vypujcni-2",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=SCI-D-402108,OU=sci-vypujcni-2,OU=3NP,OU=Knihovna,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "SCI-D-402108",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    }
                  ],
                  "Computers": [],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=Anthropologie,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "Anthropologie",
                  "Description": null,
                  "OrganizationalUnits": [
                    {
                      "DistinguishedName": "OU=Budova02,OU=Anthropologie,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Budova02",
                      "Description": null,
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=2.NP,OU=Budova02,OU=Anthropologie,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "2.NP",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=02016,OU=2.NP,OU=Budova02,OU=Anthropologie,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "02016",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=SCI-D-472070,OU=02016,OU=2.NP,OU=Budova02,OU=Anthropologie,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "SCI-D-472070",
                                  "Description": null,
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            },
                            {
                              "DistinguishedName": "OU=02009a,OU=2.NP,OU=Budova02,OU=Anthropologie,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "02009a",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=SCI-D-382679,OU=02009a,OU=2.NP,OU=Budova02,OU=Anthropologie,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "SCI-D-382679",
                                  "Description": null,
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=1.NP,OU=Budova02,OU=Anthropologie,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "1.NP",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=01017,OU=1.NP,OU=Budova02,OU=Anthropologie,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "01017",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=SCI-D-458522,OU=01017,OU=1.NP,OU=Budova02,OU=Anthropologie,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "SCI-D-458522",
                                  "Description": null,
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    }
                  ],
                  "Computers": [],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=Geologie,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "Geologie",
                  "Description": null,
                  "OrganizationalUnits": [
                    {
                      "DistinguishedName": "OU=Budova11,OU=Geologie,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Budova11",
                      "Description": null,
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=1.NP,OU=Budova11,OU=Geologie,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "1.NP",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=2.NP,OU=Budova11,OU=Geologie,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "2.NP",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=02013e,OU=2.NP,OU=Budova11,OU=Geologie,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "02013e",
                              "Description": null,
                              "OrganizationalUnits": [
                                {
                                  "DistinguishedName": "OU=SCI-URBANEK,OU=02013e,OU=2.NP,OU=Budova11,OU=Geologie,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "SCI-URBANEK",
                                  "Description": null,
                                  "OrganizationalUnits": [],
                                  "Computers": [
                                    {
                                      "DistinguishedName": "CN=SCI-D-382650,OU=SCI-URBANEK,OU=02013e,OU=2.NP,OU=Budova11,OU=Geologie,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                      "Name": "SCI-D-382650",
                                      "Description": null,
                                      "Type": "Computer"
                                    }
                                  ],
                                  "Type": "OrganizationalUnit"
                                }
                              ],
                              "Computers": [],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=Budova 3,OU=Geologie,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Budova 3",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    }
                  ],
                  "Computers": [],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=Geografie,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "Geografie",
                  "Description": null,
                  "OrganizationalUnits": [],
                  "Computers": [],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=CJV,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "CJV",
                  "Description": null,
                  "OrganizationalUnits": [
                    {
                      "DistinguishedName": "OU=2032,OU=CJV,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "2032",
                      "Description": null,
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=sci-dudova,OU=2032,OU=CJV,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "sci-dudova",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=SCI-D-391726,OU=sci-dudova,OU=2032,OU=CJV,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "SCI-D-391726",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=sci-blizkova,OU=2032,OU=CJV,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "sci-blizkova",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=SCI-D-416198,OU=sci-blizkova,OU=2032,OU=CJV,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "SCI-D-416198",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=1057,OU=CJV,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "1057",
                      "Description": null,
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=sci-kubricka,OU=1057,OU=CJV,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "sci-kubricka",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=SCI-D-391746,OU=sci-kubricka,OU=1057,OU=CJV,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "SCI-D-391746",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=1036,OU=CJV,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "1036",
                      "Description": null,
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=sci-coupkova,OU=1036,OU=CJV,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "sci-coupkova",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=SCI-D-346759,OU=sci-coupkova,OU=1036,OU=CJV,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "SCI-D-346759",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=1038,OU=CJV,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "1038",
                      "Description": null,
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=sci-j1,OU=1038,OU=CJV,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "sci-j1",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=SCI-D-382736,OU=sci-j1,OU=1038,OU=CJV,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "SCI-D-382736",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=SCI-D-416200,OU=sci-j1,OU=1038,OU=CJV,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "SCI-D-416200",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=2034,OU=CJV,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "2034",
                      "Description": null,
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=sci-j5,OU=2034,OU=CJV,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "sci-j5",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=SCI-D-416201,OU=sci-j5,OU=2034,OU=CJV,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "SCI-D-416201",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=1063,OU=CJV,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "1063",
                      "Description": null,
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=sci-sborovna,OU=1063,OU=CJV,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "sci-sborovna",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=SCI-D-415382,OU=sci-sborovna,OU=1063,OU=CJV,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "SCI-D-415382",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=sci-pavlikova,OU=1063,OU=CJV,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "sci-pavlikova",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=SCI-D-385922,OU=sci-pavlikova,OU=1063,OU=CJV,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "SCI-D-385922",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=1039,OU=CJV,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "1039",
                      "Description": null,
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=sci-j2,OU=1039,OU=CJV,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "sci-j2",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=SCI-D-416199,OU=sci-j2,OU=1039,OU=CJV,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "SCI-D-416199",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    }
                  ],
                  "Computers": [],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=UFTP,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "UFTP",
                  "Description": null,
                  "OrganizationalUnits": [
                    {
                      "DistinguishedName": "OU=INSTALL,OU=UFTP,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "INSTALL",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=SCI-D-382704,OU=INSTALL,OU=UFTP,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SCI-D-382704",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=SCI-D-382737,OU=INSTALL,OU=UFTP,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SCI-D-382737",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=SCI-D-382656,OU=INSTALL,OU=UFTP,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SCI-D-382656",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=SCI-D-382718,OU=INSTALL,OU=UFTP,OU=SCI,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SCI-D-382718",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    }
                  ],
                  "Computers": [],
                  "Type": "OrganizationalUnit"
                }
              ],
              "Computers": [],
              "Type": "OrganizationalUnit"
            },
            {
              "DistinguishedName": "OU=TEIR,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
              "Name": "TEIR",
              "Description": "ict@teiresias.muni.cz",
              "OrganizationalUnits": [
                {
                  "DistinguishedName": "OU=CJV,OU=TEIR,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "CJV",
                  "Description": null,
                  "OrganizationalUnits": [
                    {
                      "DistinguishedName": "OU=U406,OU=CJV,OU=TEIR,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "U406",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=TEIR-D-CJV44661,OU=U406,OU=CJV,OU=TEIR,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-CJV44661",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=U407,OU=CJV,OU=TEIR,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "U407",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=TEIR-D-CJV99998,OU=U407,OU=CJV,OU=TEIR,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-CJV99998",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=TEIR-D-CJV99997,OU=U407,OU=CJV,OU=TEIR,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-CJV99997",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=TEIR-D-CJV28741,OU=U407,OU=CJV,OU=TEIR,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-CJV28741",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=TEIR-D-CJV99999,OU=U407,OU=CJV,OU=TEIR,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-CJV99999",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=U408,OU=CJV,OU=TEIR,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "U408",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=TEIR-D-CJV44660,OU=U408,OU=CJV,OU=TEIR,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-CJV44660",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=218,OU=CJV,OU=TEIR,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "218",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=TEIR-D-CJV03683,OU=218,OU=CJV,OU=TEIR,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-CJV03683",
                          "Description": "Sedl\u00E1\u010Dkov\u00E1",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=TEIR-D-CJV17753,OU=218,OU=CJV,OU=TEIR,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-CJV17753",
                          "Description": "\u0160indel\u00E1\u0159ov\u00E1 Skupe\u0148ov\u00E1",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=TEIR-D-CJV03687,OU=218,OU=CJV,OU=TEIR,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-CJV03687",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=U213,OU=CJV,OU=TEIR,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "U213",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=TEIR-D-CJV49119,OU=U213,OU=CJV,OU=TEIR,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-CJV49119",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=212,OU=CJV,OU=TEIR,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "212",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=TEIR-D-CJV19845,OU=212,OU=CJV,OU=TEIR,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-CJV19845",
                          "Description": "Sd\u00EDlen\u00FD",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=TEIR-D-CJV28739,OU=212,OU=CJV,OU=TEIR,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-CJV28739",
                          "Description": "Holasov\u00E1",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=214,OU=CJV,OU=TEIR,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "214",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=TEIR-D-CJV60483,OU=214,OU=CJV,OU=TEIR,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-CJV60483",
                          "Description": "Pl\u00ED\u0161kov\u00E1",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=TEIR-D-CJV60474,OU=214,OU=CJV,OU=TEIR,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-CJV60474",
                          "Description": "Navr\u00E1tilov\u00E1",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=TEIR-D-CJV65558,OU=214,OU=CJV,OU=TEIR,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-CJV65558",
                          "Description": "Pe\u0161kov\u00E1",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=TEIR-D-CJV49159,OU=214,OU=CJV,OU=TEIR,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-CJV49159",
                          "Description": "Hobzov\u00E1",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=214A,OU=CJV,OU=TEIR,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "214A",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=TEIR-D-CJV56170,OU=214A,OU=CJV,OU=TEIR,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-CJV56170",
                          "Description": "Frecerov\u00E1 / sd\u00EDlen\u00FD",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=TEIR-D-CJV56176,OU=214A,OU=CJV,OU=TEIR,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-CJV56176",
                          "Description": "Krahula Dole\u017E\u00ED",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=TEIR-D-CJV56168,OU=214A,OU=CJV,OU=TEIR,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-CJV56168",
                          "Description": "Slo\u017Eilov\u00E1",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=TEIR-D-CJV54431,OU=214A,OU=CJV,OU=TEIR,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-CJV54431",
                          "Description": "Alchazidu",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=215,OU=CJV,OU=TEIR,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "215",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=TEIR-D-CJV62180,OU=215,OU=CJV,OU=TEIR,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-CJV62180",
                          "Description": "Kuzdasov\u00E1",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=TEIR-D-CJV28740,OU=215,OU=CJV,OU=TEIR,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-CJV28740",
                          "Description": "Svobodov\u00E1",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=216,OU=CJV,OU=TEIR,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "216",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=TEIR-D-CJV19847,OU=216,OU=CJV,OU=TEIR,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-CJV19847",
                          "Description": "Vondr\u00E1\u010Dkov\u00E1",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=TEIR-D-CJV19848,OU=216,OU=CJV,OU=TEIR,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-CJV19848",
                          "Description": "Slez\u00E1kov\u00E1",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=217,OU=CJV,OU=TEIR,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "217",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=TEIR-D-CJV60485,OU=217,OU=CJV,OU=TEIR,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-CJV60485",
                          "Description": "Staffolani",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=TEIR-D-CJV19843,OU=217,OU=CJV,OU=TEIR,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-CJV19843",
                          "Description": "Fillinger",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=TEIR-D-CJV19846,OU=217,OU=CJV,OU=TEIR,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-CJV19846",
                          "Description": "Poledn\u00EDkov\u00E1",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=219,OU=CJV,OU=TEIR,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "219",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=TEIR-D-CJV65559,OU=219,OU=CJV,OU=TEIR,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-CJV65559",
                          "Description": "\u0160t\u011Bp\u00E1nek",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    }
                  ],
                  "Computers": [],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=Teiresias,OU=TEIR,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "Teiresias",
                  "Description": null,
                  "OrganizationalUnits": [
                    {
                      "DistinguishedName": "OU=ICT,OU=Teiresias,OU=TEIR,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ICT",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=TEIR-D-Kerberus,OU=ICT,OU=Teiresias,OU=TEIR,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-Kerberus",
                          "Description": "Ji\u0159\u00ED Letocha",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=TEIR-D-Endymion,OU=ICT,OU=Teiresias,OU=TEIR,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-Endymion",
                          "Description": "Jan Bouma",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=Vel\u00EDn,OU=Teiresias,OU=TEIR,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Vel\u00EDn",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=TEIR-D-Elektryo,OU=Vel\u00EDn,OU=Teiresias,OU=TEIR,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-Elektryo",
                          "Description": "Al\u017Eb\u011Bta Severov\u00E1",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=TEIR-D-Akamas,OU=Vel\u00EDn,OU=Teiresias,OU=TEIR,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-Akamas",
                          "Description": "Eva Hul\u00EDkov\u00E1",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=TEIR-D-Styx,OU=Vel\u00EDn,OU=Teiresias,OU=TEIR,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-Styx",
                          "Description": "Hana \u017Dampachov\u00E1",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=TEIR-D-Theseus,OU=Vel\u00EDn,OU=Teiresias,OU=TEIR,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-Theseus",
                          "Description": "Ond\u0159ej Pelech",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=TEIR-D-Ponos,OU=Vel\u00EDn,OU=Teiresias,OU=TEIR,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-Ponos",
                          "Description": "Andrea L\u00E1zni\u010Dkov\u00E1",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=TEIR-D-Harmonia,OU=Vel\u00EDn,OU=Teiresias,OU=TEIR,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-Harmonia",
                          "Description": "\u0160\u00E1rka Panochov\u00E1",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=TEIR-D-Acheron,OU=Vel\u00EDn,OU=Teiresias,OU=TEIR,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-Acheron",
                          "Description": "Anna Kol\u00E1\u0159ov\u00E1",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=TEIR-D-Merope,OU=Vel\u00EDn,OU=Teiresias,OU=TEIR,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-Merope",
                          "Description": "Luk\u00E1\u0161 M\u00E1silko",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=TEIR-D-Lethe,OU=Vel\u00EDn,OU=Teiresias,OU=TEIR,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-Lethe",
                          "Description": "Ester Det\u00E1ryov\u00E1",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=OVK,OU=Teiresias,OU=TEIR,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "OVK",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=TEIR-D-Iamus,OU=OVK,OU=Teiresias,OU=TEIR,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-Iamus",
                          "Description": "p\u016Fvodn\u011B Jind\u0159ich Mikul\u00EDk",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=TEIR-D-Metis,OU=OVK,OU=Teiresias,OU=TEIR,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-Metis",
                          "Description": "Dana Pe\u0148\u00E1zov\u00E1",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=TEIR-D-Nereus,OU=OVK,OU=Teiresias,OU=TEIR,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-Nereus",
                          "Description": "Ivana Kup\u010D\u00EDkov\u00E1",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=TEIR-D-Dike,OU=OVK,OU=Teiresias,OU=TEIR,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-Dike",
                          "Description": "Jan Fikejs",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=TEIR-D-Euadne,OU=OVK,OU=Teiresias,OU=TEIR,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-Euadne",
                          "Description": "Alena Sklen\u00E1kov\u00E1",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=TEIR-D-Talos,OU=OVK,OU=Teiresias,OU=TEIR,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-Talos",
                          "Description": "Alexandr Zvonek",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=TEIR-D-Zeus,OU=OVK,OU=Teiresias,OU=TEIR,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-Zeus",
                          "Description": "Mirka Tylov\u00E1",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=TEIR-D-Androkta,OU=OVK,OU=Teiresias,OU=TEIR,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-Androkta",
                          "Description": "Ond\u0159ej Klof\u00E1\u010D",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=TEIR-D-Limos,OU=OVK,OU=Teiresias,OU=TEIR,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-Limos",
                          "Description": "Zbyn\u011Bk Cincibus",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=Audiostudio,OU=Teiresias,OU=TEIR,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Audiostudio",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=TEIR-D-Perses,OU=Audiostudio,OU=Teiresias,OU=TEIR,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-Perses",
                          "Description": "audiostudio",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=Videostudio,OU=Teiresias,OU=TEIR,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Videostudio",
                      "Description": null,
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=Studio,OU=Videostudio,OU=Teiresias,OU=TEIR,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "Studio",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=TEIR-D-Eileithy,OU=Studio,OU=Videostudio,OU=Teiresias,OU=TEIR,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "TEIR-D-Eileithy",
                              "Description": "videostudio - n\u00E1hledov\u00FD",
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=Re\u017Eie,OU=Videostudio,OU=Teiresias,OU=TEIR,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "Re\u017Eie",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=TEIR-D-Terpsich,OU=Re\u017Eie,OU=Videostudio,OU=Teiresias,OU=TEIR,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "TEIR-D-Terpsich",
                              "Description": "videostudio - re\u017Eie",
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=TEIR-D-Kronos1,OU=Videostudio,OU=Teiresias,OU=TEIR,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-Kronos1",
                          "Description": "videostudio",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=TEIR-D-Kronos2,OU=Videostudio,OU=Teiresias,OU=TEIR,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-Kronos2",
                          "Description": "videostudio",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=TEIR-D-Kronos3,OU=Videostudio,OU=Teiresias,OU=TEIR,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-Kronos3",
                          "Description": "videostudio",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=Sekretari\u00E1t,OU=Teiresias,OU=TEIR,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Sekretari\u00E1t",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=TEIR-D-Polymath,OU=Sekretari\u00E1t,OU=Teiresias,OU=TEIR,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-Polymath",
                          "Description": "Lenka T\u00F3thov\u00E1",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=TEIR-D-Aristaeu,OU=Sekretari\u00E1t,OU=Teiresias,OU=TEIR,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-Aristaeu",
                          "Description": "Eva Han\u00E1kov\u00E1",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=TEIR-D-Ariadne,OU=Sekretari\u00E1t,OU=Teiresias,OU=TEIR,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-Ariadne",
                          "Description": "Jitka Carcou\u00EBt",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=TEIR-D-Kokytos,OU=Sekretari\u00E1t,OU=Teiresias,OU=TEIR,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-Kokytos",
                          "Description": "Ale\u0161 Moravec",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=TEIR-D-Pausania,OU=Sekretari\u00E1t,OU=Teiresias,OU=TEIR,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-Pausania",
                          "Description": "Jana Havlov\u00E1",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=TEIR-D-Flegetho,OU=Sekretari\u00E1t,OU=Teiresias,OU=TEIR,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-Flegetho",
                          "Description": "Ond\u0159ej Banovsk\u00FD",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=Knihovn\u00EDci,OU=Teiresias,OU=TEIR,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Knihovn\u00EDci",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=TEIR-D-Polyneik,OU=Knihovn\u00EDci,OU=Teiresias,OU=TEIR,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-Polyneik",
                          "Description": "Kate\u0159ina Kom\u00E1rkov\u00E1",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=TEIR-D-Megaira,OU=Knihovn\u00EDci,OU=Teiresias,OU=TEIR,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-Megaira",
                          "Description": "Veronika \u0160im\u0161ov\u00E1",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=Tiska\u0159i,OU=Teiresias,OU=TEIR,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Tiska\u0159i",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=TEIR-D-Aglaia,OU=Tiska\u0159i,OU=Teiresias,OU=TEIR,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-Aglaia",
                          "Description": "tisk\u00E1rna - spole\u010Dn\u00FD (PC po P. Du\u0161kovi)",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    }
                  ],
                  "Computers": [],
                  "Type": "OrganizationalUnit"
                }
              ],
              "Computers": [],
              "Type": "OrganizationalUnit"
            },
            {
              "DistinguishedName": "OU=ESF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
              "Name": "ESF",
              "Description": "cikt@econ.muni.cz",
              "OrganizationalUnits": [
                {
                  "DistinguishedName": "OU=DEK,OU=ESF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "DEK",
                  "Description": null,
                  "OrganizationalUnits": [],
                  "Computers": [
                    {
                      "DistinguishedName": "CN=ESF-D-DEKPrikry,OU=DEK,OU=ESF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-DEKPrikry",
                      "Description": "DEK Lucie P\u0159ikrylov\u00E1",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-DEK05,OU=DEK,OU=ESF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-DEK05",
                      "Description": "Zusk\u00E1\u010Dov\u00E1",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-DEK03,OU=DEK,OU=ESF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-DEK03",
                      "Description": "So\u0148a Slune\u010Dnice",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-DEK04,OU=DEK,OU=ESF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-DEK04",
                      "Description": "Blaninka Kopretinka",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-DEK06,OU=DEK,OU=ESF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-DEK06",
                      "Description": "\u0160ustrov\u00E1",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-DEKZachov,OU=DEK,OU=ESF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-DEKZachov",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-DEKJezova,OU=DEK,OU=ESF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-DEKJezova",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-DEKKuchov,OU=DEK,OU=ESF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-DEKKuchov",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-DEKJurkov,OU=DEK,OU=ESF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-DEKJurkov",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-DHM398270,OU=DEK,OU=ESF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-DHM398270",
                      "Description": "\u0160palek",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-DEK02,OU=DEK,OU=ESF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-DEK02",
                      "Description": "Adamcov\u00E1",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-DEKRozmah,OU=DEK,OU=ESF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-DEKRozmah",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-DEK01,OU=DEK,OU=ESF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-DEK01",
                      "Description": "Ani\u010Dka R\u016F\u017Ei\u010Dka",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-DEKNezval,OU=DEK,OU=ESF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-DEKNezval",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-DEKCharva,OU=DEK,OU=ESF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-DEKCharva",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-DEKRytir,OU=DEK,OU=ESF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-DEKRytir",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-DEKPodate,OU=DEK,OU=ESF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-DEKPodate",
                      "Description": null,
                      "Type": "Computer"
                    }
                  ],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=KAMI,OU=ESF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "KAMI",
                  "Description": null,
                  "OrganizationalUnits": [],
                  "Computers": [],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=CIKT,OU=ESF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "CIKT",
                  "Description": null,
                  "OrganizationalUnits": [],
                  "Computers": [
                    {
                      "DistinguishedName": "CN=ESF-D-CIKTKyryl,OU=CIKT,OU=ESF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-CIKTKyryl",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-TEST1,OU=CIKT,OU=ESF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-TEST1",
                      "Description": "testovac\u00ED PC",
                      "Type": "Computer"
                    }
                  ],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=KVE,OU=ESF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "KVE",
                  "Description": null,
                  "OrganizationalUnits": [],
                  "Computers": [
                    {
                      "DistinguishedName": "CN=ESF-D-KVE01,OU=KVE,OU=ESF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-KVE01",
                      "Description": "\u0160pa\u010Dek",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-KVE02,OU=KVE,OU=ESF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-KVE02",
                      "Description": "Pavl\u00EDk",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-KVE03,OU=KVE,OU=ESF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-KVE03",
                      "Description": "Delalande",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-KVEHruza,OU=KVE,OU=ESF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-KVEHruza",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-DHM422079,OU=KVE,OU=ESF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-DHM422079",
                      "Description": null,
                      "Type": "Computer"
                    }
                  ],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=KRE,OU=ESF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "KRE",
                  "Description": null,
                  "OrganizationalUnits": [],
                  "Computers": [
                    {
                      "DistinguishedName": "CN=ESF-D-KREDohod,OU=KRE,OU=ESF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-KREDohod",
                      "Description": null,
                      "Type": "Computer"
                    }
                  ],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=SVI,OU=ESF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "SVI",
                  "Description": null,
                  "OrganizationalUnits": [],
                  "Computers": [
                    {
                      "DistinguishedName": "CN=ESF-D-SVIKarol,OU=SVI,OU=ESF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-SVIKarol",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-DHM448371,OU=SVI,OU=ESF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-DHM448371",
                      "Description": null,
                      "Type": "Computer"
                    }
                  ],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=CJV,OU=ESF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "CJV",
                  "Description": null,
                  "OrganizationalUnits": [],
                  "Computers": [
                    {
                      "DistinguishedName": "CN=ESF-D-CJV01,OU=CJV,OU=ESF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-CJV01",
                      "Description": null,
                      "Type": "Computer"
                    }
                  ],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=KPEM,OU=ESF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "KPEM",
                  "Description": null,
                  "OrganizationalUnits": [],
                  "Computers": [
                    {
                      "DistinguishedName": "CN=ESF-D-KPEM01,OU=KPEM,OU=ESF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-KPEM01",
                      "Description": "Proch\u00E1zka",
                      "Type": "Computer"
                    }
                  ],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=KF,OU=ESF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "KF",
                  "Description": null,
                  "OrganizationalUnits": [],
                  "Computers": [
                    {
                      "DistinguishedName": "CN=ESF-D-KF01,OU=KF,OU=ESF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-KF01",
                      "Description": "V\u00E1vrov\u00E1",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-KF02,OU=KF,OU=ESF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-KF02",
                      "Description": "Havl\u00ED\u010Dkov\u00E1",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-KF03,OU=KF,OU=ESF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-KF03",
                      "Description": "O\u0161krdalov\u00E1",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-KF04,OU=KF,OU=ESF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-KF04",
                      "Description": "Benada",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-KFValouch,OU=KF,OU=ESF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-KFValouch",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-KFVyrost,OU=KF,OU=ESF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-KFVyrost",
                      "Description": null,
                      "Type": "Computer"
                    }
                  ],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=KE,OU=ESF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "KE",
                  "Description": null,
                  "OrganizationalUnits": [],
                  "Computers": [
                    {
                      "DistinguishedName": "CN=ESF-D-KESvenhov,OU=KE,OU=ESF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-KESvenhov",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-KEKrcal,OU=KE,OU=ESF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-KEKrcal",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-KEJonas,OU=KE,OU=ESF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-KEJonas",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-KE01,OU=KE,OU=ESF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-KE01",
                      "Description": "Reichel",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-KE02,OU=KE,OU=ESF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-KE02",
                      "Description": "Kvizda",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-KE03,OU=KE,OU=ESF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-KE03",
                      "Description": "N\u011Bmec",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-KEChalmov,OU=KE,OU=ESF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-KEChalmov",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-KEShah,OU=KE,OU=ESF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-KEShah",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-KEStanek,OU=KE,OU=ESF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-KEStanek",
                      "Description": null,
                      "Type": "Computer"
                    }
                  ],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=KPH,OU=ESF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "KPH",
                  "Description": null,
                  "OrganizationalUnits": [],
                  "Computers": [
                    {
                      "DistinguishedName": "CN=ESF-D-KPHKrcal,OU=KPH,OU=ESF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-KPHKrcal",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-KPHRadova,OU=KPH,OU=ESF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-KPHRadova",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-KPHSkapa,OU=KPH,OU=ESF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-KPHSkapa",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-KPHZakova,OU=KPH,OU=ESF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-KPHZakova",
                      "Description": "KPH \u017D\u00E1kov\u00E1-Talpov\u00E1",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-KPHAhad,OU=KPH,OU=ESF,OU=Desktops,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-KPHAhad",
                      "Description": null,
                      "Type": "Computer"
                    }
                  ],
                  "Type": "OrganizationalUnit"
                }
              ],
              "Computers": [],
              "Type": "OrganizationalUnit"
            }
          ],
          "Computers": [],
          "Type": "OrganizationalUnit"
        },
        {
          "DistinguishedName": "OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
          "Name": "ClassRooms",
          "Description": null,
          "OrganizationalUnits": [
            {
              "DistinguishedName": "OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
              "Name": "FSS",
              "Description": "cikt@fss.muni.cz",
              "OrganizationalUnits": [
                {
                  "DistinguishedName": "OU=Doktorandi,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "Doktorandi",
                  "Description": null,
                  "OrganizationalUnits": [
                    {
                      "DistinguishedName": "OU=533,OU=Doktorandi,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "533",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FSS-D-C466582,OU=533,OU=Doktorandi,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FSS-D-C466582",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FSS-D-C466581,OU=533,OU=Doktorandi,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FSS-D-C466581",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FSS-D-C466580,OU=533,OU=Doktorandi,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FSS-D-C466580",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FSS-D-C466578,OU=533,OU=Doktorandi,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FSS-D-C466578",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=322,OU=Doktorandi,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "322",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FSS-D-C378015,OU=322,OU=Doktorandi,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FSS-D-C378015",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FSS-D-C422092,OU=322,OU=Doktorandi,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FSS-D-C422092",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=411,OU=Doktorandi,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "411",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FSS-D-C466353,OU=411,OU=Doktorandi,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FSS-D-C466353",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FSS-D-C466354,OU=411,OU=Doktorandi,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FSS-D-C466354",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FSS-D-C466355,OU=411,OU=Doktorandi,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FSS-D-C466355",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FSS-D-C466356,OU=411,OU=Doktorandi,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FSS-D-C466356",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=314,OU=Doktorandi,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "314",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=310,OU=Doktorandi,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "310",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=451,OU=Doktorandi,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "451",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=252,OU=Doktorandi,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "252",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FSS-D-C466301,OU=252,OU=Doktorandi,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FSS-D-C466301",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FSS-D-C466303,OU=252,OU=Doktorandi,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FSS-D-C466303",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=455a,OU=Doktorandi,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "455a",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=455b,OU=Doktorandi,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "455b",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FSS-D-C466269,OU=455b,OU=Doktorandi,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FSS-D-C466269",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FSS-D-C466270,OU=455b,OU=Doktorandi,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FSS-D-C466270",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FSS-D-C466271,OU=455b,OU=Doktorandi,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FSS-D-C466271",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FSS-D-C466272,OU=455b,OU=Doktorandi,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FSS-D-C466272",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=532,OU=Doktorandi,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "532",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FSS-D-C343148,OU=532,OU=Doktorandi,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FSS-D-C343148",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FSS-D-C466583,OU=532,OU=Doktorandi,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FSS-D-C466583",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FSS-D-C466584,OU=532,OU=Doktorandi,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FSS-D-C466584",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FSS-D-C466585,OU=532,OU=Doktorandi,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FSS-D-C466585",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FSS-D-C466586,OU=532,OU=Doktorandi,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FSS-D-C466586",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=263,OU=Doktorandi,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "263",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FSS-D-C414338,OU=263,OU=Doktorandi,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FSS-D-C414338",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FSS-D-C414339,OU=263,OU=Doktorandi,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FSS-D-C414339",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=335,OU=Doktorandi,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "335",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FSS-D-C466288,OU=335,OU=Doktorandi,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FSS-D-C466288",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FSS-D-C466287,OU=335,OU=Doktorandi,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FSS-D-C466287",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FSS-D-C466289,OU=335,OU=Doktorandi,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FSS-D-C466289",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FSS-D-C466290,OU=335,OU=Doktorandi,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FSS-D-C466290",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    }
                  ],
                  "Computers": [],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=Katedry,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "Katedry",
                  "Description": null,
                  "OrganizationalUnits": [
                    {
                      "DistinguishedName": "OU=AVC,OU=Katedry,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "AVC",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FSS-D-KAVC,OU=AVC,OU=Katedry,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FSS-D-KAVC",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    }
                  ],
                  "Computers": [
                    {
                      "DistinguishedName": "CN=FSS-D-KU41,OU=Katedry,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FSS-D-KU41",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FSS-D-KP21a,OU=Katedry,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FSS-D-KP21a",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FSS-D-KAula,OU=Katedry,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FSS-D-KAula",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FSS-D-KM117,OU=Katedry,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FSS-D-KM117",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FSS-D-KU35,OU=Katedry,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FSS-D-KU35",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FSS-D-KU36,OU=Katedry,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FSS-D-KU36",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FSS-D-KP31,OU=Katedry,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FSS-D-KP31",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FSS-D-KP22,OU=Katedry,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FSS-D-KP22",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FSS-D-KU34,OU=Katedry,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FSS-D-KU34",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FSS-D-KP52a,OU=Katedry,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FSS-D-KP52a",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FSS-D-KP52b,OU=Katedry,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FSS-D-KP52b",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FSS-D-KP21b,OU=Katedry,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FSS-D-KP21b",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FSS-D-KU23,OU=Katedry,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FSS-D-KU23",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FSS-D-KP24a,OU=Katedry,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FSS-D-KP24a",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FSS-D-KP24b,OU=Katedry,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FSS-D-KP24b",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FSS-D-KU42,OU=Katedry,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FSS-D-KU42",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FSS-D-KU53,OU=Katedry,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FSS-D-KU53",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FSS-D-KU32,OU=Katedry,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FSS-D-KU32",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FSS-D-KU33,OU=Katedry,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FSS-D-KU33",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FSS-D-KU43,OU=Katedry,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FSS-D-KU43",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FSS-D-KU44,OU=Katedry,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FSS-D-KU44",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FSS-D-KP51,OU=Katedry,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FSS-D-KP51",
                      "Description": null,
                      "Type": "Computer"
                    }
                  ],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "ucebnyPC",
                  "Description": null,
                  "OrganizationalUnits": [
                    {
                      "DistinguishedName": "OU=Studio,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Studio",
                      "Description": null,
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=studentsky,OU=Studio,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "studentsky",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=FSS-D-ST01,OU=studentsky,OU=Studio,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-ST01",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-ST02,OU=studentsky,OU=Studio,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-ST02",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-ST03,OU=studentsky,OU=Studio,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-ST03",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-ST04,OU=studentsky,OU=Studio,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-ST04",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-ST05,OU=studentsky,OU=Studio,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-ST05",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-ST07,OU=studentsky,OU=Studio,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-ST07",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-ST08,OU=studentsky,OU=Studio,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-ST08",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-ST09,OU=studentsky,OU=Studio,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-ST09",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-ST10,OU=studentsky,OU=Studio,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-ST10",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-ST06,OU=studentsky,OU=Studio,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-ST06",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=ucitelsky,OU=Studio,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ucitelsky",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=FSS-D-ST11,OU=ucitelsky,OU=Studio,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-ST11",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=PC54,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "PC54",
                      "Description": null,
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=ucitelsky,OU=PC54,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ucitelsky",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=FSS-D-PC5421,OU=ucitelsky,OU=PC54,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-PC5421",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=studentsky,OU=PC54,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "studentsky",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=FSS-D-PC5401,OU=studentsky,OU=PC54,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-PC5401",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-PC5402,OU=studentsky,OU=PC54,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-PC5402",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-PC5403,OU=studentsky,OU=PC54,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-PC5403",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-PC5404,OU=studentsky,OU=PC54,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-PC5404",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-PC5405,OU=studentsky,OU=PC54,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-PC5405",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-PC5406,OU=studentsky,OU=PC54,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-PC5406",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-PC5407,OU=studentsky,OU=PC54,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-PC5407",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-PC5408,OU=studentsky,OU=PC54,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-PC5408",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-PC5409,OU=studentsky,OU=PC54,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-PC5409",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-PC5410,OU=studentsky,OU=PC54,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-PC5410",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-PC5411,OU=studentsky,OU=PC54,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-PC5411",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-PC5412,OU=studentsky,OU=PC54,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-PC5412",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-PC5413,OU=studentsky,OU=PC54,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-PC5413",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-PC5414,OU=studentsky,OU=PC54,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-PC5414",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-PC5415,OU=studentsky,OU=PC54,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-PC5415",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-PC5416,OU=studentsky,OU=PC54,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-PC5416",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-PC5417,OU=studentsky,OU=PC54,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-PC5417",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-PC5418,OU=studentsky,OU=PC54,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-PC5418",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-PC5419,OU=studentsky,OU=PC54,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-PC5419",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-PC5420,OU=studentsky,OU=PC54,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-PC5420",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=PC25,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "PC25",
                      "Description": null,
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=ucitelsky,OU=PC25,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ucitelsky",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=FSS-D-PC2501,OU=ucitelsky,OU=PC25,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-PC2501",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=studentsky,OU=PC25,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "studentsky",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=FSS-D-PC2502,OU=studentsky,OU=PC25,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-PC2502",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-PC2503,OU=studentsky,OU=PC25,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-PC2503",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-PC2504,OU=studentsky,OU=PC25,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-PC2504",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-PC2505,OU=studentsky,OU=PC25,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-PC2505",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-PC2506,OU=studentsky,OU=PC25,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-PC2506",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-PC2507,OU=studentsky,OU=PC25,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-PC2507",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-PC2508,OU=studentsky,OU=PC25,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-PC2508",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-PC2509,OU=studentsky,OU=PC25,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-PC2509",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-PC2510,OU=studentsky,OU=PC25,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-PC2510",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-PC2511,OU=studentsky,OU=PC25,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-PC2511",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-PC2512,OU=studentsky,OU=PC25,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-PC2512",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-PC2513,OU=studentsky,OU=PC25,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-PC2513",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-PC2514,OU=studentsky,OU=PC25,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-PC2514",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-PC2515,OU=studentsky,OU=PC25,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-PC2515",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-PC2516,OU=studentsky,OU=PC25,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-PC2516",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-PC2517,OU=studentsky,OU=PC25,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-PC2517",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-PC2518,OU=studentsky,OU=PC25,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-PC2518",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-PC2519,OU=studentsky,OU=PC25,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-PC2519",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-PC2520,OU=studentsky,OU=PC25,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-PC2520",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-PC2521,OU=studentsky,OU=PC25,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-PC2521",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-PC2522,OU=studentsky,OU=PC25,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-PC2522",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-PC2523,OU=studentsky,OU=PC25,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-PC2523",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-PC2524,OU=studentsky,OU=PC25,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-PC2524",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-PC2525,OU=studentsky,OU=PC25,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-PC2525",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-PC2526,OU=studentsky,OU=PC25,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-PC2526",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-PC2527,OU=studentsky,OU=PC25,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-PC2527",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-PC2528,OU=studentsky,OU=PC25,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-PC2528",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-PC2529,OU=studentsky,OU=PC25,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-PC2529",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-PC2530,OU=studentsky,OU=PC25,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-PC2530",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-PC2531,OU=studentsky,OU=PC25,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-PC2531",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-PC2532,OU=studentsky,OU=PC25,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-PC2532",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-PC2533,OU=studentsky,OU=PC25,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-PC2533",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=PC26,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "PC26",
                      "Description": null,
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=ucitelsky,OU=PC26,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ucitelsky",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=FSS-D-PC2621,OU=ucitelsky,OU=PC26,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-PC2621",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=studentsky,OU=PC26,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "studentsky",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=FSS-D-PC2620,OU=studentsky,OU=PC26,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-PC2620",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-PC2601,OU=studentsky,OU=PC26,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-PC2601",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-PC2602,OU=studentsky,OU=PC26,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-PC2602",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-PC2603,OU=studentsky,OU=PC26,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-PC2603",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-PC2604,OU=studentsky,OU=PC26,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-PC2604",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-PC2605,OU=studentsky,OU=PC26,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-PC2605",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-PC2606,OU=studentsky,OU=PC26,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-PC2606",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-PC2607,OU=studentsky,OU=PC26,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-PC2607",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-PC2608,OU=studentsky,OU=PC26,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-PC2608",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-PC2609,OU=studentsky,OU=PC26,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-PC2609",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-PC2610,OU=studentsky,OU=PC26,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-PC2610",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-PC2611,OU=studentsky,OU=PC26,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-PC2611",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-PC2612,OU=studentsky,OU=PC26,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-PC2612",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-PC2613,OU=studentsky,OU=PC26,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-PC2613",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-PC2614,OU=studentsky,OU=PC26,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-PC2614",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-PC2615,OU=studentsky,OU=PC26,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-PC2615",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-PC2616,OU=studentsky,OU=PC26,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-PC2616",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-PC2617,OU=studentsky,OU=PC26,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-PC2617",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-PC2618,OU=studentsky,OU=PC26,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-PC2618",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-PC2619,OU=studentsky,OU=PC26,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-PC2619",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=Radio,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Radio",
                      "Description": null,
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=ucitelsky,OU=Radio,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ucitelsky",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=FSS-D-RAD06,OU=ucitelsky,OU=Radio,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-RAD06",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=studentsky,OU=Radio,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "studentsky",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=FSS-D-RAD01,OU=studentsky,OU=Radio,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-RAD01",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-RAD02,OU=studentsky,OU=Radio,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-RAD02",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-RAD03,OU=studentsky,OU=Radio,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-RAD03",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-RAD04,OU=studentsky,OU=Radio,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-RAD04",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-RAD05,OU=studentsky,OU=Radio,OU=ucebnyPC,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-RAD05",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    }
                  ],
                  "Computers": [],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=Knihovna-U3V,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "Knihovna-U3V",
                  "Description": null,
                  "OrganizationalUnits": [],
                  "Computers": [
                    {
                      "DistinguishedName": "CN=FSS-D-U3V01,OU=Knihovna-U3V,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FSS-D-U3V01",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FSS-D-U3V02,OU=Knihovna-U3V,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FSS-D-U3V02",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FSS-D-U3V03,OU=Knihovna-U3V,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FSS-D-U3V03",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FSS-D-U3V04,OU=Knihovna-U3V,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FSS-D-U3V04",
                      "Description": null,
                      "Type": "Computer"
                    }
                  ],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=Knihovna,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "Knihovna",
                  "Description": null,
                  "OrganizationalUnits": [
                    {
                      "DistinguishedName": "OU=PreKiosk,OU=Knihovna,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "PreKiosk",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=Studovny,OU=Knihovna,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Studovny",
                      "Description": null,
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=130PS,OU=Studovny,OU=Knihovna,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "130PS",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=ucitelsky,OU=130PS,OU=Studovny,OU=Knihovna,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "ucitelsky",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=FSS-D-130PS19,OU=ucitelsky,OU=130PS,OU=Studovny,OU=Knihovna,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "FSS-D-130PS19",
                                  "Description": null,
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=FSS-D-130PS04,OU=130PS,OU=Studovny,OU=Knihovna,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-130PS04",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-130PS05,OU=130PS,OU=Studovny,OU=Knihovna,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-130PS05",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-130PS13,OU=130PS,OU=Studovny,OU=Knihovna,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-130PS13",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-130PS14,OU=130PS,OU=Studovny,OU=Knihovna,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-130PS14",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-130PS15,OU=130PS,OU=Studovny,OU=Knihovna,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-130PS15",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-130PS16,OU=130PS,OU=Studovny,OU=Knihovna,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-130PS16",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-130PS17,OU=130PS,OU=Studovny,OU=Knihovna,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-130PS17",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-130PS18,OU=130PS,OU=Studovny,OU=Knihovna,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-130PS18",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-130PS06,OU=130PS,OU=Studovny,OU=Knihovna,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-130PS06",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-130PS07,OU=130PS,OU=Studovny,OU=Knihovna,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-130PS07",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-130PS08,OU=130PS,OU=Studovny,OU=Knihovna,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-130PS08",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-130PS09,OU=130PS,OU=Studovny,OU=Knihovna,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-130PS09",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-130PS10,OU=130PS,OU=Studovny,OU=Knihovna,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-130PS10",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-130PS11,OU=130PS,OU=Studovny,OU=Knihovna,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-130PS11",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-130PS12,OU=130PS,OU=Studovny,OU=Knihovna,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-130PS12",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-130PS01,OU=130PS,OU=Studovny,OU=Knihovna,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-130PS01",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-130PS02,OU=130PS,OU=Studovny,OU=Knihovna,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-130PS02",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-130PS03,OU=130PS,OU=Studovny,OU=Knihovna,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-130PS03",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=131PS,OU=Studovny,OU=Knihovna,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "131PS",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=FSS-D-131PS01,OU=131PS,OU=Studovny,OU=Knihovna,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-131PS01",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-131PS02,OU=131PS,OU=Studovny,OU=Knihovna,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-131PS02",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=152O,OU=Studovny,OU=Knihovna,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "152O",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=FSS-D-152O01,OU=152O,OU=Studovny,OU=Knihovna,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-152O01",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-152O02,OU=152O,OU=Studovny,OU=Knihovna,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-152O02",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-152O03,OU=152O,OU=Studovny,OU=Knihovna,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-152O03",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-152O05,OU=152O,OU=Studovny,OU=Knihovna,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-152O05",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-152O06,OU=152O,OU=Studovny,OU=Knihovna,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-152O06",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-152O07,OU=152O,OU=Studovny,OU=Knihovna,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-152O07",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-152O08,OU=152O,OU=Studovny,OU=Knihovna,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-152O08",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-152O04,OU=152O,OU=Studovny,OU=Knihovna,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-152O04",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=151Z,OU=Studovny,OU=Knihovna,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "151Z",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=FSS-D-151Z02,OU=151Z,OU=Studovny,OU=Knihovna,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-151Z02",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-151Z04,OU=151Z,OU=Studovny,OU=Knihovna,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-151Z04",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-151Z05,OU=151Z,OU=Studovny,OU=Knihovna,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-151Z05",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-151Z06,OU=151Z,OU=Studovny,OU=Knihovna,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-151Z06",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-151Z07,OU=151Z,OU=Studovny,OU=Knihovna,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-151Z07",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-151Z01,OU=151Z,OU=Studovny,OU=Knihovna,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-151Z01",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FSS-D-151Z03,OU=151Z,OU=Studovny,OU=Knihovna,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-151Z03",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=053S,OU=Studovny,OU=Knihovna,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "053S",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=FSS-D-053S01,OU=053S,OU=Studovny,OU=Knihovna,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FSS-D-053S01",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=Kiosk,OU=Knihovna,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Kiosk",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FSS-D-KIOSK08,OU=Kiosk,OU=Knihovna,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FSS-D-KIOSK08",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FSS-D-KIOSK06,OU=Kiosk,OU=Knihovna,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FSS-D-KIOSK06",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FSS-D-KIOSK05,OU=Kiosk,OU=Knihovna,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FSS-D-KIOSK05",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FSS-D-KIOSK07,OU=Kiosk,OU=Knihovna,OU=FSS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FSS-D-KIOSK07",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    }
                  ],
                  "Computers": [],
                  "Type": "OrganizationalUnit"
                }
              ],
              "Computers": [],
              "Type": "OrganizationalUnit"
            },
            {
              "DistinguishedName": "OU=PED,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
              "Name": "PED",
              "Description": "kryzan@ped.muni.cz",
              "OrganizationalUnits": [
                {
                  "DistinguishedName": "OU=knihovna,OU=PED,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "knihovna",
                  "Description": null,
                  "OrganizationalUnits": [
                    {
                      "DistinguishedName": "OU=servis,OU=knihovna,OU=PED,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "servis",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=1NP,OU=knihovna,OU=PED,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "1NP",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=PED-D-01UK1,OU=1NP,OU=knihovna,OU=PED,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "PED-D-01UK1",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=PED-D-02UK1,OU=1NP,OU=knihovna,OU=PED,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "PED-D-02UK1",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=PED-D-03UK1,OU=1NP,OU=knihovna,OU=PED,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "PED-D-03UK1",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=PED-D-04UK1,OU=1NP,OU=knihovna,OU=PED,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "PED-D-04UK1",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=PED-D-05UK1,OU=1NP,OU=knihovna,OU=PED,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "PED-D-05UK1",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=PED-D-06UK1,OU=1NP,OU=knihovna,OU=PED,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "PED-D-06UK1",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=PED-D-07UK1,OU=1NP,OU=knihovna,OU=PED,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "PED-D-07UK1",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=PED-D-08UK1,OU=1NP,OU=knihovna,OU=PED,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "PED-D-08UK1",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=PED-D-09UK1,OU=1NP,OU=knihovna,OU=PED,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "PED-D-09UK1",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=PED-D-10UK1,OU=1NP,OU=knihovna,OU=PED,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "PED-D-10UK1",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=PED-D-11UK1,OU=1NP,OU=knihovna,OU=PED,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "PED-D-11UK1",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=PED-D-12UK1,OU=1NP,OU=knihovna,OU=PED,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "PED-D-12UK1",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=PED-D-13UK1,OU=1NP,OU=knihovna,OU=PED,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "PED-D-13UK1",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=PED-D-14UK1,OU=1NP,OU=knihovna,OU=PED,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "PED-D-14UK1",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=PED-D-16UK1,OU=1NP,OU=knihovna,OU=PED,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "PED-D-16UK1",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=2NP,OU=knihovna,OU=PED,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "2NP",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=PED-D-10UK2,OU=2NP,OU=knihovna,OU=PED,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "PED-D-10UK2",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=PED-D-03UK2,OU=2NP,OU=knihovna,OU=PED,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "PED-D-03UK2",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=PED-D-04UK2,OU=2NP,OU=knihovna,OU=PED,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "PED-D-04UK2",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=PED-D-05UK2,OU=2NP,OU=knihovna,OU=PED,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "PED-D-05UK2",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=PED-D-06UK2,OU=2NP,OU=knihovna,OU=PED,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "PED-D-06UK2",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=PED-D-07UK2,OU=2NP,OU=knihovna,OU=PED,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "PED-D-07UK2",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=PED-D-08UK2,OU=2NP,OU=knihovna,OU=PED,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "PED-D-08UK2",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=PED-D-09UK2,OU=2NP,OU=knihovna,OU=PED,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "PED-D-09UK2",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=PED-D-11UK2,OU=2NP,OU=knihovna,OU=PED,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "PED-D-11UK2",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=PED-D-12UK2,OU=2NP,OU=knihovna,OU=PED,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "PED-D-12UK2",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=PED-D-13UK2,OU=2NP,OU=knihovna,OU=PED,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "PED-D-13UK2",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=PED-D-01UK2,OU=2NP,OU=knihovna,OU=PED,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "PED-D-01UK2",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=PED-D-02UK2,OU=2NP,OU=knihovna,OU=PED,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "PED-D-02UK2",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=3NP,OU=knihovna,OU=PED,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "3NP",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=PED-D-01UK3,OU=3NP,OU=knihovna,OU=PED,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "PED-D-01UK3",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=PED-D-02UK3,OU=3NP,OU=knihovna,OU=PED,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "PED-D-02UK3",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=PED-D-03UK3,OU=3NP,OU=knihovna,OU=PED,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "PED-D-03UK3",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=PED-D-04UK3,OU=3NP,OU=knihovna,OU=PED,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "PED-D-04UK3",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=PED-D-05UK3,OU=3NP,OU=knihovna,OU=PED,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "PED-D-05UK3",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=PED-D-06UK3,OU=3NP,OU=knihovna,OU=PED,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "PED-D-06UK3",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=PED-D-07UK3,OU=3NP,OU=knihovna,OU=PED,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "PED-D-07UK3",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=PED-D-08UK3,OU=3NP,OU=knihovna,OU=PED,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "PED-D-08UK3",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=PED-D-09UK3,OU=3NP,OU=knihovna,OU=PED,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "PED-D-09UK3",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=PED-D-10UK3,OU=3NP,OU=knihovna,OU=PED,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "PED-D-10UK3",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=PED-D-11UK3,OU=3NP,OU=knihovna,OU=PED,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "PED-D-11UK3",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=PED-D-12UK3,OU=3NP,OU=knihovna,OU=PED,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "PED-D-12UK3",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=PED-D-13UK3,OU=3NP,OU=knihovna,OU=PED,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "PED-D-13UK3",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=PED-D-14UK3,OU=3NP,OU=knihovna,OU=PED,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "PED-D-14UK3",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=PED-D-15UK3,OU=3NP,OU=knihovna,OU=PED,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "PED-D-15UK3",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=Kiosky,OU=knihovna,OU=PED,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Kiosky",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=PED-D-16UK3,OU=Kiosky,OU=knihovna,OU=PED,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "PED-D-16UK3",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=PED-D-02KIOSK,OU=Kiosky,OU=knihovna,OU=PED,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "PED-D-02KIOSK",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=PED-D-03KIOSK,OU=Kiosky,OU=knihovna,OU=PED,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "PED-D-03KIOSK",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=PED-D-04KIOSK,OU=Kiosky,OU=knihovna,OU=PED,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "PED-D-04KIOSK",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=PED-D-05KIOSK,OU=Kiosky,OU=knihovna,OU=PED,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "PED-D-05KIOSK",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=PED-D-06KIOSK,OU=Kiosky,OU=knihovna,OU=PED,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "PED-D-06KIOSK",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=PED-D-07KIOSK,OU=Kiosky,OU=knihovna,OU=PED,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "PED-D-07KIOSK",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=PED-D-08KIOSK,OU=Kiosky,OU=knihovna,OU=PED,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "PED-D-08KIOSK",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=PED-D-09KIOSK,OU=Kiosky,OU=knihovna,OU=PED,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "PED-D-09KIOSK",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=PED-D-01kiosk,OU=Kiosky,OU=knihovna,OU=PED,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "PED-D-01kiosk",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=PED-D-10KIOSK,OU=Kiosky,OU=knihovna,OU=PED,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "PED-D-10KIOSK",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    }
                  ],
                  "Computers": [
                    {
                      "DistinguishedName": "CN=PED-D-TISK01,OU=knihovna,OU=PED,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "PED-D-TISK01",
                      "Description": null,
                      "Type": "Computer"
                    }
                  ],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=ucebna72,OU=PED,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "ucebna72",
                  "Description": null,
                  "OrganizationalUnits": [
                    {
                      "DistinguishedName": "OU=lektor,OU=ucebna72,OU=PED,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "lektor",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=PED-D-00UC72,OU=lektor,OU=ucebna72,OU=PED,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "PED-D-00UC72",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=student,OU=ucebna72,OU=PED,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "student",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=PED-D-01UC72,OU=student,OU=ucebna72,OU=PED,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "PED-D-01UC72",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=PED-D-02UC72,OU=student,OU=ucebna72,OU=PED,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "PED-D-02UC72",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=PED-D-03UC72,OU=student,OU=ucebna72,OU=PED,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "PED-D-03UC72",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=PED-D-04UC72,OU=student,OU=ucebna72,OU=PED,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "PED-D-04UC72",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=PED-D-05UC72,OU=student,OU=ucebna72,OU=PED,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "PED-D-05UC72",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=PED-D-06UC72,OU=student,OU=ucebna72,OU=PED,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "PED-D-06UC72",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=PED-D-07UC72,OU=student,OU=ucebna72,OU=PED,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "PED-D-07UC72",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=PED-D-08UC72,OU=student,OU=ucebna72,OU=PED,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "PED-D-08UC72",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=PED-D-09UC72,OU=student,OU=ucebna72,OU=PED,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "PED-D-09UC72",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=PED-D-10UC72,OU=student,OU=ucebna72,OU=PED,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "PED-D-10UC72",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=PED-D-11UC72,OU=student,OU=ucebna72,OU=PED,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "PED-D-11UC72",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=PED-D-12UC72,OU=student,OU=ucebna72,OU=PED,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "PED-D-12UC72",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=PED-D-13UC72,OU=student,OU=ucebna72,OU=PED,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "PED-D-13UC72",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=PED-D-14UC72,OU=student,OU=ucebna72,OU=PED,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "PED-D-14UC72",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=PED-D-15UC72,OU=student,OU=ucebna72,OU=PED,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "PED-D-15UC72",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=PED-D-16UC72,OU=student,OU=ucebna72,OU=PED,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "PED-D-16UC72",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=PED-D-17UC72,OU=student,OU=ucebna72,OU=PED,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "PED-D-17UC72",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=PED-D-18UC72,OU=student,OU=ucebna72,OU=PED,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "PED-D-18UC72",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=PED-D-19UC72,OU=student,OU=ucebna72,OU=PED,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "PED-D-19UC72",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=PED-D-20UC72,OU=student,OU=ucebna72,OU=PED,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "PED-D-20UC72",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=PED-D-21UC72,OU=student,OU=ucebna72,OU=PED,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "PED-D-21UC72",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=PED-D-22UC72,OU=student,OU=ucebna72,OU=PED,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "PED-D-22UC72",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=PED-D-23UC72,OU=student,OU=ucebna72,OU=PED,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "PED-D-23UC72",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=PED-D-24UC72,OU=student,OU=ucebna72,OU=PED,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "PED-D-24UC72",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=PED-D-25UC72,OU=student,OU=ucebna72,OU=PED,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "PED-D-25UC72",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=PED-D-26UC72,OU=student,OU=ucebna72,OU=PED,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "PED-D-26UC72",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=PED-D-27UC72,OU=student,OU=ucebna72,OU=PED,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "PED-D-27UC72",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=PED-D-28UC72,OU=student,OU=ucebna72,OU=PED,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "PED-D-28UC72",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=PED-D-29UC72,OU=student,OU=ucebna72,OU=PED,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "PED-D-29UC72",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=PED-D-30UC72,OU=student,OU=ucebna72,OU=PED,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "PED-D-30UC72",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    }
                  ],
                  "Computers": [],
                  "Type": "OrganizationalUnit"
                }
              ],
              "Computers": [],
              "Type": "OrganizationalUnit"
            },
            {
              "DistinguishedName": "OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
              "Name": "ESF",
              "Description": "cikt@econ.muni.cz",
              "OrganizationalUnits": [
                {
                  "DistinguishedName": "OU=VT204,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "VT204",
                  "Description": null,
                  "OrganizationalUnits": [],
                  "Computers": [
                    {
                      "DistinguishedName": "CN=ESF-D-VT20406,OU=VT204,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT20406",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT20445,OU=VT204,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT20445",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT20429,OU=VT204,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT20429",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT20438,OU=VT204,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT20438",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT20439,OU=VT204,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT20439",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT20424,OU=VT204,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT20424",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT20428,OU=VT204,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT20428",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT20412,OU=VT204,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT20412",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT20427,OU=VT204,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT20427",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT20423,OU=VT204,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT20423",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT20442,OU=VT204,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT20442",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT20416,OU=VT204,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT20416",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT20419,OU=VT204,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT20419",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT20430,OU=VT204,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT20430",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT20431,OU=VT204,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT20431",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT20422,OU=VT204,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT20422",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT20404,OU=VT204,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT20404",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT20409,OU=VT204,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT20409",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT20426,OU=VT204,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT20426",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT20421,OU=VT204,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT20421",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT20413,OU=VT204,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT20413",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT20446,OU=VT204,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT20446",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT20418,OU=VT204,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT20418",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT20408,OU=VT204,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT20408",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT20420,OU=VT204,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT20420",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT20407,OU=VT204,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT20407",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT20410,OU=VT204,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT20410",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT20411,OU=VT204,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT20411",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT20414,OU=VT204,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT20414",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT20435,OU=VT204,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT20435",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT20434,OU=VT204,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT20434",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT20401,OU=VT204,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT20401",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT20447,OU=VT204,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT20447",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT20433,OU=VT204,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT20433",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT20415,OU=VT204,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT20415",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT20432,OU=VT204,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT20432",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT20417,OU=VT204,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT20417",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT20448,OU=VT204,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT20448",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT20436,OU=VT204,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT20436",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT20405,OU=VT204,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT20405",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT20437,OU=VT204,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT20437",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT20443,OU=VT204,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT20443",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT20402,OU=VT204,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT20402",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT20403,OU=VT204,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT20403",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT20441,OU=VT204,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT20441",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT20444,OU=VT204,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT20444",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT20425,OU=VT204,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT20425",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT20440,OU=VT204,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT20440",
                      "Description": null,
                      "Type": "Computer"
                    }
                  ],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=S314,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "S314",
                  "Description": null,
                  "OrganizationalUnits": [],
                  "Computers": [
                    {
                      "DistinguishedName": "CN=ESF-D-S31412,OU=S314,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-S31412",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-S31424,OU=S314,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-S31424",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-S31410,OU=S314,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-S31410",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-S31407,OU=S314,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-S31407",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-S31409,OU=S314,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-S31409",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-S31420,OU=S314,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-S31420",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-S31405,OU=S314,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-S31405",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-S31416,OU=S314,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-S31416",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-S31418,OU=S314,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-S31418",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-S31414,OU=S314,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-S31414",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-S31417,OU=S314,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-S31417",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-S31419,OU=S314,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-S31419",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-S31425,OU=S314,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-S31425",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-S31403,OU=S314,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-S31403",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-S31402,OU=S314,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-S31402",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-S31415,OU=S314,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-S31415",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-S31411,OU=S314,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-S31411",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-S31423,OU=S314,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-S31423",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-S31401,OU=S314,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-S31401",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-S31406,OU=S314,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-S31406",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-S31421,OU=S314,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-S31421",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-S31422,OU=S314,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-S31422",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-S31413,OU=S314,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-S31413",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-S31404,OU=S314,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-S31404",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-S31408,OU=S314,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-S31408",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-S31426,OU=S314,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-S31426",
                      "Description": null,
                      "Type": "Computer"
                    }
                  ],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=VT202,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "VT202",
                  "Description": null,
                  "OrganizationalUnits": [],
                  "Computers": [
                    {
                      "DistinguishedName": "CN=ESF-D-VT20217,OU=VT202,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT20217",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT20209,OU=VT202,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT20209",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT20206,OU=VT202,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT20206",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT20203,OU=VT202,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT20203",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT20202,OU=VT202,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT20202",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT20223,OU=VT202,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT20223",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT20210,OU=VT202,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT20210",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT20216,OU=VT202,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT20216",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT20221,OU=VT202,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT20221",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT20224,OU=VT202,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT20224",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT20201,OU=VT202,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT20201",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT20222,OU=VT202,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT20222",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT20208,OU=VT202,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT20208",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT20204,OU=VT202,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT20204",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT20218,OU=VT202,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT20218",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT20215,OU=VT202,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT20215",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT20220,OU=VT202,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT20220",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT20207,OU=VT202,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT20207",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT20214,OU=VT202,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT20214",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT20205,OU=VT202,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT20205",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT20211,OU=VT202,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT20211",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT20219,OU=VT202,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT20219",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT20212,OU=VT202,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT20212",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT20213,OU=VT202,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT20213",
                      "Description": null,
                      "Type": "Computer"
                    }
                  ],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=VT206,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "VT206",
                  "Description": null,
                  "OrganizationalUnits": [],
                  "Computers": [
                    {
                      "DistinguishedName": "CN=ESF-D-VT20601,OU=VT206,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT20601",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT20602,OU=VT206,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT20602",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT20603,OU=VT206,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT20603",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT20604,OU=VT206,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT20604",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT20605,OU=VT206,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT20605",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT20606,OU=VT206,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT20606",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT20607,OU=VT206,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT20607",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT20608,OU=VT206,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT20608",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT20609,OU=VT206,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT20609",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT20610,OU=VT206,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT20610",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT20611,OU=VT206,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT20611",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT20612,OU=VT206,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT20612",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT20613,OU=VT206,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT20613",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT20614,OU=VT206,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT20614",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT20615,OU=VT206,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT20615",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT20616,OU=VT206,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT20616",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT20617,OU=VT206,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT20617",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT20618,OU=VT206,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT20618",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT20619,OU=VT206,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT20619",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT20620,OU=VT206,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT20620",
                      "Description": null,
                      "Type": "Computer"
                    }
                  ],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=Poslucharny,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "Poslucharny",
                  "Description": null,
                  "OrganizationalUnits": [],
                  "Computers": [
                    {
                      "DistinguishedName": "CN=ESF-D-S308,OU=Poslucharny,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-S308",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT20621,OU=Poslucharny,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT20621",
                      "Description": "u\u010Ditelsk\u00FD PC",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT10527,OU=Poslucharny,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT10527",
                      "Description": "u\u010Ditelsk\u00FD PC",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-P102,OU=Poslucharny,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-P102",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-P104,OU=Poslucharny,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-P104",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-P106,OU=Poslucharny,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-P106",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-P201,OU=Poslucharny,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-P201",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-P302A,OU=Poslucharny,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-P302A",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-P304,OU=Poslucharny,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-P304",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-P403,OU=Poslucharny,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-P403",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-S301,OU=Poslucharny,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-S301",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-S306,OU=Poslucharny,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-S306",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-S307,OU=Poslucharny,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-S307",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-S31427,OU=Poslucharny,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-S31427",
                      "Description": "u\u010Ditelsk\u00FD PC",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-S311,OU=Poslucharny,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-S311",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-S315,OU=Poslucharny,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-S315",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-S401,OU=Poslucharny,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-S401",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-S402,OU=Poslucharny,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-S402",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT20451,OU=Poslucharny,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT20451",
                      "Description": "u\u010Ditelsk\u00FD PC",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-MT205,OU=Poslucharny,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-MT205",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-P103,OU=Poslucharny,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-P103",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-S309,OU=Poslucharny,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-S309",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-S313,OU=Poslucharny,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-S313",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-S305,OU=Poslucharny,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-S305",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-S310,OU=Poslucharny,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-S310",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-P312,OU=Poslucharny,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-P312",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT20225,OU=Poslucharny,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT20225",
                      "Description": "u\u010Ditelsk\u00FD PC",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-P302B,OU=Poslucharny,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-P302B",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-P303,OU=Poslucharny,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-P303",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-P101,OU=Poslucharny,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-P101",
                      "Description": null,
                      "Type": "Computer"
                    }
                  ],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=VT105,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "VT105",
                  "Description": null,
                  "OrganizationalUnits": [],
                  "Computers": [
                    {
                      "DistinguishedName": "CN=ESF-D-VT10504,OU=VT105,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT10504",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT10519,OU=VT105,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT10519",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT10507,OU=VT105,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT10507",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT10526,OU=VT105,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT10526",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT10501,OU=VT105,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT10501",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT10502,OU=VT105,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT10502",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT10524,OU=VT105,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT10524",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT10514,OU=VT105,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT10514",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT10515,OU=VT105,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT10515",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT10505,OU=VT105,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT10505",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT10520,OU=VT105,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT10520",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT10513,OU=VT105,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT10513",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT10511,OU=VT105,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT10511",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT10522,OU=VT105,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT10522",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT10503,OU=VT105,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT10503",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT10506,OU=VT105,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT10506",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT10516,OU=VT105,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT10516",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT10521,OU=VT105,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT10521",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT10523,OU=VT105,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT10523",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT10518,OU=VT105,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT10518",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT10508,OU=VT105,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT10508",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT10512,OU=VT105,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT10512",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT10509,OU=VT105,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT10509",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT10510,OU=VT105,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT10510",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT10517,OU=VT105,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT10517",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-VT10525,OU=VT105,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-VT10525",
                      "Description": null,
                      "Type": "Computer"
                    }
                  ],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=Test,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "Test",
                  "Description": null,
                  "OrganizationalUnits": [],
                  "Computers": [],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=Knihovna,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "Knihovna",
                  "Description": null,
                  "OrganizationalUnits": [],
                  "Computers": [
                    {
                      "DistinguishedName": "CN=ESF-D-KNIH16,OU=Knihovna,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-KNIH16",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-KNIH04,OU=Knihovna,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-KNIH04",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-KNIH14,OU=Knihovna,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-KNIH14",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-KNIH05,OU=Knihovna,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-KNIH05",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-KNIH17,OU=Knihovna,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-KNIH17",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-MSupport,OU=Knihovna,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-MSupport",
                      "Description": "KAMI Support Centrum",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-KLUB,OU=Knihovna,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-KLUB",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-KNIH07,OU=Knihovna,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-KNIH07",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-GALERIE1,OU=Knihovna,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-GALERIE1",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-GALERIE3,OU=Knihovna,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-GALERIE3",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-KNIH08,OU=Knihovna,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-KNIH08",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-KATALOG1,OU=Knihovna,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-KATALOG1",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-KATALOG2,OU=Knihovna,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-KATALOG2",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-KNIH10,OU=Knihovna,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-KNIH10",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-KNIH01,OU=Knihovna,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-KNIH01",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-GALERIE2,OU=Knihovna,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-GALERIE2",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-GALERIE4,OU=Knihovna,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-GALERIE4",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-KNIH02,OU=Knihovna,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-KNIH02",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-BOX2,OU=Knihovna,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-BOX2",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-BOX4,OU=Knihovna,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-BOX4",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-KNIH09,OU=Knihovna,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-KNIH09",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-KNIH12,OU=Knihovna,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-KNIH12",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-KNIH13,OU=Knihovna,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-KNIH13",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-BOX5,OU=Knihovna,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-BOX5",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-KNIH18,OU=Knihovna,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-KNIH18",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-BOX1,OU=Knihovna,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-BOX1",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-KNIH03,OU=Knihovna,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-KNIH03",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-KNIH15,OU=Knihovna,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-KNIH15",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-KNIH06,OU=Knihovna,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-KNIH06",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-GALERIE5,OU=Knihovna,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-GALERIE5",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-KNIH11,OU=Knihovna,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-KNIH11",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=ESF-D-BOX3,OU=Knihovna,OU=ESF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ESF-D-BOX3",
                      "Description": null,
                      "Type": "Computer"
                    }
                  ],
                  "Type": "OrganizationalUnit"
                }
              ],
              "Computers": [],
              "Type": "OrganizationalUnit"
            },
            {
              "DistinguishedName": "OU=FAF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
              "Name": "FAF",
              "Description": "cit@pharm.muni.cz",
              "OrganizationalUnits": [
                {
                  "DistinguishedName": "OU=Ucitele,OU=FAF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "Ucitele",
                  "Description": null,
                  "OrganizationalUnits": [
                    {
                      "DistinguishedName": "OU=44,OU=Ucitele,OU=FAF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "44",
                      "Description": null,
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=247,OU=44,OU=Ucitele,OU=FAF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "247",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=FAF-D-44247,OU=247,OU=44,OU=Ucitele,OU=FAF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FAF-D-44247",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=249,OU=44,OU=Ucitele,OU=FAF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "249",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=FAF-D-44249,OU=249,OU=44,OU=Ucitele,OU=FAF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FAF-D-44249",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=056,OU=44,OU=Ucitele,OU=FAF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "056",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=FAF-D-44056,OU=056,OU=44,OU=Ucitele,OU=FAF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FAF-D-44056",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=037,OU=44,OU=Ucitele,OU=FAF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "037",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=FAF-D-44037,OU=037,OU=44,OU=Ucitele,OU=FAF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FAF-D-44037",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=016,OU=44,OU=Ucitele,OU=FAF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "016",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=FAF-D-4401601,OU=016,OU=44,OU=Ucitele,OU=FAF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FAF-D-4401601",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=144,OU=44,OU=Ucitele,OU=FAF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "144",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=FAF-D-4414401,OU=144,OU=44,OU=Ucitele,OU=FAF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FAF-D-4414401",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=337,OU=44,OU=Ucitele,OU=FAF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "337",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=FAF-D-44337,OU=337,OU=44,OU=Ucitele,OU=FAF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FAF-D-44337",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=344,OU=44,OU=Ucitele,OU=FAF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "344",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=FAF-D-44344,OU=344,OU=44,OU=Ucitele,OU=FAF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FAF-D-44344",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=310,OU=44,OU=Ucitele,OU=FAF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "310",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=45,OU=Ucitele,OU=FAF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "45",
                      "Description": null,
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=224,OU=45,OU=Ucitele,OU=FAF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "224",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=FAF-D-45224,OU=224,OU=45,OU=Ucitele,OU=FAF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FAF-D-45224",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=119,OU=45,OU=Ucitele,OU=FAF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "119",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=FAF-D-45119,OU=119,OU=45,OU=Ucitele,OU=FAF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FAF-D-45119",
                              "Description": "DHM 449425",
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=135,OU=45,OU=Ucitele,OU=FAF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "135",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=FAF-D-45135,OU=135,OU=45,OU=Ucitele,OU=FAF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FAF-D-45135",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=221,OU=45,OU=Ucitele,OU=FAF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "221",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=FAF-D-45221,OU=221,OU=45,OU=Ucitele,OU=FAF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FAF-D-45221",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=321,OU=45,OU=Ucitele,OU=FAF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "321",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=FAF-D-45321,OU=321,OU=45,OU=Ucitele,OU=FAF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FAF-D-45321",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=18,OU=Ucitele,OU=FAF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "18",
                      "Description": null,
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=122,OU=18,OU=Ucitele,OU=FAF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "122",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=FAF-D-18122,OU=122,OU=18,OU=Ucitele,OU=FAF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FAF-D-18122",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    }
                  ],
                  "Computers": [],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=Studenti,OU=FAF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "Studenti",
                  "Description": null,
                  "OrganizationalUnits": [
                    {
                      "DistinguishedName": "OU=45,OU=Studenti,OU=FAF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "45",
                      "Description": null,
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=132,OU=45,OU=Studenti,OU=FAF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "132",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=FAF-D-45132,OU=132,OU=45,OU=Studenti,OU=FAF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FAF-D-45132",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=44,OU=Studenti,OU=FAF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "44",
                      "Description": null,
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=144,OU=44,OU=Studenti,OU=FAF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "144",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=FAF-D-4414402,OU=144,OU=44,OU=Studenti,OU=FAF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FAF-D-4414402",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FAF-D-4414403,OU=144,OU=44,OU=Studenti,OU=FAF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FAF-D-4414403",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FAF-D-4414404,OU=144,OU=44,OU=Studenti,OU=FAF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FAF-D-4414404",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FAF-D-4414405,OU=144,OU=44,OU=Studenti,OU=FAF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FAF-D-4414405",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FAF-D-4414406,OU=144,OU=44,OU=Studenti,OU=FAF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FAF-D-4414406",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FAF-D-4414407,OU=144,OU=44,OU=Studenti,OU=FAF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FAF-D-4414407",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FAF-D-4414408,OU=144,OU=44,OU=Studenti,OU=FAF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FAF-D-4414408",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FAF-D-4414409,OU=144,OU=44,OU=Studenti,OU=FAF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FAF-D-4414409",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FAF-D-4414410,OU=144,OU=44,OU=Studenti,OU=FAF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FAF-D-4414410",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=046,OU=44,OU=Studenti,OU=FAF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "046",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=FAF-D-44046,OU=046,OU=44,OU=Studenti,OU=FAF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FAF-D-44046",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=047,OU=44,OU=Studenti,OU=FAF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "047",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=FAF-D-44047,OU=047,OU=44,OU=Studenti,OU=FAF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FAF-D-44047",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=016,OU=44,OU=Studenti,OU=FAF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "016",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=FAF-D-4401602,OU=016,OU=44,OU=Studenti,OU=FAF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FAF-D-4401602",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FAF-D-4401603,OU=016,OU=44,OU=Studenti,OU=FAF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FAF-D-4401603",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FAF-D-4401604,OU=016,OU=44,OU=Studenti,OU=FAF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FAF-D-4401604",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FAF-D-4401605,OU=016,OU=44,OU=Studenti,OU=FAF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FAF-D-4401605",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FAF-D-4401606,OU=016,OU=44,OU=Studenti,OU=FAF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FAF-D-4401606",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FAF-D-4401607,OU=016,OU=44,OU=Studenti,OU=FAF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FAF-D-4401607",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FAF-D-4401608,OU=016,OU=44,OU=Studenti,OU=FAF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FAF-D-4401608",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FAF-D-4401609,OU=016,OU=44,OU=Studenti,OU=FAF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FAF-D-4401609",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FAF-D-4401610,OU=016,OU=44,OU=Studenti,OU=FAF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FAF-D-4401610",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FAF-D-4401611,OU=016,OU=44,OU=Studenti,OU=FAF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FAF-D-4401611",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FAF-D-4401612,OU=016,OU=44,OU=Studenti,OU=FAF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FAF-D-4401612",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FAF-D-4401613,OU=016,OU=44,OU=Studenti,OU=FAF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FAF-D-4401613",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FAF-D-4401614,OU=016,OU=44,OU=Studenti,OU=FAF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FAF-D-4401614",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FAF-D-4401615,OU=016,OU=44,OU=Studenti,OU=FAF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FAF-D-4401615",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FAF-D-4401616,OU=016,OU=44,OU=Studenti,OU=FAF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FAF-D-4401616",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FAF-D-4401617,OU=016,OU=44,OU=Studenti,OU=FAF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FAF-D-4401617",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FAF-D-4401618,OU=016,OU=44,OU=Studenti,OU=FAF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FAF-D-4401618",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FAF-D-4401619,OU=016,OU=44,OU=Studenti,OU=FAF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FAF-D-4401619",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FAF-D-4401620,OU=016,OU=44,OU=Studenti,OU=FAF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FAF-D-4401620",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FAF-D-4401621,OU=016,OU=44,OU=Studenti,OU=FAF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FAF-D-4401621",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FAF-D-4401622,OU=016,OU=44,OU=Studenti,OU=FAF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FAF-D-4401622",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FAF-D-4401623,OU=016,OU=44,OU=Studenti,OU=FAF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FAF-D-4401623",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FAF-D-4401624,OU=016,OU=44,OU=Studenti,OU=FAF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FAF-D-4401624",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FAF-D-4401625,OU=016,OU=44,OU=Studenti,OU=FAF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FAF-D-4401625",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FAF-D-4401626,OU=016,OU=44,OU=Studenti,OU=FAF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FAF-D-4401626",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=015,OU=44,OU=Studenti,OU=FAF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "015",
                          "Description": null,
                          "OrganizationalUnits": [
                            {
                              "DistinguishedName": "OU=AMDFX6350,OU=015,OU=44,OU=Studenti,OU=FAF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "AMDFX6350",
                              "Description": null,
                              "OrganizationalUnits": [],
                              "Computers": [
                                {
                                  "DistinguishedName": "CN=FAF-D-4401501,OU=AMDFX6350,OU=015,OU=44,OU=Studenti,OU=FAF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "FAF-D-4401501",
                                  "Description": null,
                                  "Type": "Computer"
                                },
                                {
                                  "DistinguishedName": "CN=FAF-D-4401503,OU=AMDFX6350,OU=015,OU=44,OU=Studenti,OU=FAF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "FAF-D-4401503",
                                  "Description": null,
                                  "Type": "Computer"
                                },
                                {
                                  "DistinguishedName": "CN=FAF-D-4401504,OU=AMDFX6350,OU=015,OU=44,OU=Studenti,OU=FAF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "FAF-D-4401504",
                                  "Description": null,
                                  "Type": "Computer"
                                },
                                {
                                  "DistinguishedName": "CN=FAF-D-4401505,OU=AMDFX6350,OU=015,OU=44,OU=Studenti,OU=FAF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                                  "Name": "FAF-D-4401505",
                                  "Description": "vyradeny",
                                  "Type": "Computer"
                                }
                              ],
                              "Type": "OrganizationalUnit"
                            }
                          ],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=FAF-D-427475,OU=015,OU=44,OU=Studenti,OU=FAF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FAF-D-427475",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=FAF-D-4401502,OU=015,OU=44,OU=Studenti,OU=FAF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FAF-D-4401502",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=338,OU=44,OU=Studenti,OU=FAF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "338",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=FAF-D-44338,OU=338,OU=44,OU=Studenti,OU=FAF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FAF-D-44338",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=311,OU=44,OU=Studenti,OU=FAF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "311",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=FAF-D-44311,OU=311,OU=44,OU=Studenti,OU=FAF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FAF-D-44311",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=111,OU=44,OU=Studenti,OU=FAF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "111",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=FAF-D-44111,OU=111,OU=44,OU=Studenti,OU=FAF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FAF-D-44111",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=18,OU=Studenti,OU=FAF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "18",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    }
                  ],
                  "Computers": [],
                  "Type": "OrganizationalUnit"
                }
              ],
              "Computers": [],
              "Type": "OrganizationalUnit"
            },
            {
              "DistinguishedName": "OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
              "Name": "LAW",
              "Description": "cit@law.muni.cz",
              "OrganizationalUnits": [
                {
                  "DistinguishedName": "OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "StudyRooms",
                  "Description": "Study rooms at Faculty of Law",
                  "OrganizationalUnits": [
                    {
                      "DistinguishedName": "OU=Library,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Library",
                      "Description": "Study room in the library",
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=Kiosks,OU=Library,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "Kiosks",
                          "Description": "Library computers in kiosk mode",
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=LAW-D-LIBPC06,OU=Kiosks,OU=Library,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-LIBPC06",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-LIBPC16,OU=Kiosks,OU=Library,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-LIBPC16",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-LIBPC17,OU=Kiosks,OU=Library,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-LIBPC17",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=LAW-D-LIBPC19,OU=Library,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-LIBPC19",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-LIBPC01,OU=Library,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-LIBPC01",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-LIBPC02,OU=Library,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-LIBPC02",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-LIBPC03,OU=Library,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-LIBPC03",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-LIBPC04,OU=Library,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-LIBPC04",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-LIBPC05,OU=Library,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-LIBPC05",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-LIBPC07,OU=Library,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-LIBPC07",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-LIBPC08,OU=Library,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-LIBPC08",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-LIBPC09,OU=Library,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-LIBPC09",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-LIBPC10,OU=Library,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-LIBPC10",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-LIBPC11,OU=Library,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-LIBPC11",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-LIBPC12,OU=Library,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-LIBPC12",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-LIBPC13,OU=Library,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-LIBPC13",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-LIBPC14,OU=Library,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-LIBPC14",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-LIBPC15,OU=Library,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-LIBPC15",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-LIBPC18,OU=Library,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-LIBPC18",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=020,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "020",
                      "Description": "Classroom 020",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=LAW-D-U20PC00,OU=020,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-U20PC00",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-U20PC01,OU=020,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-U20PC01",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-U20PC02,OU=020,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-U20PC02",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-U20PC03,OU=020,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-U20PC03",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-U20PC04,OU=020,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-U20PC04",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-U20PC05,OU=020,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-U20PC05",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-U20PC06,OU=020,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-U20PC06",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-U20PC07,OU=020,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-U20PC07",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-U20PC08,OU=020,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-U20PC08",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-U20PC09,OU=020,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-U20PC09",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-U20PC10,OU=020,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-U20PC10",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-U20PC11,OU=020,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-U20PC11",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-U20PC12,OU=020,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-U20PC12",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-U20PC13,OU=020,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-U20PC13",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-U20PC14,OU=020,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-U20PC14",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-U20PC15,OU=020,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-U20PC15",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-U20PC16,OU=020,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-U20PC16",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-U20PC17,OU=020,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-U20PC17",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-U20PC18,OU=020,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-U20PC18",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-U20PC19,OU=020,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-U20PC19",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-U20PC20,OU=020,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-U20PC20",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-U20PCxx,OU=020,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-U20PCxx",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=024,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "024",
                      "Description": "Classroom 024",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=LAW-D-U24PC00,OU=024,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-U24PC00",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-U24PC01,OU=024,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-U24PC01",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-U24PC02,OU=024,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-U24PC02",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-U24PC03,OU=024,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-U24PC03",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-U24PC04,OU=024,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-U24PC04",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-U24PC05,OU=024,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-U24PC05",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-U24PC06,OU=024,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-U24PC06",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-U24PC07,OU=024,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-U24PC07",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-U24PC08,OU=024,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-U24PC08",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-U24PC09,OU=024,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-U24PC09",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-U24PC10,OU=024,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-U24PC10",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-U24PC11,OU=024,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-U24PC11",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-U24PC12,OU=024,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-U24PC12",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-U24PC13,OU=024,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-U24PC13",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-U24PC14,OU=024,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-U24PC14",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-U24PC15,OU=024,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-U24PC15",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-U24PC16,OU=024,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-U24PC16",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-U24PC17,OU=024,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-U24PC17",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-U24PC18,OU=024,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-U24PC18",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-U24PC19,OU=024,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-U24PC19",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-U24PC20,OU=024,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-U24PC20",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=057,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "057",
                      "Description": "Classroom 057",
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=test,OU=057,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "test",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=LAW-D-U57PC01,OU=057,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-U57PC01",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-U57PC02,OU=057,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-U57PC02",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-U57PC03,OU=057,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-U57PC03",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-U57PC05,OU=057,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-U57PC05",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-U57PC04,OU=057,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-U57PC04",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-U57PC06,OU=057,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-U57PC06",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-U57PC07,OU=057,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-U57PC07",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-U57PC08,OU=057,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-U57PC08",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-U57PC09,OU=057,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-U57PC09",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-U57PC10,OU=057,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-U57PC10",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-U57PC11,OU=057,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-U57PC11",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-U57PC12,OU=057,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-U57PC12",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-U57PC13,OU=057,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-U57PC13",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-U57PC14,OU=057,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-U57PC14",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-U57PC15,OU=057,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-U57PC15",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-U57PC16,OU=057,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-U57PC16",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-U57PC17,OU=057,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-U57PC17",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-U57PC18,OU=057,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-U57PC18",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-U57PC19,OU=057,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-U57PC19",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-U57PC20,OU=057,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-U57PC20",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-U57PC21,OU=057,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-U57PC21",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-U57PC22,OU=057,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-U57PC22",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-U57PC23,OU=057,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-U57PC23",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-U57PC24,OU=057,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-U57PC24",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-U57PC25,OU=057,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-U57PC25",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-U57PC26,OU=057,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-U57PC26",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-U57PC27,OU=057,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-U57PC27",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-U57PC28,OU=057,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-U57PC28",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-U57PC29,OU=057,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-U57PC29",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-U57PC30,OU=057,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-U57PC30",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-U57PC31,OU=057,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-U57PC31",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-U57PC32,OU=057,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-U57PC32",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-U57PC33,OU=057,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-U57PC33",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-U57PC34,OU=057,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-U57PC34",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-U57PC35,OU=057,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-U57PC35",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-U57PC36,OU=057,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-U57PC36",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-U57PC37,OU=057,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-U57PC37",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-U57PC38,OU=057,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-U57PC38",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-U57PC39,OU=057,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-U57PC39",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-U57PC40,OU=057,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-U57PC40",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-U57PC41,OU=057,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-U57PC41",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-U57PC42,OU=057,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-U57PC42",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-U57PC43,OU=057,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-U57PC43",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-U57PC44,OU=057,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-U57PC44",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=020test,OU=StudyRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "020test",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    }
                  ],
                  "Computers": [],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=LectureRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "LectureRooms",
                  "Description": "Lecture rooms at Faculty of Law (poslucharny)",
                  "OrganizationalUnits": [
                    {
                      "DistinguishedName": "OU=1 screen,OU=LectureRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "1 screen",
                      "Description": "Computers with 1 projector",
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=AiO,OU=1 screen,OU=LectureRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "AiO",
                          "Description": "AllinOne computers",
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=LAW-D-POS257,OU=AiO,OU=1 screen,OU=LectureRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-POS257",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-POS259,OU=AiO,OU=1 screen,OU=LectureRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-POS259",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-POS315,OU=AiO,OU=1 screen,OU=LectureRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-POS315",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-POS124,OU=AiO,OU=1 screen,OU=LectureRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-POS124",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-POS126,OU=AiO,OU=1 screen,OU=LectureRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-POS126",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-POS129,OU=AiO,OU=1 screen,OU=LectureRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-POS129",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-POS131,OU=AiO,OU=1 screen,OU=LectureRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-POS131",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-POS148,OU=AiO,OU=1 screen,OU=LectureRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-POS148",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-POS160,OU=AiO,OU=1 screen,OU=LectureRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-POS160",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=LAW-D-POS258,OU=AiO,OU=1 screen,OU=LectureRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "LAW-D-POS258",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=LAW-D-POS042,OU=1 screen,OU=LectureRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-POS042",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-POS209,OU=1 screen,OU=LectureRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-POS209",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-POS041,OU=1 screen,OU=LectureRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-POS041",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-POS302,OU=1 screen,OU=LectureRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-POS302",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-POS316,OU=1 screen,OU=LectureRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-POS316",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-POS214,OU=1 screen,OU=LectureRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-POS214",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-POS215,OU=1 screen,OU=LectureRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-POS215",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-POS208,OU=1 screen,OU=LectureRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-POS208",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=2 screens,OU=LectureRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "2 screens",
                      "Description": "Computers with 2 projectors",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=LAW-D-POS038,OU=2 screens,OU=LectureRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-POS038",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-POS133,OU=2 screens,OU=LectureRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-POS133",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-POS140,OU=2 screens,OU=LectureRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-POS140",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-POS030,OU=2 screens,OU=LectureRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-POS030",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-POS034,OU=2 screens,OU=LectureRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-POS034",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-POS136,OU=2 screens,OU=LectureRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-POS136",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=LAW-D-POS025,OU=2 screens,OU=LectureRooms,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "LAW-D-POS025",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    }
                  ],
                  "Computers": [],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=TestOU,OU=LAW,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "TestOU",
                  "Description": null,
                  "OrganizationalUnits": [],
                  "Computers": [],
                  "Type": "OrganizationalUnit"
                }
              ],
              "Computers": [],
              "Type": "OrganizationalUnit"
            },
            {
              "DistinguishedName": "OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
              "Name": "FF",
              "Description": "cit@phil.muni.cz",
              "OrganizationalUnits": [
                {
                  "DistinguishedName": "OU=AB,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "AB",
                  "Description": null,
                  "OrganizationalUnits": [],
                  "Computers": [
                    {
                      "DistinguishedName": "CN=FF-D-A11,OU=AB,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-A11",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-A21,OU=AB,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-A21",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-A31,OU=AB,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-A31",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-A24,OU=AB,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-A24",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-B11,OU=AB,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-B11",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-B12,OU=AB,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-B12",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-B13,OU=AB,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-B13",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-B21,OU=AB,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-B21",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-B22,OU=AB,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-B22",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-B23,OU=AB,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-B23",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-B24,OU=AB,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-B24",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-B32,OU=AB,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-B32",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-B34,OU=AB,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-B34",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-B41,OU=AB,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-B41",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-B42,OU=AB,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-B42",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-B43,OU=AB,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-B43",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-B44,OU=AB,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-B44",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-B51,OU=AB,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-B51",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-B52,OU=AB,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-B52",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-B141,OU=AB,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-B141",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-A10,OU=AB,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-A10",
                      "Description": null,
                      "Type": "Computer"
                    }
                  ],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=AB_old,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "AB_old",
                  "Description": null,
                  "OrganizationalUnits": [],
                  "Computers": [],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=CD,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "CD",
                  "Description": null,
                  "OrganizationalUnits": [],
                  "Computers": [
                    {
                      "DistinguishedName": "CN=FF-D-C12,OU=CD,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-C12",
                      "Description": "(kisk C128)",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-D11,OU=CD,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-D11",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-C11,OU=CD,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-C11",
                      "Description": "\u002B",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-C31,OU=CD,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-C31",
                      "Description": "\u002B",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-C32,OU=CD,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-C32",
                      "Description": "\u002B",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-C33,OU=CD,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-C33",
                      "Description": "\u002B",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-C34,OU=CD,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-C34",
                      "Description": "\u002B",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-C41,OU=CD,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-C41",
                      "Description": "\u002B",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-C42,OU=CD,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-C42",
                      "Description": "\u002B",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-C43,OU=CD,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-C43",
                      "Description": "\u002B",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-C51,OU=CD,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-C51",
                      "Description": "\u002B",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-D21,OU=CD,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-D21",
                      "Description": "\u002B",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-D22,OU=CD,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-D22",
                      "Description": "\u002B",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-D31,OU=CD,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-D31",
                      "Description": "\u002B",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-D32,OU=CD,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-D32",
                      "Description": "\u002B",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-D33,OU=CD,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-D33",
                      "Description": "\u002B",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-D41,OU=CD,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-D41",
                      "Description": "\u002B",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-D42,OU=CD,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-D42",
                      "Description": "\u002B",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-D43,OU=CD,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-D43",
                      "Description": "\u002B",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-D51,OU=CD,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-D51",
                      "Description": "\u002B",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-D53,OU=CD,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-D53",
                      "Description": "-",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-D2TYM7,OU=CD,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-D2TYM7",
                      "Description": "\u002B",
                      "Type": "Computer"
                    }
                  ],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=GJ,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "GJ",
                  "Description": null,
                  "OrganizationalUnits": [],
                  "Computers": [
                    {
                      "DistinguishedName": "CN=FF-D-G34,OU=GJ,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-G34",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-G12,OU=GJ,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-G12",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-G11,OU=GJ,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-G11",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-Z01,OU=GJ,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-Z01",
                      "Description": "n\u00E1hradn\u00ED PC do u\u010Debny, 2023-10",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-J01,OU=GJ,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-J01",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-G01,OU=GJ,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-G01",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-G03,OU=GJ,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-G03",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-G23,OU=GJ,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-G23",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-G24,OU=GJ,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-G24",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-G25,OU=GJ,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-G25",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-G31,OU=GJ,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-G31",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-G32,OU=GJ,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-G32",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-G33,OU=GJ,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-G33",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-J21,OU=GJ,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-J21",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-J22,OU=GJ,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-J22",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-J31,OU=GJ,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-J31",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-J32,OU=GJ,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-J32",
                      "Description": "knihovna etnologie, DHM 435632, 2024-09 reinst.",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-RELIG09,OU=GJ,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-RELIG09",
                      "Description": "J.509, promitaci, 2023-10",
                      "Type": "Computer"
                    }
                  ],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=KL,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "KL",
                  "Description": null,
                  "OrganizationalUnits": [],
                  "Computers": [
                    {
                      "DistinguishedName": "CN=FF-D-K12,OU=KL,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-K12",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-K21,OU=KL,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-K21",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-K22,OU=KL,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-K22",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-K23,OU=KL,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-K23",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-K24,OU=KL,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-K24",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-K31,OU=KL,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-K31",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-K32,OU=KL,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-K32",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-K33,OU=KL,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-K33",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-L10,OU=KL,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-L10",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-L21,OU=KL,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-L21",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-L31,OU=KL,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-L31",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-L32,OU=KL,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-L32",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-L33,OU=KL,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-L33",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-L34,OU=KL,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-L34",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-L35,OU=KL,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-L35",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-L41,OU=KL,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-L41",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-L42,OU=KL,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-L42",
                      "Description": null,
                      "Type": "Computer"
                    }
                  ],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=N,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "N",
                  "Description": null,
                  "OrganizationalUnits": [],
                  "Computers": [
                    {
                      "DistinguishedName": "CN=FF-D-N21,OU=N,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-N21",
                      "Description": "DONE",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-N41,OU=N,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-N41",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-N42,OU=N,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-N42",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-N43,OU=N,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-N43",
                      "Description": "DONE",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-N51,OU=N,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-N51",
                      "Description": null,
                      "Type": "Computer"
                    }
                  ],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=Ucebna-B33,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "Ucebna-B33",
                  "Description": null,
                  "OrganizationalUnits": [
                    {
                      "DistinguishedName": "OU=Student,OU=Ucebna-B33,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Student",
                      "Description": null,
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=test,OU=Student,OU=Ucebna-B33,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "test",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=FF-D-BUBEN41,OU=test,OU=Student,OU=Ucebna-B33,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FF-D-BUBEN41",
                              "Description": "testovaci",
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FF-D-BUBEN01,OU=Student,OU=Ucebna-B33,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-BUBEN01",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-BUBEN02,OU=Student,OU=Ucebna-B33,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-BUBEN02",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-BUBEN03,OU=Student,OU=Ucebna-B33,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-BUBEN03",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-BUBEN04,OU=Student,OU=Ucebna-B33,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-BUBEN04",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-BUBEN05,OU=Student,OU=Ucebna-B33,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-BUBEN05",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-BUBEN06,OU=Student,OU=Ucebna-B33,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-BUBEN06",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-BUBEN07,OU=Student,OU=Ucebna-B33,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-BUBEN07",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-BUBEN08,OU=Student,OU=Ucebna-B33,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-BUBEN08",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-BUBEN09,OU=Student,OU=Ucebna-B33,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-BUBEN09",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-BUBEN10,OU=Student,OU=Ucebna-B33,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-BUBEN10",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-BUBEN11,OU=Student,OU=Ucebna-B33,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-BUBEN11",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-BUBEN12,OU=Student,OU=Ucebna-B33,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-BUBEN12",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-BUBEN13,OU=Student,OU=Ucebna-B33,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-BUBEN13",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-BUBEN14,OU=Student,OU=Ucebna-B33,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-BUBEN14",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-BUBEN15,OU=Student,OU=Ucebna-B33,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-BUBEN15",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-BUBEN16,OU=Student,OU=Ucebna-B33,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-BUBEN16",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-BUBEN17,OU=Student,OU=Ucebna-B33,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-BUBEN17",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-BUBEN18,OU=Student,OU=Ucebna-B33,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-BUBEN18",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-BUBEN19,OU=Student,OU=Ucebna-B33,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-BUBEN19",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-BUBEN20,OU=Student,OU=Ucebna-B33,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-BUBEN20",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-BUBEN21,OU=Student,OU=Ucebna-B33,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-BUBEN21",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-BUBEN22,OU=Student,OU=Ucebna-B33,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-BUBEN22",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-BUBEN23,OU=Student,OU=Ucebna-B33,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-BUBEN23",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-BUBEN24,OU=Student,OU=Ucebna-B33,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-BUBEN24",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-BUBEN25,OU=Student,OU=Ucebna-B33,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-BUBEN25",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-BUBEN26,OU=Student,OU=Ucebna-B33,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-BUBEN26",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-BUBEN27,OU=Student,OU=Ucebna-B33,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-BUBEN27",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-BUBEN28,OU=Student,OU=Ucebna-B33,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-BUBEN28",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-BUBEN29,OU=Student,OU=Ucebna-B33,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-BUBEN29",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-BUBEN30,OU=Student,OU=Ucebna-B33,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-BUBEN30",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-BUBEN31,OU=Student,OU=Ucebna-B33,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-BUBEN31",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-BUBEN32,OU=Student,OU=Ucebna-B33,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-BUBEN32",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-BUBEN33,OU=Student,OU=Ucebna-B33,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-BUBEN33",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-BUBEN34,OU=Student,OU=Ucebna-B33,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-BUBEN34",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-BUBEN35,OU=Student,OU=Ucebna-B33,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-BUBEN35",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-BUBEN36,OU=Student,OU=Ucebna-B33,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-BUBEN36",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-BUBEN37,OU=Student,OU=Ucebna-B33,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-BUBEN37",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-BUBEN38,OU=Student,OU=Ucebna-B33,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-BUBEN38",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-BUBEN39,OU=Student,OU=Ucebna-B33,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-BUBEN39",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-BUBEN40,OU=Student,OU=Ucebna-B33,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-BUBEN40",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=Ucitel,OU=Ucebna-B33,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Ucitel",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FF-D-B33,OU=Ucitel,OU=Ucebna-B33,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-B33",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    }
                  ],
                  "Computers": [],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=Ucebna-G13,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "Ucebna-G13",
                  "Description": null,
                  "OrganizationalUnits": [
                    {
                      "DistinguishedName": "OU=Student,OU=Ucebna-G13,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Student",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FF-D-GAVIN01,OU=Student,OU=Ucebna-G13,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-GAVIN01",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-GAVIN02,OU=Student,OU=Ucebna-G13,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-GAVIN02",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-GAVIN03,OU=Student,OU=Ucebna-G13,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-GAVIN03",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-GAVIN04,OU=Student,OU=Ucebna-G13,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-GAVIN04",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-GAVIN05,OU=Student,OU=Ucebna-G13,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-GAVIN05",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-GAVIN06,OU=Student,OU=Ucebna-G13,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-GAVIN06",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-GAVIN07,OU=Student,OU=Ucebna-G13,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-GAVIN07",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-GAVIN08,OU=Student,OU=Ucebna-G13,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-GAVIN08",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-GAVIN09,OU=Student,OU=Ucebna-G13,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-GAVIN09",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-GAVIN10,OU=Student,OU=Ucebna-G13,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-GAVIN10",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-GAVIN11,OU=Student,OU=Ucebna-G13,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-GAVIN11",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-GAVIN14,OU=Student,OU=Ucebna-G13,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-GAVIN14",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-GAVIN12,OU=Student,OU=Ucebna-G13,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-GAVIN12",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-GAVIN13,OU=Student,OU=Ucebna-G13,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-GAVIN13",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-GAVIN15,OU=Student,OU=Ucebna-G13,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-GAVIN15",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-GAVIN16,OU=Student,OU=Ucebna-G13,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-GAVIN16",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-GAVIN17,OU=Student,OU=Ucebna-G13,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-GAVIN17",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-GAVIN18,OU=Student,OU=Ucebna-G13,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-GAVIN18",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-GAVIN19,OU=Student,OU=Ucebna-G13,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-GAVIN19",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-GAVIN20,OU=Student,OU=Ucebna-G13,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-GAVIN20",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=Ucitel,OU=Ucebna-G13,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Ucitel",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FF-D-G13,OU=Ucitel,OU=Ucebna-G13,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-G13",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    }
                  ],
                  "Computers": [],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=Ucebna-N31,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "Ucebna-N31",
                  "Description": null,
                  "OrganizationalUnits": [
                    {
                      "DistinguishedName": "OU=Student,OU=Ucebna-N31,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Student",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FF-D-NIJEL01,OU=Student,OU=Ucebna-N31,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-NIJEL01",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-NIJEL02,OU=Student,OU=Ucebna-N31,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-NIJEL02",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-NIJEL03,OU=Student,OU=Ucebna-N31,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-NIJEL03",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-NIJEL04,OU=Student,OU=Ucebna-N31,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-NIJEL04",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-NIJEL05,OU=Student,OU=Ucebna-N31,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-NIJEL05",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-NIJEL06,OU=Student,OU=Ucebna-N31,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-NIJEL06",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-NIJEL07,OU=Student,OU=Ucebna-N31,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-NIJEL07",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-NIJEL08,OU=Student,OU=Ucebna-N31,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-NIJEL08",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-NIJEL09,OU=Student,OU=Ucebna-N31,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-NIJEL09",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-NIJEL10,OU=Student,OU=Ucebna-N31,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-NIJEL10",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-NIJEL11,OU=Student,OU=Ucebna-N31,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-NIJEL11",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-NIJEL12,OU=Student,OU=Ucebna-N31,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-NIJEL12",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-NIJEL13,OU=Student,OU=Ucebna-N31,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-NIJEL13",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-NIJEL14,OU=Student,OU=Ucebna-N31,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-NIJEL14",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=Ucitel,OU=Ucebna-N31,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Ucitel",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FF-D-N31,OU=Ucitel,OU=Ucebna-N31,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-N31",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    }
                  ],
                  "Computers": [],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=Ucebna-G02,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "Ucebna-G02",
                  "Description": null,
                  "OrganizationalUnits": [
                    {
                      "DistinguishedName": "OU=Student,OU=Ucebna-G02,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Student",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FF-D-GASPODA01,OU=Student,OU=Ucebna-G02,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-GASPODA01",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-GASPODA02,OU=Student,OU=Ucebna-G02,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-GASPODA02",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-GASPODA03,OU=Student,OU=Ucebna-G02,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-GASPODA03",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-GASPODA04,OU=Student,OU=Ucebna-G02,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-GASPODA04",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-GASPODA05,OU=Student,OU=Ucebna-G02,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-GASPODA05",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-GASPODA06,OU=Student,OU=Ucebna-G02,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-GASPODA06",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-GASPODA07,OU=Student,OU=Ucebna-G02,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-GASPODA07",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-GASPODA08,OU=Student,OU=Ucebna-G02,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-GASPODA08",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-GASPODA09,OU=Student,OU=Ucebna-G02,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-GASPODA09",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-GASPODA10,OU=Student,OU=Ucebna-G02,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-GASPODA10",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-GASPODA11,OU=Student,OU=Ucebna-G02,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-GASPODA11",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-GASPODA12,OU=Student,OU=Ucebna-G02,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-GASPODA12",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-GASPODA13,OU=Student,OU=Ucebna-G02,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-GASPODA13",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-GASPODA14,OU=Student,OU=Ucebna-G02,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-GASPODA14",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-GASPODA15,OU=Student,OU=Ucebna-G02,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-GASPODA15",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-GASPODA16,OU=Student,OU=Ucebna-G02,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-GASPODA16",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-GASPODA17,OU=Student,OU=Ucebna-G02,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-GASPODA17",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-GASPODA18,OU=Student,OU=Ucebna-G02,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-GASPODA18",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-GASPODA19,OU=Student,OU=Ucebna-G02,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-GASPODA19",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-GASPODA20,OU=Student,OU=Ucebna-G02,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-GASPODA20",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=Ucitel,OU=Ucebna-G02,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Ucitel",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FF-D-G02,OU=Ucitel,OU=Ucebna-G02,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-G02",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    }
                  ],
                  "Computers": [],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=Ucebna-G22,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "Ucebna-G22",
                  "Description": null,
                  "OrganizationalUnits": [
                    {
                      "DistinguishedName": "OU=Student,OU=Ucebna-G22,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Student",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FF-D-G22NB01,OU=Student,OU=Ucebna-G22,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-G22NB01",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-G22NB02,OU=Student,OU=Ucebna-G22,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-G22NB02",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-G22NB03,OU=Student,OU=Ucebna-G22,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-G22NB03",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-G22NB04,OU=Student,OU=Ucebna-G22,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-G22NB04",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-G22NB06,OU=Student,OU=Ucebna-G22,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-G22NB06",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-G22NB05,OU=Student,OU=Ucebna-G22,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-G22NB05",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-G22NB07,OU=Student,OU=Ucebna-G22,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-G22NB07",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-G22NB08,OU=Student,OU=Ucebna-G22,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-G22NB08",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-G22NB09,OU=Student,OU=Ucebna-G22,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-G22NB09",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-G22NB10,OU=Student,OU=Ucebna-G22,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-G22NB10",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-G22NB11,OU=Student,OU=Ucebna-G22,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-G22NB11",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-G22NB12,OU=Student,OU=Ucebna-G22,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-G22NB12",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=Ucitel,OU=Ucebna-G22,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Ucitel",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FF-D-G22,OU=Ucitel,OU=Ucebna-G22,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-G22",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    }
                  ],
                  "Computers": [],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=Ucebna-G21,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "Ucebna-G21",
                  "Description": null,
                  "OrganizationalUnits": [
                    {
                      "DistinguishedName": "OU=Student,OU=Ucebna-G21,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Student",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FF-D-GRYF01,OU=Student,OU=Ucebna-G21,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-GRYF01",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-GRYF02,OU=Student,OU=Ucebna-G21,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-GRYF02",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-GRYF03,OU=Student,OU=Ucebna-G21,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-GRYF03",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-GRYF04,OU=Student,OU=Ucebna-G21,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-GRYF04",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-GRYF05,OU=Student,OU=Ucebna-G21,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-GRYF05",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-GRYF06,OU=Student,OU=Ucebna-G21,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-GRYF06",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-GRYF07,OU=Student,OU=Ucebna-G21,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-GRYF07",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-GRYF08,OU=Student,OU=Ucebna-G21,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-GRYF08",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-GRYF09,OU=Student,OU=Ucebna-G21,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-GRYF09",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-GRYF10,OU=Student,OU=Ucebna-G21,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-GRYF10",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-GRYF11,OU=Student,OU=Ucebna-G21,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-GRYF11",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-GRYF12,OU=Student,OU=Ucebna-G21,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-GRYF12",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-GRYF13,OU=Student,OU=Ucebna-G21,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-GRYF13",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-GRYF14,OU=Student,OU=Ucebna-G21,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-GRYF14",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-GRYF15,OU=Student,OU=Ucebna-G21,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-GRYF15",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-GRYF16,OU=Student,OU=Ucebna-G21,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-GRYF16",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-GRYF17,OU=Student,OU=Ucebna-G21,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-GRYF17",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-GRYF18,OU=Student,OU=Ucebna-G21,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-GRYF18",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-GRYF19,OU=Student,OU=Ucebna-G21,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-GRYF19",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-GRYF20,OU=Student,OU=Ucebna-G21,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-GRYF20",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=Ucitel,OU=Ucebna-G21,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Ucitel",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FF-D-G21,OU=Ucitel,OU=Ucebna-G21,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-G21",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    }
                  ],
                  "Computers": [],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=Ucebna-L11,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "Ucebna-L11",
                  "Description": null,
                  "OrganizationalUnits": [
                    {
                      "DistinguishedName": "OU=Student,OU=Ucebna-L11,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Student",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FF-D-LUPIN01,OU=Student,OU=Ucebna-L11,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-LUPIN01",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-LUPIN02,OU=Student,OU=Ucebna-L11,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-LUPIN02",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-LUPIN03,OU=Student,OU=Ucebna-L11,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-LUPIN03",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-LUPIN04,OU=Student,OU=Ucebna-L11,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-LUPIN04",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-LUPIN05,OU=Student,OU=Ucebna-L11,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-LUPIN05",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-LUPIN06,OU=Student,OU=Ucebna-L11,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-LUPIN06",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-LUPIN07,OU=Student,OU=Ucebna-L11,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-LUPIN07",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-LUPIN08,OU=Student,OU=Ucebna-L11,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-LUPIN08",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-LUPIN09,OU=Student,OU=Ucebna-L11,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-LUPIN09",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-LUPIN10,OU=Student,OU=Ucebna-L11,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-LUPIN10",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-LUPIN11,OU=Student,OU=Ucebna-L11,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-LUPIN11",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-LUPIN12,OU=Student,OU=Ucebna-L11,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-LUPIN12",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-LUPIN13,OU=Student,OU=Ucebna-L11,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-LUPIN13",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-LUPIN14,OU=Student,OU=Ucebna-L11,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-LUPIN14",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-LUPIN15,OU=Student,OU=Ucebna-L11,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-LUPIN15",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-LUPIN16,OU=Student,OU=Ucebna-L11,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-LUPIN16",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-LUPIN17,OU=Student,OU=Ucebna-L11,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-LUPIN17",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-LUPIN18,OU=Student,OU=Ucebna-L11,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-LUPIN18",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-LUPIN19,OU=Student,OU=Ucebna-L11,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-LUPIN19",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-LUPIN20,OU=Student,OU=Ucebna-L11,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-LUPIN20",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-LUPIN21,OU=Student,OU=Ucebna-L11,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-LUPIN21",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-LUPIN22,OU=Student,OU=Ucebna-L11,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-LUPIN22",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-LUPIN23,OU=Student,OU=Ucebna-L11,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-LUPIN23",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-LUPIN24,OU=Student,OU=Ucebna-L11,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-LUPIN24",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-LUPIN25,OU=Student,OU=Ucebna-L11,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-LUPIN25",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-LUPIN26,OU=Student,OU=Ucebna-L11,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-LUPIN26",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-LUPIN27,OU=Student,OU=Ucebna-L11,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-LUPIN27",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-LUPIN28,OU=Student,OU=Ucebna-L11,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-LUPIN28",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-LUPIN29,OU=Student,OU=Ucebna-L11,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-LUPIN29",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-LUPIN30,OU=Student,OU=Ucebna-L11,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-LUPIN30",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=Ucitel,OU=Ucebna-L11,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Ucitel",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FF-D-L11,OU=Ucitel,OU=Ucebna-L11,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-L11",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    }
                  ],
                  "Computers": [],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=M,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "M",
                  "Description": null,
                  "OrganizationalUnits": [],
                  "Computers": [
                    {
                      "DistinguishedName": "CN=FF-D-M11,OU=M,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-M11",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-M12,OU=M,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-M12",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-M21,OU=M,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-M21",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-M22,OU=M,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-M22",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-MATRIUM,OU=M,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-MATRIUM",
                      "Description": null,
                      "Type": "Computer"
                    }
                  ],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=B2.206,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "B2.206",
                  "Description": null,
                  "OrganizationalUnits": [],
                  "Computers": [
                    {
                      "DistinguishedName": "CN=FF-D-D53PC02,OU=B2.206,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-D53PC02",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-D53PC04,OU=B2.206,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-D53PC04",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-D53PC06,OU=B2.206,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-D53PC06",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-D53PC07,OU=B2.206,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-D53PC07",
                      "Description": null,
                      "Type": "Computer"
                    }
                  ],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=D2TYM,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "D2TYM",
                  "Description": null,
                  "OrganizationalUnits": [],
                  "Computers": [
                    {
                      "DistinguishedName": "CN=FF-D-D2TYM1,OU=D2TYM,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-D2TYM1",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-D2TYM2,OU=D2TYM,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-D2TYM2",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-D2TYM3,OU=D2TYM,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-D2TYM3",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-D2TYM4,OU=D2TYM,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-D2TYM4",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-D2TYM5,OU=D2TYM,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-D2TYM5",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-D2TYM6,OU=D2TYM,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-D2TYM6",
                      "Description": null,
                      "Type": "Computer"
                    }
                  ],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=Knihovna Archeologie,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "Knihovna Archeologie",
                  "Description": null,
                  "OrganizationalUnits": [],
                  "Computers": [
                    {
                      "DistinguishedName": "CN=FF-D-ARCHKNIH01,OU=Knihovna Archeologie,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-ARCHKNIH01",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-ARCHKNIH02,OU=Knihovna Archeologie,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-ARCHKNIH02",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-ARCHKNIH03,OU=Knihovna Archeologie,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-ARCHKNIH03",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-ARCHKNIH04,OU=Knihovna Archeologie,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-ARCHKNIH04",
                      "Description": null,
                      "Type": "Computer"
                    }
                  ],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=Knihovna Historie,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "Knihovna Historie",
                  "Description": null,
                  "OrganizationalUnits": [],
                  "Computers": [
                    {
                      "DistinguishedName": "CN=FF-D-HISTORIE02,OU=Knihovna Historie,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-HISTORIE02",
                      "Description": "Knihovna historie (B2)",
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-HISTORIE07,OU=Knihovna Historie,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-HISTORIE07",
                      "Description": "Knihovna historie (B2) [i3-4130]",
                      "Type": "Computer"
                    }
                  ],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=Knihovna Muzikologie,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "Knihovna Muzikologie",
                  "Description": null,
                  "OrganizationalUnits": [],
                  "Computers": [
                    {
                      "DistinguishedName": "CN=FF-D-UHVKNIH02,OU=Knihovna Muzikologie,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-UHVKNIH02",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-UHVKNIH01,OU=Knihovna Muzikologie,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-UHVKNIH01",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-UHVKNIH04,OU=Knihovna Muzikologie,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-UHVKNIH04",
                      "Description": "ASUS AIO I5-11GEN",
                      "Type": "Computer"
                    }
                  ],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=Omezeni,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "Omezeni",
                  "Description": null,
                  "OrganizationalUnits": [],
                  "Computers": [],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=Knihovna,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "Knihovna",
                  "Description": null,
                  "OrganizationalUnits": [
                    {
                      "DistinguishedName": "OU=1NP,OU=Knihovna,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "1NP",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FF-D-STUDA02,OU=1NP,OU=Knihovna,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-STUDA02",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-STUDA03,OU=1NP,OU=Knihovna,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-STUDA03",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-STUDA04,OU=1NP,OU=Knihovna,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-STUDA04",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-STUDA05,OU=1NP,OU=Knihovna,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-STUDA05",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-STUDA06,OU=1NP,OU=Knihovna,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-STUDA06",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-STUDA07,OU=1NP,OU=Knihovna,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-STUDA07",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=2NP,OU=Knihovna,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "2NP",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FF-D-STUDB02,OU=2NP,OU=Knihovna,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-STUDB02",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-STUDB03,OU=2NP,OU=Knihovna,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-STUDB03",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-STUDB04,OU=2NP,OU=Knihovna,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-STUDB04",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-STUDB05,OU=2NP,OU=Knihovna,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-STUDB05",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-STUDB06,OU=2NP,OU=Knihovna,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-STUDB06",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-STUDB07,OU=2NP,OU=Knihovna,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-STUDB07",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-STUDB08,OU=2NP,OU=Knihovna,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-STUDB08",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-STUDB09,OU=2NP,OU=Knihovna,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-STUDB09",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-STUDB10,OU=2NP,OU=Knihovna,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-STUDB10",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-STUDB11,OU=2NP,OU=Knihovna,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-STUDB11",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-STUDB12,OU=2NP,OU=Knihovna,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-STUDB12",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-STUDB13,OU=2NP,OU=Knihovna,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-STUDB13",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-STUDB14,OU=2NP,OU=Knihovna,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-STUDB14",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-STUDB15,OU=2NP,OU=Knihovna,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-STUDB15",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-STUDB16,OU=2NP,OU=Knihovna,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-STUDB16",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-STUDB17,OU=2NP,OU=Knihovna,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-STUDB17",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-STUDB18,OU=2NP,OU=Knihovna,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-STUDB18",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-STUDB19,OU=2NP,OU=Knihovna,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-STUDB19",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-STUDB20,OU=2NP,OU=Knihovna,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-STUDB20",
                          "Description": "-",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-STUDB21,OU=2NP,OU=Knihovna,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-STUDB21",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-STUDB22,OU=2NP,OU=Knihovna,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-STUDB22",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=3NP,OU=Knihovna,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "3NP",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FF-D-STUDC02,OU=3NP,OU=Knihovna,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-STUDC02",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-STUDC03,OU=3NP,OU=Knihovna,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-STUDC03",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-STUDC04,OU=3NP,OU=Knihovna,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-STUDC04",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-STUDC05,OU=3NP,OU=Knihovna,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-STUDC05",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-STUDC06,OU=3NP,OU=Knihovna,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-STUDC06",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-STUDC07,OU=3NP,OU=Knihovna,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-STUDC07",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-STUDC08,OU=3NP,OU=Knihovna,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-STUDC08",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-STUDC09,OU=3NP,OU=Knihovna,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-STUDC09",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-STUDC10,OU=3NP,OU=Knihovna,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-STUDC10",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-STUDC11,OU=3NP,OU=Knihovna,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-STUDC11",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-STUDC12,OU=3NP,OU=Knihovna,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-STUDC12",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-STUDC13,OU=3NP,OU=Knihovna,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-STUDC13",
                          "Description": "-",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-STUDC14,OU=3NP,OU=Knihovna,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-STUDC14",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-STUDC15,OU=3NP,OU=Knihovna,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-STUDC15",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-STUDC16,OU=3NP,OU=Knihovna,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-STUDC16",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-STUDC17,OU=3NP,OU=Knihovna,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-STUDC17",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-STUDC18,OU=3NP,OU=Knihovna,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-STUDC18",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-STUDC19,OU=3NP,OU=Knihovna,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-STUDC19",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-STUDC20,OU=3NP,OU=Knihovna,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-STUDC20",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-STUDC21,OU=3NP,OU=Knihovna,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-STUDC21",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-STUDC22,OU=3NP,OU=Knihovna,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-STUDC22",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=4NP,OU=Knihovna,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "4NP",
                      "Description": null,
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=Makerspace,OU=4NP,OU=Knihovna,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "Makerspace",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=FF-D-STUDD13,OU=Makerspace,OU=4NP,OU=Knihovna,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "FF-D-STUDD13",
                              "Description": "Makerspace",
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FF-D-STUDD02,OU=4NP,OU=Knihovna,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-STUDD02",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-STUDD03,OU=4NP,OU=Knihovna,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-STUDD03",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-STUDD04,OU=4NP,OU=Knihovna,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-STUDD04",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-STUDD05,OU=4NP,OU=Knihovna,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-STUDD05",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-STUDD06,OU=4NP,OU=Knihovna,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-STUDD06",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-STUDD07,OU=4NP,OU=Knihovna,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-STUDD07",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-STUDD08,OU=4NP,OU=Knihovna,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-STUDD08",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-STUDD09,OU=4NP,OU=Knihovna,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-STUDD09",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-STUDD10,OU=4NP,OU=Knihovna,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-STUDD10",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-STUDD11,OU=4NP,OU=Knihovna,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-STUDD11",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-STUDD12,OU=4NP,OU=Knihovna,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-STUDD12",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-STUDD14,OU=4NP,OU=Knihovna,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-STUDD14",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-STUDD15,OU=4NP,OU=Knihovna,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-STUDD15",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-STUDD16,OU=4NP,OU=Knihovna,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-STUDD16",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-STUDD17,OU=4NP,OU=Knihovna,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-STUDD17",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-STUDD18,OU=4NP,OU=Knihovna,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-STUDD18",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-STUDD19,OU=4NP,OU=Knihovna,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-STUDD19",
                          "Description": "-",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-STUDD20,OU=4NP,OU=Knihovna,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-STUDD20",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-STUDD21,OU=4NP,OU=Knihovna,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-STUDD21",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-STUDD22,OU=4NP,OU=Knihovna,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-STUDD22",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=Kiosky,OU=Knihovna,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Kiosky",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=FF-D-STUDD01,OU=Kiosky,OU=Knihovna,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-STUDD01",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-STUDA01,OU=Kiosky,OU=Knihovna,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-STUDA01",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-STUDB01,OU=Kiosky,OU=Knihovna,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-STUDB01",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=FF-D-STUDC01,OU=Kiosky,OU=Knihovna,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "FF-D-STUDC01",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    }
                  ],
                  "Computers": [],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=ucebnatest,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "ucebnatest",
                  "Description": null,
                  "OrganizationalUnits": [],
                  "Computers": [
                    {
                      "DistinguishedName": "CN=FF-D-D21A,OU=ucebnatest,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-D21A",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-B12D,OU=ucebnatest,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-B12D",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=FF-D-A21A,OU=ucebnatest,OU=FF,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "FF-D-A21A",
                      "Description": null,
                      "Type": "Computer"
                    }
                  ],
                  "Type": "OrganizationalUnit"
                }
              ],
              "Computers": [],
              "Type": "OrganizationalUnit"
            },
            {
              "DistinguishedName": "OU=RCX,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
              "Name": "RCX",
              "Description": "rcx-it@recetox.muni.cz",
              "OrganizationalUnits": [
                {
                  "DistinguishedName": "OU=D29-252,OU=RCX,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "D29-252",
                  "Description": null,
                  "OrganizationalUnits": [
                    {
                      "DistinguishedName": "OU=Students,OU=D29-252,OU=RCX,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Students",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=Lectors,OU=D29-252,OU=RCX,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Lectors",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    }
                  ],
                  "Computers": [],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=IndividualStudentComputers,OU=RCX,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "IndividualStudentComputers",
                  "Description": null,
                  "OrganizationalUnits": [],
                  "Computers": [
                    {
                      "DistinguishedName": "CN=RCX-D-01050,OU=IndividualStudentComputers,OU=RCX,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "RCX-D-01050",
                      "Description": null,
                      "Type": "Computer"
                    }
                  ],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=D29-347,OU=RCX,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "D29-347",
                  "Description": null,
                  "OrganizationalUnits": [
                    {
                      "DistinguishedName": "OU=Lectors,OU=D29-347,OU=RCX,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Lectors",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=RCX-D-01048,OU=Lectors,OU=D29-347,OU=RCX,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "RCX-D-01048",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=RCX-D-01049,OU=Lectors,OU=D29-347,OU=RCX,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "RCX-D-01049",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=Students,OU=D29-347,OU=RCX,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Students",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=RCX-D-01707,OU=Students,OU=D29-347,OU=RCX,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "RCX-D-01707",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=RCX-D-01685,OU=Students,OU=D29-347,OU=RCX,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "RCX-D-01685",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=RCX-D-01695,OU=Students,OU=D29-347,OU=RCX,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "RCX-D-01695",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=RCX-D-03338,OU=Students,OU=D29-347,OU=RCX,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "RCX-D-03338",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=RCX-D-01711,OU=Students,OU=D29-347,OU=RCX,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "RCX-D-01711",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=RCX-D-01691,OU=Students,OU=D29-347,OU=RCX,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "RCX-D-01691",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=RCX-D-01665,OU=Students,OU=D29-347,OU=RCX,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "RCX-D-01665",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=RCX-D-01719,OU=Students,OU=D29-347,OU=RCX,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "RCX-D-01719",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=RCX-D-01696,OU=Students,OU=D29-347,OU=RCX,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "RCX-D-01696",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=RCX-D-01675,OU=Students,OU=D29-347,OU=RCX,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "RCX-D-01675",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=RCX-D-01720,OU=Students,OU=D29-347,OU=RCX,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "RCX-D-01720",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=RCX-D-03222,OU=Students,OU=D29-347,OU=RCX,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "RCX-D-03222",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=RCX-D-03224,OU=Students,OU=D29-347,OU=RCX,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "RCX-D-03224",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=RCX-D-03225,OU=Students,OU=D29-347,OU=RCX,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "RCX-D-03225",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=RCX-D-03226,OU=Students,OU=D29-347,OU=RCX,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "RCX-D-03226",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=RCX-D-03227,OU=Students,OU=D29-347,OU=RCX,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "RCX-D-03227",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=RCX-D-03228,OU=Students,OU=D29-347,OU=RCX,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "RCX-D-03228",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=RCX-D-03229,OU=Students,OU=D29-347,OU=RCX,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "RCX-D-03229",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=RCX-D-03592,OU=Students,OU=D29-347,OU=RCX,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "RCX-D-03592",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=RCX-D-03593,OU=Students,OU=D29-347,OU=RCX,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "RCX-D-03593",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=RCX-D-03594,OU=Students,OU=D29-347,OU=RCX,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "RCX-D-03594",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=RCX-D-03595,OU=Students,OU=D29-347,OU=RCX,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "RCX-D-03595",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=RCX-D-03596,OU=Students,OU=D29-347,OU=RCX,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "RCX-D-03596",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=RCX-D-03597,OU=Students,OU=D29-347,OU=RCX,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "RCX-D-03597",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=RCX-D-03598,OU=Students,OU=D29-347,OU=RCX,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "RCX-D-03598",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=RCX-D-03599,OU=Students,OU=D29-347,OU=RCX,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "RCX-D-03599",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=RCX-D-01714,OU=Students,OU=D29-347,OU=RCX,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "RCX-D-01714",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=RCX-D-01704,OU=Students,OU=D29-347,OU=RCX,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "RCX-D-01704",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=RCX-D-01682,OU=Students,OU=D29-347,OU=RCX,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "RCX-D-01682",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=RCX-D-01688,OU=Students,OU=D29-347,OU=RCX,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "RCX-D-01688",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=RCX-D-01666,OU=Students,OU=D29-347,OU=RCX,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "RCX-D-01666",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=RCX-D-01669,OU=Students,OU=D29-347,OU=RCX,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "RCX-D-01669",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=RCX-D-01721,OU=Students,OU=D29-347,OU=RCX,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "RCX-D-01721",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=RCX-D-01709,OU=Students,OU=D29-347,OU=RCX,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "RCX-D-01709",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=RCX-D-01690,OU=Students,OU=D29-347,OU=RCX,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "RCX-D-01690",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=RCX-D-01676,OU=Students,OU=D29-347,OU=RCX,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "RCX-D-01676",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=RCX-D-01673,OU=Students,OU=D29-347,OU=RCX,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "RCX-D-01673",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=RCX-D-01686,OU=Students,OU=D29-347,OU=RCX,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "RCX-D-01686",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=RCX-D-01681,OU=Students,OU=D29-347,OU=RCX,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "RCX-D-01681",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=RCX-D-01697,OU=Students,OU=D29-347,OU=RCX,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "RCX-D-01697",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=RCX-D-01668,OU=Students,OU=D29-347,OU=RCX,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "RCX-D-01668",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=RCX-D-01667,OU=Students,OU=D29-347,OU=RCX,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "RCX-D-01667",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=RCX-D-01715,OU=Students,OU=D29-347,OU=RCX,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "RCX-D-01715",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=RCX-D-01680,OU=Students,OU=D29-347,OU=RCX,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "RCX-D-01680",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=RCX-D-01699,OU=Students,OU=D29-347,OU=RCX,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "RCX-D-01699",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=RCX-D-01698,OU=Students,OU=D29-347,OU=RCX,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "RCX-D-01698",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=RCX-D-01708,OU=Students,OU=D29-347,OU=RCX,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "RCX-D-01708",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=RCX-D-01678,OU=Students,OU=D29-347,OU=RCX,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "RCX-D-01678",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=RCX-D-01683,OU=Students,OU=D29-347,OU=RCX,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "RCX-D-01683",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=RCX-D-01700,OU=Students,OU=D29-347,OU=RCX,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "RCX-D-01700",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=RCX-D-01706,OU=Students,OU=D29-347,OU=RCX,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "RCX-D-01706",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=RCX-D-01718,OU=Students,OU=D29-347,OU=RCX,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "RCX-D-01718",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=RCX-D-01716,OU=Students,OU=D29-347,OU=RCX,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "RCX-D-01716",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=RCX-D-01689,OU=Students,OU=D29-347,OU=RCX,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "RCX-D-01689",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    }
                  ],
                  "Computers": [],
                  "Type": "OrganizationalUnit"
                }
              ],
              "Computers": [],
              "Type": "OrganizationalUnit"
            },
            {
              "DistinguishedName": "OU=IBA,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
              "Name": "IBA",
              "Description": "krejci@iba.muni.cz",
              "OrganizationalUnits": [
                {
                  "DistinguishedName": "OU=F01-709,OU=IBA,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "F01-709",
                  "Description": null,
                  "OrganizationalUnits": [
                    {
                      "DistinguishedName": "OU=Lektor,OU=F01-709,OU=IBA,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Lektor",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=IBA-D-UC367878,OU=Lektor,OU=F01-709,OU=IBA,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "IBA-D-UC367878",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=Student,OU=F01-709,OU=IBA,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Student",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=IBA-D-UC367827,OU=Student,OU=F01-709,OU=IBA,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "IBA-D-UC367827",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=IBA-D-UC367828,OU=Student,OU=F01-709,OU=IBA,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "IBA-D-UC367828",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=IBA-D-UC367829,OU=Student,OU=F01-709,OU=IBA,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "IBA-D-UC367829",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=IBA-D-UC367832,OU=Student,OU=F01-709,OU=IBA,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "IBA-D-UC367832",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=IBA-D-UC367833,OU=Student,OU=F01-709,OU=IBA,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "IBA-D-UC367833",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=IBA-D-UC367835,OU=Student,OU=F01-709,OU=IBA,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "IBA-D-UC367835",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=IBA-D-UC367836,OU=Student,OU=F01-709,OU=IBA,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "IBA-D-UC367836",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=IBA-D-UC367840,OU=Student,OU=F01-709,OU=IBA,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "IBA-D-UC367840",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=IBA-D-UC367841,OU=Student,OU=F01-709,OU=IBA,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "IBA-D-UC367841",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=IBA-D-UC367847,OU=Student,OU=F01-709,OU=IBA,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "IBA-D-UC367847",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=IBA-D-UC367857,OU=Student,OU=F01-709,OU=IBA,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "IBA-D-UC367857",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=IBA-D-UC367858,OU=Student,OU=F01-709,OU=IBA,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "IBA-D-UC367858",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=IBA-D-UC367859,OU=Student,OU=F01-709,OU=IBA,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "IBA-D-UC367859",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=IBA-D-UC367861,OU=Student,OU=F01-709,OU=IBA,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "IBA-D-UC367861",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=IBA-D-UC367867,OU=Student,OU=F01-709,OU=IBA,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "IBA-D-UC367867",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=IBA-D-UC367872,OU=Student,OU=F01-709,OU=IBA,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "IBA-D-UC367872",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=IBA-D-UC367877,OU=Student,OU=F01-709,OU=IBA,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "IBA-D-UC367877",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=IBA-D-UC367880,OU=Student,OU=F01-709,OU=IBA,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "IBA-D-UC367880",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=IBA-D-UC367891,OU=Student,OU=F01-709,OU=IBA,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "IBA-D-UC367891",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=IBA-D-UC367892,OU=Student,OU=F01-709,OU=IBA,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "IBA-D-UC367892",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=IBA-D-UC367900,OU=Student,OU=F01-709,OU=IBA,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "IBA-D-UC367900",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=IBA-D-UC367908,OU=Student,OU=F01-709,OU=IBA,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "IBA-D-UC367908",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=IBA-D-UC367924,OU=Student,OU=F01-709,OU=IBA,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "IBA-D-UC367924",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=IBA-D-UC367925,OU=Student,OU=F01-709,OU=IBA,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "IBA-D-UC367925",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=IBA-D-UC367928,OU=Student,OU=F01-709,OU=IBA,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "IBA-D-UC367928",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=IBA-D-UC367929,OU=Student,OU=F01-709,OU=IBA,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "IBA-D-UC367929",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=IBA-D-UC367931,OU=Student,OU=F01-709,OU=IBA,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "IBA-D-UC367931",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=IBA-D-UC367932,OU=Student,OU=F01-709,OU=IBA,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "IBA-D-UC367932",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=IBA-D-UC367934,OU=Student,OU=F01-709,OU=IBA,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "IBA-D-UC367934",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=IBA-D-UC367862,OU=Student,OU=F01-709,OU=IBA,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "IBA-D-UC367862",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    }
                  ],
                  "Computers": [],
                  "Type": "OrganizationalUnit"
                }
              ],
              "Computers": [],
              "Type": "OrganizationalUnit"
            },
            {
              "DistinguishedName": "OU=RMU,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
              "Name": "RMU",
              "Description": "lvt@rect.muni.cz",
              "OrganizationalUnits": [
                {
                  "DistinguishedName": "OU=Zerotinovo nam. 9,OU=RMU,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "Zerotinovo nam. 9",
                  "Description": null,
                  "OrganizationalUnits": [
                    {
                      "DistinguishedName": "OU=4.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "4.patro",
                      "Description": null,
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=m475 MZ,OU=4.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "m475 MZ",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=RMU-N-ZER9M475,OU=m475 MZ,OU=4.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "RMU-N-ZER9M475",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=m476 VZ,OU=4.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "m476 VZ",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=RMU-D-ZER9M476,OU=m476 VZ,OU=4.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "RMU-D-ZER9M476",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=m445 EZ,OU=4.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "m445 EZ",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=RMU-N-ZER9m445,OU=m445 EZ,OU=4.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "RMU-N-ZER9m445",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=5.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "5.patro",
                      "Description": null,
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=m585 kounic,OU=5.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "m585 kounic",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=RMU-N-ZER9M585,OU=m585 kounic,OU=5.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "RMU-N-ZER9M585",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=2.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "2.patro",
                      "Description": null,
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=m275,OU=2.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "m275",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=m214,OU=2.patro,OU=Zerotinovo nam. 9,OU=RMU,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "m214",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    }
                  ],
                  "Computers": [],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=Komenskeho nam. 2,OU=RMU,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "Komenskeho nam. 2",
                  "Description": null,
                  "OrganizationalUnits": [
                    {
                      "DistinguishedName": "OU=SYRI,OU=Komenskeho nam. 2,OU=RMU,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "SYRI",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=RMU-D-CHAIR,OU=SYRI,OU=Komenskeho nam. 2,OU=RMU,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "RMU-D-CHAIR",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=RMU-D-REZIE,OU=SYRI,OU=Komenskeho nam. 2,OU=RMU,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "RMU-D-REZIE",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=RMU-D-OPERATOR,OU=SYRI,OU=Komenskeho nam. 2,OU=RMU,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "RMU-D-OPERATOR",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=1.patro,OU=Komenskeho nam. 2,OU=RMU,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "1.patro",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=2.patro,OU=Komenskeho nam. 2,OU=RMU,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "2.patro",
                      "Description": null,
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=m200,OU=2.patro,OU=Komenskeho nam. 2,OU=RMU,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "m200",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=RMU-D-KOM2M200,OU=m200,OU=2.patro,OU=Komenskeho nam. 2,OU=RMU,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "RMU-D-KOM2M200",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=m257,OU=2.patro,OU=Komenskeho nam. 2,OU=RMU,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "m257",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=3.patro,OU=Komenskeho nam. 2,OU=RMU,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "3.patro",
                      "Description": null,
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=m332 - CERPEK,OU=3.patro,OU=Komenskeho nam. 2,OU=RMU,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "m332 - CERPEK",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=RMU-N-KOM2M332,OU=m332 - CERPEK,OU=3.patro,OU=Komenskeho nam. 2,OU=RMU,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "RMU-N-KOM2M332",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=m328 - CERPEK,OU=3.patro,OU=Komenskeho nam. 2,OU=RMU,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "m328 - CERPEK",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=RMU-N-KOM2M328,OU=m328 - CERPEK,OU=3.patro,OU=Komenskeho nam. 2,OU=RMU,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "RMU-N-KOM2M328",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=m300,OU=3.patro,OU=Komenskeho nam. 2,OU=RMU,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "m300",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=RMU-N-KOM2M300A,OU=m300,OU=3.patro,OU=Komenskeho nam. 2,OU=RMU,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "RMU-N-KOM2M300A",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=RMU-N-KOM2M300,OU=m300,OU=3.patro,OU=Komenskeho nam. 2,OU=RMU,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "RMU-N-KOM2M300",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=4.patro,OU=Komenskeho nam. 2,OU=RMU,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "4.patro",
                      "Description": null,
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=m409,OU=4.patro,OU=Komenskeho nam. 2,OU=RMU,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "m409",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=RMU-D-KOM2M409A,OU=m409,OU=4.patro,OU=Komenskeho nam. 2,OU=RMU,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "RMU-D-KOM2M409A",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=m410,OU=4.patro,OU=Komenskeho nam. 2,OU=RMU,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "m410",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=RMU-D-KOM2M410,OU=m410,OU=4.patro,OU=Komenskeho nam. 2,OU=RMU,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "RMU-D-KOM2M410",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=-1.patro,OU=Komenskeho nam. 2,OU=RMU,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "-1.patro",
                      "Description": null,
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=S116,OU=-1.patro,OU=Komenskeho nam. 2,OU=RMU,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "S116",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=RMU-D-KOM2S116,OU=S116,OU=-1.patro,OU=Komenskeho nam. 2,OU=RMU,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "RMU-D-KOM2S116",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=S117,OU=-1.patro,OU=Komenskeho nam. 2,OU=RMU,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "S117",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=RMU-D-KOM2S117,OU=S117,OU=-1.patro,OU=Komenskeho nam. 2,OU=RMU,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "RMU-D-KOM2S117",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=S137 Tryba,OU=-1.patro,OU=Komenskeho nam. 2,OU=RMU,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "S137 Tryba",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=RMU-D-KOM2S137,OU=S137 Tryba,OU=-1.patro,OU=Komenskeho nam. 2,OU=RMU,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "RMU-D-KOM2S137",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        },
                        {
                          "DistinguishedName": "OU=S104 Spolkovna,OU=-1.patro,OU=Komenskeho nam. 2,OU=RMU,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "S104 Spolkovna",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=RMU-D-KOM2S104,OU=S104 Spolkovna,OU=-1.patro,OU=Komenskeho nam. 2,OU=RMU,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "RMU-D-KOM2S104",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=5.patro,OU=Komenskeho nam. 2,OU=RMU,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "5.patro",
                      "Description": null,
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=m501 - U3V,OU=5.patro,OU=Komenskeho nam. 2,OU=RMU,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "m501 - U3V",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=RMU-N-KOM2M501,OU=m501 - U3V,OU=5.patro,OU=Komenskeho nam. 2,OU=RMU,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "RMU-N-KOM2M501",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    }
                  ],
                  "Computers": [],
                  "Type": "OrganizationalUnit"
                }
              ],
              "Computers": [],
              "Type": "OrganizationalUnit"
            },
            {
              "DistinguishedName": "OU=TEIR,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
              "Name": "TEIR",
              "Description": "ict@teiresias.muni.cz",
              "OrganizationalUnits": [
                {
                  "DistinguishedName": "OU=Teiresias,OU=TEIR,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "Teiresias",
                  "Description": null,
                  "OrganizationalUnits": [
                    {
                      "DistinguishedName": "OU=_Archiv ICC \u002723,OU=Teiresias,OU=TEIR,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "_Archiv ICC \u002723",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=hovornaA,OU=Teiresias,OU=TEIR,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "hovornaA",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=TEIR-D-Delos1,OU=hovornaA,OU=Teiresias,OU=TEIR,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-Delos1",
                          "Description": "Hovorna 120 (u technik\u016F)",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=hovornaB,OU=Teiresias,OU=TEIR,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "hovornaB",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=TEIR-D-Delos2,OU=hovornaB,OU=Teiresias,OU=TEIR,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-Delos2",
                          "Description": "Hovorna 120A (u vel\u00EDna)",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=hovornaC,OU=Teiresias,OU=TEIR,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "hovornaC",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=TEIR-D-Delos3,OU=hovornaC,OU=Teiresias,OU=TEIR,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-Delos3",
                          "Description": "Hovorna 104 (u lektor\u016F)",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=hovornaD,OU=Teiresias,OU=TEIR,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "hovornaD",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=TEIR-D-Delos4,OU=hovornaD,OU=Teiresias,OU=TEIR,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-Delos4",
                          "Description": "Hovorna 116 (u galerie)",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=ICT - Test,OU=Teiresias,OU=TEIR,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ICT - Test",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=TEIR-D-Test1,OU=ICT - Test,OU=Teiresias,OU=TEIR,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-Test1",
                          "Description": "Testovac\u00ED stroj pro na\u0161e u\u010Debny v UCN",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=knihovna,OU=Teiresias,OU=TEIR,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "knihovna",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=TEIR-D-Libethra,OU=knihovna,OU=Teiresias,OU=TEIR,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-Libethra",
                          "Description": "Knihovna 128A",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=teachers,OU=Teiresias,OU=TEIR,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "teachers",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=TEIR-D-Andros3,OU=teachers,OU=Teiresias,OU=TEIR,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-Andros3",
                          "Description": "Pracovna lektor\u016F 104",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=TEIR-D-Andros4,OU=teachers,OU=Teiresias,OU=TEIR,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-Andros4",
                          "Description": "Pracovna lektor\u016F 104",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=TEIR-D-Andros2,OU=teachers,OU=Teiresias,OU=TEIR,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-Andros2",
                          "Description": "Pracovna lektor\u016F 104",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=TEIR-D-Andros1,OU=teachers,OU=Teiresias,OU=TEIR,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-Andros1",
                          "Description": "Pracovna lektor\u016F 104",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=u105,OU=Teiresias,OU=TEIR,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "u105",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=TEIR-D-Telepyl1,OU=u105,OU=Teiresias,OU=TEIR,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-Telepyl1",
                          "Description": "u105",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=TEIR-D-Telepyl2,OU=u105,OU=Teiresias,OU=TEIR,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-Telepyl2",
                          "Description": "u105",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=TEIR-D-Telepyl3,OU=u105,OU=Teiresias,OU=TEIR,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-Telepyl3",
                          "Description": "u105",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=TEIR-D-Telepyl4,OU=u105,OU=Teiresias,OU=TEIR,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-Telepyl4",
                          "Description": "u105",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=TEIR-D-Telepyl5,OU=u105,OU=Teiresias,OU=TEIR,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-Telepyl5",
                          "Description": "u105",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=TEIR-D-Telepyl6,OU=u105,OU=Teiresias,OU=TEIR,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-Telepyl6",
                          "Description": "u105",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=TEIR-D-Telepyl7,OU=u105,OU=Teiresias,OU=TEIR,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-Telepyl7",
                          "Description": "u105",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=u106,OU=Teiresias,OU=TEIR,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "u106",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=TEIR-D-Sparta1,OU=u106,OU=Teiresias,OU=TEIR,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-Sparta1",
                          "Description": "u106",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=TEIR-D-Sparta2,OU=u106,OU=Teiresias,OU=TEIR,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-Sparta2",
                          "Description": "u106",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=TEIR-D-Sparta3,OU=u106,OU=Teiresias,OU=TEIR,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-Sparta3",
                          "Description": "u106",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=TEIR-D-Sparta4,OU=u106,OU=Teiresias,OU=TEIR,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-Sparta4",
                          "Description": "u106",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=TEIR-D-Sparta5,OU=u106,OU=Teiresias,OU=TEIR,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-Sparta5",
                          "Description": "u106",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=TEIR-D-Sparta6,OU=u106,OU=Teiresias,OU=TEIR,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-Sparta6",
                          "Description": "u106",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=TEIR-D-Sparta7,OU=u106,OU=Teiresias,OU=TEIR,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-Sparta7",
                          "Description": "u106",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=TEIR-D-Sparta8,OU=u106,OU=Teiresias,OU=TEIR,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-Sparta8",
                          "Description": "u106",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=TEIR-D-Sparta9,OU=u106,OU=Teiresias,OU=TEIR,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-Sparta9",
                          "Description": "u106",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=TEIR-D-Sparta10,OU=u106,OU=Teiresias,OU=TEIR,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-Sparta10",
                          "Description": "u106",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=u107,OU=Teiresias,OU=TEIR,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "u107",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=TEIR-D-Elysion,OU=u107,OU=Teiresias,OU=TEIR,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-Elysion",
                          "Description": "u107",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=u108,OU=Teiresias,OU=TEIR,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "u108",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=TEIR-D-Kythira1,OU=u108,OU=Teiresias,OU=TEIR,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-Kythira1",
                          "Description": "u108",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=TEIR-D-Kythira2,OU=u108,OU=Teiresias,OU=TEIR,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-Kythira2",
                          "Description": "u108",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=TEIR-D-Kythira3,OU=u108,OU=Teiresias,OU=TEIR,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-Kythira3",
                          "Description": "u108",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=TEIR-D-Kythira4,OU=u108,OU=Teiresias,OU=TEIR,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-Kythira4",
                          "Description": "u108",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=TEIR-D-Kythira5,OU=u108,OU=Teiresias,OU=TEIR,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-Kythira5",
                          "Description": "u108",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=u109,OU=Teiresias,OU=TEIR,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "u109",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=TEIR-D-Kalydon1,OU=u109,OU=Teiresias,OU=TEIR,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-Kalydon1",
                          "Description": "u109",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=TEIR-D-Kalydon2,OU=u109,OU=Teiresias,OU=TEIR,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-Kalydon2",
                          "Description": "u109",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=TEIR-D-Kalydon3,OU=u109,OU=Teiresias,OU=TEIR,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-Kalydon3",
                          "Description": "u109",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=u110,OU=Teiresias,OU=TEIR,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "u110",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=TEIR-D-Korint1,OU=u110,OU=Teiresias,OU=TEIR,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-Korint1",
                          "Description": "u110",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=TEIR-D-Korint2,OU=u110,OU=Teiresias,OU=TEIR,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-Korint2",
                          "Description": "u110",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=TEIR-D-Korint3,OU=u110,OU=Teiresias,OU=TEIR,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-Korint3",
                          "Description": "u110",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=TEIR-D-Korint4,OU=u110,OU=Teiresias,OU=TEIR,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-Korint4",
                          "Description": "u110",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=u114,OU=Teiresias,OU=TEIR,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "u114",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=TEIR-D-Ithaki1,OU=u114,OU=Teiresias,OU=TEIR,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-Ithaki1",
                          "Description": "u114",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=TEIR-D-Ithaki2,OU=u114,OU=Teiresias,OU=TEIR,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-Ithaki2",
                          "Description": "u114",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=TEIR-D-Ithaki3,OU=u114,OU=Teiresias,OU=TEIR,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-Ithaki3",
                          "Description": "u114",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=TEIR-D-Ithaki4,OU=u114,OU=Teiresias,OU=TEIR,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-Ithaki4",
                          "Description": "u114",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=TEIR-D-Ithaki5,OU=u114,OU=Teiresias,OU=TEIR,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-Ithaki5",
                          "Description": "u114",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=u115,OU=Teiresias,OU=TEIR,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "u115",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=TEIR-D-Troia1,OU=u115,OU=Teiresias,OU=TEIR,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-Troia1",
                          "Description": "u115",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=TEIR-D-Troia2,OU=u115,OU=Teiresias,OU=TEIR,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-Troia2",
                          "Description": "u115",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=TEIR-D-Troia3,OU=u115,OU=Teiresias,OU=TEIR,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-Troia3",
                          "Description": "u115",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=u117,OU=Teiresias,OU=TEIR,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "u117",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=TEIR-D-Atlanti1,OU=u117,OU=Teiresias,OU=TEIR,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-Atlanti1",
                          "Description": "u117",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=TEIR-D-Atlanti2,OU=u117,OU=Teiresias,OU=TEIR,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-Atlanti2",
                          "Description": "u117",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=TEIR-D-Atlanti3,OU=u117,OU=Teiresias,OU=TEIR,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-Atlanti3",
                          "Description": "u117",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=TEIR-D-Atlanti4,OU=u117,OU=Teiresias,OU=TEIR,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-Atlanti4",
                          "Description": "u117",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=TEIR-D-Atlanti5,OU=u117,OU=Teiresias,OU=TEIR,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-Atlanti5",
                          "Description": "u117",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=TEIR-D-Atlanti6,OU=u117,OU=Teiresias,OU=TEIR,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-Atlanti6",
                          "Description": "u117",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=TEIR-D-Atlanti7,OU=u117,OU=Teiresias,OU=TEIR,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-Atlanti7",
                          "Description": "u117",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=u118,OU=Teiresias,OU=TEIR,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "u118",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=TEIR-D-Kolchid1,OU=u118,OU=Teiresias,OU=TEIR,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-Kolchid1",
                          "Description": "u118",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=TEIR-D-Kolchid2,OU=u118,OU=Teiresias,OU=TEIR,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-Kolchid2",
                          "Description": "u118",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=TEIR-D-Kolchid3,OU=u118,OU=Teiresias,OU=TEIR,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "TEIR-D-Kolchid3",
                          "Description": "u118",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=workshop,OU=Teiresias,OU=TEIR,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "workshop",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=Zkouskovy rezim - bez internetu,OU=Teiresias,OU=TEIR,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Zkouskovy rezim - bez internetu",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=Zkouskovy rezim - bez internetu a bez USB flash,OU=Teiresias,OU=TEIR,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Zkouskovy rezim - bez internetu a bez USB flash",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=Zkouskovy rezim - povolen ELF\\, IS,OU=Teiresias,OU=TEIR,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Zkouskovy rezim - povolen ELF, IS",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=Zkouskovy rezim - povolen ELF\\, IS\\, ASPI\\, Wolfram\\, Beck Online,OU=Teiresias,OU=TEIR,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Zkouskovy rezim - povolen ELF, IS, ASPI, Wolfram, Beck Online",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=Zkouskovy rezim - povolen ELF\\, IS\\, bez USB flash,OU=Teiresias,OU=TEIR,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Zkouskovy rezim - povolen ELF, IS, bez USB flash",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    }
                  ],
                  "Computers": [],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=CJV,OU=TEIR,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "CJV",
                  "Description": null,
                  "OrganizationalUnits": [],
                  "Computers": [],
                  "Type": "OrganizationalUnit"
                }
              ],
              "Computers": [],
              "Type": "OrganizationalUnit"
            },
            {
              "DistinguishedName": "OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
              "Name": "SCI",
              "Description": "ovt@sci.muni.cz",
              "OrganizationalUnits": [
                {
                  "DistinguishedName": "OU=Knihovna,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "Knihovna",
                  "Description": null,
                  "OrganizationalUnits": [
                    {
                      "DistinguishedName": "OU=1NP,OU=Knihovna,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "1NP",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=SCI-D-UK101,OU=1NP,OU=Knihovna,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SCI-D-UK101",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=SCI-D-UK102,OU=1NP,OU=Knihovna,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SCI-D-UK102",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=SCI-D-UK103,OU=1NP,OU=Knihovna,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SCI-D-UK103",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=SCI-D-UK106,OU=1NP,OU=Knihovna,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SCI-D-UK106",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=SCI-D-UK104,OU=1NP,OU=Knihovna,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SCI-D-UK104",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=2NP,OU=Knihovna,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "2NP",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=SCI-D-UK201,OU=2NP,OU=Knihovna,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SCI-D-UK201",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=SCI-D-UK202,OU=2NP,OU=Knihovna,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SCI-D-UK202",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=SCI-D-UK203,OU=2NP,OU=Knihovna,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SCI-D-UK203",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=SCI-D-UK204,OU=2NP,OU=Knihovna,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SCI-D-UK204",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=SCI-D-UK205,OU=2NP,OU=Knihovna,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SCI-D-UK205",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=SCI-D-UK206,OU=2NP,OU=Knihovna,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SCI-D-UK206",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=SCI-D-UK207,OU=2NP,OU=Knihovna,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SCI-D-UK207",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=SCI-D-UK208,OU=2NP,OU=Knihovna,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SCI-D-UK208",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=SCI-D-UK209,OU=2NP,OU=Knihovna,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SCI-D-UK209",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=SCI-D-UK210,OU=2NP,OU=Knihovna,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SCI-D-UK210",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=SCI-D-UK211,OU=2NP,OU=Knihovna,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SCI-D-UK211",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=SCI-D-UK212,OU=2NP,OU=Knihovna,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SCI-D-UK212",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=SCI-D-UK213,OU=2NP,OU=Knihovna,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SCI-D-UK213",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=SCI-D-UK214,OU=2NP,OU=Knihovna,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SCI-D-UK214",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=SCI-D-UK215,OU=2NP,OU=Knihovna,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SCI-D-UK215",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=SCI-D-UK216,OU=2NP,OU=Knihovna,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SCI-D-UK216",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=SCI-D-UK217,OU=2NP,OU=Knihovna,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SCI-D-UK217",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=SCI-D-UK218,OU=2NP,OU=Knihovna,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SCI-D-UK218",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=SCI-D-UK219,OU=2NP,OU=Knihovna,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SCI-D-UK219",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=SCI-D-UK220,OU=2NP,OU=Knihovna,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SCI-D-UK220",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=SCI-D-UK221,OU=2NP,OU=Knihovna,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SCI-D-UK221",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=SCI-D-UK222,OU=2NP,OU=Knihovna,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SCI-D-UK222",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=SCI-D-UK223,OU=2NP,OU=Knihovna,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SCI-D-UK223",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=SCI-D-UK224,OU=2NP,OU=Knihovna,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SCI-D-UK224",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=SCI-D-UK225,OU=2NP,OU=Knihovna,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SCI-D-UK225",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=SCI-D-UK226,OU=2NP,OU=Knihovna,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SCI-D-UK226",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=SCI-D-UK227,OU=2NP,OU=Knihovna,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SCI-D-UK227",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=SCI-D-UK228,OU=2NP,OU=Knihovna,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SCI-D-UK228",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=SCI-D-UK229,OU=2NP,OU=Knihovna,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SCI-D-UK229",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=SCI-D-UK230,OU=2NP,OU=Knihovna,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SCI-D-UK230",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=SCI-D-UK231,OU=2NP,OU=Knihovna,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SCI-D-UK231",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=SCI-D-UK232,OU=2NP,OU=Knihovna,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SCI-D-UK232",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=SCI-D-UK234,OU=2NP,OU=Knihovna,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SCI-D-UK234",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=SCI-D-UK235,OU=2NP,OU=Knihovna,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SCI-D-UK235",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=SCI-D-UK236,OU=2NP,OU=Knihovna,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SCI-D-UK236",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=SCI-D-UK237,OU=2NP,OU=Knihovna,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SCI-D-UK237",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=SCI-D-UK238,OU=2NP,OU=Knihovna,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SCI-D-UK238",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=3NP,OU=Knihovna,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "3NP",
                      "Description": null,
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=Geografie drives,OU=3NP,OU=Knihovna,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "Geografie drives",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [
                            {
                              "DistinguishedName": "CN=SCI-D-UK307,OU=Geografie drives,OU=3NP,OU=Knihovna,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "SCI-D-UK307",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=SCI-D-UK311,OU=Geografie drives,OU=3NP,OU=Knihovna,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "SCI-D-UK311",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=SCI-D-UK312,OU=Geografie drives,OU=3NP,OU=Knihovna,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "SCI-D-UK312",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=SCI-D-UK313,OU=Geografie drives,OU=3NP,OU=Knihovna,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "SCI-D-UK313",
                              "Description": null,
                              "Type": "Computer"
                            },
                            {
                              "DistinguishedName": "CN=SCI-D-UK314,OU=Geografie drives,OU=3NP,OU=Knihovna,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                              "Name": "SCI-D-UK314",
                              "Description": null,
                              "Type": "Computer"
                            }
                          ],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=SCI-D-UK304,OU=3NP,OU=Knihovna,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SCI-D-UK304",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=SCI-D-UK324,OU=3NP,OU=Knihovna,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SCI-D-UK324",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=SCI-D-UK301,OU=3NP,OU=Knihovna,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SCI-D-UK301",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=SCI-D-UK302,OU=3NP,OU=Knihovna,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SCI-D-UK302",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=SCI-D-UK305,OU=3NP,OU=Knihovna,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SCI-D-UK305",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=SCI-D-UK306,OU=3NP,OU=Knihovna,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SCI-D-UK306",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=SCI-D-UK308,OU=3NP,OU=Knihovna,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SCI-D-UK308",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=SCI-D-UK309,OU=3NP,OU=Knihovna,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SCI-D-UK309",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=SCI-D-UK310,OU=3NP,OU=Knihovna,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SCI-D-UK310",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=SCI-D-UK315,OU=3NP,OU=Knihovna,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SCI-D-UK315",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=SCI-D-UK316,OU=3NP,OU=Knihovna,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SCI-D-UK316",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=SCI-D-UK317,OU=3NP,OU=Knihovna,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SCI-D-UK317",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=SCI-D-UK318,OU=3NP,OU=Knihovna,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SCI-D-UK318",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=SCI-D-UK319,OU=3NP,OU=Knihovna,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SCI-D-UK319",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=SCI-D-UK320,OU=3NP,OU=Knihovna,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SCI-D-UK320",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=SCI-D-UK321,OU=3NP,OU=Knihovna,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SCI-D-UK321",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=SCI-D-UK322,OU=3NP,OU=Knihovna,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SCI-D-UK322",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=SCI-D-UK303,OU=3NP,OU=Knihovna,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SCI-D-UK303",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=Katalogy,OU=Knihovna,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Katalogy",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=SCI-D-UK105,OU=Katalogy,OU=Knihovna,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SCI-D-UK105",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=SCI-D-UK233,OU=Katalogy,OU=Knihovna,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SCI-D-UK233",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=SCI-D-UK323,OU=Katalogy,OU=Knihovna,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SCI-D-UK323",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    }
                  ],
                  "Computers": [],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=Aula,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "Aula",
                  "Description": "Aula Kotl\u00E1\u0159sk\u00E1",
                  "OrganizationalUnits": [],
                  "Computers": [
                    {
                      "DistinguishedName": "CN=SCI-D-AULA,OU=Aula,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "SCI-D-AULA",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=SCI-D-AULAKOT,OU=Aula,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "SCI-D-AULAKOT",
                      "Description": null,
                      "Type": "Computer"
                    }
                  ],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=Ucebna IC2,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "Ucebna IC2",
                  "Description": null,
                  "OrganizationalUnits": [
                    {
                      "DistinguishedName": "OU=Ucitelsky,OU=Ucebna IC2,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Ucitelsky",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=SCI-D-IC200,OU=Ucitelsky,OU=Ucebna IC2,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SCI-D-IC200",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=Studentske,OU=Ucebna IC2,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Studentske",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=SCI-D-IC201,OU=Studentske,OU=Ucebna IC2,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SCI-D-IC201",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=SCI-D-IC202,OU=Studentske,OU=Ucebna IC2,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SCI-D-IC202",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=SCI-D-IC203,OU=Studentske,OU=Ucebna IC2,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SCI-D-IC203",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=SCI-D-IC204,OU=Studentske,OU=Ucebna IC2,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SCI-D-IC204",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=SCI-D-IC205,OU=Studentske,OU=Ucebna IC2,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SCI-D-IC205",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=SCI-D-IC206,OU=Studentske,OU=Ucebna IC2,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SCI-D-IC206",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=SCI-D-IC207,OU=Studentske,OU=Ucebna IC2,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SCI-D-IC207",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=SCI-D-IC208,OU=Studentske,OU=Ucebna IC2,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SCI-D-IC208",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=SCI-D-IC209,OU=Studentske,OU=Ucebna IC2,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SCI-D-IC209",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=SCI-D-IC210,OU=Studentske,OU=Ucebna IC2,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SCI-D-IC210",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=SCI-D-IC211,OU=Studentske,OU=Ucebna IC2,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SCI-D-IC211",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=SCI-D-IC212,OU=Studentske,OU=Ucebna IC2,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SCI-D-IC212",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=SCI-D-IC213,OU=Studentske,OU=Ucebna IC2,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SCI-D-IC213",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    }
                  ],
                  "Computers": [],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=Zasedaci mistnosti,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "Zasedaci mistnosti",
                  "Description": null,
                  "OrganizationalUnits": [
                    {
                      "DistinguishedName": "OU=SCI-N-ZASHORNI,OU=Zasedaci mistnosti,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "SCI-N-ZASHORNI",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=SCI-N-ZASHORNI,OU=SCI-N-ZASHORNI,OU=Zasedaci mistnosti,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SCI-N-ZASHORNI",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=SCI-D-ZASDOLNI,OU=Zasedaci mistnosti,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "SCI-D-ZASDOLNI",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=SCI-D-ZASDOLNI,OU=SCI-D-ZASDOLNI,OU=Zasedaci mistnosti,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SCI-D-ZASDOLNI",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    }
                  ],
                  "Computers": [],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=Ucebna IC1,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "Ucebna IC1",
                  "Description": null,
                  "OrganizationalUnits": [
                    {
                      "DistinguishedName": "OU=Ucitelsky,OU=Ucebna IC1,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Ucitelsky",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=SCI-D-IC100,OU=Ucitelsky,OU=Ucebna IC1,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SCI-D-IC100",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=Studentske,OU=Ucebna IC1,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Studentske",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=SCI-D-IC106,OU=Studentske,OU=Ucebna IC1,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SCI-D-IC106",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=SCI-D-IC103,OU=Studentske,OU=Ucebna IC1,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SCI-D-IC103",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=SCI-D-IC108,OU=Studentske,OU=Ucebna IC1,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SCI-D-IC108",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=SCI-D-IC102,OU=Studentske,OU=Ucebna IC1,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SCI-D-IC102",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=SCI-D-IC105,OU=Studentske,OU=Ucebna IC1,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SCI-D-IC105",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=SCI-D-IC104,OU=Studentske,OU=Ucebna IC1,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SCI-D-IC104",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=SCI-D-IC101,OU=Studentske,OU=Ucebna IC1,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SCI-D-IC101",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=SCI-D-IC110,OU=Studentske,OU=Ucebna IC1,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SCI-D-IC110",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=SCI-D-IC109,OU=Studentske,OU=Ucebna IC1,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SCI-D-IC109",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=SCI-D-IC111,OU=Studentske,OU=Ucebna IC1,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SCI-D-IC111",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=SCI-D-IC112,OU=Studentske,OU=Ucebna IC1,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SCI-D-IC112",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=SCI-D-IC107,OU=Studentske,OU=Ucebna IC1,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "SCI-D-IC107",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    }
                  ],
                  "Computers": [],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=Geografie Z1,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "Geografie Z1",
                  "Description": null,
                  "OrganizationalUnits": [],
                  "Computers": [
                    {
                      "DistinguishedName": "CN=SCI-D-GEOZ116,OU=Geografie Z1,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "SCI-D-GEOZ116",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=SCI-D-GEOZ105,OU=Geografie Z1,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "SCI-D-GEOZ105",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=SCI-D-GEOZ120,OU=Geografie Z1,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "SCI-D-GEOZ120",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=SCI-D-GEOZ103,OU=Geografie Z1,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "SCI-D-GEOZ103",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=SCI-D-GEOZ104,OU=Geografie Z1,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "SCI-D-GEOZ104",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=SCI-D-GEOZ112,OU=Geografie Z1,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "SCI-D-GEOZ112",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=SCI-D-GEOZ102,OU=Geografie Z1,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "SCI-D-GEOZ102",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=SCI-D-GEOZ108,OU=Geografie Z1,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "SCI-D-GEOZ108",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=SCI-D-GEOZ107,OU=Geografie Z1,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "SCI-D-GEOZ107",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=SCI-D-GEOZ117,OU=Geografie Z1,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "SCI-D-GEOZ117",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=SCI-D-GEOZ110,OU=Geografie Z1,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "SCI-D-GEOZ110",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=SCI-D-GEOZ101,OU=Geografie Z1,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "SCI-D-GEOZ101",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=SCI-D-GEOZ100,OU=Geografie Z1,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "SCI-D-GEOZ100",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=SCI-D-GEOZ118,OU=Geografie Z1,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "SCI-D-GEOZ118",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=SCI-D-GEOZ119,OU=Geografie Z1,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "SCI-D-GEOZ119",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=SCI-D-GEOZ111,OU=Geografie Z1,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "SCI-D-GEOZ111",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=SCI-D-GEOZ113,OU=Geografie Z1,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "SCI-D-GEOZ113",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=SCI-D-GEOZ106,OU=Geografie Z1,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "SCI-D-GEOZ106",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=SCI-D-GEOZ109,OU=Geografie Z1,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "SCI-D-GEOZ109",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=SCI-D-GEOZ114,OU=Geografie Z1,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "SCI-D-GEOZ114",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=SCI-D-GEOZ115,OU=Geografie Z1,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "SCI-D-GEOZ115",
                      "Description": null,
                      "Type": "Computer"
                    }
                  ],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=Geografie Z7,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "Geografie Z7",
                  "Description": null,
                  "OrganizationalUnits": [],
                  "Computers": [
                    {
                      "DistinguishedName": "CN=SCI-D-GEOZ713,OU=Geografie Z7,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "SCI-D-GEOZ713",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=SCI-D-GEOZ716,OU=Geografie Z7,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "SCI-D-GEOZ716",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=SCI-D-GEOZ703,OU=Geografie Z7,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "SCI-D-GEOZ703",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=SCI-D-GEOZ714,OU=Geografie Z7,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "SCI-D-GEOZ714",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=SCI-D-GEOZ700,OU=Geografie Z7,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "SCI-D-GEOZ700",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=SCI-D-GEOZ710,OU=Geografie Z7,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "SCI-D-GEOZ710",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=SCI-D-GEOTEST,OU=Geografie Z7,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "SCI-D-GEOTEST",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=SCI-D-GEOZ701,OU=Geografie Z7,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "SCI-D-GEOZ701",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=SCI-D-GEOZ705,OU=Geografie Z7,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "SCI-D-GEOZ705",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=SCI-D-GEOZ708,OU=Geografie Z7,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "SCI-D-GEOZ708",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=SCI-D-GEOZ715,OU=Geografie Z7,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "SCI-D-GEOZ715",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=SCI-D-GEOZ704,OU=Geografie Z7,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "SCI-D-GEOZ704",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=SCI-D-GEOZ706,OU=Geografie Z7,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "SCI-D-GEOZ706",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=SCI-D-GEOZ702,OU=Geografie Z7,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "SCI-D-GEOZ702",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=SCI-D-GEOZ707,OU=Geografie Z7,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "SCI-D-GEOZ707",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=SCI-D-GEOZ709,OU=Geografie Z7,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "SCI-D-GEOZ709",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=SCI-D-GEOZ712,OU=Geografie Z7,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "SCI-D-GEOZ712",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=SCI-D-GEOZ711,OU=Geografie Z7,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "SCI-D-GEOZ711",
                      "Description": null,
                      "Type": "Computer"
                    }
                  ],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=Anthropologie,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "Anthropologie",
                  "Description": null,
                  "OrganizationalUnits": [],
                  "Computers": [
                    {
                      "DistinguishedName": "CN=SCI-D-ANTH01,OU=Anthropologie,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "SCI-D-ANTH01",
                      "Description": null,
                      "Type": "Computer"
                    }
                  ],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=Geologie,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "Geologie",
                  "Description": null,
                  "OrganizationalUnits": [],
                  "Computers": [
                    {
                      "DistinguishedName": "CN=SCI-D-GEOL20,OU=Geologie,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "SCI-D-GEOL20",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=SCI-D-GEOL04,OU=Geologie,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "SCI-D-GEOL04",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=SCI-D-GEOL10,OU=Geologie,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "SCI-D-GEOL10",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=SCI-D-GEOL02,OU=Geologie,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "SCI-D-GEOL02",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=SCI-D-GEOL09,OU=Geologie,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "SCI-D-GEOL09",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=SCI-D-GEOL16,OU=Geologie,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "SCI-D-GEOL16",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=SCI-D-GEOL07,OU=Geologie,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "SCI-D-GEOL07",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=SCI-D-GEOL15,OU=Geologie,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "SCI-D-GEOL15",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=SCI-D-GEOL18,OU=Geologie,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "SCI-D-GEOL18",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=SCI-D-GEOL11,OU=Geologie,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "SCI-D-GEOL11",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=SCI-D-GEOL00,OU=Geologie,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "SCI-D-GEOL00",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=SCI-D-GEOL01,OU=Geologie,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "SCI-D-GEOL01",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=SCI-D-GEOL12,OU=Geologie,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "SCI-D-GEOL12",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=SCI-D-GEOL19,OU=Geologie,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "SCI-D-GEOL19",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=SCI-D-GEOL14,OU=Geologie,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "SCI-D-GEOL14",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=SCI-D-GEOL13,OU=Geologie,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "SCI-D-GEOL13",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=SCI-D-GEOL17,OU=Geologie,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "SCI-D-GEOL17",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=SCI-D-GEOL08,OU=Geologie,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "SCI-D-GEOL08",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=SCI-D-GEOL03,OU=Geologie,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "SCI-D-GEOL03",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=SCI-D-GEOL05,OU=Geologie,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "SCI-D-GEOL05",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=SCI-D-GEOL06,OU=Geologie,OU=SCI,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "SCI-D-GEOL06",
                      "Description": null,
                      "Type": "Computer"
                    }
                  ],
                  "Type": "OrganizationalUnit"
                }
              ],
              "Computers": [],
              "Type": "OrganizationalUnit"
            },
            {
              "DistinguishedName": "OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
              "Name": "UKB",
              "Description": "cit@ukb.muni.cz",
              "OrganizationalUnits": [
                {
                  "DistinguishedName": "OU=A19-326OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "A19-326OU",
                  "Description": null,
                  "OrganizationalUnits": [
                    {
                      "DistinguishedName": "OU=UKB-D-406977OU,OU=A19-326OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-D-406977OU",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-406977,OU=UKB-D-406977OU,OU=A19-326OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-406977",
                          "Description": "Lektor A19/326",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    }
                  ],
                  "Computers": [
                    {
                      "DistinguishedName": "CN=UKB-N-472201,OU=A19-326OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-N-472201",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-N-472202,OU=A19-326OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-N-472202",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-N-472203,OU=A19-326OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-N-472203",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-N-472204,OU=A19-326OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-N-472204",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-N-472205,OU=A19-326OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-N-472205",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-N-472206,OU=A19-326OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-N-472206",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-N-472207,OU=A19-326OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-N-472207",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-N-472208,OU=A19-326OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-N-472208",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-N-472209,OU=A19-326OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-N-472209",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-N-472210,OU=A19-326OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-N-472210",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-D-407035,OU=A19-326OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-D-407035",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-D-407047,OU=A19-326OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-D-407047",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-D-407044,OU=A19-326OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-D-407044",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-D-407033,OU=A19-326OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-D-407033",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-D-407039,OU=A19-326OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-D-407039",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-D-407046,OU=A19-326OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-D-407046",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-D-407045,OU=A19-326OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-D-407045",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-D-407040,OU=A19-326OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-D-407040",
                      "Description": null,
                      "Type": "Computer"
                    }
                  ],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "B09-KUKOU",
                  "Description": "W11 - Studentske pocitace knihovny univerzitniho kampusu",
                  "OrganizationalUnits": [
                    {
                      "DistinguishedName": "OU=B09-KIOSEKOU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "B09-KIOSEKOU",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-KATALOG1,OU=B09-KIOSEKOU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KATALOG1",
                          "Description": "DHM 457076",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-KATALOG2,OU=B09-KIOSEKOU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KATALOG2",
                          "Description": "DHM 457077",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-KATALOG3,OU=B09-KIOSEKOU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KATALOG3",
                          "Description": "DHM 457078",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=B09-228OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "B09-228OU",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-KUKPC01,OU=B09-228OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KUKPC01",
                          "Description": "DHM 456778",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-KUKPC02,OU=B09-228OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KUKPC02",
                          "Description": "DHM 456779",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-KUKPC03,OU=B09-228OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KUKPC03",
                          "Description": "DHM 456780",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-KUKPC04,OU=B09-228OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KUKPC04",
                          "Description": "DHM 456781",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-KUKPC05,OU=B09-228OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KUKPC05",
                          "Description": "DHM 456782",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-KUKPC06,OU=B09-228OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KUKPC06",
                          "Description": "DHM 456783",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-KUKPC07,OU=B09-228OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KUKPC07",
                          "Description": "DHM 456784",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-KUKPC08,OU=B09-228OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KUKPC08",
                          "Description": "DHM 456785",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-KUKPC09,OU=B09-228OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KUKPC09",
                          "Description": "DHM 456786",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-KUKPC10,OU=B09-228OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KUKPC10",
                          "Description": "DHM 456787",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-KUKPC11,OU=B09-228OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KUKPC11",
                          "Description": "DHM 456788",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-KUKPC12,OU=B09-228OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KUKPC12",
                          "Description": "DHM 456789",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-KUKPC13,OU=B09-228OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KUKPC13",
                          "Description": "DHM 456790",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-KUKPC14,OU=B09-228OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KUKPC14",
                          "Description": "DHM 456791",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-KUKPC15,OU=B09-228OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KUKPC15",
                          "Description": "DHM 456792",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-KUKPC16,OU=B09-228OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KUKPC16",
                          "Description": "DHM 456793",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-KUKPC17,OU=B09-228OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KUKPC17",
                          "Description": "DHM 456794",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-KUKPC18,OU=B09-228OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KUKPC18",
                          "Description": "DHM 456795",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-KUKPC19,OU=B09-228OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KUKPC19",
                          "Description": "DHM 456796",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-KUKPC20,OU=B09-228OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KUKPC20",
                          "Description": "DHM 456797",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-KUKPC21,OU=B09-228OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KUKPC21",
                          "Description": "DHM 456798",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-KUKPC56,OU=B09-228OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KUKPC56",
                          "Description": "DHM 456833",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-KUKPC54,OU=B09-228OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KUKPC54",
                          "Description": "DHM 456831",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-KUKPC55,OU=B09-228OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KUKPC55",
                          "Description": "DHM 456832",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-KUKPC53,OU=B09-228OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KUKPC53",
                          "Description": "DHM 456830",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-KUKPC52,OU=B09-228OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KUKPC52",
                          "Description": "DHM 456829",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-KUKPC51,OU=B09-228OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KUKPC51",
                          "Description": "DHM 456828",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-KUKPC50,OU=B09-228OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KUKPC50",
                          "Description": "DHM 456827",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-KUKPC49,OU=B09-228OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KUKPC49",
                          "Description": "DHM 456826",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-KUKPC48,OU=B09-228OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KUKPC48",
                          "Description": "DHM 456825",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-KUKPC47,OU=B09-228OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KUKPC47",
                          "Description": "DHM 456824",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-KUKPC46,OU=B09-228OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KUKPC46",
                          "Description": "DHM 456823",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-KUKPC45,OU=B09-228OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KUKPC45",
                          "Description": "DHM 456822",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-KUKPC44,OU=B09-228OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KUKPC44",
                          "Description": "DHM 456821",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-KUKPC22,OU=B09-228OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KUKPC22",
                          "Description": "DHM 456799",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-KUKPC23,OU=B09-228OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KUKPC23",
                          "Description": "DHM 456800",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-KUKPC24,OU=B09-228OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KUKPC24",
                          "Description": "DHM 456801",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-KUKPC25,OU=B09-228OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KUKPC25",
                          "Description": "DHM 456802",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-KUKPC26,OU=B09-228OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KUKPC26",
                          "Description": "DHM 456803",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-KUKPC27,OU=B09-228OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KUKPC27",
                          "Description": "DHM 456804",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-KUKPC28,OU=B09-228OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KUKPC28",
                          "Description": "DHM 456805",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-KUKPC29,OU=B09-228OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KUKPC29",
                          "Description": "DHM 456806",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-KUKPC30,OU=B09-228OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KUKPC30",
                          "Description": "DHM 456807",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-KUKPC31,OU=B09-228OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KUKPC31",
                          "Description": "DHM 456808",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-KUKPC32,OU=B09-228OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KUKPC32",
                          "Description": "DHM 456809",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-KUKPC33,OU=B09-228OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KUKPC33",
                          "Description": "DHM 456810",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-KUKPC34,OU=B09-228OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KUKPC34",
                          "Description": "DHM 456811",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-KUKPC35,OU=B09-228OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KUKPC35",
                          "Description": "DHM 456812",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-KUKPC36,OU=B09-228OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KUKPC36",
                          "Description": "DHM 456813",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-KUKPC37,OU=B09-228OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KUKPC37",
                          "Description": "DHM 456814",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-KUKPC38,OU=B09-228OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KUKPC38",
                          "Description": "DHM 456815",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-KUKPC39,OU=B09-228OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KUKPC39",
                          "Description": "DHM 456816",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-KUKPC40,OU=B09-228OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KUKPC40",
                          "Description": "DHM 456817",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-KUKPC41,OU=B09-228OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KUKPC41",
                          "Description": "DHM 456818",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-KUKPC42,OU=B09-228OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KUKPC42",
                          "Description": "DHM 456819",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-KUKPC43,OU=B09-228OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KUKPC43",
                          "Description": "DHM 456820",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=B09-119OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "B09-119OU",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-KUKPC75,OU=B09-119OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KUKPC75",
                          "Description": "DHM 456748",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-KUKPC74,OU=B09-119OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KUKPC74",
                          "Description": "DHM 456757",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-KUKPC73,OU=B09-119OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KUKPC73",
                          "Description": "DHM 456756",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-KUKPC72,OU=B09-119OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KUKPC72",
                          "Description": "DHM 456755",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-KUKPC71,OU=B09-119OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KUKPC71",
                          "Description": "DHM 456754",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-KUKPC70,OU=B09-119OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KUKPC70",
                          "Description": "DHM 456753",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-KUKPC69,OU=B09-119OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KUKPC69",
                          "Description": "DHM 456752",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-KUKPC68,OU=B09-119OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KUKPC68",
                          "Description": "DHM 456751",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-KUKPC67,OU=B09-119OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KUKPC67",
                          "Description": "DHM 456750",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-KUKPC66,OU=B09-119OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KUKPC66",
                          "Description": "DHM 456749",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-KUKPC65,OU=B09-119OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KUKPC65",
                          "Description": "DHM 456748",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-KUKPC64,OU=B09-119OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KUKPC64",
                          "Description": "DHM 456747",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-KUKPC63,OU=B09-119OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KUKPC63",
                          "Description": "DHM 456746",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-KUKPC62,OU=B09-119OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KUKPC62",
                          "Description": "DHM 456745",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-KUKPC61,OU=B09-119OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KUKPC61",
                          "Description": "DHM 456741",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=B09-111OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "B09-111OU",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-KUKPC91,OU=B09-111OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KUKPC91",
                          "Description": "DHM 457069",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-KUKPC92,OU=B09-111OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KUKPC92",
                          "Description": "DHM 457070",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-KUKPC93,OU=B09-111OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KUKPC93",
                          "Description": "DHM 457071",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-KUKPC94,OU=B09-111OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KUKPC94",
                          "Description": "DHM 457072",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-KUKPC95,OU=B09-111OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KUKPC95",
                          "Description": "DHM 457073",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-KUKPC96,OU=B09-111OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KUKPC96",
                          "Description": "DHM 457074",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-KUKPC97,OU=B09-111OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KUKPC97",
                          "Description": "DHM 457075",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-KUKPC90,OU=B09-111OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KUKPC90",
                          "Description": "DHM 456770",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-KUKPC89,OU=B09-111OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KUKPC89",
                          "Description": "DHM 456769",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-KUKPC88,OU=B09-111OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KUKPC88",
                          "Description": "DHM 456768",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-KUKPC87,OU=B09-111OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KUKPC87",
                          "Description": "DHM 456767",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-KUKPC86,OU=B09-111OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KUKPC86",
                          "Description": "DHM 456766",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-KUKPC85,OU=B09-111OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KUKPC85",
                          "Description": "DHM 456765",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-KUKPC84,OU=B09-111OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KUKPC84",
                          "Description": "DHM 456764",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-KUKPC83,OU=B09-111OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KUKPC83",
                          "Description": "DHM 456763",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-KUKPC82,OU=B09-111OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KUKPC82",
                          "Description": "DHM 456762",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-KUKPC81,OU=B09-111OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KUKPC81",
                          "Description": "DHM 456761",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-KUKPC80,OU=B09-111OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KUKPC80",
                          "Description": "DHM 456760",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-KUKPC79,OU=B09-111OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KUKPC79",
                          "Description": "DHM 456759",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=B09-110OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "B09-110OU",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-KUKPC78,OU=B09-110OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KUKPC78",
                          "Description": "DHM 456777",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-KUKPC77,OU=B09-110OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KUKPC77",
                          "Description": "DHM 456776",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-KUKPC76,OU=B09-110OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KUKPC76",
                          "Description": "DHM 456775",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=B09-120OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "B09-120OU",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-KUKPC60,OU=B09-120OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KUKPC60",
                          "Description": "DHM 456774",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-KUKPC59,OU=B09-120OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KUKPC59",
                          "Description": "DHM 456773",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-KUKPC58,OU=B09-120OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KUKPC58",
                          "Description": "DHM 456772",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-KUKPC57,OU=B09-120OU,OU=B09-KUKOU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-KUKPC57",
                          "Description": "DHM 456771",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    }
                  ],
                  "Computers": [],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=A16-308OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "A16-308OU",
                  "Description": "W11 - Farmakologicky ustav / Blablova",
                  "OrganizationalUnits": [
                    {
                      "DistinguishedName": "OU=UKB-D-406976OU,OU=A16-308OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-D-406976OU",
                      "Description": "Lektor - A16/308",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-406976,OU=UKB-D-406976OU,OU=A16-308OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-406976",
                          "Description": "Lektor A16/308",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    }
                  ],
                  "Computers": [
                    {
                      "DistinguishedName": "CN=UKB-D-453774,OU=A16-308OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-D-453774",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-D-453779,OU=A16-308OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-D-453779",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-D-453785,OU=A16-308OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-D-453785",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-D-453786,OU=A16-308OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-D-453786",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-D-453787,OU=A16-308OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-D-453787",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-D-453788,OU=A16-308OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-D-453788",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-D-453789,OU=A16-308OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-D-453789",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-D-453791,OU=A16-308OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-D-453791",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-D-453795,OU=A16-308OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-D-453795",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-D-453796,OU=A16-308OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-D-453796",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-D-453799,OU=A16-308OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-D-453799",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-D-453800,OU=A16-308OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-D-453800",
                      "Description": null,
                      "Type": "Computer"
                    }
                  ],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=A19-112OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "A19-112OU",
                  "Description": "W11 - Farmakologicky ustav / Blablova",
                  "OrganizationalUnits": [
                    {
                      "DistinguishedName": "OU=UKB-D-51696OU,OU=A19-112OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-D-51696OU",
                      "Description": "Lektor - A19/112",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-51696,OU=UKB-D-51696OU,OU=A19-112OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-51696",
                          "Description": "Lektor A19/112 - ZP",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    }
                  ],
                  "Computers": [
                    {
                      "DistinguishedName": "CN=UKB-D-453801,OU=A19-112OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-D-453801",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-D-453802,OU=A19-112OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-D-453802",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-D-453803,OU=A19-112OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-D-453803",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-D-453804,OU=A19-112OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-D-453804",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-D-453805,OU=A19-112OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-D-453805",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-D-453806,OU=A19-112OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-D-453806",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-D-453807,OU=A19-112OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-D-453807",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-D-453808,OU=A19-112OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-D-453808",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-D-453809,OU=A19-112OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-D-453809",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-D-453810,OU=A19-112OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-D-453810",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-D-453790,OU=A19-112OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-D-453790",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-D-453797,OU=A19-112OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-D-453797",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-D-453798,OU=A19-112OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-D-453798",
                      "Description": null,
                      "Type": "Computer"
                    }
                  ],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=A16-217OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "A16-217OU",
                  "Description": "W11 - Biochemicky ustav / Necasova",
                  "OrganizationalUnits": [
                    {
                      "DistinguishedName": "OU=UKB-D-407080OU,OU=A16-217OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-D-407080OU",
                      "Description": "A16/217 - Lektor",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-407080,OU=UKB-D-407080OU,OU=A16-217OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-407080",
                          "Description": "Lektor A16/217",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    }
                  ],
                  "Computers": [
                    {
                      "DistinguishedName": "CN=UKB-D-407059,OU=A16-217OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-D-407059",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-D-407060,OU=A16-217OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-D-407060",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-D-407061,OU=A16-217OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-D-407061",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-D-407062,OU=A16-217OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-D-407062",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-D-407063,OU=A16-217OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-D-407063",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-D-407064,OU=A16-217OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-D-407064",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-D-407065,OU=A16-217OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-D-407065",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-D-407066,OU=A16-217OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-D-407066",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-D-407067,OU=A16-217OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-D-407067",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-D-407068,OU=A16-217OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-D-407068",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-D-407069,OU=A16-217OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-D-407069",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-D-407070,OU=A16-217OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-D-407070",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-D-407071,OU=A16-217OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-D-407071",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-D-407072,OU=A16-217OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-D-407072",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-D-407073,OU=A16-217OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-D-407073",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-D-407074,OU=A16-217OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-D-407074",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-D-407075,OU=A16-217OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-D-407075",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-D-407076,OU=A16-217OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-D-407076",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-D-407077,OU=A16-217OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-D-407077",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-D-407078,OU=A16-217OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-D-407078",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-D-407079,OU=A16-217OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-D-407079",
                      "Description": null,
                      "Type": "Computer"
                    }
                  ],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=B09-316OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "B09-316OU",
                  "Description": "W11 - Chemie / Necas",
                  "OrganizationalUnits": [
                    {
                      "DistinguishedName": "OU=lektor,OU=B09-316OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "lektor",
                      "Description": "PCSM - dont rename",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-408151,OU=lektor,OU=B09-316OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-408151",
                          "Description": "Lektor B09/316",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=student,OU=B09-316OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "student",
                      "Description": "PCSM - dont rename",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-416007,OU=student,OU=B09-316OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-416007",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-416025,OU=student,OU=B09-316OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-416025",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-416013,OU=student,OU=B09-316OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-416013",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-416008,OU=student,OU=B09-316OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-416008",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-416012,OU=student,OU=B09-316OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-416012",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-416020,OU=student,OU=B09-316OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-416020",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-416015,OU=student,OU=B09-316OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-416015",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-416014,OU=student,OU=B09-316OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-416014",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-416011,OU=student,OU=B09-316OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-416011",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-416004,OU=student,OU=B09-316OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-416004",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-416009,OU=student,OU=B09-316OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-416009",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-416006,OU=student,OU=B09-316OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-416006",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-416005,OU=student,OU=B09-316OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-416005",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-416019,OU=student,OU=B09-316OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-416019",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-416024,OU=student,OU=B09-316OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-416024",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-416027,OU=student,OU=B09-316OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-416027",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-416023,OU=student,OU=B09-316OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-416023",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-416026,OU=student,OU=B09-316OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-416026",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-416022,OU=student,OU=B09-316OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-416022",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-416021,OU=student,OU=B09-316OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-416021",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-416010,OU=student,OU=B09-316OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-416010",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-416016,OU=student,OU=B09-316OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-416016",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-416018,OU=student,OU=B09-316OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-416018",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-416017,OU=student,OU=B09-316OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-416017",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    }
                  ],
                  "Computers": [],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=C15-332OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "C15-332OU",
                  "Description": "W10 - Ustav verejneho zdravi / Novohradska",
                  "OrganizationalUnits": [
                    {
                      "DistinguishedName": "OU=NutriPro-VPN,OU=C15-332OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "NutriPro-VPN",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-383164,OU=NutriPro-VPN,OU=C15-332OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-383164",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-383165,OU=NutriPro-VPN,OU=C15-332OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-383165",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-383166,OU=NutriPro-VPN,OU=C15-332OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-383166",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-383167,OU=NutriPro-VPN,OU=C15-332OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-383167",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-383168,OU=NutriPro-VPN,OU=C15-332OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-383168",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-383169,OU=NutriPro-VPN,OU=C15-332OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-383169",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-383466,OU=NutriPro-VPN,OU=C15-332OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-383466",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-383467,OU=NutriPro-VPN,OU=C15-332OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-383467",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=UKB-D-406983OU,OU=C15-332OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-D-406983OU",
                      "Description": "Lektor C15/332",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-406983,OU=UKB-D-406983OU,OU=C15-332OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-406983",
                          "Description": "Lektor C15/332",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=UKB-D-383160OU,OU=C15-332OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-D-383160OU",
                      "Description": "Nemazat OU - Alimenta",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-383160,OU=UKB-D-383160OU,OU=C15-332OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-383160",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=UKB-D-383161OU,OU=C15-332OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-D-383161OU",
                      "Description": "Nemazat OU - Alimenta",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-383161,OU=UKB-D-383161OU,OU=C15-332OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-383161",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    }
                  ],
                  "Computers": [
                    {
                      "DistinguishedName": "CN=UKB-D-383162,OU=C15-332OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-D-383162",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-D-383163,OU=C15-332OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-D-383163",
                      "Description": null,
                      "Type": "Computer"
                    }
                  ],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=C15-207OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "C15-207OU",
                  "Description": "W11 - FSPS / Sedlacek",
                  "OrganizationalUnits": [
                    {
                      "DistinguishedName": "OU=UKB-D-404271OU,OU=C15-207OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-D-404271OU",
                      "Description": "Lektor C15/207",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-404271,OU=UKB-D-404271OU,OU=C15-207OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-404271",
                          "Description": "Lektor C15/207",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    }
                  ],
                  "Computers": [
                    {
                      "DistinguishedName": "CN=UKB-D-448583,OU=C15-207OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-D-448583",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-D-448584,OU=C15-207OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-D-448584",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-D-448585,OU=C15-207OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-D-448585",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-D-448586,OU=C15-207OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-D-448586",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-D-448587,OU=C15-207OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-D-448587",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-D-448588,OU=C15-207OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-D-448588",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-D-448589,OU=C15-207OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-D-448589",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-D-448590,OU=C15-207OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-D-448590",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-D-448591,OU=C15-207OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-D-448591",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-D-448592,OU=C15-207OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-D-448592",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-D-448593,OU=C15-207OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-D-448593",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-D-448594,OU=C15-207OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-D-448594",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-D-448595,OU=C15-207OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-D-448595",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-D-448596,OU=C15-207OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-D-448596",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-D-448597,OU=C15-207OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-D-448597",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-D-448598,OU=C15-207OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-D-448598",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-D-448599,OU=C15-207OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-D-448599",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-D-448600,OU=C15-207OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-D-448600",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-D-448601,OU=C15-207OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-D-448601",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-D-448602,OU=C15-207OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-D-448602",
                      "Description": null,
                      "Type": "Computer"
                    }
                  ],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=F01-310OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "F01-310OU",
                  "Description": "W11 - Biofyzikalni ustav / Staffa",
                  "OrganizationalUnits": [],
                  "Computers": [
                    {
                      "DistinguishedName": "CN=UKB-N-403863,OU=F01-310OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-N-403863",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-N-403864,OU=F01-310OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-N-403864",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-N-403862,OU=F01-310OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-N-403862",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-N-403850,OU=F01-310OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-N-403850",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-N-403849,OU=F01-310OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-N-403849",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-N-403866,OU=F01-310OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-N-403866",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-N-403847,OU=F01-310OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-N-403847",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-N-403865,OU=F01-310OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-N-403865",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-N-403851,OU=F01-310OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-N-403851",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-N-403852,OU=F01-310OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-N-403852",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-N-403853,OU=F01-310OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-N-403853",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-N-403854,OU=F01-310OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-N-403854",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-N-403855,OU=F01-310OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-N-403855",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-N-403856,OU=F01-310OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-N-403856",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-N-403857,OU=F01-310OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-N-403857",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-N-403861,OU=F01-310OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-N-403861",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-N-403860,OU=F01-310OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-N-403860",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-N-403859,OU=F01-310OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-N-403859",
                      "Description": null,
                      "Type": "Computer"
                    },
                    {
                      "DistinguishedName": "CN=UKB-N-403858,OU=F01-310OU,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "UKB-N-403858",
                      "Description": null,
                      "Type": "Computer"
                    }
                  ],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "Lektor",
                  "Description": "Lektorske pocitace mimo ucebny",
                  "OrganizationalUnits": [
                    {
                      "DistinguishedName": "OU=E35-1S102OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "E35-1S102OU",
                      "Description": "EN - E35/1S102",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-345582,OU=E35-1S102OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-345582",
                          "Description": "EN - Lektor E35/1S102",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=A18-205OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "A18-205OU",
                      "Description": "A18/205",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-407050,OU=A18-205OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-407050",
                          "Description": "Lektor A18/205",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=A18-208OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "A18-208OU",
                      "Description": "A18/208",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-398041,OU=A18-208OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-398041",
                          "Description": "Lektor A18/208",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=F01-313OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "F01-313OU",
                      "Description": "F01/313",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-407028,OU=F01-313OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-407028",
                          "Description": "Lektor F01/313",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=A16-216OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "A16-216OU",
                      "Description": "W10 - A16/216",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-383319,OU=A16-216OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-383319",
                          "Description": "W10 - Lektor A16/216",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=F01-528OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "F01-528OU",
                      "Description": "F01/528",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-407053,OU=F01-528OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-407053",
                          "Description": "Lektor F01/528",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=F01B02-1S106OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "F01B02-1S106OU",
                      "Description": "F01B02/1S106",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-417407,OU=F01B02-1S106OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-417407",
                          "Description": "Lektor F01B02/1S106",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=A18-112OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "A18-112OU",
                      "Description": "W10 - A18/112",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-391020,OU=A18-112OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-391020",
                          "Description": "W10 - Lektor A18/112",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=F01B02-2S249OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "F01B02-2S249OU",
                      "Description": "F01B02/2S249",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-407031,OU=F01B02-2S249OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-407031",
                          "Description": "Lektor F01B02/2S249",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=D36-212OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "D36-212OU",
                      "Description": "D36/212",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-461867,OU=D36-212OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-461867",
                          "Description": "Lektor D36/212",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=F01-122OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "F01-122OU",
                      "Description": "F01/122",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-395523,OU=F01-122OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-395523",
                          "Description": "Lektor F01/122",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=D36-215OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "D36-215OU",
                      "Description": "D36/215",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-461869,OU=D36-215OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-461869",
                          "Description": "Lektor D36/215",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=B22-116OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "B22-116OU",
                      "Description": "B22/116",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-404274,OU=B22-116OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-404274",
                          "Description": "Lektor B22/116",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=E25-119OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "E25-119OU",
                      "Description": "W10 - E25/119",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-349384,OU=E25-119OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-349384",
                          "Description": "W10 - Lektor E25/119",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=F01-126OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "F01-126OU",
                      "Description": "W10 - F01/126",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-388986,OU=F01-126OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-388986",
                          "Description": "W10 - Lektor F01/126",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=E35-211OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "E35-211OU",
                      "Description": "W10 - EN - E35/211",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-466657,OU=E35-211OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-466657",
                          "Description": "EN - Lektor E35/211",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=C05-114OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "C05-114OU",
                      "Description": "C05/114",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-407143,OU=C05-114OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-407143",
                          "Description": "Lektor C05/114",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=C15-114OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "C15-114OU",
                      "Description": "W10 - C15/114 - Brave",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-404264,OU=C15-114OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-404264",
                          "Description": "Lektor C15/114",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=A21-107OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "A21-107OU",
                      "Description": "A21/107 - R, RStudio, Inkscape",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-406989,OU=A21-107OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-406989",
                          "Description": "Lektor A21/107",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=C15-212OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "C15-212OU",
                      "Description": "W10 - C15/212",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-474841,OU=C15-212OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-474841",
                          "Description": "Lektor C15/212",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=A21-111OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "A21-111OU",
                      "Description": "A21/111 - R, RStudio, Inkscape",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-406987,OU=A21-111OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-406987",
                          "Description": "Lektor A21/111",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=A21-112OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "A21-112OU",
                      "Description": "A21/112 - R, RStudio, Inkscape",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-406985,OU=A21-112OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-406985",
                          "Description": "Lektor A21/112",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=C15-308OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "C15-308OU",
                      "Description": "C15/308 - R, RStudio, Inkscape",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-406981,OU=C15-308OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-406981",
                          "Description": "Lektor C15/308",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=C05-107OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "C05-107OU",
                      "Description": "C05/107",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-407142,OU=C05-107OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-407142",
                          "Description": "Lektor C05/107",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=B17-432OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "B17-432OU",
                      "Description": "B17/432",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-418163,OU=B17-432OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-418163",
                          "Description": "Lektor B17/432",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=C15-333OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "C15-333OU",
                      "Description": "C15/333 - R, RStudio, Inkscape",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-406984,OU=C15-333OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-406984",
                          "Description": "Lektor C15/333",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=E34-123OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "E34-123OU",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-470068,OU=E34-123OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-470068",
                          "Description": "Lektor E34/123",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=F37-206OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "F37-206OU",
                      "Description": "F37/206",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-429188,OU=F37-206OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-429188",
                          "Description": "Lektor F37/206",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=E25-209OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "E25-209OU",
                      "Description": "W10 - E25/209",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-349386,OU=E25-209OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-349386",
                          "Description": "W10 - Lektor E25/209",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=F37-207OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "F37-207OU",
                      "Description": "F37/207",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-429189,OU=F37-207OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-429189",
                          "Description": "Lektor F37/207",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=F37-211OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "F37-211OU",
                      "Description": "F37/211",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-429190,OU=F37-211OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-429190",
                          "Description": "Lektor F37/211",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=F37-212OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "F37-212OU",
                      "Description": "F37/212",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-429191,OU=F37-212OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-429191",
                          "Description": "Lektor F37/212",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=F37-214OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "F37-214OU",
                      "Description": "F37/214",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-429192,OU=F37-214OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-429192",
                          "Description": "Lektor F37/214",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=F37-366OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "F37-366OU",
                      "Description": "F37/366",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-429203,OU=F37-366OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-429203",
                          "Description": "Lektor F37/366",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=F37-215OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "F37-215OU",
                      "Description": "F37/215",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-429193,OU=F37-215OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-429193",
                          "Description": "Lektor F37/215",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=F37-216OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "F37-216OU",
                      "Description": "F37/216",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-429194,OU=F37-216OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-429194",
                          "Description": "Lektor F37/216",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=F37-346OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "F37-346OU",
                      "Description": "F37/346",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-429202,OU=F37-346OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-429202",
                          "Description": "Lektor F37/346",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=F37-217OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "F37-217OU",
                      "Description": "F37/217",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-429195,OU=F37-217OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-429195",
                          "Description": "Lektor F37/217",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=F37-331OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "F37-331OU",
                      "Description": "F37/331",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-429201,OU=F37-331OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-429201",
                          "Description": "Lektor F37/331",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=F37-328OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "F37-328OU",
                      "Description": "F37/328",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-429199,OU=F37-328OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-429199",
                          "Description": "Lektor F37/328",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=F37-327OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "F37-327OU",
                      "Description": "F37/327",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-429198,OU=F37-327OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-429198",
                          "Description": "Lektor F37/327",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=C04-211OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "C04-211OU",
                      "Description": "C04/211",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-442009,OU=C04-211OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-442009",
                          "Description": "Lektor C04/211",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=F37-329OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "F37-329OU",
                      "Description": "F37/329",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-429200,OU=F37-329OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-429200",
                          "Description": "Lektor F37/329",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=F37-218OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "F37-218OU",
                      "Description": "F37/218",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-429196,OU=F37-218OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-429196",
                          "Description": "Lektor F37/218",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=C04-1S57OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "C04-1S57OU",
                      "Description": "C04/1S57",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-415088,OU=C04-1S57OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-415088",
                          "Description": "Lektor C04/1S57",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=A19-133OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "A19-133OU",
                      "Description": "A19/133",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-51694,OU=A19-133OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-51694",
                          "Description": "Lektor A19/133 -  ZP",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=F37-219OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "F37-219OU",
                      "Description": "F37/219",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-429197,OU=F37-219OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-429197",
                          "Description": "Lektor F37/219",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=C02-211OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "C02-211OU",
                      "Description": "W10 - C02/211",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-346350,OU=C02-211OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-346350",
                          "Description": "W10 - Lektor C02/211",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=C15-113OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "C15-113OU",
                      "Description": "W10 - C15/113",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-407081,OU=C15-113OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-407081",
                          "Description": "Lektor C15/113",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=A19-118OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "A19-118OU",
                      "Description": "A19/118",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-407036,OU=A19-118OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-407036",
                          "Description": "Lektor A19/118",
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-407038,OU=A19-118OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-407038",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=UKB-D-406980,OU=A19-118OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-406980",
                          "Description": "Lektor A19/118",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=E35-145OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "E35-145OU",
                      "Description": "EN - Lektor E35/145",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-466658,OU=E35-145OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-466658",
                          "Description": "EN - Lektor E35/145",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=A20-113OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "A20-113OU",
                      "Description": "A20/113 - Foxit reader",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-406926,OU=A20-113OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-406926",
                          "Description": "Lektor A20/113",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=A20-207OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "A20-207OU",
                      "Description": "A20/207",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-406929,OU=A20-207OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-406929",
                          "Description": "Lektor A20/207",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=F01-519OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "F01-519OU",
                      "Description": "F01/519",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-N-403824,OU=F01-519OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-N-403824",
                          "Description": "Lektor F01/519",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=F01-521OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "F01-521OU",
                      "Description": "F01/521",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-N-403825,OU=F01-521OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-N-403825",
                          "Description": "Lektor F01/521",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=F01-513OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "F01-513OU",
                      "Description": "F01/513",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-N-403826,OU=F01-513OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-N-403826",
                          "Description": "Lektor F01/513",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=A20-114OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "A20-114OU",
                      "Description": "A20/114 - Foxit Reader",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-406927,OU=A20-114OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-406927",
                          "Description": "Lektor A20/114",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=A16-215OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "A16-215OU",
                      "Description": "A16/215",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-407058,OU=A16-215OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-407058",
                          "Description": "Lektor A16/215",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=A20-115OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "A20-115OU",
                      "Description": "A20/115 - Foxit Reader",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-406928,OU=A20-115OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-406928",
                          "Description": "Lektor A20/115",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=A16-213OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "A16-213OU",
                      "Description": "A16/213",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-407057,OU=A16-213OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-407057",
                          "Description": "Lektor A16/213",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=E34-203OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "E34-203OU",
                      "Description": "E34/203",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-403932,OU=E34-203OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-403932",
                          "Description": "Lektor E34/203",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=A16-212OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "A16-212OU",
                      "Description": "A16/212",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-453536,OU=A16-212OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-453536",
                          "Description": "Lektor A16/212",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=A16-211OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "A16-211OU",
                      "Description": "W10 - A16/211",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-337657,OU=A16-211OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-337657",
                          "Description": "W10 - Lektor A16/211",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=A21-232OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "A21-232OU",
                      "Description": "A21/232 - NutriPro",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-406986,OU=A21-232OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-406986",
                          "Description": "Lektor A21/232",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=D36-308OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "D36-308OU",
                      "Description": "W10 - D36/308",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-386123,OU=D36-308OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-386123",
                          "Description": "W10 - Lektor D36/308",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=E26-222OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "E26-222OU",
                      "Description": "W10/EN - E26/222",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-364886,OU=E26-222OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-364886",
                          "Description": "W10/EN - E26/222",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=E34-153OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "E34-153OU",
                      "Description": "E34/153",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-403930,OU=E34-153OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-403930",
                          "Description": "Lektor E34/153",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=E34-119OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "E34-119OU",
                      "Description": "E34/119",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-451536,OU=E34-119OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-451536",
                          "Description": "Lektor E34/119",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=E25-111OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "E25-111OU",
                      "Description": "E25/111",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-460334,OU=E25-111OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-460334",
                          "Description": "Lektor E25/111",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=E34-207OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "E34-207OU",
                      "Description": "E34/207",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-51829,OU=E34-207OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-51829",
                          "Description": "Lektor E34/207",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=F01-316OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "F01-316OU",
                      "Description": "F01/316",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-407027,OU=F01-316OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-407027",
                          "Description": "Lektor F01/316",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=F01-309OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "F01-309OU",
                      "Description": "F01/309",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-470183,OU=F01-309OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-470183",
                          "Description": "Lektor F01/309",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=A21-108OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "A21-108OU",
                      "Description": "A21/108 - R, Rstudio, Inkscape",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-406988,OU=A21-108OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-406988",
                          "Description": "Lektor A21/108",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=B11-114OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "B11-114OU",
                      "Description": "B11/114",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-404281,OU=B11-114OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-404281",
                          "Description": "Lektor B11/114",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=A18-124OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "A18-124OU",
                      "Description": "A18/124",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-51679,OU=A18-124OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-51679",
                          "Description": "Lektor A18/124 - ZP",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=B11-132OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "B11-132OU",
                      "Description": "B11/132",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-404275,OU=B11-132OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-404275",
                          "Description": "Lektor B11/132",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=B11-205OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "B11-205OU",
                      "Description": "B11/205",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-404286,OU=B11-205OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-404286",
                          "Description": "Lektor B11/205",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=B11-206OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "B11-206OU",
                      "Description": "B11/206",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-404284,OU=B11-206OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-404284",
                          "Description": "Lektor B11/206",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=F01-530OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "F01-530OU",
                      "Description": "W10 - F01/530",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-461572,OU=F01-530OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-461572",
                          "Description": "F01-1/530 - Lektor",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=B11-211OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "B11-211OU",
                      "Description": "B11/211",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-404285,OU=B11-211OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-404285",
                          "Description": "Lektor B11/211",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=B11-228OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "B11-228OU",
                      "Description": "B11/228",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-404276,OU=B11-228OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-404276",
                          "Description": "Lektor B11/228",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=B11-234OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "B11-234OU",
                      "Description": "B11/234",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-404272,OU=B11-234OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-404272",
                          "Description": "Lektor B11/234",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=B11-235OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "B11-235OU",
                      "Description": "B11/235",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-404269,OU=B11-235OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-404269",
                          "Description": "Lektor B11/235",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=F01-509OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "F01-509OU",
                      "Description": "F01/509",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-470184,OU=F01-509OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-470184",
                          "Description": "Lektor F01/509",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=B11-236OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "B11-236OU",
                      "Description": "B11/236",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-404279,OU=B11-236OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-404279",
                          "Description": "Lektor B11/236",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=F01-535OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "F01-535OU",
                      "Description": "F01/535",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-407054,OU=F01-535OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-407054",
                          "Description": "Lektor F01/535",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=B11-305OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "B11-305OU",
                      "Description": "B11/305",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-404273,OU=B11-305OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-404273",
                          "Description": "Lektor B11/305",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=B11-306OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "B11-306OU",
                      "Description": "B11/306",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-404280,OU=B11-306OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-404280",
                          "Description": "Lektor B11/306",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=B11-311OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "B11-311OU",
                      "Description": "B11/311",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-404278,OU=B11-311OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-404278",
                          "Description": "Lektor B11/311",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=B07-214OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "B07-214OU",
                      "Description": "B07/214 - Azure cielo manager",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-406974,OU=B07-214OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-406974",
                          "Description": "Lektor B07/214",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=C13-332OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "C13-332OU",
                      "Description": "C13/332",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-407144,OU=C13-332OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-407144",
                          "Description": "Lektor C13/332",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=B11-327OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "B11-327OU",
                      "Description": "B11/327",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-404282,OU=B11-327OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-404282",
                          "Description": "Lektor B11/327",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=B11-333OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "B11-333OU",
                      "Description": "B11/333 - PyMol",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-404288,OU=B11-333OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-404288",
                          "Description": "Lektor B11/333",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=F01-320OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "F01-320OU",
                      "Description": "F01/320",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-N-403846,OU=F01-320OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-N-403846",
                          "Description": "Lektor F01/320",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=B11-334OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "B11-334OU",
                      "Description": "B11/334",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-404287,OU=B11-334OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-404287",
                          "Description": "Lektor B11/334",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=F01-327OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "F01-327OU",
                      "Description": "F01/327",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-N-457050,OU=F01-327OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-N-457050",
                          "Description": "Lektor F01/327",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=B11-335OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "B11-335OU",
                      "Description": "B11/335",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-404283,OU=B11-335OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-404283",
                          "Description": "Lektor B11/335",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=F01-609OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "F01-609OU",
                      "Description": "F01/609",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-455501,OU=F01-609OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-455501",
                          "Description": "Lektor F01/609",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=C14-207OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "C14-207OU",
                      "Description": "C14/207",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-408152,OU=C14-207OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-408152",
                          "Description": "Lektor C14/207",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=A19-115OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "A19-115OU",
                      "Description": "A19/115",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-51695,OU=A19-115OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-51695",
                          "Description": "Lektor A19/115 - ZP",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=A08-309OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "A08-309OU",
                      "Description": "A08/309",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-408149,OU=A08-309OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-408149",
                          "Description": "Lektor A08/309",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=C12-311OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "C12-311OU",
                      "Description": "C12/311",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-408150,OU=C12-311OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-408150",
                          "Description": "Lektor C12/311",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=D33-103OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "D33-103OU",
                      "Description": "D33/103",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-403757,OU=D33-103OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-403757",
                          "Description": "Lektor D33/103",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=D33-227OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "D33-227OU",
                      "Description": "D33/227",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-418740,OU=D33-227OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-418740",
                          "Description": "Lektor D33/227",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=D33-331OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "D33-331OU",
                      "Description": "D33/331",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-403933,OU=D33-331OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-403933",
                          "Description": "Lektor D33/331",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=E34-225OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "E34-225OU",
                      "Description": "E34/225",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-403941,OU=E34-225OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-403941",
                          "Description": "Lektor E34/225",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=A19-112OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "A19-112OU",
                      "Description": "A19/112 - REZERVA",
                      "OrganizationalUnits": [],
                      "Computers": [],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=B07-205OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "B07-205OU",
                      "Description": "B07/205 - Azure cielo manager",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-406973,OU=B07-205OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-406973",
                          "Description": "Lektor B07/205",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=C15-227OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "C15-227OU",
                      "Description": "W10 - C15/227 - Brave",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-404277,OU=C15-227OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-404277",
                          "Description": "Lektor C15/227",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=B09-1NPOU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "B09-1NPOU",
                      "Description": "B09/1NP - konferencni cast knihovny",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-404270,OU=B09-1NPOU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-404270",
                          "Description": "B09/1NP - konferencni cast knihovny",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=C15-211OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "C15-211OU",
                      "Description": "W10 - C15/211",
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UKB-D-474840,OU=C15-211OU,OU=Lektor,OU=UKB,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UKB-D-474840",
                          "Description": "Lektor C15/211",
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    }
                  ],
                  "Computers": [],
                  "Type": "OrganizationalUnit"
                }
              ],
              "Computers": [],
              "Type": "OrganizationalUnit"
            },
            {
              "DistinguishedName": "OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
              "Name": "ICS",
              "Description": null,
              "OrganizationalUnits": [
                {
                  "DistinguishedName": "OU=Telc,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "Telc",
                  "Description": null,
                  "OrganizationalUnits": [
                    {
                      "DistinguishedName": "OU=Ucebna-014,OU=Telc,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Ucebna-014",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=ICS-D-UCT01401,OU=Ucebna-014,OU=Telc,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-UCT01401",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-UCT01402,OU=Ucebna-014,OU=Telc,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-UCT01402",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=Ucebna-210,OU=Telc,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Ucebna-210",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=ICS-D-UCT21001,OU=Ucebna-210,OU=Telc,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-UCT21001",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-UCT21002,OU=Ucebna-210,OU=Telc,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-UCT21002",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-UCT21003,OU=Ucebna-210,OU=Telc,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-UCT21003",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-UCT21004,OU=Ucebna-210,OU=Telc,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-UCT21004",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-UCT21005,OU=Ucebna-210,OU=Telc,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-UCT21005",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-UCT21006,OU=Ucebna-210,OU=Telc,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-UCT21006",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-UCT21007,OU=Ucebna-210,OU=Telc,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-UCT21007",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-UCT21008,OU=Ucebna-210,OU=Telc,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-UCT21008",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-UCT21009,OU=Ucebna-210,OU=Telc,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-UCT21009",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-UCT21010,OU=Ucebna-210,OU=Telc,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-UCT21010",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-UCT21011,OU=Ucebna-210,OU=Telc,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-UCT21011",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-UCT21012,OU=Ucebna-210,OU=Telc,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-UCT21012",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-UCT21013,OU=Ucebna-210,OU=Telc,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-UCT21013",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-UCT21014,OU=Ucebna-210,OU=Telc,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-UCT21014",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-UCT21015,OU=Ucebna-210,OU=Telc,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-UCT21015",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-UCT21016,OU=Ucebna-210,OU=Telc,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-UCT21016",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-UCT21017,OU=Ucebna-210,OU=Telc,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-UCT21017",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-UCT21018,OU=Ucebna-210,OU=Telc,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-UCT21018",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-UCT21019,OU=Ucebna-210,OU=Telc,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-UCT21019",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-UCT21020,OU=Ucebna-210,OU=Telc,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-UCT21020",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-UCT21021,OU=Ucebna-210,OU=Telc,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-UCT21021",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-UCT21022,OU=Ucebna-210,OU=Telc,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-UCT21022",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-UCT21023,OU=Ucebna-210,OU=Telc,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-UCT21023",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-UCT21024,OU=Ucebna-210,OU=Telc,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-UCT21024",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=Prezentacni,OU=Telc,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Prezentacni",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=ICS-D-UCTP020,OU=Prezentacni,OU=Telc,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-UCTP020",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-UCTP022,OU=Prezentacni,OU=Telc,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-UCTP022",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-UCTP025,OU=Prezentacni,OU=Telc,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-UCTP025",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-UCTP109,OU=Prezentacni,OU=Telc,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-UCTP109",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-UCTP110,OU=Prezentacni,OU=Telc,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-UCTP110",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-UCTP111,OU=Prezentacni,OU=Telc,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-UCTP111",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-UCTP112,OU=Prezentacni,OU=Telc,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-UCTP112",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-UCTP115,OU=Prezentacni,OU=Telc,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-UCTP115",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-UCTP117,OU=Prezentacni,OU=Telc,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-UCTP117",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-UCTP205,OU=Prezentacni,OU=Telc,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-UCTP205",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-UCTP208,OU=Prezentacni,OU=Telc,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-UCTP208",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-UCTP210,OU=Prezentacni,OU=Telc,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-UCTP210",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-UCTP211,OU=Prezentacni,OU=Telc,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-UCTP211",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-UCTP218,OU=Prezentacni,OU=Telc,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-UCTP218",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-UCTP219,OU=Prezentacni,OU=Telc,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-UCTP219",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    }
                  ],
                  "Computers": [],
                  "Type": "OrganizationalUnit"
                },
                {
                  "DistinguishedName": "OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                  "Name": "CPS",
                  "Description": null,
                  "OrganizationalUnits": [
                    {
                      "DistinguishedName": "OU=ucebna2,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ucebna2",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=ICS-D-CPS076,OU=ucebna2,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS076",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS043,OU=ucebna2,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS043",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS071,OU=ucebna2,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS071",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS052,OU=ucebna2,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS052",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS040,OU=ucebna2,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS040",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS073,OU=ucebna2,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS073",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS028,OU=ucebna2,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS028",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS037,OU=ucebna2,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS037",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS056,OU=ucebna2,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS056",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS060,OU=ucebna2,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS060",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS066,OU=ucebna2,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS066",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS036,OU=ucebna2,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS036",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS070,OU=ucebna2,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS070",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS027,OU=ucebna2,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS027",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS042,OU=ucebna2,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS042",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS050,OU=ucebna2,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS050",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS047,OU=ucebna2,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS047",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS029,OU=ucebna2,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS029",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS078,OU=ucebna2,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS078",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS079,OU=ucebna2,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS079",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS054,OU=ucebna2,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS054",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS032,OU=ucebna2,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS032",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS033,OU=ucebna2,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS033",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS077,OU=ucebna2,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS077",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS041,OU=ucebna2,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS041",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS026,OU=ucebna2,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS026",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS069,OU=ucebna2,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS069",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS063,OU=ucebna2,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS063",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS074,OU=ucebna2,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS074",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS075,OU=ucebna2,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS075",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS064,OU=ucebna2,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS064",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS038,OU=ucebna2,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS038",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS049,OU=ucebna2,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS049",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS025,OU=ucebna2,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS025",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS057,OU=ucebna2,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS057",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS044,OU=ucebna2,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS044",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS053,OU=ucebna2,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS053",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS058,OU=ucebna2,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS058",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS031,OU=ucebna2,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS031",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS046,OU=ucebna2,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS046",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS048,OU=ucebna2,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS048",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS034,OU=ucebna2,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS034",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS045,OU=ucebna2,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS045",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS067,OU=ucebna2,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS067",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS051,OU=ucebna2,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS051",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS035,OU=ucebna2,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS035",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS062,OU=ucebna2,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS062",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS061,OU=ucebna2,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS061",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS059,OU=ucebna2,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS059",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS072,OU=ucebna2,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS072",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS055,OU=ucebna2,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS055",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS039,OU=ucebna2,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS039",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS065,OU=ucebna2,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS065",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS068,OU=ucebna2,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS068",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS030,OU=ucebna2,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS030",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=Virtuals,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "Virtuals",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=UPS15,OU=Virtuals,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "UPS15",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=ucebna3,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ucebna3",
                      "Description": null,
                      "OrganizationalUnits": [],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=ICS-D-CPS096,OU=ucebna3,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS096",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS112,OU=ucebna3,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS112",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS090,OU=ucebna3,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS090",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS106,OU=ucebna3,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS106",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS111,OU=ucebna3,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS111",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS113,OU=ucebna3,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS113",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS105,OU=ucebna3,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS105",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS109,OU=ucebna3,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS109",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS081,OU=ucebna3,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS081",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS087,OU=ucebna3,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS087",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS083,OU=ucebna3,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS083",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS097,OU=ucebna3,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS097",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS086,OU=ucebna3,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS086",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS085,OU=ucebna3,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS085",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS080,OU=ucebna3,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS080",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS084,OU=ucebna3,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS084",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS115,OU=ucebna3,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS115",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS103,OU=ucebna3,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS103",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS088,OU=ucebna3,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS088",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS092,OU=ucebna3,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS092",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS101,OU=ucebna3,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS101",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS110,OU=ucebna3,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS110",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS091,OU=ucebna3,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS091",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS098,OU=ucebna3,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS098",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS102,OU=ucebna3,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS102",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS100,OU=ucebna3,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS100",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS082,OU=ucebna3,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS082",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS104,OU=ucebna3,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS104",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS089,OU=ucebna3,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS089",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS107,OU=ucebna3,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS107",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS095,OU=ucebna3,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS095",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS094,OU=ucebna3,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS094",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS093,OU=ucebna3,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS093",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS108,OU=ucebna3,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS108",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS099,OU=ucebna3,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS099",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS114,OU=ucebna3,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS114",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    },
                    {
                      "DistinguishedName": "OU=ucebna1,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                      "Name": "ucebna1",
                      "Description": null,
                      "OrganizationalUnits": [
                        {
                          "DistinguishedName": "OU=Test,OU=ucebna1,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "Test",
                          "Description": null,
                          "OrganizationalUnits": [],
                          "Computers": [],
                          "Type": "OrganizationalUnit"
                        }
                      ],
                      "Computers": [
                        {
                          "DistinguishedName": "CN=ICS-D-CPS013,OU=ucebna1,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS013",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS009,OU=ucebna1,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS009",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS023,OU=ucebna1,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS023",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS020,OU=ucebna1,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS020",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS006,OU=ucebna1,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS006",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS003,OU=ucebna1,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS003",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS015,OU=ucebna1,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS015",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS021,OU=ucebna1,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS021",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS014,OU=ucebna1,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS014",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS004,OU=ucebna1,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS004",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS017,OU=ucebna1,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS017",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS019,OU=ucebna1,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS019",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS005,OU=ucebna1,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS005",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS008,OU=ucebna1,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS008",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS024,OU=ucebna1,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS024",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS022,OU=ucebna1,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS022",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS012,OU=ucebna1,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS012",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS016,OU=ucebna1,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS016",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS010,OU=ucebna1,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS010",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS002,OU=ucebna1,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS002",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS018,OU=ucebna1,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS018",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS001,OU=ucebna1,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS001",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS011,OU=ucebna1,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS011",
                          "Description": null,
                          "Type": "Computer"
                        },
                        {
                          "DistinguishedName": "CN=ICS-D-CPS007,OU=ucebna1,OU=CPS,OU=ICS,OU=ClassRooms,OU=Computers,OU=Devices,DC=ucn,DC=muni,DC=cz",
                          "Name": "ICS-D-CPS007",
                          "Description": null,
                          "Type": "Computer"
                        }
                      ],
                      "Type": "OrganizationalUnit"
                    }
                  ],
                  "Computers": [],
                  "Type": "OrganizationalUnit"
                }
              ],
              "Computers": [],
              "Type": "OrganizationalUnit"
            }
          ],
          "Computers": [],
          "Type": "OrganizationalUnit"
        }
      ];

}