import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MenuService } from "../../services/menu/menu.service";
import {
  isExternalLink,
  isMenuCategory,
  isMenuSection,
  menuContents
} from "../../services/menu/menu.interface";
import { AuthService } from "../../services/auth/auth.service";

@Component({
  selector: 'smart-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MainMenuComponent implements OnInit {

  @Input() collapsed: boolean = false;

  contents?: menuContents;
  width: number = 200;

  // republish functions for template
  public isMenuSection  = isMenuSection;
  public isMenuCategory = isMenuCategory;
  public isExternalLink = isExternalLink;

  constructor(
    private menuService: MenuService,
    public auth: AuthService
  ) {
    this.menuService.getMainMenu().subscribe( menuContents => this.contents = menuContents );
  }

  ngOnInit(): void { }

}
