export default {
  'generic': {
    'add': 'add',
    'author': 'author',
    'type': 'type',
    'name': 'name',
    'edit': 'edit',
    'cancel': 'cancel',
    'save': 'save',
    'delete': 'delete',
    'back': 'back',
    'try_again': 'try again',
    'search': 'search',
    'email': 'e-mail',
    'and': ' and ',
    'nobody': 'nobody',
    'anybody': 'anybody',
    'everybody': 'everybody',
    'nothing': 'nothing',
    'loading': 'loading…',
    'unknown': 'unknown',
    'person': 'person',
    'group': 'group',
    'from': 'from',
    'to': 'to',
    'days': {
      'sunday': 'sunday',
      'monday': 'monday',
      'tuesday': 'tuesday',
      'wednesday': 'wednesday',
      'thursday': 'thursday',
      'friday': 'friday',
      'saturday': 'saturday',
    },
    'language': 'language',
    'languages': {
      'english': 'English',
      'czech': 'Czech',
    },
    'uin': 'UCO',
  }, // 'generic'

  'footer': {
    'mu': 'Masaryk University',
    'ics': 'Institute of Computer Science',
    'releasenotes': 'Release notes',
    'documentation': 'Documentation',
    'team': 'Smart team',
  },

  'notifications': {
    'error': 'Error',
    'httpError': 'Error {statusCode}',
    'serviceError': 'Connected service error', // TODO: Add the name of the service
    'success': 'Success'
  },  // 'notifications'

  /* Core messages */
  'home': 'Home',
  'smart': {
    'statusTitle': 'SMART System Status',
    'connectors': 'Connectors',
    'tasks': {
      'running': 'Running tasks',
    },
  },
  'error': {
    'title': 'Error',
    '0': {
      'title': 'Undefined Error',
      'explanation': 'Blocked by CORS or firewall?',
    },
    '400': {
      'title': 'Bad Request',
    },
    '401': {
      'title': 'Unauthenticated',
      'header': 'Who do you say you are?',
      'explanation': 'Your authentication probably expired and now we cannot be sure who we\'re dealing with. Please log in again.',
    },
    '403': {
      'title': 'Forbidden',
      'header': 'Whoa! What are you doing here?!',
      'explanation': 'You really aren\'t supposed to be here. We took a note about your mischief and will be watching you closely.',
    },
    '404': {
      'title': 'Not Found',
      'header': 'Oops! We really cannot find this page.',
      'explanation': 'So now we really don\'t know what to show you. The requested page does not exist or has been removed.',
    },
    '405': {
      'title': 'Not Allowed',
    },
    '500': {
      'title': 'Internal Server Error',
      'header': 'Oops! Something went terribly wrong.',
      'explanation': 'Even we aren\'t sure what blew up in our faces. Bear with us, we\'ll figure it out and get it back on.',
    },
    '504': {
      'title': 'Gateway Timeout',
    },
  }, // 'error'
  'login': {
    'title': 'Log in',
    'welcome': 'Welcome to the {appName} application.',
    'description': 'Automation of IT service management',
    'cardTitle': 'Select your login method',
  }, // 'login'
  'profile': {
    'title': 'User profile',
    'phone': 'Phone',
    'position': 'Position',
    'role': 'Role',
    'show': 'Show profile',
    'logout': 'Log out',
  }, // 'profile'
  'user': {
    'tasks': {
      'title': 'My tasks',
      'tooltip-no-active': 'No active tasks',
      'detail': 'Task detail',
      'active': 'Active tasks',
      'completed': 'Completed tasks',
      'failed': 'Failed tasks',
      'all': 'All tasks',
      'cancel': 'Cancel task',
      'retry': 'Retry task',
      'status': {
        'title': 'Status',
        'CREATED': 'Created',
        'SMART_UNAVAILABLE': 'SMART server unavailable',
        'REQUEST_ERROR': 'Request failed',
        'REQUESTED': 'Validating',
        'PROCESSING': 'Processing',
        'QUEUED': 'Enqueued',
        'STARTING': 'Starting',
        'RUNNING': 'Running',
        'COMPLETED': 'Completed',
        'FAILED': 'Failed',
        'CANCEL_REQUESTED': 'Cancel requested',
        'TERMINATING': 'Cancelling',
        'CANCELLED': 'Cancelled',
        'NOTFOUND': 'Not found',
        'UNKNOWN': 'Unknown',
      },
      'created': 'Created',
      'lastUpdate': 'Last update',
      'priority': {
        'title': 'Priority',
        'LOW': 'Low',
        'NORMAL': 'Normal',
        'HIGH': 'High',
        'CRITICAL': 'Critical',
        'REALTIME': 'Realtime',
      },
      'endpoint': 'Endpoint',
      'credential': 'Credential',
      'command': {
        'title': 'Command',
        'parameters': 'Parameters',
      },
      'owner': 'Owner',
      'group': 'Group',
      'source': {
        'local': 'local',
        'online': 'online',
      },
      'localTasksTitle': 'Local Tasks',
      'onlineTasksTitle': 'Server Tasks',
    },
    'menu': {
      'help': 'Help',
    },
    'profile': {
      'active': 'Active profile',
      'notes': 'Notes',
      'studies': 'Studies',
      'employment': 'Employment',
    },
    'notifications': {
      'invalidUCO': 'The requested uco {uco} is invalid.',
    }
  }, // 'user'
  'bookmarks': {
    'tip': 'Bookmarks',
    'add': 'Add the page to bookmarks',
    'remove': 'Remove the page from bookmarks',
    'no-items': 'No bookmarks yet',
  }, // 'favorite'
  'settings': {
    'title': 'Settings',
    'language': 'Language',
    'appearance': {
      'title': 'Appearance',
      'theme': 'Theme',
      'light': 'Light',
      'dark': 'Dark',
      'system': 'System',
      'accentColor': 'Accent color',
    }
  }, // 'settings'
  'audit': {
    'title': "Audit",
    'report': "Reports",
    'operations': "Operations log",
  }, // 'audit'
  'uiOptions': {
    'title': 'Application UI Settings',
    'tooltip': 'UI Settings',
    'dark.tooltip': 'Dark / Light mode switch',
    'menuLayout': 'Menu Layout',
    'menuLayout.left': 'Left Menu',
    'menuLayout.top': 'Top Menu',
    'animation.title': 'Page Switch Animation',
    'animation.none': 'None',
    'animation.slideUp': 'Slide Up',
    'animation.slideDown': 'Slide Down',
    'animation.fadeIn': 'Fade In',
    'animation.zoom': 'Zoom',
    'leftMenuWidth': 'Left Menu Width',
    'otherOptions': 'Other Options',
    'colorOptions': 'Color Options',
    'colorOptions.accent': 'Accent Color',
    'colorOptions.accent.reset': 'Reset',
    'leftMenuShadow': 'Left Menu Shadow',
    'contentFull': 'Content Fill',
    'narrow': 'Narrow Screen',
    'contrast': 'Gray backg. (light only)',
    'squares': 'Squared edges',
    'shadows': 'Element Shadows',
  }, // 'uiOptions'

  /* Main modules */
  'assets': {
    'title': 'Assets',
    'manage': 'Asset management',
    'credentials': 'Accounts and tokens',
    'key': 'Key',
    'value': 'Value',
    'addCredential': 'Add Credential',
    'endpoints': 'Endpoints',
    'url': 'URL',
    'addEndpoint': 'Add Endpoint',
  },
  'processes': {
    'title': 'Processes',
    'manage': 'Process management',
    'overview': 'Processes overview',
    'add': 'Add process',
    'addWizard': 'Add new process wizard',
  }, // 'processes'
  'services': {
    'title': 'Services',
    'overview': 'Services overview',
  }, // 'services'
  'tasks': {
    'title': 'Tasks',
    'manage': 'Task management',
    'overview': 'Tasks overview',
    'add': {
      'title': 'Add task',
      'wizardTitle': 'Add new task wizard',
      'basics': 'Basic information',
      'type': 'Task type',
      'inputs': 'Inputs',
      'outputs': 'Outputs',
      'extra': 'Extra options',
      'acl': 'Permissions',
    },
  }, // 'user-tasks'

  /* Extra modules */
  'events': {
    'title': 'Events'
  },
  'adminTools': {
    'title': 'Administration tools',
    'persons': {
      'title': 'Persons',
      'banning': {
        'title': 'Service banning',
        'person': 'Person',
        'service': 'Service',
        'start': 'Start',
        'expiration': 'Expiration',
        'creator': 'Initiator',
        'createBan': {
          'title': 'Create ban',
          'target': 'UČO',
          'service': 'Service',
          'reason': 'Offence',
          'expiration': 'Valid to (inclusive)',
          'successNotificationMsg': 'The user {uco} has been banned from service {service}.',
          'errorNotificationMsg': 'Ban creation failed with an unknown error.'
        },
        'filterHint': 'Filter bans by UČO',
        'activeFilter': 'Active filter',
        'state': {
          'title': 'State',
          'Running': 'Active',
          'Terminated': 'Terminated',
          'Expired': 'Expired',
        },
        'banReason': 'Ban reason',
        'terminationReason': 'Termination reason',
        'terminationReasonHint': 'Enter the reason for ban termination',
        'terminationSubmit': 'Terminate ban',
        'terminationSuccessNotification': 'The ban of user {uco} on service {service} has been terminated.',
      },
      "fslogix": {
        "title": "FSLogix profiles",
        "titleFull": "FSLogix travel profiles",
        "findProfile": "Search profile",
        "findProfilePlaceholder": "Enter the UCO",
        "profileLoadPlaceholder": "Begin by looking up the profile by UCO.",
        "profileTitle": "FSLogix profile",
        "profileNotFound": "Profile {uco} not found",
        "connectedOn": "Connected on",
        "resetProfile": "Reset profile",
        "openedProfileResetWarning": "Cannot reset an opened profile.",
      }, // FSLogix
    },
    'm365': {
      'title': 'Microsoft 365',
      'securityGroups': {
        'title': 'Group moderation',
        'searchHint': 'Group name or e-mail fragment',
        'groupIdentifier': 'Group ID',
        'groupName': 'Group name',
        'groupEmail': 'Primary e-mail',
        'owner': 'Owner',
        'moderated': 'Moderated',
        'moderatedTooltip': 'In moderated groups, each message must be approved by a moderator (except for messages from unmoderated users or groups).',
        'moderators': 'Moderators',
        'moderatorMissingError': 'At least one moderator has to be present for moderated groups.',
        'senders': 'Allowed senders',
        'sendersTooltip': 'Users who are allowed to send messages to the group. If not set, it is allowed to everyone.',
        'unmoderated': 'Unmoderated',
        'users': '{userCnt, plural, =0{} one{1 user} other{# users}}',
        'groups': '{groupCnt, plural, =0{} one{1 group} other{# groups}}',
        'addPerson': 'Add user',
        'addGroup': 'Add group',
        'addModerator': 'Add moderator',
        'addSender': 'Add sender',
        'addUnmoderated': 'Add unmoderated sender',
      },
      'sharepoint': {
        'title': 'SharePoint',
        'searchHint': 'Enter the full SharePoint URL or fragment after teams/sites',
        'url': 'SharePoint URL',
        'storageTitle': 'Title',
        'searchTextEmptyError': 'Search text cannot be empty',
        'searchIn': 'Search',
        'standaloneSites': 'standalone SharePoints',
        'connectedSites': 'team, M365 group or Engage community SharePoints',
        'usage': 'Usage',
        'usageTooltip': '{usage}MB used',
        'warning': 'Warning level',
        'disabled': 'Disabled',
        'quota': 'Quota',
        'sharing': 'Sharing',
        'sharingCapabilities': {
          'Disabled': 'Don\'t share outside organisation',
          'ExistingExternalUserSharingOnly': 'Share with existing external users',
          'ExternalUserSharingOnly': 'Share with logged-in external users',
          'ExternalUserAndGuestSharing': 'Share with anyone by link (anonymously)',
        },
        'durationWarning': 'The search for storages is a time-consuming operation that can take several minutes.',
      }
    }, // m365
    'study-rooms': {
      'title': 'Study rooms',
      'schedule': {
        'title': 'Schedules',
        'studyroom': 'Study room',
        'schedules': 'Schedules',
        'schedule': 'Schedule',
        'closures': 'Closures',
        'closure': 'Closure',
        'curentlyClosedMsg': 'This study room is currently closed.',
        'openStudyroom': 'End the closure',
        'forever': 'forever',
        'addSchedule': 'Add schedule',
        'newSchedule': 'New schedule',
        'addClosure': 'Add closure',
        'deleteInterval': 'Delete this interval',
        'emptyScheduleError': 'The schedule must not be empty. To close the study room, use the closure instead.',
        'useCurrentForEmpty': 'Use current schedule for empty days.',
        'time-limited': 'Time-limited',
        'unlimited': 'Unlimited',
        'default': 'Default',
        'valid-from': 'Valid from',
        'closurePastError': 'The closure must start in the future.',
        'midnightInfo': 'The validity will be set until midnight (24:00).',
        'validityEmpty': 'The schedule must have defined validity period.',
        'notifications': {
          'unknownValidityMode': 'The validity mode is not recognised, the schedule could not be created.',
          'newClosureSuccess': 'The new closure was successfully created.',
          'closureDeleteSuccess': 'The closure was deleted.',
          'closureDeleteError': 'The closure could not be deleted as the ID was not found.',
          'openStudyroomSuccess': 'The studyroom closure was ended.',
          'openStudyroomError': 'No active closure of the study room was found.',
          'newScheduleSuccess': 'The new schedule was successfully created.',
          'pastScheduleError': 'Cannot delete the currently active or past schedule.',
          'missingId': 'Missing identification number of the schedule.',
          'scheduleDeleteSuccess': 'The schedule was deleted.',
          'scheduleDeleteError': 'The schedule could not be deleted.',
        },
        'tooltips': {
          'deleteDefaultSchedule': 'The previous schedule will be extended indefinitely',
        }
      },
      'reservation': {
        'title': 'Reservations',
        'locality': 'Locality',
        'studyroom': 'Studyroom',
        'addReservation': 'New reservation',
        'localityReservations': 'Reservations of the whole locality',
        'studyroomReservations': 'Reservations of studyrooms',
        'noLocalitySelected': 'No locality selected',
        'from': 'From',
        'to': 'To',
        'note': 'Purpose',
        'createReservationTitle': 'Create a new reservation',
        'multi-day': 'Multi-day',
        'date': 'Date',
        'time': 'Time',
        'create': 'Reserve',
        'noteValidationError': 'The purpose has to be at least 3 characters long.',
        'timeTooltip': 'If no time is selected, the whole day will be reserved.',
        'timeValidationError': 'Must start in the future and last at least 10 minutes.',
        'endReservation': 'End now',
      },
      'occupation': {
        'shortTitle': 'Occupation',
        'title': 'Study room occupation',
        'filterLocalities': 'Filter localities',
        'availableComputers': 'Available computers',
        'computerStatus': {
          'PowerOff': 'The computer is powered down',
          'PowerOn': 'The computer is running',
          'LoggedIn': 'The user {uco} is logged in',
          'UserLeft': 'The logged user {uco} has left the study room',
          'MultiLog': 'The user {uco} is logged in multiple computers',
          'External': 'An external user is logged in',
          'Maintenance': 'Computer is undergoing maintenance',
          'unknown': 'An unknown computer status: {status}'
        },
      },
    }, // Study rooms
    'libraries': {
      'title': 'Libraries',
      'sponsoring': {
        'title': 'Account sponsoring',
        'searchHint': 'Search account by name, UČO, or e-mail',
        'tabSearch': 'Search accounts',
        'tabSponsoredByMe': 'Accounts sponsored by me',
        'initPlaceholder': 'Start by searching for accounts',
        'sponsors': 'Sponsors',
        'activeSponsors': 'Active sponsors',
        'libraryServices': {
          'title': 'Group membership',
          'tooltip': 'Expiration of the membership in the MU Library Guests group',
          'unlimited': 'Unlimited',
          'notMember': 'Not a member',
        },
        'account': {
          'name': 'Name',
          'status': {
            'title': 'Status',
            'active': 'Active',
            'expired': 'Expired',
          },
          'resetPassword': 'Reset password',
          'resetPwdGuestsOnly': 'This feature is available only for public accounts. Alumni must reset their password in the IS MU system.'
        },
        'createPublic': {
          'title': 'Create a public account',
          'buttonTxt': 'Create account',
          'titleBefore': 'Title (before n.)',
          'firstName': 'First name',
          'surname': 'Surname',
          'nameError': 'The name has to be set.',
          'titleAfter': 'Title (after n.)',
          'emailError': 'Enter a valid e-mail.',
          'languageError': 'Unknown language.',
          'expiration': 'Validity',
          'expirationError': 'Account expiration has to be set.'
        },
        'sponsorAccount': {
          'buttonTxt': 'Sponsor',
          'editButtonTxt': 'Edit',
          'editButtonTxtLong': 'Edit my sponsorship',
          'mySponsorship': 'My sponsorship',
          'sponsorUntil': 'Sponsor until'
        },
        'notifications': {
          'passwordResetSuccess': 'The password reset mail has been sent successfully.',
          'sponsorSuccess': 'The user account was successfully sponsored.',
          'expirationInPast': 'The sponsoring expiration cannot be in the past.',
        }
      }
    }, // Libraries
  }, // 'adminTools'
  'externalSystems': {
    'title': 'Externí systémy',
    'security': {
      title: 'Security'
    },
    'identity': {
      title: 'Identity'
    },
    'infrastructure': {
      title: 'Infrastructure'
    },
    'web': {
      title: 'Websites'
    },
  },
}
