import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'smart-new-entity-wizard-inputs',
  templateUrl: './new-entity-wizard-inputs.component.html',
  styleUrls: ['./new-entity-wizard-inputs.component.scss']
})
export class NewEntityWizardInputsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
