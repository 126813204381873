<div>
  <span class="dropdown">
    <a nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu">
      <i class="icon {{isBookmarked() ? 'fa-solid' : 'fa-regular'}} fa-star" nz-tooltip nzTooltipTitle="{{ 'bookmarks.tip' | translate }}" nzTooltipPlacement="bottom"></i>
    </a>
    <nz-dropdown-menu #menu="nzDropdownMenu">
      <ul nz-menu>
        <ng-container *ngIf="getCurrentLink() !== '/'">
          <ng-container *ngIf="!isBookmarked(); else removeItem">
            <li nz-menu-item (click)="addBookmark()">{{ 'bookmarks.add' | translate | firstLetterCapital }}</li>
          </ng-container>
          <ng-template #removeItem>
            <li nz-menu-item (click)="removeBookmark()">{{ 'bookmarks.remove' | translate | firstLetterCapital }}</li>
          </ng-template>

          <li nz-menu-divider></li>
        </ng-container>
        <ng-container *ngFor="let bookmark of bookmarks">
          <li nz-menu-item routerLink="{{ bookmark.link }}">{{ bookmark.name | translate }}</li>
        </ng-container>
        <ng-container *ngIf="bookmarks.length == 0">
          <li nz-menu-item nzDisabled>{{ 'bookmarks.no-items' | translate }}</li>
        </ng-container>
      </ul>
    </nz-dropdown-menu>
  </span>
</div>
