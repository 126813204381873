<div class="copyright">
  © {{currentYear}}
  <a href="https://muni.cz">{{'footer.mu' | translate | firstLetterCapital}}</a>
  <a href="https://www.ics.muni.cz/">{{'footer.ics' | translate | firstLetterCapital}}</a>
</div>

<div class="shortcuts">
  <a href="https://servicedesk-muni.atlassian.net/wiki/spaces/services/pages/821498863/Documentation+for+IT+Community+-+SMART">{{'footer.documentation' | translate | firstLetterCapital}}</a>
  <a href="https://servicedesk-muni.atlassian.net/wiki/spaces/services/pages/985432072/SMART+Release+Notes">{{'footer.releasenotes' | translate | firstLetterCapital}}</a>
  <a href="https://servicedesk-muni.atlassian.net/wiki/spaces/services/pages/959381791/SMART+Development+Team">{{'footer.team' | translate | firstLetterCapital}}</a>
  <a class="ict-mail" href="mailto:it@muni.cz">it&#64;muni.cz</a>
</div>
