import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { tap, withLatestFrom } from "rxjs";
import { TaskFinished, TaskNotificationsSeen, TaskRequestError, TaskServUnavailable } from "./tasks.actions";
import { selectTasksState } from "./tasks.selector";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { TranslateService } from "@ngx-translate/core";
import { TaskFinalStates } from "../../util/task";

@Injectable()
export class TasksEffects {

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
    private readonly translate: TranslateService,
    private readonly notifications: NzNotificationService,
  ) {
  }

  allNotificationsSeen$ = createEffect( () => this.actions$.pipe(
    ofType(TaskNotificationsSeen),
    withLatestFrom(this.store.select(selectTasksState)),
    tap(([_, tasksState]) => {
      for (let uuid in tasksState.tasks) {
        if (TaskFinalStates.has(tasksState.tasks[uuid].status)) {
          this.store.dispatch(TaskFinished({uuid: uuid}));
        }
      }
    })
  ), {dispatch: false});

  serverUnavailable$ = createEffect( () => this.actions$.pipe(
    ofType(TaskServUnavailable),
    withLatestFrom(this.store.select(selectTasksState)),
    tap( ([action, tasksState]) => {
      this.notifications.error(
        tasksState.tasks[action.uuid].name,
        this.translate.instant(action.error)
      );
    })
  ), {dispatch: false});

  requestError$ = createEffect( () => this.actions$.pipe(
    ofType(TaskRequestError),
    withLatestFrom(this.store.select(selectTasksState)),
    tap( ([action, tasksState]) => {
      this.notifications.error(
        tasksState.tasks[action.uuid].name,
        this.translate.instant(action.error)
      );
    })
  ), {dispatch: false});
}
