/**
 * Contains all the API objects of Perun
 */

export namespace perun {

  export const UUID = "d81a8e0a-11e3-4eac-ad64-06294f97ba99";

  export enum EMembersSortColumn {
    NAME = "NAME"
  }

  export enum EOrderDirection {
    ASCENDING = "ASCENDING",
    DESCENDING = "DESCENDING"
  }

  export enum EMembershipStatus {
    VALID = "VALID",
    EXPIRED = "EXPIRED"
  }

  export enum EMembershipType {
    DIRECT = "DIRECT",
    NOT_DEFINED = "NOT_DEFINED"
  }

  export enum ESpecificType {
    NORMAL = "NORMAL"
  }

  export enum EGroupStatus {
    VALID = "VALID"
  }

  export interface IGetMembersParameters {
    "pageSize": number,
    "offset": number,
    "order": EOrderDirection,
    "sortColumn": string,
    "statuses": any[],
    "groupStatuses": any[],
    "searchString": string
  }

  export interface IGetMembersResult {
    "totalCount": number,
    "pageSize": number,
    "offset": number,
    "data": IRichMember[]
  }

  export interface IGetSponsoredMembersResult extends Array<ISponsoredMember>{}

  export interface IPostSponsoredMemberParameters {
    "userData": {
      "titleBefore": string,
      "firstName": string,
      "lastName": string,
      "titleAfter": string,

      "email": string,
      "namespace": string
    },
    "sponsor": number,  // Member ID of the sponsor
    "sendActivationLink": boolean,
    "language": string,
    "validityTo": string  // YYYY-MM-DD
  }

  export interface IPostAddMemberParameters {
    "Member": number,  // UČO of the person (who shall be added to the library group)
  }

  export interface IPutGroupMembershipParameters {
    "Member": number,  // UČO of the person
    "Expiration": string | null // Expiration in the form YYYY-MM-DD
  }

  export interface IPostResetPasswordParameters {
    "Member": number,  // Perun ID of the person whose password shall be reset
    "EmailAttributeURN": string,
  }

  export interface IPostSponsorsParameters {
    "Members": number[],  // List of Perun IDs of the members which shall be sponsored
    "sponsor": number,  // Member ID of the calling person (will be set as sponsor)
    "ValidityTo": string | null,  // Expiration in the form YYYY-MM-DD
  }

  export interface IPutSponsorshipParameters {
    "Member": number,  // List of Perun IDs of the members which shall be sponsored
    "Sponsor": number,  // Member ID of the calling person (will be set as sponsor)
    "ValidityTo": string | null,  // Expiration in the form YYYY-MM-DD
  }

  export interface IDeleteSponsorshipParameters {
    "Member": number,  // Perun IDs of the member whose sponsors shall be edited
    "sponsor": number,  // Sponsor member ID which shall be removed
  }

  export interface IGetSponsorsResult extends Array<ISponsor> {}

  export interface IGetMembershipsResult extends Array<IRichMember> {}

  export interface IRichMember {
    "id": number,  // Perun ID
    "userId": number,  // Member ID
    "voId": number,

    "status": EMembershipStatus,
    "sponsored": boolean,

    "user": IUser,
    "userExtSources": IUserExtSource[],
    "userAttributes": IAttribute[],

    "membershipType": EMembershipType,
    "memberAttributes": IAttribute[],

    "sourceGroupId"?: number,
    "groupStatus": EGroupStatus,
    "groupStatuses": {
      [index: string]: EGroupStatus
    },

    "createdAt": string,
    "createdBy": string,
    "createdByUid": number,

    "modifiedAt": string,
    "modifiedBy": string,
    "modifiedByUid": number,

    "beanName": "RichMember"
  }

  export interface IUser {
    "id": number,  // Member ID
    "uuid": string,

    "firstName": string,
    "lastName": string,
    "middleName"?: string,
    "titleBefore"?: string,
    "titleAfter"?: string,

    "serviceUser": boolean,
    "sponsoredUser": boolean,
    "specificUser": boolean,
    "majorSpecificType": ESpecificType,

    "userExtSources"?: IUserExtSource[],
    "userAttributes"?: IAttribute[],

    "createdAt": string,
    "createdBy": string,
    "createdByUid": number,

    "modifiedAt": string,
    "modifiedBy": string,
    "modifiedByUid": number,

    "beanName": "User"
  }

  export interface IUserExtSource {
    "id": number,
    "userId": number,  // UČO
    "login": string,
    "persistent": boolean,
    "loa": number,
    "extSource": IExtSource,

    "createdAt": string,
    "createdBy": string,
    "createdByUid": number,

    "modifiedAt": string,
    "modifiedBy": string,
    "modifiedByUid": number,

    "lastAccess": string,

    "beanName": "UserExtSource"
  }

  export interface IExtSource {
    "id": number,
    "name": string,
    "type": string,

    "createdAt": string,
    "createdBy": string,
    "createdByUid"?: number,

    "modifiedAt": string,
    "modifiedBy": string,
    "modifiedByUid"?: number,

    "beanName": "ExtSource"
  }

  export interface IAttribute {
    "id": number,
    "namespace": string,
    "entity": string,

    "displayName": string,
    "description": string,
    "friendlyName": string,
    "baseFriendlyName": string,
    "friendlyNameParameter": string,

    "writable": boolean,
    "unique": boolean,

    "value": any,
    "valueCreatedAt"?: string,
    "valueCreatedBy"?: string,
    "valueModifiedAt"?: string,
    "valueModifiedBy"?: string,

    "createdAt": string,
    "createdBy": string,
    "createdByUid"?: number,

    "modifiedAt": string,
    "modifiedBy": string,
    "modifiedByUid"?: number,

    "beanName": "Attribute",
    "type": string  // Java class identifier
  }

  export interface ISponsor {
    "user": IUser,

    "active": boolean,
    "validityTo": string,

    "userExtSources": IUserExtSource[],
    "userAttributes": IAttribute[],
  }

  export interface ISponsoredMember {
    "member": IRichMember,
    "sponsors": ISponsor[]
  }

}


/* Helper objects, not defined by the Perun API */

export interface IPerunAccount {
  pid: number,  // Perun ID
  mid: number,  // Member ID
  uco: number,  // UČO

  active: boolean,

  titleBefore?: string,
  firstName: string,
  middleName?: string,
  lastName: string,
  titleAfter?: string,
  displayName?: string,

  email?: string,

  sponsored: boolean,
  sponsors: IPerunSponsor[],
}

export interface IPerunSponsor {
  mid: number,  // Member ID
  uco: number,  // UČO
  name: string,
  active: boolean,
  expiration: string
}

export interface IPerunMemberInfo {
  mid: number,  // Member ID
  uco: number,  // UČO
  name: string,
}

export interface IPerunMembershipExps {
  [index: number]: {
    "groupStatus": perun.EGroupStatus
    "groupMembershipExpiration": string
  }
}
