/**
 * Contains all the API objects of Perun
 */

export namespace psws {

  export const UUID = "fc652cc2-cf58-4837-98a1-41b1802553a3";

  export enum EPSStatus {
    COMPLETED = "Completed",
    ERROR = "Error"
  }

  export enum EFSLogixProfileStatus {
    OPENED = "Opened",
    CLOSED = "Closed",
    CORRUPTED = "Corrupted"
  }


  export interface IFSLogixProfile {
    "psOutput" : IFSLogixPSOutput,
    "psHistory": IFSLogixHistory
  }

  export interface IFSLogixHistoryRequest {
    "psOutput" : IFSLogixHistory
  }

  export interface IFSLogixPSOutput {
    "Identity": string,
    "ProfileStatus": EFSLogixProfileStatus,
    "ProfileStatusDetail": string,
    "ConnectedOn": string,
    "PsHistory": IFSLogixHistory
  }

  export interface IFSLogixHistory {
    "nextUrl": string,
    "history": IFSLogixHistoryItem[]
  }

  export interface IFSLogixHistoryItem {
    "Initiator": string,
    "Action": string,
    "ProcessedObjectID": string,
    "PSStatus": EPSStatus,
    "TimeStamp": string,
    "RespQ": string,
    "Host": string,
    "DetailMessage": string,
    "InvocationSourceId": number
  }

  export interface IFSLogixHistory {
    "nextUrl": string,
    "history": IFSLogixHistoryItem[]
  }

  export interface IFSLogixHistoryItem {
    "Initiator": string,
    "Action": string,
    "ProcessedObjectID": string,
    "PSStatus": EPSStatus,
    "TimeStamp": string,
    "RespQ": string,
    "Host": string,
    "DetailMessage": string,
    "InvocationSourceId": number
  }


  export interface IFSLogixError {
    "psOutput" : IFSLogixPSOutputError,
    "psHistory": IFSLogixHistory
  }

  export interface IFSLogixPSOutputError {
    "ProcessedObjectID": string,
    "ExceptionType": string,
    "ExceptionMessage": string,
    "InvocationInfo": any,
    "PsHistory": IFSLogixHistory
  }


  // export type IFSLogixResponse = IFSLogixProfile | IFSLogixError;
  export type IFSLogixResponse = IFSLogixProfile | IFSLogixError;

  export function isFSLogixError(object: IFSLogixResponse): object is IFSLogixError {
    return object.psOutput.hasOwnProperty('ExceptionType');
  }


  export function isFSLogixProfile(object: IFSLogixResponse): object is IFSLogixProfile {
    return object.psOutput.hasOwnProperty('Identity');
  }
}
